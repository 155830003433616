import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Icon, useTheme } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { PAGINATION_TABLE_ACTIVITIES } from 'constants/Environment';
import { ACTIVITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { EntityModalActions } from 'actions';

import { getMomentFromDateBackend, getActivityDateFormat } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { getBackendBoolean } from 'utils/fm';
import { logEvent } from 'utils/tracking';

import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={113} height={62} fill="none">
        <path
            fill="#F4F5F6"
            d="M4.694 59.262c3.073 2.226 7.054 2.337 10.902 2.064a79.614 79.614 0 0 0 1.6-.131c.003-.001.007 0 .01-.002l.076-.006.326-.03-.026.048-.08.152c.03-.05.058-.1.087-.151l.026-.046c.997-1.745 1.986-3.554 2.238-5.546.26-2.068-.472-4.41-2.282-5.426a3.642 3.642 0 0 0-.749-.316 3.978 3.978 0 0 0-.329-.084 3.893 3.893 0 0 0-3.766 1.219 3.945 3.945 0 0 0-.786 3.899c-1.534-1.582-1.248-4.15-.719-6.295.53-2.145 1.173-4.532.094-6.459-.6-1.073-1.654-1.738-2.837-2.006l-.109-.023a5.685 5.685 0 0 0-4.104.724C2.003 42.28.84 44.999.464 47.662c-.607 4.286.737 9.069 4.23 11.6Z"
        />
        <path
            fill="#fff"
            d="M5.686 52.392c.09.945.315 1.872.67 2.752a8.49 8.49 0 0 0 1.31 2.15c1.104 1.337 2.564 2.341 4.15 3.01 1.212.5 2.482.843 3.78 1.022.538-.038 1.071-.083 1.6-.131.003-.001.007 0 .01-.002l.076-.006.326-.03-.026.048-.08.152.087-.151.026-.046a8.653 8.653 0 0 1-2.119-3.486 8.693 8.693 0 0 1-.29-4.076 8.744 8.744 0 0 1 1.616-3.726 3.966 3.966 0 0 0-.329-.084 9.05 9.05 0 0 0-.82 1.366 8.903 8.903 0 0 0-.76 5.51 9.074 9.074 0 0 0 2.282 4.517 14.506 14.506 0 0 1-.308-.025c-1.921-.164-3.838-.58-5.58-1.43A10.063 10.063 0 0 1 7.54 56.62c-1.055-1.464-1.52-3.224-1.6-5.014-.084-1.915.163-3.865.597-5.728a24.13 24.13 0 0 1 2.01-5.503.174.174 0 0 0-.07-.228.145.145 0 0 0-.108-.023.127.127 0 0 0-.084.073 24.414 24.414 0 0 0-2.214 6.359c-.382 1.904-.586 3.897-.386 5.837Z"
        />
        <path
            fill="#98D5F1"
            d="M44.642 61.861H.342a.116.116 0 0 1-.116-.117.117.117 0 0 1 .116-.117h44.3a.116.116 0 0 1 .116.117.117.117 0 0 1-.116.117Z"
        />
        <path
            fill="#FFC5AC"
            d="m31.344 30.459-2.003-9.872-.88-7.29-1.95 1.268.27 8.034 3.111 8.325a1.096 1.096 0 0 0 .13 1.208 1.084 1.084 0 0 0 1.16.339 1.085 1.085 0 0 0 .751-.951 1.095 1.095 0 0 0-.59-1.061ZM12.61 31.222l.554-10.06-.184-7.342 2.577.97.43 7.989-1.874 8.691a1.095 1.095 0 0 1-.427 1.635 1.08 1.08 0 0 1-1.192-.197 1.093 1.093 0 0 1 .115-1.686ZM18.007 60.47l1.317-.001.626-5.108h-1.943v5.108Z"
        />
        <path
            fill="#37526C"
            d="M17.67 60.037h2.594c.439 0 .859.175 1.169.486.31.312.484.735.484 1.176v.054h-4.246v-1.716Z"
        />
        <path fill="#FFC5AC" d="m22.995 59.883 1.3.202 1.397-4.952-1.92-.298-.777 5.048Z" />
        <path
            fill="#37526C"
            d="m22.728 59.404 2.563.399a1.65 1.65 0 0 1 1.081.66c.259.356.367.8.3 1.236l-.008.054-4.197-.653.261-1.696ZM17.62 58.944l-.007-.037-4.767-25.923a9.843 9.843 0 0 1 .86-6.124l2.886-5.803 8.61 1.173 1.016 7.389 3.62 14.096-.003.012-4.64 14.82-3.04.202 2.694-13.23-4.932-11.266.348 24.49-2.645.2Z"
        />
        <path
            fill="#FF8C00"
            d="m25.402 23.175-.057-.09c-.01-.015-1.056-1.6-3.902-.542-2.905 1.08-5.156-1.006-5.179-1.027l-.01-.01-.659-5.899-.776-4.788 2.472-.436 1.215-.733 4.834-.087 1.272 1.006 2.088.229.192 4.363-.001.004-1.49 8.01Z"
        />
        <path
            fill="#FF8C00"
            d="M26.095 15.583v-3.736l.587-1.078h.024c.462.017.9.217 1.216.555.59.604.858 1.61.795 2.99v.024l-2.622 1.245ZM15.859 15.177l-2.993-.362-.002-.034c-.103-1.501.158-2.616.774-3.312.559-.632 1.2-.67 1.226-.672h.018l.81.74.167 3.64Z"
        />
        <path
            fill="#37526C"
            d="M24.523 8.058h-7.876a.61.61 0 0 1-.61-.615V4.575c0-1.213.479-2.377 1.332-3.235A4.536 4.536 0 0 1 20.585 0c1.207 0 2.364.482 3.217 1.34a4.589 4.589 0 0 1 1.332 3.235v2.868a.617.617 0 0 1-.61.615Z"
        />
        <path
            fill="#FFC5AC"
            d="M23.695 6.284a3.364 3.364 0 0 0-1.327-4.552 3.325 3.325 0 0 0-4.526 1.334 3.364 3.364 0 0 0 1.326 4.552 3.325 3.325 0 0 0 4.527-1.334Z"
        />
        <path
            fill="#37526C"
            d="M23.938 4.78h-4.403a.339.339 0 0 1-.337-.3l-.057-.458a.205.205 0 0 0-.298-.155.205.205 0 0 0-.088.094l-.29.622a.343.343 0 0 1-.307.197h-.669a.338.338 0 0 1-.322-.234.342.342 0 0 1-.016-.14l.264-2.77a.345.345 0 0 1 .194-.279c2.017-.946 4.062-.945 6.078.004a.34.34 0 0 1 .192.262l.395 2.767a.342.342 0 0 1-.336.39Z"
        />
        <path
            fill="#273C50"
            d="m31.354 31.09-.164-.166 7.146-7.187h13.849l6.987-7.173h13.67l6.897-6.99h32.104v.234H79.836l-6.897 6.99h-13.67l-6.986 7.173h-13.85l-7.079 7.119Z"
        />
        <path
            fill="#fff"
            d="M59.405 21.049c2.376 0 4.302-1.937 4.302-4.327 0-2.39-1.926-4.327-4.302-4.327-2.376 0-4.302 1.938-4.302 4.327 0 2.39 1.926 4.327 4.302 4.327Z"
        />
        <path
            fill="#FF8C00"
            d="M79.869 10.875c.642 0 1.162-.523 1.162-1.169s-.52-1.17-1.162-1.17c-.642 0-1.163.524-1.163 1.17 0 .646.52 1.17 1.163 1.17ZM111.611 10.875c.642 0 1.163-.523 1.163-1.169s-.521-1.17-1.163-1.17-1.163.524-1.163 1.17c0 .646.521 1.17 1.163 1.17ZM95.798 10.875c.642 0 1.163-.523 1.163-1.169s-.52-1.17-1.163-1.17c-.642 0-1.163.524-1.163 1.17 0 .646.52 1.17 1.163 1.17Z"
        />
        <path
            fill="#273C50"
            d="M59.405 21.166a4.4 4.4 0 0 1-2.455-.749 4.44 4.44 0 0 1-1.627-1.994 4.467 4.467 0 0 1 .958-4.843 4.395 4.395 0 0 1 4.815-.963 4.424 4.424 0 0 1 1.983 1.636 4.462 4.462 0 0 1-.551 5.61 4.41 4.41 0 0 1-3.123 1.303Zm0-8.654c-.828 0-1.637.247-2.325.71a4.205 4.205 0 0 0-1.542 1.89 4.233 4.233 0 0 0 .907 4.587 4.164 4.164 0 0 0 4.562.913 4.192 4.192 0 0 0 1.879-1.55 4.227 4.227 0 0 0-.522-5.315 4.179 4.179 0 0 0-2.959-1.235Z"
        />
        <path
            fill="#98D5F1"
            d="M59.405 19.814a3.083 3.083 0 0 0 3.074-3.092 3.083 3.083 0 0 0-3.074-3.091 3.083 3.083 0 0 0-3.074 3.091 3.083 3.083 0 0 0 3.074 3.092Z"
        />
        <path
            fill="#fff"
            d="m59.112 18.027-.92-1.19.535-.418.436.563 1.471-1.562.492.469-2.014 2.138ZM38.244 28.182c2.376 0 4.302-1.937 4.302-4.326 0-2.39-1.926-4.327-4.302-4.327-2.376 0-4.302 1.937-4.302 4.327 0 2.39 1.926 4.326 4.302 4.326Z"
        />
        <path
            fill="#273C50"
            d="M38.244 28.3a4.4 4.4 0 0 1-2.455-.75 4.438 4.438 0 0 1-1.627-1.994 4.468 4.468 0 0 1 .958-4.843 4.395 4.395 0 0 1 4.815-.963 4.424 4.424 0 0 1 1.983 1.637 4.462 4.462 0 0 1-.551 5.61 4.41 4.41 0 0 1-3.123 1.302Zm0-8.654a4.17 4.17 0 0 0-2.326.71 4.205 4.205 0 0 0-1.541 1.888 4.233 4.233 0 0 0 .907 4.588 4.163 4.163 0 0 0 4.562.913 4.192 4.192 0 0 0 1.878-1.55 4.226 4.226 0 0 0-.522-5.315 4.178 4.178 0 0 0-2.958-1.234Z"
        />
        <path
            fill="#98D5F1"
            d="M38.244 26.947a3.083 3.083 0 0 0 3.074-3.091 3.083 3.083 0 0 0-3.074-3.092 3.083 3.083 0 0 0-3.074 3.092 3.083 3.083 0 0 0 3.074 3.091Z"
        />
        <path
            fill="#fff"
            d="m37.95 25.16-.92-1.19.536-.418.435.564 1.472-1.563.492.47-2.014 2.138Z"
        />
    </svg>
);

const ActivitiesDashboardWidget = memo(() => {
    const { openTab } = useEntityDetail();
    const dispatch = useDispatch();
    const theme = useTheme();
    const canCreate = useSelector(
        (state) => state?.config?.permission?.crud_permission?.[ACTIVITIES.permission]?.create,
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncEmailWithNylas = useSelector(
        (state) => state.config?.userData?.syncEmailWithNylas || 0,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();

    const getActivities = useCallback(() => {
        setLoading(true);
        setError(false);

        let filters = {
            date: {
                id: 'date',
                dataType: 'date',
                value: {
                    from: moment().startOf('day'),
                    to: '',
                },
            },
        };

        if (filter?.length) {
            filters.activityType = {
                id: 'activityType',
                dataType: 'singleValueList',
                completeValues: filter,
                value: filter?.map((i) => i.value) || [],
            };
        }

        Context.entityManager.getEntitiesManager(ACTIVITIES).getActivities(
            0,
            PAGINATION_TABLE_ACTIVITIES,
            filters,
            (result) => {
                setLoading(false);
                setList(result || []);
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [filter]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(ACTIVITIES, true));
        return subscribe(`${REFRESH_TABLE}_${ACTIVITIES.entity}`, () => {
            getActivities();
        });
    }, [dispatch, getActivities]);

    useEffect(() => {
        getActivities();
    }, [getActivities]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'list',
        });
        window.open(getRoute(ACTIVITIES.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        dispatch(
            EntityModalActions.init({
                entity: ACTIVITIES,
                data: {},
                labels: {
                    title: getLiteral('title_add_activity'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_activity_salesforce'),
                },
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'create',
        });
    }, [dispatch]);

    const onClick = useCallback(
        (activity) => {
            openTab({
                entity: ACTIVITIES,
                id: activity.Id,
                hasCrudInDetail: true,
                toTab: true,
                info: { activityType: activity.ActivityType },
                avoidRedirects: true,
            });

            logEvent({
                event: 'dashboard',
                submodule: ACTIVITIES.trueName,
                functionality: 'detailView',
            });
        },
        [openTab],
    );

    const onChangeFilter = useCallback((filter) => {
        setFilter(filter);
        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'filter',
        });
    }, []);

    const activityTypes = useMemo(() => {
        const manager = Context.entityManager.getEntitiesManager(ACTIVITIES);
        const filterSchema = manager.getFilterSchema() || [];
        const activityTypeSchemaArr = filterSchema.filter(
            (current) => current.id === 'activityType',
        );
        const field = activityTypeSchemaArr[0] || null;
        if (!field) return [];
        return field.generateOptions() || [];
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];
        if (canCreate)
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_create_new_activity')}
                </Button>,
            );

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncEmailWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => ensureRoute('/settings/nylas')}
                >
                    {getLiteral('action_sync_email')}
                </Button>,
            );
        }

        return actions;
    }, [canCreate, onAdd, syncEmailWithNylas, nylasConfig]);

    const getActivityTypeIcon = useCallback(
        (item) => {
            let icon;

            switch (item.ActivityType) {
                case ACTIVITY_TYPE_ANNOTATION:
                    icon = 'activities';
                    break;
                case ACTIVITY_TYPE_CALL:
                    let isReceived = false;
                    let isMissed = false;
                    if (item.IsReceived === '0' || item.IsReceived === 0) isReceived = true;
                    else if (item.IsReceived === '1' || item.IsReceived === 1) isReceived = false;
                    else isMissed = true;

                    icon = isReceived ? 'phoneIncoming' : 'phoneOutgoing';
                    if (isMissed) {
                        icon = 'phonecallMissed';
                    }
                    break;
                case ACTIVITY_TYPE_CHECKIN:
                    icon = 'accountCheckin';
                    break;
                case ACTIVITY_TYPE_EMAIL:
                    const isSent = getBackendBoolean(item.IsSent);
                    icon = isSent ? 'emailSend' : 'emailReceive';
                    break;
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                    icon = 'opportunityCheckin';
                    break;
                case ACTIVITY_TYPE_FAST_CHECKIN:
                    icon = 'fastCheckin';
                    break;
                case ACTIVITY_TYPE_WORKFLOW:
                    icon = 'workflow';
                    break;
                case ACTIVITY_TYPE_VIDEO_CALL:
                    icon = 'videoCamera';
                    break;
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                    icon = 'videoCheckin';
                    break;
                case ACTIVITY_TYPE_MAILCHIMP:
                    icon = 'mailchimp';
                    break;
                case ACTIVITY_TYPE_ZENDESK:
                    icon = 'zendesk';
                    break;
                case ACTIVITY_TYPE_WHATSAPP:
                    icon = 'whatsappGreen';
                    break;
            }

            return icon ? (
                <Icon name={icon} size="large" color={theme.colors.neutral600} />
            ) : undefined;
        },
        [theme.colors.neutral600],
    );

    const getCompanyName = useCallback((activity) => {
        let accountName = activity.CompanyName;
        if (activity?.Accounts?.length) {
            const finalAccount = activity.Accounts[0];
            accountName = `${finalAccount.Name}`;
            if (finalAccount.Surname) accountName = `${accountName} ${finalAccount.Surname}`;
        }
        return accountName;
    }, []);

    const getType = useCallback((activity) => {
        let type = activity.Type;
        switch (activity.ActivityType) {
            case ACTIVITY_TYPE_EMAIL:
                const isSent = getBackendBoolean(activity.IsSent);
                type = isSent ? getLiteral('label_emailsent') : getLiteral('label_emailreceived');
                break;
            case ACTIVITY_TYPE_CALL:
                let isReceived = false;
                let isMissed = false;
                if (activity.IsReceived === '0' || activity.IsReceived === 0) isReceived = true;
                else if (activity.IsReceived === '1' || activity.IsReceived === 1)
                    isReceived = false;
                else isMissed = true;

                type = isReceived
                    ? getLiteral('label_received_a_call')
                    : getLiteral('label_made_a_call');
                if (isMissed) {
                    type = getLiteral('label_missed_call');
                }

                break;
        }
        return type;
    }, []);

    const rowList = useMemo(
        () =>
            list?.map((i) => (
                <DashboardWidgetRow
                    item={i}
                    onClick={() => onClick(i)}
                    leftColumn={getActivityTypeIcon(i)}
                    title={[getCompanyName(i), getType(i)].filter((i) => i).join(' - ')}
                    subtitle={getActivityDateFormat(
                        getMomentFromDateBackend(i.ActivityDate, null, true),
                        true,
                    )}
                />
            )),
        [getActivityTypeIcon, getCompanyName, getType, list, onClick],
    );

    return (
        <DashboardWidget
            title={getLiteral('title_activities')}
            // Actions
            onViewMore={onViewMore}
            onAdd={canCreate ? onAdd : undefined}
            addTooltip={getLiteral('action_create_new_activity')}
            // Filter
            filterLabel={getLiteral('label_activity_type')}
            filterOptions={activityTypes}
            onChangeFilter={onChangeFilter}
            filterIsMulti
            // State
            isLoading={loading}
            isEmpty={list?.length === 0 && !error}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_screen_activities')}
            emptySubtitle={getLiteral('label_empty_screen_activities_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {rowList}
        </DashboardWidget>
    );
});

export default ActivitiesDashboardWidget;

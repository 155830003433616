import React, { memo, useMemo, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Routes } from 'react-router-dom';
import moment from 'moment';
import { FiltersProvider } from '@web/web5';
import {
    EntityCrudActions,
    AgendaActions,
    EntityActions,
    EntityMapActions,
    EntityListActions,
} from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { RouteOnMount, ensureRoute } from 'utils/routes';
import { AGENDA, TASKS } from 'constants/Entities';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';
import { ENTITY_LIST_SECTION } from 'constants/ActionTypes';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import SyncPopover from 'containers/components/SyncPopover';
import EntityDetail from 'containers/components/EntityDetail';
import Header from './Header';
import Menu from './Menu';
import Content from './Content';
import CONFIG from './EntityConfig';
import AgendaMap from './components/AgendaMap';
import AgendaCrud from './AgendaCrud';
import FiltersRow from './components/FiltersRow';
import AgendaToolbar from './components/AgendaToolbar';
import { AGENDA_EVENT, AGENDA_TASK } from 'constants/Constants';
import { getPreviousAndNextDate } from 'utils/dates';
import { getBackendBoolean } from 'utils/fm';
import { logEvent } from 'utils/tracking';

const mapStateToProps = (state) => {
    const crudPermissions =
        state.config.permission &&
        state.config.permission.crud_permission &&
        state.config.permission.crud_permission.AGENDA;
    const userData = state.config.userData;

    return {
        canCreate: (crudPermissions && crudPermissions.create) || false,
        canEdit: (crudPermissions && crudPermissions.update) || false,
        canDelete: (crudPermissions && crudPermissions.delete) || false,
        section: state.agenda.section,
        login: state.config.login,
        userName: `${userData.nombre} ${userData.apellidos}`,
        userId: userData.idUsuario,
        idImplementation: state.config?.userData?.idImplementacion,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        changeSection: bindActionCreators(AgendaActions, dispatch).changeSection,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
        resetAll: bindActionCreators(AgendaActions, dispatch).resetAll,
        init: bindActionCreators(EntityListActions, dispatch).init,
        initMap: bindActionCreators(EntityMapActions, dispatch).initMap,
    };
};

const Main = memo(
    ({
        openCrud,
        canCreate,
        canEdit,
        section,
        changeSection,
        login,
        isPageForbidden,
        userName,
        userId,
        resetAll,
        init,
        initMap,
    }) => {
        const [calendarApi, setCalendarApi] = useState(null);

        useEffect(() => {
            return () => {
                resetAll();
            };
        }, [resetAll]);

        const onChangeTab = useCallback(
            (event, value) => {
                if (value === ENTITY_LIST_SECTION.MAP) {
                    logEvent({
                        event: AGENDA.trueName,
                        functionality: 'mapView',
                        checkDuplicate: true,
                    });
                    initMap(AGENDA, true);
                    ensureRoute(`${AGENDA.route}/map`);
                } else {
                    logEvent({
                        event: AGENDA.trueName,
                        functionality: 'listView',
                        checkDuplicate: true,
                    });
                    init(AGENDA, true, PAGINATION_TABLE_AGENDA);
                    ensureRoute(`${AGENDA.route}`);
                }
            },
            [init, initMap],
        );

        const getCalendarApi = useCallback((api) => {
            setCalendarApi(api);
        }, []);

        const renderSection = useMemo(() => {
            if (section === ENTITY_LIST_SECTION.LIST) {
                return <Content getCalendarApi={getCalendarApi} />;
            }
            return <AgendaMap entity={AGENDA} />;
        }, [section, getCalendarApi]);

        const syncPopoverProps = useMemo(
            () => ({
                entity: AGENDA,
                popover: {
                    image: 'sync_calendar.png',
                    title: getLiteral('label_empty_screen_calendar_toast'),
                    text: getLiteral('label_empty_screen_calendar_toast_desc'),
                    buttonTxt: getLiteral('action_sync_calendar'),
                },
            }),
            [],
        );

        const eventNew = useCallback(() => {
            if (!canCreate) return;

            const href = window.location.href;
            let queryString = href.split('?')[1] || '';
            let stringParamsArr = queryString.split('&');
            const params = stringParamsArr.reduce((obj, current) => {
                const queryArr = current.split('=');
                obj[queryArr[0]] = queryArr[1];
                return obj;
            }, []);

            let startDate = null;
            if (params.date) {
                const now = moment();
                let momentDate = moment(params.date, 'YYYY-MM-DDTHH:mm');
                if (momentDate.format('HH:mm') === '00:00') {
                    momentDate.minutes(now.minutes());
                    momentDate.hours(now.hours());
                }
                momentDate.seconds(now.seconds());
                startDate = momentDate.toDate();
            }

            const defaultRange = getPreviousAndNextDate(60, startDate);

            const hini = defaultRange.previous;
            const fini = new Date(hini);
            const hfin = defaultRange.next;

            let defaultData = {
                user: {
                    label: userName,
                    value: userId,
                },
                fini,
                hini,
                hfin,
                isCompletado: '0',
                isTarea: '0',
            };

            if (params.isAllDay) {
                defaultData.isTodoDia = getBackendBoolean(params.isAllDay);
            }

            openCrud({
                entity: AGENDA,
                id: null,
                extraId: null,
                isBulkAction: null,
                isFromDetail: null,
                data: defaultData,
                isFromWorkFlow: null,
                isModal: null,
                crudTab: AGENDA_EVENT,
            });
        }, [canCreate, openCrud, userId, userName]);

        if (!login) return null;
        if (isPageForbidden([AGENDA.trueName])) return null;

        return (
            <FiltersProvider>
                <ContentLayout header={<Header onChange={onChangeTab} section={section} />}>
                    <DetailLayout header={<Menu config={CONFIG()} />}>
                        <FiltersLayout
                            className="fm-filters-layout-agenda"
                            config={CONFIG()}
                            showAdd={false}
                        >
                            <AgendaToolbar calendarApi={calendarApi} />
                            <FiltersRow />
                            {renderSection}
                            <SyncPopover {...syncPopoverProps} />
                        </FiltersLayout>
                    </DetailLayout>
                    <AgendaCrud />
                    <EntityDetail />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.LIST);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/event/new"
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_create"
                                    onMount={eventNew}
                                />
                            }
                        />
                        <Route
                            path="/map/event/new"
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_create"
                                    onMount={eventNew}
                                />
                            }
                        />
                        <Route
                            path="/task/new"
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_create"
                                    onMount={() => {
                                        if (!canCreate) return;
                                        const defaultRange = getPreviousAndNextDate(60);

                                        const hini = defaultRange.previous;
                                        const fini = new Date(hini);
                                        let defaultData = {
                                            user: {
                                                label: userName,
                                                value: userId,
                                            },
                                            fini,
                                            hini,
                                            isCompletado: '0',
                                            isTarea: '1',
                                        };

                                        openCrud({
                                            entity: TASKS,
                                            id: null,
                                            extraId: null,
                                            isBulkAction: null,
                                            isFromDetail: null,
                                            data: defaultData,
                                            isFromWorkFlow: null,
                                            isModal: null,
                                            crudTab: AGENDA_TASK,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path={`/${AGENDA_EVENT}/:id/edit`}
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_edit"
                                    onMount={(params) => {
                                        if (!canEdit || !params.id) return;

                                        openCrud({
                                            entity: AGENDA,
                                            id: params.id,
                                            extraId: null,
                                            isBulkAction: false,
                                            isFromDetail: false,
                                            data: null,
                                            isFromWorkFlow: false,
                                            isModal: false,
                                            crudTab: AGENDA_EVENT,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path={`/map/${AGENDA_EVENT}/:id/edit`}
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_edit"
                                    onMount={(params) => {
                                        if (!canEdit || !params.id) return;

                                        openCrud({
                                            entity: AGENDA,
                                            id: params.id,
                                            extraId: null,
                                            isBulkAction: false,
                                            isFromDetail: false,
                                            data: null,
                                            isFromWorkFlow: false,
                                            isModal: false,
                                            crudTab: AGENDA_EVENT,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path={`/${AGENDA_TASK}/:id/edit`}
                            element={
                                <RouteOnMount
                                    title="page_title_calendar_edit"
                                    onMount={(params) => {
                                        if (!canEdit || !params.id) return;
                                        openCrud({
                                            entity: TASKS,
                                            id: params.id,
                                            extraId: null,
                                            isBulkAction: false,
                                            isFromDetail: false,
                                            data: null,
                                            isFromWorkFlow: false,
                                            isModal: false,
                                            crudTab: AGENDA_TASK,
                                        });
                                    }}
                                />
                            }
                        />
                    </Routes>
                </ContentLayout>
            </FiltersProvider>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Main);

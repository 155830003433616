import React from 'react';

import colors from 'constants/colors';

const EmoHeart = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.95 2.9C14.272-.78 9 1.39 9 5.129 9 1.39 3.73-.781 1.05 2.899-1.72 6.706 1.01 13.372 9 17.2c7.99-3.83 10.72-10.495 7.95-14.3z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default EmoHeart;

import { UsersService } from 'services';
import { sort } from 'utils/arrays';

export default class UsersManager {
    getUsersTree(idUser, success, error) {
        const literals = this.context.store.getState().literal.literals;
        UsersService.getUsers(idUser, 'tree')
            .then((data) => {
                const tree = {};
                getTree(tree, data);
                success(tree);
            })
            .catch(error);
    }

    getUsersList(idUser, success, error) {
        UsersService.getUsers(idUser, 'list')
            .then((data) => {
                success(data);
            })
            .catch(error);
    }
}

const getTree = (tree, data) => {
    data.id = data.id.toString();
    data.idParent = data.idParent.toString();
    tree[data.id] = data;
    if (data.children.length > 0) {
        tree[data.id].children = sort(tree[data.id].children, 'name');
        for (const child of data.children) {
            getTree(tree, child);
        }
    }
};

import { getBackendBoolean } from 'utils/fm';

export const getFileExtensionIconName = (extension, isLink) => {
    const img = 'img';
    const word = 'doc';
    const mdb = 'mdb';
    const mov = 'mov';
    const file = 'file';
    const ppt = 'ppt';
    const xls = 'xls';

    const extensionNames = {
        JPG: img,
        JPEG: img,
        PNG: img,
        DOCX: word,
        DOC: word,
        ODT: word,
        PDF: 'pdf',
        SQL: mdb,
        TXT: 'txt',
        PPT: ppt,
        PPTX: ppt,
        ODP: ppt,
        XLS: xls,
        XLSX: xls,
        ODS: xls,
        CSV: xls,
        MP3: 'mp3',
        APP: 'app',
        MDB: mdb,
        MPP: file,
        ONE: file,
        PST: file,
        PUB: 'pub',
        VSD: 'vsd',
        XNS: file,
        BDD: 'bdd',
        HTML: 'html',
        MOV: mov,
        ZIP: 'zip',
        SVG: img,
        GIF: img,
        MP4: mov,
        HEIC: img,
    };

    const fileExtension = extension && extension.toUpperCase();

    if (getBackendBoolean(isLink)) {
        return 'link';
    } else {
        return fileExtension && extensionNames[fileExtension]
            ? extensionNames[fileExtension]
            : file;
    }
};

export const renameDuplicates = (oldFiles, newFiles) => {
    const oldFilesMapped = oldFiles.reduce((obj, current) => {
        obj[current.name] = current;
        return obj;
    }, {});

    let renamedFiles = [];
    newFiles.forEach((newFile) => {
        if (oldFilesMapped[newFile.name]) {
            for (let i = 1; i <= oldFiles.length; i++) {
                const sufix = `(${i})`;
                const nameSplitted = newFile.name.split('.');
                const proposalName = `${nameSplitted[0]} ${sufix}.${nameSplitted[1]}`;

                if (!oldFilesMapped[proposalName]) {
                    let finalFile = new File([newFile], proposalName, {
                        type: newFile.type,
                        lastModified: newFile.lastModified,
                    });
                    if (newFile.id) {
                        Object.assign(finalFile, {
                            id: newFile.id,
                        });
                    }
                    renamedFiles.push(finalFile);
                    break;
                }
            }
        } else renamedFiles.push(newFile);
    });

    return renamedFiles;
};

export const accepts = (file, acceptedFiles) => {
    if (file && acceptedFiles) {
        const acceptedFilesArray = Array.isArray(acceptedFiles)
            ? acceptedFiles
            : acceptedFiles.split(',');
        const fileName = file.name || '';
        const mimeType = (file.type || '').toLowerCase();
        const baseMimeType = mimeType.replace(/\/.*$/, '');

        return acceptedFilesArray.some((type) => {
            const validType = type.trim().toLowerCase();
            if (validType.charAt(0) === '.') {
                return fileName.toLowerCase().endsWith(validType);
            } else if (validType.endsWith('/*')) {
                return baseMimeType === validType.replace(/\/.*$/, '');
            }
            return mimeType === validType;
        });
    }
    return true;
};

import React from 'react';

const Outlook = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#2272B9"
                d="M.563 2.439V15.56l9.492 1.877V.563zM12.305 9.808a.432.432 0 01-.293.092.452.452 0 01-.275-.092l-1.155-.898v5.114h6.25a.61.61 0 00.606-.605V5.74l-5.133 4.069z"
            />
            <path
                d="M10.582 7.957l1.448 1.118 5.408-4.29a.609.609 0 00-.587-.53h-6.269v3.702"
                fill="#2272B9"
            />
            <path
                d="M7.966 9.015c0 .657-.113 1.233-.34 1.729a2.575 2.575 0 01-.974 1.141c-.422.266-.907.398-1.457.398a2.684 2.684 0 01-1.452-.398 2.615 2.615 0 01-.985-1.134c-.232-.491-.349-1.057-.352-1.699v-.377c0-.654.116-1.23.348-1.731.231-.5.558-.883.98-1.148a2.67 2.67 0 011.452-.399c.546 0 1.03.132 1.452.394.422.262.748.64.978 1.132.23.493.347 1.066.35 1.72v.372zm-1.164-.35c0-.742-.14-1.311-.421-1.707-.281-.396-.68-.594-1.195-.594-.503 0-.897.197-1.18.592-.284.394-.43.952-.435 1.673v.386c0 .737.143 1.306.43 1.708.287.402.685.603 1.194.603.516 0 .913-.196 1.19-.59.278-.392.417-.966.417-1.72v-.35z"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Outlook;

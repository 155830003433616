import { persistTokens, Auth } from 'services';
import Cookies from 'js-cookie';
import { getCookieDomainName } from 'utils/routes';

const CONFIG_SECTIONS = [
    'Permisos',
    'opportunitiesCompanyLevel',
    'customFieldsSchemas',
    'readonlyfields',
    'mandatoryfields',
    'customLiterals',
    'Views',
    'TiposGestion',
    'DeviceUpdates',
    'unreadNotifications',
    'webTemplates',
    'columnsUsuario',
    'usersCounterByEntity',
    'environmentsCounterByEntity',
    'defaultvaluefields',
    'standardFieldsSchemas',
];
export default class DomainManager {
    constructor(api) {
        this.api = api;
    }

    setConfigAsync(config) {
        this.setConfig(config);
        return Promise.resolve();
    }

    setConfig(config) {
        if (!config) return;

        // token consistency when mounting single components from web3
        const token =
            Cookies.get('token', {
                domain: getCookieDomainName(window.location.hostname),
                expires: 31,
            }) || config?.token;

        const refreshToken =
            Cookies.get('refreshToken', {
                domain: getCookieDomainName(window.location.hostname),
                expires: 31,
            }) || config?.refreshToken;

        persistTokens(
            {
                token,
                refreshToken,
            },
            config,
        );
        return Promise.resolve();
    }

    getTokenFromConfig(user, password) {
        return new Promise((resolve, reject) =>
            this.api.getTokenFromConfig(
                user,
                password,
                {
                    ignoreSections: CONFIG_SECTIONS,
                },
                resolve,
                reject,
            ),
        );
    }

    getPartialConfig(whitelistSections) {
        const allSections = ['UserData', ...CONFIG_SECTIONS];
        return new Promise((resolve, reject) =>
            this.api.getPartialConfig(
                {
                    ignoreSections: allSections.filter(
                        (section) => !whitelistSections.includes(section),
                    ),
                },
                resolve,
                reject,
            ),
        );
    }

    getExternalToken() {
        return this.api.getExternalToken();
    }

    getConfigUserTypeWorkFlows = (config) => {
        if (!config.userData.idTipoUsuario) return Promise.resolve(config);
        let workFlowsPermission = config.userData.workFlows;
        if (!workFlowsPermission.companies && !workFlowsPermission.opportunities)
            return Promise.resolve(config);
        if (workFlowsPermission.companies === '0' && workFlowsPermission.opportunities === '0')
            return Promise.resolve(config);
        return new Promise((resolve) => {
            Auth.getWorkFlows('idTipoUsuario', config.userData.idTipoUsuario)
                .then((workFlow) => {
                    let workFlowMap = {};
                    if (workFlow && workFlow.length > 0) {
                        workFlowMap = workFlow.reduce((obj, wf) => {
                            if (!obj[wf.entity]) obj[wf.entity] = {};
                            if (!obj[wf.entity][wf.field]) obj[wf.entity][wf.field] = {};
                            let partialObject = obj[wf.entity][wf.field];
                            wf.workflows.map((fieldWorkFlow) => {
                                let field = fieldWorkFlow.linkedField;
                                let value = fieldWorkFlow.value;
                                let valuesRelations = fieldWorkFlow.valuesRelations;
                                if (!partialObject[field]) partialObject[field] = {};
                                if (!partialObject[field][value]) partialObject[field][value] = {};

                                valuesRelations.map((vr) => {
                                    let relationsArray = vr.relations.reduce((obj, value) => {
                                        obj.push(String(value.value));
                                        return obj;
                                    }, []);
                                    partialObject[field][value][vr.value] = {
                                        relationsArray,
                                        relations: vr.relations,
                                        // permissions: vr.permissions, // not used for the moment
                                    };
                                });
                            });
                            return obj;
                        }, {});
                    }
                    config.workFlows = workFlowMap;
                    resolve(config);
                })
                .catch((err) => {
                    // there is an error with workflow, but the login is ok
                    console.error(err);
                    config.workFlows = {};
                    resolve(config);
                });
        });
    };

    logOut(callback) {
        this.api.logOut(callback, callback);
    }

    getFuzzySearch(
        entity,
        field,
        text,
        entityDependence,
        entityDependenceId,
        usersFromService,
        success,
        error,
    ) {
        this.api.getFuzzySearch(
            entity,
            field,
            text,
            entityDependence,
            entityDependenceId,
            usersFromService,
            (response) => {
                let result = response.List
                    ? response.List
                    : response.Result
                      ? response.Result
                      : response.reports
                        ? response.reports
                        : response; // PARA DOCUMENTOS
                result = result && result.List !== null ? result : [];
                success(result);
            },
            error,
        );
    }

    autoCompleteSearch(
        entity,
        field,
        search,
        feature,
        parentField,
        parentValue,
        idParent,
        success,
        error,
    ) {
        this.api.autoCompleteSearch(
            entity,
            field,
            search,
            feature,
            parentField,
            parentValue,
            idParent,
            (response) => {
                success(response || []);
            },
            error,
        );
    }

    autoCompleteSearchCompaniesCampaign(search, idCampaign, success, error) {
        this.api.autoCompleteSearchCompaniesCampaign(
            search,
            idCampaign,
            (response) => {
                success(response || []);
            },
            error,
        );
    }

    getEntity(id, entityType, withExtraFields = true, data, error) {
        return this.api.getEntity(
            id,
            entityType,
            withExtraFields,
            (result) => {
                if (result.State === '1') {
                    if (typeof result.Result === 'string') {
                        //TODO GOALS 2.0 - this will be no longer needed with the new methods
                        data(result.Result);
                    } else {
                        data(result.Result[0]);
                    }
                } else if (`${result.id}` === `${id}` || `${result.Id}` === `${id}`) {
                    data(result);
                } else if (result.data && typeof result.data === 'string') {
                    const newData = JSON.parse(result.data);
                    data(newData[0]);
                } else {
                    error(result.Message);
                }
            },
            error,
        );
    }

    getEntityList(...args) {
        return this.api.getEntityList(...args);
    }

    getGeolocatedEntities(...args) {
        return this.api.getGeolocatedEntities(...args);
    }

    getGeoCoding(...args) {
        return this.api.getGeoCoding(...args);
    }

    getEntityExists(id, entity, success, error) {
        this.api.getEntityExists(id, entity, success, error);
    }

    getNotificationsWeb(...params) {
        return this.api.getNotificationsWeb(...params);
    }

    getUnreadNotifications(params) {
        return this.api.getUnreadNotifications(params);
    }

    getFoldersDocuments(success, error) {
        this.api.getFoldersDocuments(success, error);
    }

    getDocuments(init, count, idFolder, sortField, sortDir, search, success, error) {
        this.api.getDocuments(
            init,
            count,
            idFolder,
            sortField,
            sortDir,
            search,
            (response) => {
                response.List = response.List ? response.List : [];
                success(response);
            },
            error,
        );
    }

    createEntity(entity, entityCrud, success, error) {
        this.api.createEntity(entity, formatCrud(entityCrud), success, error);
    }

    createEntityFolder(entity, entityCrud, success, error) {
        this.api.createEntity(entity, formatCrud(entityCrud), success, error);
    }

    createEntityFake(entity, entityCrud, success, error) {
        window.console.log(entity, entityCrud, success, error);
    }

    updateEntity(entity, id, entityCrud, success, error) {
        this.api.updateEntity(entity, id, formatCrud(entityCrud), success, error);
    }

    deleteEntity(entity, id, success, error) {
        this.api.deleteEntity(entity, id, success, error);
    }

    getDocumentLink(type, id, success, error) {
        this.api.getDocumentLink(type, id, success, error);
    }

    uploadFile(entity, file, parameters, success, error) {
        this.api.uploadFile(entity, file, parameters, success, error);
    }

    uploadFileWithProgress(entity, parameters, success, error) {
        this.api.uploadFileWithProgress(entity, parameters, success, error);
    }

    getRecentDocuments(init, count, success, error) {
        this.api.getRecentDocuments(init, count, success, error);
    }

    getFollowDocuments(init, count, sortField, sortDir, success, error) {
        this.api.getFollowDocuments(init, count, sortField, sortDir, success, error);
    }

    setFollow(entity, id, follow, isShared = false, success, error) {
        this.api.setFollow(entity, id, follow, isShared, success, error);
    }

    getValueList(entity, success, error) {
        this.api.getValueList(entity, success, error);
    }

    getServerList(list) {
        return this.api.getServerList(list);
    }

    getRatesServerList() {
        return this.api.getRatesServerList();
    }

    autocomplete(params) {
        return this.api.autocomplete(params);
    }

    getPlaceDetails(params) {
        return this.api.getPlaceDetails(params);
    }

    getOrders(init, count, filter, sortField, sortDir, success, error) {
        this.api.getOrders(init, count, filter, sortField, sortDir, success, error);
    }

    getOrdersGrid(init, count, filter, success, error) {
        this.api.getOrdersCards(init, count, filter, (data) => success(data.Result), error);
    }

    getProducts(idFolder, search, offset, count, success, error) {
        this.api.getProducts(idFolder, search, offset, count, success, error);
    }

    getSalesOrderProducts(
        init,
        count,
        idFolder,
        search,
        idRate,
        products,
        idCompany,
        idEntorno,
        success,
        error,
    ) {
        const productsList = products.map((salesOrderProduct) => salesOrderProduct.idproducto);

        this.api.getSalesOrderProducts(
            init,
            count,
            idFolder,
            search,
            idRate,
            productsList,
            idCompany,
            idEntorno,
            success,
            error,
        );
    }

    getSalesOrderProductsByIds(idRate, idCompany, productsIds) {
        return new Promise((resolve, reject) => {
            this.api.getSalesOrderProducts(
                0,
                500,
                -1,
                '',
                idRate,
                productsIds,
                idCompany,
                '-1',
                resolve,
                reject,
            );
        });
    }

    getOrderProducts(id, success, error) {
        this.api.getOrderProducts(id, success, error);
    }

    setAsPrincipal(id, principal, success, error) {
        this.api.setAsPrincipal(
            id,
            principal,
            (result) => {
                if (result.State === '1') {
                    success();
                } else {
                    error();
                }
            },
            (error) => {
                error();
            },
        );
    }

    getRates(offset, pageSize, filterSearch, success, error) {
        this.api.getRates(offset, pageSize, filterSearch, success, error);
    }

    getProductsRates(id, success, error) {
        this.api.getProductsRates(id, success, error);
    }

    getFamilyRates(id, success, error) {
        this.api.getFamilyRates(id, success, error);
    }

    getCompanyRates(id, success, error) {
        this.api.getCompanyRates(id, success, error);
    }

    getReports2(init, count, type, idFolder, getFolders, params, success, error) {
        this.api.getReports2(
            init,
            count,
            type,
            idFolder,
            getFolders,
            params,
            (data) => {
                success(data.reports);
            },
            error,
        );
    }

    getReports2Entity(init, count, type, idType, success, error) {
        // in a near future this function and getReports2 must be a unique function
        this.api.getReports2Entity(
            init,
            count,
            type,
            idType,
            (data) => {
                success(data.reports);
            },
            error,
        );
    }

    getReportLink(idReport, parameters, documentFormat, entityType, entityId, success, error) {
        // this.api.getReportLink(idReport, entityType, entityId, (result) => {
        //  success(result.token);
        // }, error);
        this.api.getReportLink(
            idReport,
            parameters,
            documentFormat,
            entityType,
            entityId,
            success,
            error,
        );
    }

    getTableReportLink(idReport, parameters, locale, success, error) {
        this.api.getTableReportLink(idReport, parameters, locale, success, error);
    }

    getReportTable(idReport, parameters, success, error) {
        this.api.getReportTable(idReport, parameters, success, error);
    }

    getReportParameters(idReport, isShared, success, error) {
        this.api.getReportParameters(idReport, isShared, success, error);
    }

    getReportsFolders(success, error) {
        this.api.getReportsFolders(success, error);
    }

    getFavoriteReports(init, count, success, error) {
        this.api.getFavoriteReports(
            init,
            count,
            (data) => {
                success(data.reports);
            },
            error,
        );
    }

    getReportsSearch(init, count, search, success, error) {
        this.api.getReportsSearch(
            init,
            count,
            search,
            (data) => {
                success(data.reports);
            },
            error,
        );
    }

    getCompanyAddress(id, successCallback, errorCallback) {
        this.api.getCompanyAddress(
            id,
            (result) => {
                if (result.State === '0') {
                    errorCallback(result.ErrorDetail);
                } else {
                    successCallback(result.Result);
                }
            },
            errorCallback,
        );
    }

    addCompanyAddress(entity, success, error) {
        this.api.addCompanyAddress(entity, success, error);
    }

    updateCompanyAddress(entity, id, success, error) {
        this.api.updateCompanyAddress(entity, id, success, error);
    }

    deleteCompanyAddress(id, success, error) {
        this.api.deleteCompanyAddress(id, success, error);
    }

    getDocumentsEntity(entityType, entityId, idFolder, success, error) {
        this.api.getDocumentsEntity(
            entityType.entity,
            entityId,
            idFolder,
            (response) => {
                success(response.List === null ? [] : response.List);
            },
            error,
        );
    }

    getReportsForm(idReport, nameReport, entity, idEntity, success, error, isShared) {
        this.api.getReportsForm(
            idReport,
            nameReport,
            entity,
            idEntity,
            (response) => {
                if (response.State === '1') {
                    success(response.Result);
                } else {
                    error(response.Message);
                }
            },
            error,
            isShared,
        );
    }

    getDocumentSignForm(id, success, error) {
        this.api.getDocumentSignForm(
            id,
            (response) => {
                if (response.State === '1') {
                    success(response.Result);
                } else {
                    error(response.Message);
                }
            },
            error,
        );
    }

    getDocumentSignStatus(id, success, error) {
        this.api.documentSignStatus(
            id,
            (response) => {
                if (response.State === '1') {
                    success(response.Result);
                } else {
                    error(response.Message);
                }
            },
            error,
        );
    }

    setReportsForm(entityType, signedFormModel, success, error) {
        this.api.setReportsForm(
            entityType,
            signedFormModel,
            (response) => {
                if (response.State === '1') {
                    success(response.Result);
                } else {
                    error(response.Message, response.ErrorDetail.ErrorCode);
                }
            },
            error,
        );
    }

    getProfileSettings(success, error) {
        this.api.getProfileSettings(success, error);
    }

    getCompanySettings(success, error) {
        this.api.getCompanySettings(success, error);
    }

    getCompanySettingsEnvironment(idEnvironment, success, error) {
        this.api.getCompanySettingsEnvironment(idEnvironment, success, error);
    }

    setCompanySettings(entity, success, error) {
        this.api.setCompanySettings(entity, success, error);
    }

    setCompanySettingsEnvironment(idEnvironment, entity, success, error) {
        this.api.setCompanySettingsEnvironment(idEnvironment, entity, success, error);
    }

    savePassword(data, success, error) {
        this.api.savePassword(
            data,
            (result) => {
                if (result.State !== '1') return error();
                success(result.NewToken);
            },
            error,
        );
    }

    setProfileSettings(id, entity, success, error) {
        this.api.setProfileSettings(id, entity, success, error);
    }

    deleteDocumentProvider(idProvider, success, error) {
        this.api.deleteDocumentProvider(
            idProvider,
            (response) => {
                if (response.State === '1') {
                    success(response.Result);
                } else {
                    error(response.Message);
                }
            },
            error,
        );
    }

    setConfiguration(configuration, success, error) {
        this.api.setConfiguration(configuration, success, error);
    }

    getConfiguration(success, error, config) {
        this.api.getConfiguration(
            (configuration) => {
                success(JSON.parse(configuration));
            },
            error,
            config,
        );
    }

    exportExcel(filters, sortField, sortDir, success) {
        this.context.socketManager.apiWamp.exportExcel(filters, sortField, sortDir, success);
    }

    getIntegrations(success, error) {
        this.api.getIntegrations(success, error);
    }

    setIntegrations(id, model, success, error) {
        this.api.setIntegrations(id, model, success, error);
    }

    getStartExcelExport(entity, success, error) {
        this.api.getStartExcelExport(entity, success, error);
    }

    getExportDataIDWithPagination(entity, filter, operators, options, success, error) {
        this.api.getExportDataIDWithPagination(entity, filter, operators, options, success, error);
    }

    getExportEmailOrDownload(entity, options, success, error) {
        this.api.getExportEmailOrDownload(
            entity,
            options,
            (data) => {
                try {
                    success(JSON.parse(data?.data));
                } catch (e) {
                    console.error(e);
                    error(e);
                }
            },
            error,
        );
    }

    getExcelExcelExportStatusByTokenId(entity, filter, options, success, error) {
        this.api.getExcelExcelExportStatusByTokenId(
            entity,
            filter,
            options,
            (data) => success(JSON.parse(data.status)),
            error,
        );
    }

    checkIntegrationEnabled(provider, success, error) {
        this.api.checkIntegrationEnabled(provider, success, error);
    }

    mismatchedTickets(success, error) {
        this.api.mismatchedTickets(success, error);
    }

    getIntegrationProperties(idprovider, properties, success, error) {
        this.api.getIntegrationProperties(idprovider, properties, success, error);
    }

    setIntegrationProperties(idprovider, properties, success, error) {
        this.api.setIntegrationProperties(idprovider, properties, success, error);
    }

    getSalesforceUsers(success, error) {
        this.api.getSalesforceUsers(success, error);
    }

    insertMatchedEntities(properties, success, error) {
        this.api.insertMatchedEntities(properties, success, error);
    }

    integrationJobActions(idprovider, properties, success, error) {
        this.api.integrationJobActions(idprovider, properties, success, error);
    }

    setProviderUserConfiguration(idprovider, properties, success, error) {
        this.api.setProviderUserConfiguration(idprovider, properties, success, error);
    }

    getSyncroStats(provider, properties, success, error) {
        this.api.getSyncroStats(provider, properties, success, error);
    }

    getSyncroLogs(provider, success, error) {
        this.api.getSyncroLogs(provider, success, error);
    }

    getSalesforceSyncLog(success, error) {
        this.api.getSalesforceSyncLog(success, error);
    }

    sageSyncroStats(properties, success, error) {
        this.api.sageSyncroStats(properties, success, error);
    }

    getSageSyncLog(success, error) {
        this.api.getSageSyncLog(success, error);
    }

    setUsersProviderRoles(providerName, properties, success, error) {
        this.api.setUsersProviderRoles(providerName, properties, success, error);
    }

    getUsersProviderRoles(providerName, offset, maxResults, success, error) {
        this.api.getUsersProviderRoles(providerName, offset, maxResults, success, error);
    }

    getBlackListedEmails(offset, maxResults, success, error) {
        this.api.getBlackListedEmails(offset, maxResults, success, error);
    }

    setConfigItemStatus(properties, success, error) {
        this.api.setConfigItemStatus(
            properties,
            (result) => {
                if (result.State === '1') {
                    if (result.Result === 'ok') success();
                    else error();
                } else error();
            },
            error,
        );
    }

    sendMailSimple(data, success, error) {
        this.api.sendMailSimple(
            data,
            (result) => {
                result = JSON.parse(result);
                if (result.State === '1') {
                    success();
                } else {
                    error();
                }
            },
            error,
        );
    }

    sendSignatureReminder(data, success, error) {
        this.api.sendSignatureReminder(
            data,
            (result) => {
                result = JSON.parse(result);
                if (result.State === '1') {
                    success();
                } else {
                    error();
                }
            },
            error,
        );
    }

    getUsersTree(idUser, success, error) {
        this.api.getUsersTree(
            idUser,
            (result) => {
                if (result.State === '1') {
                    success(result.Result);
                }
            },
            error,
        );
    }

    refreshWebTemplates(success, error) {
        this.api.refreshWebTemplates((result) => {
            if (result) {
                success(result);
            }
        }, error);
    }

    mergeEntities(data, entity, success, error) {
        this.api.mergeEntities(data, entity, success, error);
    }

    getMergeCandidates(sourceId, entity, success, error) {
        this.api.getMergeCandidates(sourceId, entity, success, error);
    }

    startBulk(params, success, error) {
        this.api.startBulk(params, success, error);
    }

    getEntityCounts(...args) {
        return this.api.getEntityCounts(...args);
    }

    getCompanyDiscounts(id, success, error) {
        this.api.getCompanyDiscounts(id, success, error);
    }

    deleteCompanyDiscounts(id, success, error) {
        this.api.deleteCompanyDiscounts(id, success, error);
    }

    setCompanyDiscounts(data, success, error) {
        this.api.setCompanyDiscounts(data, success, error);
    }

    getApiKey(success, error) {
        this.api.getApiKey((data) => {
            if (data.State === '1') {
                const result = data.Result;
                if (result.hasOwnProperty('apiKey') && result.hasOwnProperty('privateApiKey')) {
                    success(data.Result.apiKey, data.Result.privateApiKey);
                } else error();
            } else {
                error();
            }
        }, error);
    }

    createApiKey(success, error) {
        this.api.createApiKey((data) => {
            if (data.State === '1') {
                const result = data.Result;
                if (result.hasOwnProperty('apiKey') && result.hasOwnProperty('privateApiKey')) {
                    success(data.Result.apiKey, data.Result.privateApiKey);
                } else error();
            } else {
                error();
            }
        }, error);
    }

    getEntityDocuments(id, entity, success, error) {
        this.api.getEntityDocuments(id, entity, success, error);
    }

    getProductsDocuments(id, entity, success, error) {
        this.api.getProductsDocuments(id, entity.entityDocuments, success, error);
    }

    getBillboardMenu(success, error) {
        this.api.getBillboardMenu((data) => {
            if (data.State === '1') {
                const result = data.Result;
                success(result);
            } else {
                error();
            }
        }, error);
    }

    getBillboardList(offset, pageSize, parameters, success, error) {
        this.api.getBillboardList(
            offset,
            pageSize,
            parameters,
            (data) => {
                if (data.State === '1') {
                    success(data);
                } else {
                    error('error');
                }
            },
            error,
        );
    }

    getBillboard(id, isAdmin, success, error) {
        this.api.getBillboard(
            id,
            isAdmin,
            (result) => {
                if (result.State === '1') {
                    success(result.Result[0]);
                } else {
                    error(result.Message);
                }
            },
            error,
        );
    }

    getBillboardEnvironmentsWithGroups() {
        return this.api.getBillboardEnvironmentsWithGroups();
    }

    createBillboard(entity, success, error) {
        this.api.createBillboard(
            entity,
            (data) => {
                if (data.State === '1') {
                    success(data.Result, data.Id);
                } else {
                    error();
                }
            },
            error,
        );
    }

    resendBillboard(entity, recompute, success, error) {
        this.api.resendBillboard(
            entity,
            recompute,
            (data) => {
                if (data.State === '1') {
                    success(data.Result, data.Id);
                } else {
                    error();
                }
            },
            error,
        );
    }

    markBillboardAsOpened(idBillboard, success, error) {
        this.api.markBillboardAsOpened(
            idBillboard,
            (data) => {
                if (data.State === '1' && data.Result === 'ok') {
                    success();
                } else {
                    error();
                }
            },
            error,
        );
    }

    markBillboardAsConfirmed(idBillboard, success, error) {
        this.api.markBillboardAsConfirmed(
            idBillboard,
            (data) => {
                if (data.State === '1' && data.Result === 'ok') {
                    success();
                } else {
                    error();
                }
            },
            error,
        );
    }

    getBillboardActionDetails(idBillboard, status, success, error) {
        this.api.getBillboardActionDetails(
            idBillboard,
            status,
            (data) => {
                if (data.State === '1') {
                    success(data.Result);
                } else {
                    error();
                }
            },
            error,
        );
    }

    getContacts(params) {
        return this.api.getContacts(params);
    }

    getContactsByEntity(params) {
        return this.api.getContactsByEntity(params);
    }

    getAccountsKpis(params) {
        return this.api.getAccountsKpis(params);
    }

    getDataForMailChimp(filters, operators, success, error) {
        this.api.getDataForMailChimp(
            filters,
            operators,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getMailChimpLists(success, error) {
        this.api.getMailChimpLists((data) => {
            success(data.Result);
        }, error);
    }

    createMailChimpList(list, success, error) {
        this.api.createMailChimpList(
            list,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    exportToMailchimp(data, success, error) {
        this.api.exportToMailchimp(
            data,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getIntegrationsFmFields(success, error) {
        this.api.getIntegrationsFmFields((data) => {
            success(data.Result);
        }, error);
    }

    getIntegrationsFmUsers(success, error) {
        this.api.getIntegrationsFmUsers((data) => {
            success(data.Result);
        }, error);
    }

    getIntegrationsFieldsConfig(integration, success, error) {
        this.api.getIntegrationsFieldsConfig(
            integration,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getIntegrationsUsersConfig(integration, success, error) {
        this.api.getIntegrationsUsersConfig(
            integration,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getIntegrationsExtFields(integration, entity, success, error) {
        this.api.getIntegrationsExtFields(
            integration,
            entity,
            (data) => {
                success(data);
            },
            error,
        );
    }

    getIntegrationsExtUsers(integration, success, error) {
        this.api.getIntegrationsExtUsers(
            integration,
            (data) => {
                success(data);
            },
            error,
        );
    }

    setIntegrationsFieldsConfig(integration, data, success, error) {
        this.api.setIntegrationsFieldsConfig(integration, data, success, error);
    }

    setIntegrationsUsersConfig(integration, data, success, error) {
        this.api.setIntegrationsUsersConfig(integration, data, success, error);
    }

    startProviderExport(providerName, success, error) {
        this.api.startProviderExport(
            providerName,
            (data) => {
                if (data.hasOwnProperty('token') && data.token !== '') {
                    success(data.token);
                } else {
                    error();
                }
            },
            error,
        );
    }

    providerExport(providerName, data, success, error) {
        this.api.providerExport(
            providerName,
            data,
            (data) => {
                if (data.hasOwnProperty('result') && data.result === 'Processing') {
                    success();
                } else {
                    error();
                }
            },
            error,
        );
    }

    getProviderExportDataByTokenId(MAILCHIMP_PROVIDER_NAME, tokenId, success, error) {
        this.api.getProviderExportDataByTokenId(
            MAILCHIMP_PROVIDER_NAME,
            tokenId,
            (data) => {
                if (data.hasOwnProperty('tokenId') && data.tokenId === tokenId) {
                    success(data);
                } else {
                    error();
                }
            },
            error,
        );
    }

    getExcelExportToken(entity, success, error) {
        this.api.getExcelExportToken(entity, success, error);
    }

    startExcelExport(entity, token, isDownload, success, error) {
        this.api.startExcelExport(entity, token, isDownload, success, error);
    }

    getExcelExportStatus(token, success, error) {
        this.api.getExcelExportStatus(token, success, error);
    }

    getCancelSubscriptionReasons(success, error) {
        this.api.getCancelSubscriptionReasons((data) => {
            if (data.State === '1') {
                success(data.Result);
            } else {
                error();
            }
        }, error);
    }

    saveCancelSubscription(reason, success, error) {
        this.api.saveCancelSubscription(
            reason,
            (data) => {
                if (data.State === '1') {
                    try {
                        success(JSON.parse(data.Result));
                    } catch (error) {
                        success({});
                    }
                } else {
                    error();
                }
            },
            error,
        );
    }

    setCompleteTour(success, error) {
        this.api.setCompleteTour(success, error);
    }

    getExtrafieldsByEntity(entityType, entityId, setDynamicAsHidden, success, error) {
        this.api.getExtrafieldsByEntity(entityType, entityId, setDynamicAsHidden, success, error);
    }

    setEndWalkThroughDate(dateNow, success, error) {
        this.api.setEndWalkThroughDate(dateNow, success, error);
    }

    getPasswordPolicy(success, error, hash) {
        this.api.getPasswordPolicy(success, error, hash);
    }

    getPasswordUserPolicy(success, error, hash) {
        this.api.getPasswordUserPolicy(success, error);
    }

    getPasswordPolicyAuth(authData, success, error) {
        this.api.getPasswordPolicyAuth(authData, success, error);
    }

    sendEntityListSelection(selection, onConnection) {
        return this.api.sendEntityListSelection(selection, onConnection);
    }

    sendEntityListSelectionInfo(selection) {
        return this.api.sendEntityListSelectionInfo(selection);
    }

    getOpportunitiesPipeline(filters, operators, customParams, success, error) {
        return this.api.getOpportunitiesPipeline(filters, operators, customParams, success, error);
    }

    getSalesRecordsStatistics(filters, operators, customParams) {
        return this.api.getSalesRecordsStatistics(filters, operators, customParams);
    }

    selectColumns(entityName, headers) {
        return this.api.selectColumns(entityName, headers);
    }

    getCalendarByPeriod(
        entity,
        idEntity,
        startDate,
        endDate,
        order,
        taskFlag,
        completedFlag,
        useUTCDates,
        newAttendees,
        success,
        error,
    ) {
        this.api.getCalendarByPeriod(
            entity,
            idEntity,
            startDate,
            endDate,
            order,
            taskFlag,
            completedFlag,
            useUTCDates,
            newAttendees,
            success,
            error,
        );
    }

    setTaskCompleted(calendarId, completed, success, error) {
        this.api.setTaskCompleted(calendarId, completed, success, error);
    }

    deleteWalkthroughData(success, error) {
        return this.api.deleteWalkthroughData(success, error);
    }

    removeView(id) {
        return this.api.removeView(id);
    }

    createView(view) {
        return this.api.createView(view);
    }

    getDataForEntityWidget(entity, id) {
        return this.api.getDataForEntityWidget(entity, id);
    }

    getDataForChip(chipType, entityId) {
        return this.api.getDataForChip(chipType, entityId);
    }

    getDuplicateForFields(entity, field, value) {
        return this.api.getDuplicateForFields(entity, field, value);
    }

    checkDuplicateFields(entity, fieldValues, entityId) {
        return this.api.checkDuplicateFields(entity, fieldValues, entityId);
    }

    getRelatedCompanies(...params) {
        return this.api.getRelatedCompanies(...params);
    }

    createRelation(...params) {
        return this.api.createRelation(...params);
    }

    updateRelation(...params) {
        return this.api.updateRelation(...params);
    }

    deleteRelation(...params) {
        return this.api.deleteRelation(...params);
    }

    getDocumentStatus(...params) {
        return this.api.getDocumentStatus(...params);
    }

    massiveUpdateDocumentsAndFolders(...params) {
        return this.api.massiveUpdateDocumentsAndFolders(...params);
    }

    setTrackingEvents(...params) {
        return this.api.setTrackingEvents(...params);
    }

    setRelevantFields(...params) {
        return this.api.setRelevantFields(...params);
    }

    analyticsProNewUserMail() {
        return this.api.analyticsProNewUserMail();
    }

    getPendingAgreements() {
        return this.api.getPendingAgreements();
    }

    setAgreements(...params) {
        return this.api.setAgreements(...params);
    }

    startUpgrade(...params) {
        return this.api.startUpgrade(...params);
    }

    upgradeAccount(...params) {
        return this.api.upgradeAccount(...params);
    }

    sendEmailType(...params) {
        return this.api.sendEmailType(...params);
    }

    recoverPassword(...params) {
        return this.api.recoverPassword(...params);
    }

    changePassword(...params) {
        return this.api.changePassword(...params);
    }

    changeExpiredPassword(...params) {
        return this.api.changeExpiredPassword(...params);
    }

    checkUserExists(...params) {
        return this.api.checkUserExists(...params);
    }

    setDisclaimer(...params) {
        return this.api.setDisclaimer(...params);
    }
}

const formatCrud = (saveCrud) => {
    saveCrud &&
        Object.keys(saveCrud).forEach((field) => {
            const value = saveCrud[field];
            if (typeof value === 'object') {
                saveCrud[field] = formatCrud(saveCrud[field]);
            } else if (typeof value === 'array') {
                saveCrud[field] = formatCrud(saveCrud[field]);
            } else {
                saveCrud[field] = formatToString(saveCrud[field]);
            }
        });
    return saveCrud;
};

const formatToString = (string) => (string !== null && string !== undefined ? `${string}` : '');

import userflow from 'userflow.js';
import Context from 'managers/Context';
import {
    COMPANIES,
    CONTACTS,
    AGENDA,
    OPPORTUNITIES,
    KPIS,
    CAMPAIGNS,
    SALESORDERS,
    RATES,
} from 'constants/Entities';
import { IS_PRODUCTION } from 'constants/Environment';
import { isExternalRoute } from 'utils/routes';
import { SynchronizeService } from 'services';

let IS_INIT = false;

export function init(traits) {
    if (!traits) return;
    const token = IS_PRODUCTION ? 'ct_36bxgeu7t5hyvavb3ymmtye75u' : 'ct_w2rbkevi6jhytpug2pfgnzdyt4';
    userflow.init(token);
    const identifier = `${Context.config?.userData?.idUsuario}_${Context?.config?.userData?.idImplementacion}`;
    const now = new Date();
    const nylas = Context.config?.nylas;

    let userflowSettings = {
        ...traits,
        signed_up_at: now.toISOString(),
        locale_code: Context.config?.userData?.locale || '',
        device_type: window.innerWidth > 700 ? 'desktop' : 'mobile',
    };

    if (nylas) {
        const isCalendarSynced = nylas.calendar.enabled;
        const isMailSynced = nylas.mail.enabled;
        userflowSettings.nylasActive = isCalendarSynced || isMailSynced || false;
        userflow.identify(identifier, userflowSettings);
    } else {
        userflowSettings.nylasActive = false;
        userflow.identify(identifier, userflowSettings);
    }

    IS_INIT = true;
}
const userflowVibration = () => {
    if (!IS_INIT) return;
    const userflow = document.getElementsByClassName('userflowjs-resource-center-frame')?.[0];
    userflow.classList.remove('userflow-firsttime');
    userflow.classList.add('userflow-paused');
    localStorage.setItem('userflowVisited', true);
};

const observer = new MutationObserver(function (mutations) {
    mutations.forEach((current) => {
        current.addedNodes.forEach((node) => {
            if (node?.classList?.contains('userflowjs-resource-center-frame')) {
                const userflowVisited = localStorage.getItem('userflowVisited');
                userflowHideAndShow(null, true);
                if (!userflowVisited) {
                    node.addEventListener('mouseover', userflowVibration);
                    node.classList.add('userflow-firsttime');
                }
            }
        });
    });
});

export function userflowMutationObserver() {
    if (!IS_INIT) return;
    const target = document.querySelector('body');
    const config = {
        childList: true,
    };
    observer.observe(target, config);
}

const entities = {
    [COMPANIES.entity]: COMPANIES.entity,
    [CONTACTS.entity]: CONTACTS.entity,
    [AGENDA.entity]: AGENDA.entity,
    [OPPORTUNITIES.entity]: OPPORTUNITIES.entity,
    [KPIS.entity]: KPIS.entity,
    [CAMPAIGNS.entity]: CAMPAIGNS.entity,
    [RATES.entity]: RATES.entity,
    'sales-orders': SALESORDERS.entity,
};

export function userflowHideAndShow(action, isObserver) {
    if (!IS_INIT) return;
    let userflowElement =
        document.getElementsByClassName('userflowjs-resource-center-frame')?.[0] || null;

    if (isExternalRoute()) {
        if (userflowElement) userflowElement.style.display = 'none';
        return;
    } else if (userflowElement && action) {
        if (action === 'hide' || isExternalRoute()) {
            userflowElement.style.display = 'none';
            return;
        }
        if (action === 'show') {
            userflowElement.style.display = 'block';
            return;
        }
    }

    const href = window.location.href;
    let entity = '';
    Object.keys(entities).forEach((key) => {
        if (href.split('/').includes(key)) entity = entities[key];
    });

    if (!entity) return;

    const state = Context.store.getState();

    let shouldHide = false;

    const filters = state.entityFilters?.[entity] || filters;
    const isFiltersOpen = filters?.show ? true : false;

    let isMobile =
        navigator.userAgent.match(/android/i) != null ||
        navigator.userAgent.match(/(iPad|iPhone|iPod)/i) != null;

    const mapViewRegEx = new RegExp(`${entity}\/map$`, 'g');
    const detailRegEx = new RegExp(`${entity}\/[0-9]*$`, 'g');
    const createRegEx = new RegExp(`${entity}\/new$`, 'g');
    const editRegEx = new RegExp(`${entity}\/[0-9]*\/edit$`, 'g');

    if (
        isMobile ||
        isFiltersOpen ||
        mapViewRegEx.test(href) ||
        detailRegEx.test(href) ||
        createRegEx.test(href) ||
        editRegEx.test(href) ||
        isExternalRoute()
    ) {
        shouldHide = true;
    }

    if (userflowElement && shouldHide) userflowElement.style.opacity = 0;
    else if (userflowElement) {
        userflow?.closeResourceCenter();
        userflowElement.style.opacity = 1;
    }

    if (isObserver) observer.disconnect();
}

export function track(name, attributes = {}) {
    if (!name || !IS_INIT) return;
    userflow.track(name, attributes);
}

export function start(flowId) {
    if (!IS_INIT) return;
    userflow.start(flowId);
}

import React from 'react';
import colors from 'constants/colors';

const ListDotted = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"
                id="listDotted"
            />
        </defs>
        <use
            fill={colors[color] ? colors[color] : '#788590'}
            xlinkHref="#listDotted"
            fillRule="evenodd"
        />
    </svg>
);

export default ListDotted;

import moment from 'moment';
import Context from 'managers/Context';
import { getMomentFromDateBackend, formatDate } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { isEqual } from 'utils/objects';
import { isBackendFalsy } from 'utils/fm';
import { ensureRoute } from 'utils/routes';

export const toDate = (date) => {
    // Because 'DD/MM/YYYY h:mm:ss' ...
    if (!date) return date;
    const format = 'DD/MM/YYYY HH:mm:ss';
    return moment(date, format).toDate();
};

export const toDecimal = (value, decimals = 2) => {
    let v = value ? value.replace(',', '.') : value;
    if (v && !isNaN(v))
        v = Number(Math.round(Number(v) + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    return v;
};

export const toNumber = (value) => (value ? value.replace(',', '') : value);

export const toBool = (value) => !isBackendFalsy(value);

export const toFloat = (value) => {
    let v = value ? value.replace(',', '.') : value || 0;
    if (v && !isNaN(v)) v = parseFloat(v);
    return v;
};

export const fromExtraFieldsEndpoint = (fields = [], mappedSchema) => {
    let flattenFields = {};
    fields.forEach((section) =>
        section.data.forEach((field) => {
            const fieldId = field.id;
            const fieldConfig = mappedSchema[fieldId];
            if (!fieldConfig) return;
            switch (fieldConfig.type) {
                case 'text':
                case 'textarea':
                    flattenFields[fieldId] = field.value;
                    break;
                case 'number':
                case 'integer':
                    flattenFields[fieldId] = toNumber(field.value);
                    break;
                case 'decimal':
                case 'currency':
                case 'percent':
                    flattenFields[fieldId] = toDecimal(field.value);
                    break;
                case 'dateTime':
                case 'datetime':
                case 'date':
                    flattenFields[fieldId] = toDate(field.value);
                    break;
                case 'bool':
                    flattenFields[fieldId] = toBool(field.value);
                    break;
                case 'singleValueList':
                    if (field.value && field.value !== '-1') {
                        flattenFields[fieldId] = {
                            value: field.value,
                            label: field.label,
                        };
                    }
                    break;
                case 'multipleValueList':
                    if (field.value && field.value !== '-1') {
                        let values = field.value.split(';');
                        let labels = field.label.split(';');
                        flattenFields[fieldId] = [];
                        for (let i = 0; i < values.length; i++) {
                            flattenFields[fieldId].push({ value: values[i], label: labels[i] });
                        }
                    }
                    break;
                case 'fuzzySearchSingle':
                    if (field.value && field.value !== '-1') {
                        flattenFields[fieldId] = {
                            value: field.value,
                            label: field.label,
                        };
                    }
                    break;
                case 'fuzzySearchMultiple':
                    if (field.label && field.value && field.value !== '-1') {
                        let ids = field.value.split(';');
                        let values = field.label.split(';');
                        flattenFields[fieldId] = [];
                        if (ids.length === values.length) {
                            // value label
                            for (let i = 0; i < ids.length; i++) {
                                flattenFields[fieldId].push({ value: ids[i], label: values[i] });
                            }
                        }
                    }
                    break;
                default:
                    console.warn('[CRUD] Extra field type not contempled', field.type);
                    break;
            }
        }),
    );
    return flattenFields;
};

export const fromExtraFields = (fields = [], mappedSchema) =>
    fields.reduce((obj, field) => {
        const fieldId = field.field.replace('EXTRA_', '');
        const fieldConfig = mappedSchema[fieldId];
        if (!fieldConfig) return obj;
        switch (fieldConfig.type) {
            case 'text':
            case 'textarea':
                obj[fieldId] = field.value;
                break;
            case 'number':
            case 'integer':
                obj[fieldId] = toNumber(field.value);
                break;
            case 'decimal':
            case 'currency':
            case 'percent':
                obj[fieldId] = toDecimal(field.value);
                break;
            case 'dateTime':
            case 'datetime':
            case 'date':
                obj[fieldId] = toDate(field.value);
                break;
            case 'bool':
                obj[fieldId] = toBool(field.value);
                break;
            case 'singleValueList':
                if (field.id && field.id !== '-1') {
                    obj[fieldId] = {
                        value: field.id,
                        label: field.value,
                    };
                }
                break;
            case 'multipleValueList':
                if (field.id && field.id !== '-1') {
                    let ids = field.id.split(';');
                    let values = field.value.split(';');
                    obj[fieldId] = [];
                    for (let i = 0; i < ids.length; i++) {
                        obj[fieldId].push({ value: ids[i], label: values[i] });
                    }
                }
                break;
            case 'fuzzySearchSingle':
                if (field.id && field.id !== '-1') {
                    obj[fieldId] = {
                        value: field.id,
                        label: field.value,
                    };
                }
                break;
            case 'fuzzySearchMultiple':
                if (field.id && field.value && field.id !== '-1') {
                    let ids = field.id.split(';');
                    let values = field.value.split(';');
                    obj[fieldId] = [];
                    if (ids.length === values.length) {
                        // value label
                        for (let i = 0; i < ids.length; i++) {
                            obj[fieldId].push({ value: ids[i], label: values[i] });
                        }
                    }
                }
                break;
            default:
                console.warn('[CRUD] Extra field type not contempled', field.type);
                break;
        }

        return obj;
    }, {});

export const fromExtraFieldsEndpointV2 = (fields = [], mappedSchema) => {
    let flattenFields = {};
    fields.forEach((section) =>
        section.data.forEach((field) => {
            const fieldId = field.id;
            const fieldConfig = mappedSchema[fieldId];
            if (!fieldConfig) return;
            switch (fieldConfig.type) {
                case 'text':
                case 'textarea':
                    flattenFields[fieldId] = field.value;
                    break;
                case 'number':
                case 'integer':
                    flattenFields[fieldId] = toNumber(field.value);
                    break;
                case 'decimal':
                case 'currency':
                case 'percent':
                    flattenFields[fieldId] = toDecimal(field.value);
                    break;
                case 'dateTime':
                case 'datetime':
                case 'date':
                    flattenFields[fieldId] = toDate(field.value);
                    break;
                case 'bool':
                    flattenFields[fieldId] = toBool(field.value);
                    break;
                case 'singleValueList':
                    if (field.value && field.value !== '-1') {
                        flattenFields[fieldId] = {
                            value: field.value,
                            label: field.label,
                        };
                    }
                    break;
                case 'multipleValueList':
                    if (field.value && field.value !== '-1') {
                        let ids = field.id.split(';');
                        let values = field.value.split(';');
                        flattenFields[fieldId] = [];
                        for (let i = 0; i < ids.length; i++) {
                            flattenFields[fieldId].push({ value: ids[i], label: values[i] });
                        }
                    }
                    break;
                case 'fuzzySearchSingle':
                    if (field.value && field.value !== '-1') {
                        flattenFields[fieldId] = {
                            value: field.value,
                            label: field.label,
                        };
                    }
                    break;
                case 'fuzzySearchMultiple':
                    if (field.label && field.value && field.value !== '-1') {
                        let ids = field.value.split(';');
                        let values = field.label.split(';');
                        flattenFields[fieldId] = [];
                        if (ids.length === values.length) {
                            // value label
                            for (let i = 0; i < ids.length; i++) {
                                flattenFields[fieldId].push({ value: ids[i], label: values[i] });
                            }
                        }
                    }
                    break;
                default:
                    console.warn('[CRUD] Extra field type not contempled', field.type);
                    break;
            }
        }),
    );
    return flattenFields;
};

export const fromExtraFieldsV2 = (fields = [], mappedSchema) =>
    fields.reduce((obj, field) => {
        const fieldId = field.field.replace('EXTRA_', '');
        const fieldConfig = mappedSchema[fieldId];
        if (!fieldConfig) return obj;
        switch (fieldConfig.type) {
            case 'text':
            case 'textarea':
                obj[fieldId] = field.value;
                break;
            case 'number':
            case 'integer':
                obj[fieldId] = toNumber(field.value);
                break;
            case 'decimal':
            case 'currency':
            case 'percent':
                obj[fieldId] = toDecimal(field.value);
                break;
            case 'dateTime':
            case 'datetime':
            case 'date':
                obj[fieldId] = toDate(field.value);
                break;
            case 'bool':
                obj[fieldId] = toBool(field.value);
                break;
            case 'singleValueList':
                if (field.id && field.id !== '-1') {
                    obj[fieldId] = { label: field.value, value: field.id };
                }
                break;
            case 'multipleValueList':
                if (field.id && field.id !== '-1') {
                    let ids = field.id.split(';');
                    let values = field.value.split(';');
                    obj[fieldId] = [];
                    for (let i = 0; i < ids.length; i++) {
                        obj[fieldId].push({ value: ids[i], label: values[i] });
                    }
                }
                break;
            case 'fuzzySearchSingle':
                if (field.id && field.id !== '-1') {
                    obj[fieldId] = {
                        value: field.id,
                        label: field.value,
                    };
                }
                break;
            case 'fuzzySearchMultiple':
                if (field.id && field.value && field.id !== '-1') {
                    let ids = field.id.split(';');
                    let values = field.value.split(';');
                    obj[fieldId] = [];
                    if (ids.length === values.length) {
                        // value label
                        for (let i = 0; i < ids.length; i++) {
                            obj[fieldId].push({ value: ids[i], label: values[i] });
                        }
                    }
                }
                break;
            default:
                console.warn('[CRUD] Extra field type not contempled', field.type);
                break;
        }

        return obj;
    }, {});

export const fromExtraFieldsNewEntity = (fields = [], mappedSchema) =>
    fields.reduce((obj, field) => {
        const fieldId = field.id;
        const fieldConfig = mappedSchema[fieldId];
        if (!fieldConfig) return obj;
        const value = field.value;
        switch (fieldConfig.type) {
            case 'text':
            case 'textarea':
                obj[fieldId] = value;
                break;
            case 'number':
            case 'integer':
                obj[fieldId] = !isNaN(value) && typeof value === 'string' ? toNumber(value) : value;
                break;
            case 'decimal':
            case 'currency':
            case 'percent':
                obj[fieldId] =
                    !isNaN(value) && typeof value === 'string' ? toDecimal(value) : value;
                break;
            case 'datetime':
            case 'date':
                if (!value) return obj;
                obj[fieldId] = toDate(value);
                break;
            case 'bool':
                obj[fieldId] = toBool(value);
                break;
            case 'singleValueList':
                if (!value) return obj;
                obj[fieldId] = value;
                break;
            case 'multipleValueList':
                if (!value) return obj;
                obj[fieldId] = value?.split(';').filter((el) => el !== '');
                break;
            case 'fuzzySearchSingle':
                if (!value) return obj;
                obj[fieldId] = {
                    value: value,
                    label: field.label,
                };
                break;
            case 'fuzzySearchMultiple':
                if (!value) return obj;
                let ids = field.value.split(';');
                let values = field.label.split(';');
                obj[fieldId] = [];
                if (ids.length === values.length) {
                    ids.forEach((el, index) => {
                        if (el !== '') {
                            obj[fieldId].push({ value: ids[i], label: values[i] });
                        }
                    });
                }
                break;
            default:
                console.warn('[CRUD] Extra field type not contempled', field.type);
                break;
        }
        return obj;
    }, {});

export const mapExtraFieldsValuesNewEntitiesToShow = (fields = []) =>
    // used to parse SalesOrders extra-fields and get the values correctly
    // other entities use a specific method from backend, GetExtrafieldsByEntity
    // in our code, but backend doesn't made it for sales-orders and is returned
    // in a different way and inside the getEntity itself
    fields.reduce((obj, field) => {
        let value = typeof field.value === 'object' ? field.value.value : field.value;
        if (value && !isNaN(value)) value = value.toString();
        switch (field.dataType) {
            case 'text':
                obj[field.id] = value;
                break;
            case 'number':
            case 'integer':
                obj[field.id] = !isNaN(value) ? Number(toNumber(value)) : value;
                break;
            case 'decimal':
            case 'currency':
            case 'percent':
                obj[field.id] = !isNaN(value) ? Number(toDecimal(value)) : value;
                break;
            case 'dateTime':
            case 'datetime':
            case 'date':
                let date = '';
                if (value) {
                    date = formatDate(getMomentFromDateBackend(value));
                }
                obj[field.id] = date;
                break;
            case 'bool':
                obj[field.id] = toBool(value);
                break;
            case 'singleValueList':
            case 'relatedValueList':
            case 'multipleValueList':
                obj[field.id] = field.value.description;
                break;
            default:
                console.warn('[CRUD] Extra field type not contempled', field.dataType);
                break;
        }
        return obj;
    }, {});

function processFields(field, data, isBulk, dynamicHiddenMap, useNewApi) {
    let finalData = { ...data };

    let value = finalData[field.id];
    const finalId = field.serverId || field.id;

    switch (field.type) {
        case 'multipleValueList':
            value = (value || []).map((i) => i.value);
            finalData[finalId] = value;
            break;
        case 'singleValueList':
            finalData[finalId] = value?.value;
            break;
        case 'text':
        case 'textarea':
            finalData[finalId] = (value || '').trim();
            break;
        case 'bool':
            finalData[finalId] = value || false;
            break;
        default:
            finalData[finalId] = value;
            break;
    }

    // Removing non-visible fields data
    if (field.hidden || field.dynamicHidden || dynamicHiddenMap[field.fieldConfiguration]) {
        delete finalData[finalId];
    }

    return finalData;
}

export const processFieldsForB4 = (schema, data, isBulk, dynamicHiddenMap = {}) => {
    let finalData = { ...data };
    schema.forEach((tab) => {
        tab.fields.forEach((field) => {
            if (field.readOnly && !finalData[field.id]) return;
            finalData = processFields(field, finalData, false, dynamicHiddenMap);
        });
    });

    return finalData;
};

const getEditValuesAction = (entity, url, queryString) => {
    return {
        label: getLiteral('cfm_label_valuelist_update_values'),
        icon: 'edit',
        onClick: () => {
            entity &&
                logEvent({
                    event: entity.trueName,
                    submodule: 'valueList',
                    functionality: 'createField',
                });
            const state = Context.store.getState();
            const activeCrud = getActiveCrud(state);
            const data = activeCrud?.data;
            const originalData = activeCrud?.originalData;
            return isEqual(originalData, data, true)
                ? ensureRoute(url, queryString)
                : Context.store.dispatch(
                      Context?.actions?.EntityCrudActions?.toggleExtraFieldsModal(
                          true,
                          url,
                          queryString,
                      ),
                  );
        },
    };
};

export const getCustomizeCrudAction = (entity, url, query) => {
    const config = Context.store.getState().config;
    return config?.permission?.manageExtraFields &&
        config?.permission?.canConfigImplementation &&
        entity.withCustomizeLinkOnLists
        ? [getEditValuesAction(entity, url, query)]
        : [];
};

export const getExtraCustomizeCrudAction = (entity, url, query) => {
    const config = Context.store.getState().config;
    return config?.permission?.manageExtraFields &&
        config?.permission?.canConfigImplementation &&
        entity.withCustomizeLinkOnLists
        ? [getEditValuesAction(entity, url, query)]
        : [];
};

export const toCrudV2SelectValue = (data, arr) => {
    const options = arr.reduce((obj, current) => {
        if (typeof current === 'string') {
            if (!data[current] || data[current] === '-1') return obj;
            let labelKey = current.replace('id', '');
            labelKey = labelKey.charAt(0).toLowerCase() + labelKey.slice(1);

            obj[current] = {
                label: data[labelKey],
                value: data[current],
            };
        } else if (
            typeof current === 'object' &&
            current.hasOwnProperty('label') &&
            current.hasOwnProperty('value')
        ) {
            if (!data[current.value] || data[current.value] === '-1') return obj;
            if (current.hasOwnProperty('id')) {
                obj[current.id] = {
                    label: data[current.label],
                    value: data[current.value],
                };
            } else {
                obj[current.value] = {
                    label: data[current.label],
                    value: data[current.value],
                };
            }
        }

        return obj;
    }, {});
    return options;
};

export const getActiveCrudName = (state) => {
    if (!state) state = Context.store.getState();
    const entityCrud = state?.entityCrud;
    const active =
        entityCrud?.activeModal?.length > 0
            ? entityCrud?.activeModal?.[entityCrud.activeModal.length - 1]
            : entityCrud?.active;
    return active || null;
};

export const getActiveCrud = (state) => {
    if (!state) state = Context.store.getState();
    const activeCrudName = getActiveCrudName(state);
    const entityCrud = state?.entityCrud;
    return entityCrud?.[activeCrudName] || {};
};

export const getActiveLateralCrudName = (state) => {
    if (!state) state = Context.store.getState();
    const active = state?.entityCrud?.active || '';
    return active;
};

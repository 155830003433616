import Axios, { CancelToken } from 'axios'; //<---- Careful! Don't delete the bloody CancelToken!
import { axios } from './axios';
import { handleErrors } from './utils';

export function getFileBlob(url) {
    return fetch(url, {
        cache: 'no-cache',
        headers: {
            'Cache-Control': 'no-cache',
        },
    }).then((response) => {
        return response.blob();
    });
}

const getConfig = (getUploadProgress, cancelToken) => {
    let config = {};
    if (getUploadProgress) {
        config.onUploadProgress = (progressEvent) => {
            const percentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            getUploadProgress &&
                getUploadProgress({
                    percentage,
                    loaded: progressEvent.loaded,
                    total: progressEvent.total,
                });
        };
    }

    if (cancelToken) {
        config.cancelToken = cancelToken.token;
    }

    return config;
};

export function createCancelToken() {
    return CancelToken.source();
}

export function uploadFile({
    entity,
    entityId,
    name,
    file,
    cancelToken,
    getUploadProgress,
    isCarrousel,
}) {
    return new Promise((resolve, reject) => {
        const config = getConfig(getUploadProgress, cancelToken);
        axios
            .post('documents/v1', { folderId: '-1', entity, entityId, name, isCarrousel })
            .then((response) => {
                Axios.put(response.data.locator, file, config)
                    .then(() => {
                        resolve(response);
                    })
                    .catch((thrown) => {
                        if (Axios.isCancel(thrown)) {
                            console.warn('request cancelled by user');
                        } else {
                            handleErrors(reject)(thrown);
                        }
                    });
            })
            .catch((error) => {
                handleErrors(reject);
            });
    });
}

export function updateFile(documentId, fileProps) {
    return new Promise((resolve, reject) => {
        axios
            .put(`documents/v1/${documentId}`, fileProps)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => handleErrors(reject));
    });
}

export function updateCroppedFile(documentId, file) {
    return new Promise((resolve, reject) => {
        axios.put(`documents/v1/${documentId}`, {}).then((response) => {
            Axios.put(response.data.locator, file)
                .then(() => {
                    resolve(response);
                })
                .catch(() => {
                    handleErrors(reject);
                });
        });
        resolve();
    });
}

export function deleteFile(documentId) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`documents/v1/${documentId}`)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                handleErrors(reject);
            });
    });
}

export function getFile(fileId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`documents/v1/${fileId}`)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                handleErrors(reject);
            });
    });
}

import {
    img,
    jpg,
    png,
    svg,
    gif,
    mov,
    mp4,
    doc,
    docx,
    ppt,
    pptx,
    xls,
    xlsx,
    pdf,
    mdb,
    sql,
    txt,
    mp3,
    app,
    mpp,
    one,
    pst,
    pub,
    vsd,
    xsn,
    bdd,
    html,
    zip,
    link,
    file,
} from './fileSvgStringIcons';

import {
    App,
    Bdd,
    Html,
    Img,
    Link,
    Mdb,
    MdbA,
    Mov,
    Mp3,
    Mpp,
    OneNote,
    Outlook,
    Pdf,
    Powerpoint,
    Pub,
    Reports,
    Txt,
    Unknown,
    Vsd,
    Word,
    Xls,
    Xsn,
    Zip,
    File,
} from './svgs';

const translateExtension = {
    aac: 'file',
    css: 'file',
    json: 'file',
    xml: 'file',
    ogg: 'file',
    opus: 'file',
    otf: 'file',
    rtf: 'file',
    mp2t: 'file',
    ttf: 'file',
    webp: 'file',
    woff: 'file',
    woff2: 'file',
    plain: 'file',
    '3gpp': 'file',
    '3gpp2': 'file',
    wav: 'file',
    bmp: 'img',
    gif: 'img',
    jpeg: 'img',
    jpg: 'img',
    png: 'img',
    tiff: 'img',
    tif: 'img',
    mpeg: 'mov',
    webm: 'mov',
    mdb: 'mdb',
    bdd: 'bdd',
    html: 'html',
    txt: 'txt',
    csv: 'xls',
    ods: 'xls',
    xls: 'xls',
    xlsx: 'xls',
    odp: 'ppt',
    ppt: 'ppt',
    pptx: 'pptx',
    msword: 'doc',
    odt: 'doc',
    doc: 'doc',
    docx: 'doc',
    pdf: 'pdf',
    mpp: 'mpp',
    one: 'one',
    pub: 'pub',
    vsd: 'vsd',
    xsn: 'xsn',
    gzip: 'zip',
    zip: 'zip',
    rar: 'zip',
};

const extensionsSvg = {
    img: Img,
    jpg: Img,
    png: Img,
    svg: Img,
    gif: Img,
    mov: Mov,
    mp4: Mov,
    doc: Word,
    docx: Word,
    ppt: Powerpoint,
    pptx: Powerpoint,
    xls: Xls,
    xlsx: Xls,
    pdf: Pdf,
    mdb: Mdb,
    sql: Mdb,
    txt: Txt,
    mp3: Mp3,
    app: App,
    mpp: Mpp,
    one: OneNote,
    pst: Txt,
    pub: Pub,
    vsd: Vsd,
    xsn: Xsn,
    bdd: Bdd,
    html: Html,
    zip: Zip,
    link: Link,
    file: File,
};

const extensionsString = {
    img,
    jpg,
    png,
    svg,
    gif,
    mov,
    mp4,
    doc,
    docx,
    ppt,
    pptx,
    xls,
    xlsx,
    pdf,
    mdb,
    sql,
    txt,
    mp3,
    app,
    mpp,
    one,
    pst,
    pub,
    vsd,
    xsn,
    bdd,
    html,
    zip,
    link,
    file,
};

export const getFileSvgIcon = (extension, isLink, inString = false) => {
    const fileExtension = extension && extension.toLowerCase();
    if (isLink) {
        if (inString) return extensionsString.link;
        else return extensionsSvg.link;
    }
    let translatedExtension = translateExtension[fileExtension];
    if (inString) {
        return extensionsString[translatedExtension]
            ? extensionsString[translatedExtension]
            : extensionsString.file;
    } else {
        return extensionsSvg[translatedExtension]
            ? extensionsSvg[translatedExtension]
            : extensionsSvg.file;
    }
};

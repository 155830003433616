import { B2_URL, API_URL } from 'constants/Environment';
import IntegrationUtils from './IntegrationUtils';

const redirectUrl = `${B2_URL}/Handlers/Integrations/SageLiveAuth.ashx`;
const idclient =
    '3MVG9HxRZv05HarTddHPb6ygxv3hRmDr0GC8NM6ts9uYL89mMxByXQBIpyYumrSX9SBVRqh7QTTUXSPYdCxh6';

const fmUrl = `${API_URL}`;

class SageUtils {
    getSageUrl(iduser, implementation) {
        const impldata = {
            UserId: iduser,
            UserImplementation: implementation,
        };
        const url = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${idclient}&redirect_uri=${redirectUrl}&state=${IntegrationUtils._encode64(
            JSON.stringify(impldata),
        )}`;
        return url;
    }
}

// TODO no es necesario singleton
const StoreSingleton = (function () {
    let instance;

    function createInstance() {
        const object = new SageUtils();
        return object;
    }

    return {
        getInstance() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default StoreSingleton.getInstance();

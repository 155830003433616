import React, { memo, useMemo, useCallback } from 'react';
import { COMPANIES, OPPORTUNITIES } from 'constants/Entities';
import { Text, Spacer } from 'hoi-poi-ui';
import SearchList from 'containers/components/SearchList';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import SearchRow from 'components/SearchRow';
import { getLiteral } from 'utils/getLiteral';
import UtilFormat from 'utils/UtilFormat';
import { CONTACTS } from '../../../../constants/Entities';
import EntityMergeDatesRow from './components/EntityMergeDatesRow';

const EntityMergeHeader = memo(
    ({
        data,
        id2,
        entityType,
        schema,
        dataEntity,
        onSelectEntityForFuzzy,
        onChangeAdvancedSearch,
        literals,
    }) => {
        const entityConfig = useMemo(() => {
            switch (entityType) {
                case CONTACTS:
                    return {
                        rowClassName: 'fm-row-search__contacts',
                        list: 'contactos',
                        format: (current) => ({
                            label: current.MatchingInfo || '',
                            label2: current.ExtraInfo || '',
                            label3: current.ExtraInfo3 || '',
                            label4: current.ExtraInfoId4 || '',
                            value: current.Id || '',
                        }),
                        rowProps: (item) => {
                            const subtitles = Object.keys(item).reduce((arr, key) => {
                                if (key !== 'label' && key !== 'value') {
                                    arr.push(item[key]);
                                }
                                return arr;
                            }, []);
                            return {
                                title: item.label,
                                subtitles,
                            };
                        },
                    };
                case COMPANIES:
                    return {
                        rowClassName: 'fm-row-search__companies',
                        list: 'empresas',
                        format: (current) => ({
                            label: current.MatchingInfo || '',
                            label2: current.ExtraInfo || '',
                            label3: current.ExtraInfo3 || '',
                            label4: current.ExtraInfoId4 || '',
                            value: current.Id || '',
                        }),
                        rowProps: (item) => {
                            const subtitles = Object.keys(item).reduce((arr, key) => {
                                if (key !== 'label' && key !== 'value') {
                                    arr.push(item[key]);
                                }
                                return arr;
                            }, []);
                            return {
                                title: item.label,
                                subtitles,
                            };
                        },
                    };
                case OPPORTUNITIES:
                    return {
                        rowClassName: 'fm-row-search fm-row-search__opportunities',
                        list: 'fullExpedientes',
                        format: (current) => ({
                            description: current.reference || '',
                            state: current.opportunityState || '',
                            company: current.company || '',
                            amount: current.amount || '',
                            won: current.win,
                            lost: current.lost,
                            days: UtilFormat.getDifferentDay(current.expectedCloseDate) || '',
                            value: current.id,
                        }),
                        rowProps: (item) => {
                            if (!item) return {};
                            const subtitles = Object.keys(item).reduce((arr, key) => {
                                if (key === 'state' || key === 'amount') {
                                    arr.push(item[key]);
                                }
                                return arr;
                            }, []);

                            subtitles.reverse();

                            if (item.client) {
                                subtitles.push(item.client);
                            }
                            return {
                                title: item.description,
                                subtitles,
                            };
                        },
                    };
            }
        }, [entityType]);

        const customOption = useCallback(
            (item, getMatchingCharacters) => {
                const props = entityConfig.rowProps(item);
                if (!props.title) return;
                return (
                    <SearchRow
                        className={entityConfig.rowClassName}
                        onClick={() => onSelectEntityForFuzzy(item)}
                        entity={entityType}
                        getMatchingCharacters={getMatchingCharacters}
                        {...props}
                    />
                );
            },
            [entityConfig, entityType, onSelectEntityForFuzzy],
        );

        const formatOptions = useCallback((data) => data.map(entityConfig.format), []);

        let name = '';
        let name2 = '';

        data &&
            schema.map((section) => {
                section.tabFields.map((field) => {
                    if (field.title) {
                        name = field.id;
                        name2 = field.id;
                    }
                });
            });

        name = data && data[name] ? data[name] : '';
        name2 = dataEntity && dataEntity[name2] ? dataEntity[name2] : '';

        const entity1Name = <Literal literal={literals.labelEntity1Name} />;
        const entity2Name = <Literal literal={literals.labelEntity2Name} />;
        let advancedSearch = null;

        if (onChangeAdvancedSearch && !name2) {
            advancedSearch = (
                <div
                    className="fm-entity-merge__advanced-button"
                    onClick={() => onChangeAdvancedSearch(true)}
                >
                    <Literal literal={literals.labelSuggestDuplicates} />
                </div>
            );
        }

        let showSecondFuzzy = (!id2 && !dataEntity) || (id2 && !dataEntity);

        const secondColumnStyle = ['fm-entity-merge__header-left'];
        if (id2) secondColumnStyle.push('with-padding');

        return (
            <div className="fm-entity-merge__header">
                <div className={secondColumnStyle.join(' ')}>
                    <Text type="caption" color="neutral700">
                        {entity1Name}
                    </Text>
                    <Spacer y={1} />
                    <Text type="subtitle">{name}</Text>
                    <Spacer y={2} />
                    <EntityMergeDatesRow
                        label={getLiteral('label_created')}
                        date={data?.Created || data?.CreationDate}
                    />
                    <EntityMergeDatesRow
                        label={getLiteral('label_modified')}
                        date={data?.Modified}
                    />
                </div>
                <div className="fm-entity-merge__header-right">
                    <Text type="caption" color="neutral700">
                        {entity2Name}
                    </Text>
                    <Spacer y={1} />
                    {showSecondFuzzy ? (
                        <>
                            <SearchList
                                entity={entityType}
                                list={entityConfig?.list}
                                field="entity-search"
                                placeholder={getLiteral('action_search')}
                                formatOptions={formatOptions}
                                customOption={customOption}
                            />
                            {advancedSearch}
                        </>
                    ) : (
                        <>
                            <Text type="subtitle">{name2}</Text>
                            <Spacer y={2} />
                            <EntityMergeDatesRow
                                label={getLiteral('label_created')}
                                date={dataEntity?.Created || dataEntity?.CreationDate}
                            />
                            <EntityMergeDatesRow
                                label={getLiteral('label_modified')}
                                date={dataEntity?.Modified}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    },
);

export default EntityMergeHeader;

import React, { memo, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'hoi-poi-ui';

import colors from 'constants/colors';
import Context from 'managers/Context';
import { REPORTS } from 'constants/Entities';
import { ensureRoute, getCurrentPath } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { hashHistory } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { getPathFromSelected } from 'utils/treeNavigation';

import { LookerService } from 'services';
import SingleNode from 'components/TreeNavigatorNew/SingleNode';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';

import DashboardFolderForm from './DashboardFolderForm';
import MetricsHeader from './MetricsHeader';
import MetricsTree from './MetricsTree';
import MetricsMenu from './MetricsMenu';
import MetricsContent from './MetricsContent';
import './styles.scss';

const Metrics = memo(() => {
    // Permissions
    const login = useSelector((state) => state.config.login);
    const currentUserId = useSelector((state) => parseInt(state.config?.userData?.idUsuario, 10));
    const analyticsEnabled = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
    );
    const canCreateAnalyticsFolders = useSelector(
        (state) => state.config?.permission?.analyticsProCreateFolder,
    );
    const reportsEnabled = useSelector(
        (state) => state.config?.permission?.informes && state.config?.permission?.reports_general,
    );
    const sfmEnabled = useSelector((state) => state.config?.permission?.sfm);

    // Sidebar root items
    const [sectionSelected, setSectionSelected] = useState(() => {
        let section = getCurrentPath()?.split('/')?.[2];
        if (!['dashboards', 'reports', 'sfm', 'favorites'].includes(section)) {
            if (sfmEnabled) section = 'sfm';
            else if (analyticsEnabled) section = 'dashboards';
            else if (reportsEnabled) section = 'reports';
        }
        return section;
    });
    const oldSection = useRef(sectionSelected);

    // Breadcrumb state
    const [pathItemSelected, setPathItemSelected] = useState({
        id: -1,
    });
    const currentFolderId = useRef(-1);

    const [pathItems, setPathItems] = useState([]);

    // Drag state
    const dragInfo = useRef({ dragged: null, target: null });
    const getDragInfo = useCallback((newDragInfo) => {
        if (newDragInfo && Object.keys(newDragInfo).length > 0) {
            for (let key in newDragInfo) {
                dragInfo.current[key] = newDragInfo[key];
            }
        }
    }, []);

    const onDrop = useCallback(
        (dragged, target) => {
            if (dragged?.id === target?.id) return;
            if (dragged?.folderId === target?.id) return;
            if (dragged?.parentId === target?.id) return;

            let pr;
            if (dragged?.isDashboardFolder) pr = LookerService.moveFolder(dragged.id, target.id);
            else if (dragged?.isDashboard) pr = LookerService.moveDashboard(dragged.id, target.id);

            pr.then(() => {
                initList();
            }).catch((err) => {
                console.error(err);
                errorToast({
                    text: getLiteral('label_analyticspro_toast_ko'),
                });
            });
        },
        [initList],
    );

    // Data state
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [searchResults, setSearchResults] = useState();
    const [favorites, setFavorites] = useState();
    const dashboardTree = useRef([]);
    const reportsTree = useRef([]);
    const formModal = useRef();
    const searchText = useRef();

    const onSearch = useCallback(
        (value) => {
            searchText.current = value;
            if (!value) {
                setSectionSelected(oldSection.current);
                return load().then(() => {
                    changeFolderTree(getCurrentPath());
                });
            }
            setSectionSelected('searching');
            setLoading(true);
            return new Promise((_, reject) => {
                Promise.all([
                    Context.reportsManager.getAllReports(REPORTS, null, {
                        search: value,
                    }),
                    LookerService.getAnalytics(value),
                ])
                    .then(([reports, dashboards]) => {
                        const searchResults = [...reports, ...dashboards.widgetDashboardsSorted];
                        setSearchResults(
                            searchResults.sort((a, b) => a.name.localeCompare(b.name)),
                        );
                    })
                    .catch((err) => {
                        console.error(err);
                        setError(err);
                        reject(err);
                    })
                    .then(() => {
                        setLoading(false);
                    });
            });
        },
        [changeFolderTree, load],
    );

    // Tree handlers
    const onClickFavorites = useCallback(() => {
        if (loading) return;
        if (sectionSelected === 'favorites') return;
        hashHistory.push(`/metrics/favorites`);
    }, [loading, sectionSelected]);

    const onClickSFM = useCallback(() => {
        if (loading) return;
        if (sectionSelected === 'sfm') return;
        hashHistory.push(`/metrics/sfm`);
    }, [loading, sectionSelected]);

    const onChangeRoute = useCallback(
        (folderOrId, currentSection) => {
            const folderId = folderOrId?.id || folderOrId;
            if (!folderId || folderId === -1)
                return hashHistory.push(`/metrics/${currentSection || sectionSelected}`);
            hashHistory.push(`/metrics/${currentSection || sectionSelected}/${folderId}`);
        },
        [sectionSelected],
    );

    // Main data management
    const changeFolderTree = useCallback((pathname) => {
        const splitted = pathname?.split('/');
        const section = splitted?.[2];
        const folderId = splitted?.[3];
        let nextTreePath;
        if (section === 'dashboards') {
            nextTreePath = [dashboardTree.current];
        } else if (section === 'reports') {
            nextTreePath = [reportsTree.current];
        } else {
            return;
        }

        const path = getPathFromSelected(nextTreePath[0], folderId);
        if (path) {
            nextTreePath = [...nextTreePath, ...path];
        }
        const selected = nextTreePath[nextTreePath.length - 1];
        setPathItemSelected(selected);
        currentFolderId.current = selected.id;
        setPathItems(nextTreePath);
    }, []);

    const load = useCallback((inBackground = false) => {
        if (!inBackground) setLoading(true);
        return new Promise((resolve, reject) => {
            Promise.all([
                Context.reportsManager.getReportsFolders(),
                LookerService.getAnalytics(),
                Context.reportsManager.getAllReports(REPORTS, null, {
                    following: true,
                }),
            ])
                .then(([reports, dashboards, favoriteReports = []]) => {
                    reportsTree.current = reports;
                    dashboardTree.current = dashboards.tree;

                    const favorites = [...(dashboards.favorites || []), ...favoriteReports];
                    setFavorites(favorites.sort((a, b) => a.name.localeCompare(b.name)));

                    resolve({ reports, dashboards });
                })
                .catch((err) => {
                    console.error(err);
                    if (!inBackground) setError(err);
                    reject(err);
                })
                .then(() => {
                    if (!inBackground) setLoading(false);
                });
        });
    }, []);

    const initList = useCallback(() => {
        if (searchText.current) {
            return onSearch(searchText.current);
        } else {
            return load().then(() => {
                changeFolderTree(getCurrentPath());
            });
        }
    }, [changeFolderTree, load, onSearch]);

    // CRUD Handlers
    const onCreateFolder = useCallback(() => {
        formModal.current.open({
            parentId: currentFolderId.current,
            modalTitle: getLiteral('label_new_folder'),
            label: getLiteral('label_foldername'),
            shared: false,
        });
    }, []);

    const onEditFolder = useCallback(
        (item) => {
            formModal.current.open({
                ...item,
                modalTitle: getLiteral('label_looker_edit_folder'),
                label: getLiteral('label_foldername'),
                isOwner: item.salesRepIdCreated.id === currentUserId,
            });
        },
        [currentUserId],
    );

    const onSaveForm = useCallback(
        (form, isCreation) => {
            initList().then(() => {
                if (isCreation) onChangeRoute(form.id);
            });
        },
        [initList, onChangeRoute],
    );

    useEffect(() => {
        let section = getCurrentPath()?.split('/')?.[2];
        if (!section) {
            if (sfmEnabled) section = 'sfm';
            else if (reportsEnabled) section = 'reports';
            else if (analyticsEnabled) section = 'dashboards';
            hashHistory.push(`/metrics/${section}`);
        }

        initList();

        const listener = hashHistory.listen((match) => {
            const section = getCurrentPath()?.split('/')?.[2] || 'dashboards';
            setSectionSelected(section);

            if (['dashboards', 'reports'].includes(section)) {
                changeFolderTree(match.location.pathname);
            }
            return;
        });
        return listener;
    }, [analyticsEnabled, changeFolderTree, initList, reportsEnabled, sfmEnabled]);

    // Keeking current section when back from searching
    useEffect(() => {
        if (sectionSelected !== 'searching') {
            if (oldSection.current) {
                logEvent({
                    event: 'metrics',
                    submodule: sectionSelected,
                    functionality: 'menuClick',
                });
            }
            oldSection.current = sectionSelected;
        }
    }, [sectionSelected]);

    const path = useMemo(() => {
        switch (sectionSelected) {
            case 'reports':
            case 'dashboards':
                return pathItems;
            case 'sfm':
                return [{ id: -1, name: getLiteral('title_sales_force') }];
            case 'favorites':
                return [{ id: -1, name: getLiteral('label_favourite_male_pl') }];
        }
    }, [pathItems, sectionSelected]);

    const navigators = useMemo(
        () => [
            <SingleNode
                icon={<Icon name="starBorder" />}
                iconSelected={<Icon name="star" />}
                label="label_favourite_male_pl"
                onClick={onClickFavorites}
                className="favorite"
                isSelected={sectionSelected === 'favorites'}
            />,
            <div className="navigator-separator"></div>,
            <div className="fm-metrics__trees">
                {sfmEnabled && (
                    <SingleNode
                        icon={<Icon name="salesForce" color={colors['$neutral600']} />}
                        iconSelected={<Icon name="salesForce" color={colors['$neutral900']} />}
                        label="title_sales_force"
                        onClick={onClickSFM}
                        className="fm-metrics__sfm-folder"
                        isSelected={sectionSelected === 'sfm'}
                    />
                )}
                {reportsEnabled && (
                    <MetricsTree
                        onChange={onChangeRoute}
                        getDragInfo={getDragInfo}
                        dragInfo={dragInfo.current}
                        folderTree={reportsTree.current}
                        pathItemSelected={pathItemSelected}
                        pathItems={pathItems}
                        section="reports"
                        isSelected={sectionSelected === 'reports'}
                        sorted
                        getIcon={(data) => {
                            if (data.id === -1) {
                                return <Icon name="reports" color={colors['$neutral600']} />;
                            }

                            let icon;
                            if (data.toggled) {
                                icon = <Icon name="folderOpen" color={colors['$neutral900']} />;
                            } else icon = <Icon name="folder" color={colors['$neutral600']} />;
                            return icon;
                        }}
                    />
                )}
                {analyticsEnabled && (
                    <MetricsTree
                        onChange={onChangeRoute}
                        getDragInfo={getDragInfo}
                        dragInfo={dragInfo.current}
                        folderTree={dashboardTree.current}
                        pathItemSelected={pathItemSelected}
                        pathItems={pathItems}
                        onDrop={onDrop}
                        canDrag
                        section="dashboards"
                        isSelected={sectionSelected === 'dashboards'}
                        getIcon={(data) => {
                            let icon;

                            if (data.id === -1) {
                                return <Icon name="dashboard" />;
                            }

                            if (data.shared) {
                                if (data.toggled) {
                                    icon = <Icon name="folderOpen" color={colors['$neutral900']} />;
                                } else icon = <Icon name="folder" color={colors['$neutral600']} />;
                            } else {
                                if (data.toggled) {
                                    icon = (
                                        <Icon name="folderKeyOpen" color={colors['$neutral900']} />
                                    );
                                } else
                                    icon = <Icon name="folderKey" color={colors['$neutral600']} />;
                            }

                            return icon;
                        }}
                    />
                )}
            </div>,
        ],
        [
            analyticsEnabled,
            getDragInfo,
            onChangeRoute,
            onClickFavorites,
            onClickSFM,
            onDrop,
            pathItemSelected,
            pathItems,
            reportsEnabled,
            sectionSelected,
            sfmEnabled,
        ],
    );

    if (!login) return null;
    if (!analyticsEnabled && !reportsEnabled && !sfmEnabled) return ensureRoute('/companies');

    return (
        <ContentLayout header={<MetricsHeader />}>
            <DetailLayout
                className="fm-metrics_detail-layout"
                header={
                    <MetricsMenu
                        pathItemSelected={pathItemSelected}
                        pathItems={path}
                        onCreateFolder={
                            canCreateAnalyticsFolders && sectionSelected === 'dashboards'
                                ? onCreateFolder
                                : undefined
                        }
                        sectionSelected={sectionSelected}
                        onChange={onChangeRoute}
                        onSearch={onSearch}
                    />
                }
                navigator={navigators}
                navigatorTitle={getLiteral('label_your_metrics')}
            >
                <MetricsContent
                    pathItemSelected={pathItemSelected}
                    sectionSelected={sectionSelected}
                    loading={loading}
                    error={error}
                    dragInfo={dragInfo}
                    onDrop={onDrop}
                    searchResults={searchResults}
                    favorites={favorites}
                    refreshList={initList}
                    refreshFavorites={load}
                    onChange={onChangeRoute}
                    onCreateFolder={onCreateFolder}
                    onEditFolder={onEditFolder}
                />
                <DashboardFolderForm
                    onRef={(ref) => (formModal.current = ref)}
                    onSave={onSaveForm}
                />
            </DetailLayout>
        </ContentLayout>
    );
});

export default Metrics;

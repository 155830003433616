export const getParameterByName = (name) => {
    const location = window.location.search || '';

    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(location);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const cleanUrl = () => {
    const location = window.location.search || '';
    if (location.indexOf('?') > -1) {
        let url = window.location.href.replace(location, '');
        window.history.replaceState(null, null, url);
    }
};

export const getQueryString = (parameters = {}) =>
    encodeURI(
        `?${Object.keys(parameters)
            .map((key) => `${key}=${parameters[key]}`)
            .join('&')}`,
    );

export const getNewQueryString = (parameters = {}) =>
    `?${Object.keys(parameters)
        .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
        .join('&')}`;

export const asTelUrl = (tel) => (tel ? `tel:${tel}` : tel);
export const asEmailUrl = (email) => (email ? `mailto:${email}` : email);

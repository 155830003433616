const content = {
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px',
    borderRadius: '0',
    height: '100%',
    position: 'fixed',
    zIndex: '1000',
    left: 'auto',
    top: '0',
    overflow: 'hidden',
    right: '0',
    backgroundColor: 'rgb(255, 255, 255)',
};

export default ({}) => ({
    contentAnimation: (width, fixedWidth) => ({
        ...content,
        width: fixedWidth || width - 350,
    }),
    content: (show, width) => ({
        ...content,
        width: show ? fixedWidth || width - 350 : 0,
    }),
    modal: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.541176)',
        position: 'fixed',
        top: 0,
        zIndex: 999,
    },
});

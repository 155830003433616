import { operators } from '@web/web5';
import { ACTIVITIES, COMPANIES, CONTACTS, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

const {
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    LAST_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    THIS_QUARTER,
    LAST_QUARTER,
    THIS_YEAR,
    LAST_YEAR,
    INSIDE_RANGE,
} = operators;

export const ENTITIES_WITH_ADVANCED_FILTERS = [
    ACTIVITIES.trueName,
    CONTACTS.trueName,
    COMPANIES.trueName,
    OPPORTUNITIES.trueName,
    SALESORDERS.trueName,
];

export const ENTITIES_ALLOWED_OPERATORS = {
    [ACTIVITIES.trueName]: {
        date: [
            TODAY,
            YESTERDAY,
            THIS_WEEK,
            LAST_WEEK,
            THIS_MONTH,
            LAST_MONTH,
            THIS_QUARTER,
            LAST_QUARTER,
            THIS_YEAR,
            LAST_YEAR,
            INSIDE_RANGE,
        ],
    },
};

export const ENTITIES_WITH_CROSS_FILTERS = [
    ACTIVITIES.trueName,
    COMPANIES.trueName,
    CONTACTS.trueName,
    OPPORTUNITIES.trueName,
    SALESORDERS.trueName,
];

export const ENTITIES_ALLOWED_FOR_CROSS_FILTERS = [
    COMPANIES,
    CONTACTS,
    ACTIVITIES,
    OPPORTUNITIES,
    SALESORDERS,
];

export const ENTITIES_ALLOWED_FOR_CROSS_FILTERS_PER_ENTITY = {
    [ACTIVITIES.entity]: [COMPANIES, CONTACTS, OPPORTUNITIES],
    [COMPANIES.entity]: [CONTACTS, ACTIVITIES, OPPORTUNITIES, SALESORDERS],
    [CONTACTS.entity]: [COMPANIES, ACTIVITIES, OPPORTUNITIES, SALESORDERS],
    [OPPORTUNITIES.entity]: [COMPANIES, CONTACTS, ACTIVITIES, SALESORDERS],
    [SALESORDERS.entity]: [COMPANIES, CONTACTS, OPPORTUNITIES],
};

// This filters are shown as cross-filters in the filters panels so they are referenced
// with their crossId (entity__filter) in the quickFilters, visibleFilters and openFilters
// props of the component's state, but BE expects them to be sent as regular entity filters.
export const SENT_TO_BE_AS_STANDARD_FILTERS = [
    'hasAccount',
    'hasActivity',
    'hasContact',
    'hasOpportunity',
    'hasOrder',
];

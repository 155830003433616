import {
    DANA_STORE_CHAT,
    DANA_STORE_CHAT_FINISHED,
    DANA_STORE_CHAT_CLEAR,
    DANA_STORE_CHAT_VOTE,
    DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS,
} from 'constants/ActionTypes';

let openChats = {};

export function storeDanaChat({
    entity,
    subEntity,
    id,
    text,
    loading,
    error,
    interactionId,
    conversationId,
    parentId,
    progress,
    reports,
    tables,
}) {
    return (dispatch, getState) => {
        if (!entity?.entity || !id || !interactionId) return;

        let chatId = `${entity.entity}-${id}`;
        if (subEntity?.entity) chatId = `${chatId}-${subEntity.entity}`;
        if (!openChats[chatId]) {
            openChats[chatId] = setTimeout(
                () => {
                    clearDanaChat({ entity, id, subEntity });
                },
                1000 * 60 * 60 * 24,
            );
        }

        let content = { content: text || '' };
        content.progress = progress || '';
        content.reports = reports || {};
        content.tables = tables || {};

        if (conversationId || loading || error) content.role = 'assistant';
        else content.role = 'user';
        if (typeof loading === 'boolean') content.loading = loading;
        // Error allows us to know if we need to print error for that interaction
        if (typeof error === 'boolean') content.error = error;
        // We use parentId to know what whas the question, so we can ask again
        if (parentId) content.parentId = parentId;

        const obj = {
            entity: entity.entity,
            subEntity: subEntity?.entity || '',
            id,
            interactionId,
            content,
        };

        if (conversationId) obj.conversationId = conversationId;

        dispatch({
            type: DANA_STORE_CHAT,
            ...obj,
        });
    };
}

export function setDanaChatFinished({ chatId, interactionId }) {
    return (dispatch) => {
        dispatch({ type: DANA_STORE_CHAT_FINISHED, chatId, interactionId });
    };
}

export function clearDanaChat({ entity, id, subEntity }) {
    return (dispatch) => {
        if (!entity?.entity) return;
        let chatId = `${entity.entity}-${id}`;
        if (subEntity?.entity) chatId = `${chatId}-${subEntity.entity}`;
        if (openChats[chatId]) {
            clearTimeout(openChats[chatId]);
            delete openChats[chatId];
        }

        dispatch({
            type: DANA_STORE_CHAT_CLEAR,
            entity: entity.entity,
            subEntity: subEntity?.entity || '',
            id,
        });
    };
}

export function danaVote({ chatId, interactionId, vote }) {
    return (dispatch) => {
        if (!chatId || !interactionId) return;
        dispatch({
            type: DANA_STORE_CHAT_VOTE,
            chatId,
            interactionId,
            vote,
        });
    };
}

export function updateCTAViews() {
    return (dispatch) => {
        dispatch({ type: DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS });
    };
}

import Context from 'managers/Context';
import { PRODUCTS } from '../../constants/Entities';
import { ProductModel } from '../../models';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { logEvent } from 'utils/tracking';
import { FilesService } from 'services';

function sortByCurrentLanguage(languages) {
    let currentLang = Context.config?.userData?.langISOInterface;
    currentLang = currentLang?.split('-')?.[1] || currentLang;

    if (currentLang === 'es') return languages;

    const idx = languages.findIndex((elemento) => elemento.endsWith('_' + currentLang));
    if (idx > -1) {
        const el = languages.splice(idx, 1)[0];
        languages.unshift(el);
    }

    return languages;
}

export default class ProductsManager {
    constructor(actions) {
        this.actions = actions;
    }

    canAsyncExtraFields() {
        return true;
    }

    getEntity(id, success, error, withExtraFields = false) {
        this.context.domainManager.getEntity(
            id,
            PRODUCTS.entity,
            withExtraFields,
            (data) => success(data, data),
            error,
        );
    }

    getSchema = (defaultInputSchema) => {
        const state = Context.store.getState();
        const isFreeTrial = state?.config?.subscriptionModeCode === 'free_trial';

        let schema = [
            {
                title: getLiteral('label_info'),
                show: true,
                columns: 2,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'modelo',
                        serverId: 'modelo',
                        fieldConfiguration: 'modelo',
                        type: 'translations',
                        label: getLiteral('label_model'),
                        column: 1,
                        inputAttrs: {
                            languages: sortByCurrentLanguage([
                                'modelo',
                                'modelo_en',
                                'modelo_us',
                                'modelo_de',
                                'modelo_fr',
                                'modelo_it',
                                'modelo_pt',
                                'modelo_br',
                                'modelo_ru',
                            ]),
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'descripcion',
                        serverId: 'descripcion',
                        fieldConfiguration: 'descripcion',
                        type: 'translations',
                        label: getLiteral('label_description'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 5,
                            languages: sortByCurrentLanguage([
                                'descripcion',
                                'descripcion_en',
                                'descripcion_us',
                                'descripcion_de',
                                'descripcion_fr',
                                'descripcion_it',
                                'descripcion_pt',
                                'descripcion_br',
                                'descripcion_ru',
                            ]),
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'precio',
                        serverId: 'precio',
                        fieldConfiguration: 'precio',
                        type: 'decimal',
                        label: getLiteral('label_price'),
                        column: 1,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'coste',
                        serverId: 'coste',
                        fieldConfiguration: 'preciocoste',
                        type: 'decimal',
                        label: getLiteral('label_cost'),
                        column: 1,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'descmax',
                        serverId: 'descmax',
                        fieldConfiguration: 'descmax',
                        type: 'percent',
                        label: getLiteral('label_maxdiscount'),
                        inputAttrs: {
                            min: 0,
                            max: 10,
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'stock',
                        serverId: 'stock',
                        fieldConfiguration: 'stock',
                        type: 'number',
                        label: getLiteral('label_stock'),
                        inputAttrs: {
                            maxLength: 5,
                        },
                        column: 1,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'recommendedQuantity',
                        serverId: 'recommendedQuantity',
                        type: 'number',
                        label: getLiteral('label_recommended_quantity'),
                        inputAttrs: {
                            maxLength: 5,
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idtipo',
                        serverId: 'idtipo',
                        fieldConfiguration: 'idtipo',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_productcategory'),
                        inputAttrs: {
                            list: 'productcategory',
                        },
                        column: 1,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idfamilia',
                        serverId: 'idfamilia',
                        fieldConfiguration: 'idfamilia',
                        type: 'selectFamilyModal',
                        label: getLiteral('label_family'),
                        defaultValue: {
                            label: getLiteral('title_products'),
                            value: '-1',
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'strIdEnvironments',
                        serverId: 'strIdEnvironments',
                        fieldConfiguration: 'strIdEnvironments',
                        type: 'multipleValueList',
                        label: getLiteral('label_selectallowedenvirontments'),
                        hint: isFreeTrial && getLiteral('helptext_environment_accounts'),
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                        column: 1,
                    },
                    {
                        id: 'available',
                        serverId: 'available',
                        fieldConfiguration: 'available',
                        type: 'bool',
                        label: getLiteral('label_product_available'),
                        column: 2,
                    },
                ],
            },
            {
                title: getLiteral('action_uploadgalleryimage'),
                show: true,
                fields: [
                    {
                        id: 'files',
                        type: 'uploadFiles',
                        inputAttrs: {
                            shouldRenderField: true,
                        },
                        canUploadDocuments: true,
                        showDocuments: true,
                        isCustom: true,
                    },
                ],
            },
        ];

        return schema;
    };

    getFilterSchema = (success, error) => [
        {
            id: 'productCategories',
            description: getLiteral('label_productcategory'),
            dataType: 'multipleValueList',
            inputAttrs: {
                ...FuzzyMap.productCategories,
            },
        },
        {
            id: 'fcreado',
            description: getLiteral('label_created'),
            dataType: 'date',
            asExtra: true,
            isAudit: true,
            locators: {
                first: 'products-filter__creation-date-start',
                last: 'products-filter__creation-date-end',
            },
        },
        {
            id: 'fmodificado',
            description: getLiteral('label_modified'),
            dataType: 'date',
            asExtra: true,
            isAudit: true,
            locators: {
                first: 'products-filter__modified-date-start',
                last: 'products-filter__modified-date-end',
            },
        },
        {
            id: 'useAvailableFilterProducts',
            description: getLiteral('label_product_filter_available_products'),
            dataType: 'bool',
        },
        {
            id: 'idProductFamily',
            dataType: 'singleValueList',
            hideForCount: true,
        },
    ];

    transformDataForEntityList = (backendData) => {
        return {
            Data: backendData || [],
        };
    };

    getProducts(idFolder, search, offset, count, success, error) {
        this.context.domainManager.getProducts(
            idFolder,
            search,
            offset,
            count,
            (data) => {
                success(data[PRODUCTS.entity]);
            },
            error,
        );
    }

    getSalesOrderProducts(idFolder, search, idRate, idCompany, callbackSuccess, callbackError) {
        const offset = 0;
        const count = 500;
        this.context.domainManager.getSalesOrderProducts(
            offset,
            count,
            idFolder,
            search,
            idRate,
            [],
            idCompany,
            '-1',
            (productSearch) => {
                const result = productSearch.Result;
                const group = {};
                result.forEach((product) => {
                    let section = group[product.idFolder];
                    if (!section) {
                        section = {
                            id: product.idFolder,
                            pathid: product.pathId,
                            path: product.path,
                            products: [],
                        };
                        group[product.idFolder] = section;
                    }
                    section.products.push(new ProductModel(product));
                });
                callbackSuccess(
                    Object.keys(group)
                        .map((key) => group[key])
                        .sort((a, b) => Number(a.id) - Number(b.id)),
                );
            },
            callbackError,
        );
    }

    getSalesOrderProductsfuzzy(search, idRate, type, idCompany, callbackSuccess, callbackError) {
        const offset = 0;
        const count = 10;
        const idFolder = -1;
        this.context.domainManager.getSalesOrderProducts(
            offset,
            count,
            idFolder,
            search,
            idRate,
            [],
            idCompany,
            '-1',
            (productSearch) => {
                callbackSuccess(productSearch.Result);
            },
            callbackError,
        );
    }

    getSalesOrderProductsLine(idRate, data, idCompany, callbackSuccess, callbackError) {
        const offset = 0;
        const count = 500;
        const idFolder = -1;
        const search = '';
        this.context.domainManager.getSalesOrderProducts(
            offset,
            count,
            idFolder,
            search,
            idRate,
            data,
            idCompany,
            '-1',
            (productSearch) => {
                callbackSuccess(productSearch.Result);
            },
            callbackError,
        );
    }

    beforeSave = (schema, data) => {
        if (data?.idfamilia?.value) data.idfamilia = data?.idfamilia?.value;
        if (typeof data?.modelo === 'object' && data?.modelo !== null)
            data = { ...data, ...data.modelo };

        if (typeof data?.descripcion === 'object' && data?.descripcion !== null)
            data = { ...data, ...data.descripcion };

        data.available === 'true' || data.available === true
            ? (data.not_available = 'False')
            : (data.not_available = 'True');
        delete data.available;

        if (data?.strIdEnvironments) {
            data.strIdEnvironments = data.strIdEnvironments
                .split(';')
                .filter((env) => env)
                .join(',');
        }

        return data;
    };

    whileInitCrud = (data) => {
        return new Promise((resolve, reject) => {
            let finalData = data;
            const id = data.id || data.Id || '';
            if (id) {
                this.getFilesForCrud(id)
                    .then((files) => {
                        if (files.length) {
                            finalData.files = files?.reverse?.().map((current) => {
                                current.size = Number(current.size);
                                return current;
                            });
                        }
                        resolve(finalData);
                    })
                    .catch((error) => {
                        resolve(finalData);
                    });
            } else {
                resolve(finalData);
            }
        });
    };

    getFilesForCrud(id) {
        return new Promise((resolve, reject) => {
            this.context.entityManager.getEntityDocuments(
                id,
                PRODUCTS,
                (response) => resolve(response),
                (error) => reject(error),
            );
        });
    }

    getFiles(id) {
        Context.store.dispatch(Context.actions.ProductsActions.getFiles(id));
    }

    saveFilesPostSave = (id, files) => {
        if (!files.length || !id) return Promise.resolve();

        const filesToCreate = files.filter(
            (current) => !current.edit && !current.delete && !current.file,
        );
        const filesToEdit = files.filter((current) => current.file && current.edit);
        const filesToDelete = files.filter((current) => current.file && current.delete);

        let promises = [];

        filesToCreate.map((current) => {
            let file = current;
            let name = current.name;

            const uploadPromise = new Promise((resolve, reject) => {
                FilesService.uploadFile({
                    entity: PRODUCTS.entity,
                    entityId: id,
                    name,
                    file,
                    isCarrousel: true,
                }).then((response) => {
                    let counter = 0;
                    const syncro = setInterval(() => {
                        FilesService.getFile(response.data.id).then((data) => {
                            if (counter > 10) {
                                clearInterval(syncro);
                                reject('notSync');
                            }
                            counter++;
                            if (data.data.synchronized) {
                                clearInterval(syncro);
                                resolve();
                            }
                        });
                    }, 300);
                });
            });

            promises.push(uploadPromise);
        });

        filesToEdit.forEach((current) => {
            if (!current.id || !current.file) return Promise.resolve();
            promises.push(FilesService.updateFile(current.id, current.file));
        });

        filesToDelete.forEach((current) => {
            if (!current.id || !current.file) return Promise.resolve();
            promises.push(FilesService.deleteFile(current.id));
        });

        return Promise.all(promises)
            .then(() => {
                this.getFiles(id);

                logEvent({
                    event: PRODUCTS.trueName,
                    submodule: 'crud',
                    functionality: 'attachFiles',
                });
            })
            .catch((error) => {
                console.error(error);
                if (error === 'notSync') {
                    this.getFiles(id);

                    infoToast({ text: getLiteral('label_attachment_waiting_synchronized') });
                }
            });
    };
}

import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Text } from 'hoi-poi-ui';
import { AddOns, AddOnInfo, ModalDisableAddOn } from '@web/web5';
import { ConfigActions } from 'actions';
import { BILLBOARD, CAMPAIGNS, OPPORTUNITIES, KPIS, SALESORDERS } from 'constants/Entities';
import { SettingsService } from 'services';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { errorToast, successToast } from 'utils/toast';
import { getDiffDaysFromToday } from 'utils/dates';
import { isBackendFalsy } from 'utils/fm';
import { trackEvent } from 'utils/intercom';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { logEvent } from 'utils/tracking';
import * as Userflow from 'utils/userflow';
import { TRACKING_EMAIL_DISCLAIMER } from 'containers/components/EmailEditor/constants';
import { ensureRoute } from 'utils/routes';
import useSettings, { checkURLContentType, EMAIL_TYPES } from '../hooks/useSettings';
import { PURCHASE_ADDON } from '../PurchaseModal/constants';

import './styles.scss';

const { getAddOns, getFeatures, getLimits, updateAddOn } = SettingsService;

import {
    ADDON_GOALMANAGER,
    ADDON_SALESORDERS,
    ADDON_OUTLOOK,
    ADDON_CALLERID,
    ADDON_MICROSOFT_TEAMS,
    ADDON_ANDROID_TRACKER,
    ADDON_SCAN_CARD,
    ADDON_OPPS_GEOPOS,
    ADDON_NEWSMANAGER,
    ADDON_SALES_CAMPAIGNS,
    ADDON_EMAIL_TRACKING,
    ICONS,
    PICS,
    STATUS,
    DOWNLOAD_URLS,
    ENTITIES,
    INTERCOM_METADATA,
} from './constants';

const numberFormatOptions = {
    maximumFractionDigits: 2,
};

const AddOnsManagement = () => {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [modalDisable, setModalDisable] = useState(null);
    const [schema, setSchema] = useState({});
    const isFirstLoad = useRef(true);

    const dispatch = useDispatch();

    const {
        currencySymbol,
        licenseEditionCode,
        implementacion,
        trackingEmailDisclaimer,
        isAdmin,
        isBusiness,
    } = useSelector((state) => {
        const config = state.config || {};
        const currencySymbol = config.userData?.currencySymbol;
        const implementacion = config.userData?.implementacion;
        const trackingEmailDisclaimer =
            config?.permission?.trackingEmailDisclaimer || TRACKING_EMAIL_DISCLAIMER.NOT_ACTIVE;

        return {
            currencySymbol,
            licenseEditionCode: config.licenseEditionCode,
            implementacion,
            trackingEmailDisclaimer,
            isAdmin: config?.permission?.canConfigImplementation,
            isBusiness: config?.productModeCode === 'business',
        };
    });

    const trackingConsentRef = useRef(trackingEmailDisclaimer);

    const {
        handleContactRequest,
        handleUpdatePermissions,
        handleUpgradePlan,
        handleModalPurchase,
        isKitDigital,
        isSage,
        planName,
        renderContactModal,
        renderUpgradeModal,
        renderPurchaseModal,
        showComparePlans,
        renderSupportChat,
    } = useSettings({
        submodule: 'addons',
    });

    const handleEnable = useCallback(
        (key, enabled, title) => {
            updateAddOn(key, { value: !!enabled })
                .then((data) => {
                    fetchData(true);
                    if (enabled) {
                        logEvent({
                            event: 'settings',
                            submodule: 'addons',
                            functionality: 'tryIt',
                        });

                        Userflow.track('addon_try_it', { code: key });
                        trackEvent('tryAddon', INTERCOM_METADATA[key]);
                        handleUpdatePermissions(key);

                        handleModalInfo({ itemCode: key, title, statusCode: data.code });
                    } else {
                        logEvent({
                            event: 'settings',
                            submodule: 'addons',
                            functionality: 'disable',
                        });

                        Userflow.track('addon_disabled', { code: key });
                        trackEvent('disableAddon', INTERCOM_METADATA[key]);
                        handleContactRequest({
                            action: EMAIL_TYPES.DISABLE_ADDON,
                            relatedItemLabel: title,
                            onSuccess: () => {
                                setModalDisable(null);
                            },
                        });
                    }
                })
                .catch((error) => {
                    errorToast({
                        text: getLiteral('error_generalerror'),
                    });
                    console.error(error);
                });
        },
        [fetchData, handleContactRequest, handleModalInfo, handleUpdatePermissions],
    );

    const handleTrackingConsent = useCallback(
        (grant) => {
            const isGrantAccepted = grant === TRACKING_EMAIL_DISCLAIMER.ACCEPTED;
            const toastFunction = isGrantAccepted ? successToast : errorToast;
            const title = isGrantAccepted
                ? 'title_sucess_email_tracking_accepted'
                : 'title_success_email_tracking_declined';
            const text = isGrantAccepted
                ? 'label_success_email_accepted'
                : 'label_success_email_tracking_declined';

            dispatch(
                ConfigActions.setDisclaimer(
                    {
                        key: 'trackingEmailDisclaimer',
                        value: isGrantAccepted,
                    },
                    true,
                    { key: 'trackingEmailDisclaimer', value: grant },
                ),
            )
                .then(() => {
                    toastFunction({ title: getLiteral(title), text: getLiteral(text) });
                    logEvent({
                        event: 'settings',
                        submodule: 'addons',
                        functionality: isGrantAccepted ? 'activate' : 'deactivate',
                    });
                    trackingConsentRef.current = grant;
                    fetchData(true);
                })
                .catch((error) => console.error(error));
        },
        [dispatch, fetchData],
    );

    const trackingEmailsCardActions = useCallback(
        (consent) => {
            const actions = [];

            const grant = {
                type: 'primary',
                text: getLiteral('action_activate'),
                onClick: () => handleTrackingConsent(TRACKING_EMAIL_DISCLAIMER.ACCEPTED),
            };

            const deny = {
                type: 'primary',
                text: getLiteral('action_do_not_activate'),
                onClick: () => handleTrackingConsent(TRACKING_EMAIL_DISCLAIMER.NOT_ACCEPTED),
            };

            switch (consent) {
                case TRACKING_EMAIL_DISCLAIMER.ACCEPTED:
                    actions.push(deny);
                    break;
                case TRACKING_EMAIL_DISCLAIMER.NOT_ACCEPTED:
                    actions.push(grant);
                    break;
                default:
                    actions.push(deny);
                    actions.push(grant);
                    break;
            }

            return actions;
        },
        [handleTrackingConsent],
    );

    const trackingEmailsCardInfoAction = useCallback(
        (consent) => {
            return consent === TRACKING_EMAIL_DISCLAIMER.ACCEPTED
                ? {
                      confirmText: getLiteral('action_do_not_activate'),
                      onConfirm: () =>
                          handleTrackingConsent(TRACKING_EMAIL_DISCLAIMER.NOT_ACCEPTED),
                  }
                : {
                      confirmText: getLiteral('action_activate'),
                      onConfirm: () => handleTrackingConsent(TRACKING_EMAIL_DISCLAIMER.ACCEPTED),
                  };
        },
        [handleTrackingConsent],
    );

    const handleDisable = useCallback(
        (key, title) => {
            setModalDisable({
                title: getLiteralWithParameters('title_disable_addons_confirm', [title]),
                content: [getLiteral('title_disable_addons_confirm_desc')],
                confirmText: getLiteral('action_disable_permanently'),
                cancelText: getLiteral('action_cancel'),
                onConfirm: () => handleEnable(key, false, title),
                onCancel: () => setModalDisable((modal) => ({ isOpen: false, ...modal })),
                onRequestClose: () => setModalDisable((modal) => ({ isOpen: false, ...modal })),
            });
        },
        [handleEnable],
    );

    const handleDownload = useCallback((itemCode) => {
        const url = DOWNLOAD_URLS[itemCode];
        if (url) {
            logEvent({
                event: 'settings',
                submodule: 'addons',
                functionality: 'install',
            });
            window.open(getLiteral(url), '_blank');
        }
    }, []);

    const handleLearnMore = useCallback(
        (payload) => {
            handleModalInfo(payload);
            logEvent({
                event: 'settings',
                submodule: 'addons',
                functionality: 'learnMore',
            });
        },
        [handleModalInfo],
    );

    const handlePurchase = useCallback(
        ({ itemCode, title }) => {
            handleModalPurchase({
                type: PURCHASE_ADDON,
                payload: {
                    itemCode,
                    title,
                    onSuccess: () => {
                        handleUpdatePermissions(itemCode);
                        fetchData(true);
                    },
                },
            });

            logEvent({
                event: 'settings',
                submodule: 'addons',
                functionality: 'purchase',
            });
        },
        [fetchData, handleModalPurchase, handleUpdatePermissions],
    );

    const handleGetStarted = useCallback((itemCode) => {
        const entity = ENTITIES[itemCode];
        if (entity) ensureRoute(entity.route);
    }, []);

    const onSuccess = useCallback(
        () =>
            successToast({
                title: getLiteral('label_addon_contact_us'),
                text: getLiteral('label_addon_contact_us_desc'),
            }),
        [],
    );

    const handleModalInfo = useCallback(
        (data) => {
            let content,
                mediaSrc,
                showCancel,
                showContact,
                showDownload,
                showTryIt,
                showStart,
                modalTitle;
            const { itemCode, title, statusCode } = data;

            switch (statusCode) {
                case STATUS.ACTIVE:
                    modalTitle = getLiteralWithParameters('title_addon_unocked', [title]);
                    break;
                case STATUS.FEATURE:
                    modalTitle = getLiteralWithParameters('title_learn_more_addon_information', [
                        title,
                    ]);
                    break;
                default:
                    modalTitle = getLiteralWithParameters('title_addon_in_context_promotion', [
                        title,
                    ]);
                    break;
            }

            let finalData = {};

            switch (true) {
                case itemCode === ADDON_GOALMANAGER && statusCode === STATUS.ACTIVE:
                    content = getLiteral('label_addon_unlocked_goals_desc');
                    mediaSrc = getLiteral('link_video_goals');
                    showCancel = false;
                    showStart = true;
                    break;
                case itemCode === ADDON_GOALMANAGER:
                    content = getLiteral('label_addon_unlock_goals_desc');
                    mediaSrc = getLiteral('link_video_goals');
                    showContact = true;
                    showTryIt = true;
                    break;
                case itemCode === ADDON_SALESORDERS && statusCode === STATUS.ACTIVE:
                    content = getLiteral('label_addon_unlocked_quotesOrders_desc');
                    mediaSrc = getLiteral('link_video_orders_products');
                    showCancel = false;
                    showStart = true;
                    break;
                case itemCode === ADDON_SALESORDERS:
                    content = getLiteral('label_addon_unlock_quotesOrders_desc');
                    mediaSrc = getLiteral('link_video_orders_products');
                    showContact = true;
                    showTryIt = true;
                    break;
                case itemCode === ADDON_OPPS_GEOPOS && statusCode === STATUS.ACTIVE:
                    content = getLiteral('label_addon_unlocked_opps_geopos_desc');
                    mediaSrc = getLiteral('label_opps_geopos_image');
                    showContact = true;
                    showTryIt = true;
                    break;
                case itemCode === ADDON_OPPS_GEOPOS:
                    content = getLiteral('label_addon_unlock_opps_geopos_desc');
                    mediaSrc = getLiteral('label_opps_geopos_image');
                    showCancel = false;
                    showStart = true;
                    break;
                case itemCode === ADDON_NEWSMANAGER && statusCode === STATUS.ACTIVE:
                    content = getLiteral('label_unlocked_newsmanager_desc');
                    mediaSrc = getLiteral('label_newsmanager_video');
                    showCancel = false;
                    showStart = true;
                    break;
                case itemCode === ADDON_NEWSMANAGER:
                    content = getLiteral('label_unlock_newsmanager_desc');
                    mediaSrc = getLiteral('label_newsmanager_video');
                    showContact = true;
                    showTryIt = true;
                    break;
                case itemCode === ADDON_SALES_CAMPAIGNS && statusCode === STATUS.ACTIVE:
                    content = getLiteral('label_addon_unlocked_salescampaigns_desc');
                    mediaSrc = getLiteral('label_video_landing_campaigns');
                    showCancel = false;
                    showStart = true;
                    break;
                case itemCode === ADDON_SALES_CAMPAIGNS:
                    content = getLiteral('label_addon_unlock_salescampaigns_desc');
                    mediaSrc = getLiteral('label_video_landing_campaigns');
                    showContact = true;
                    showTryIt = true;
                    break;
                case itemCode === ADDON_OUTLOOK:
                    content = getLiteral('label_feature_outlook_desc');
                    mediaSrc = getLiteral('link_video_outlook');
                    showContact = true;
                    showDownload = true;
                    break;
                case itemCode === ADDON_CALLERID:
                    content = getLiteral('label_feature_callerid_desc');
                    mediaSrc = getLiteral('link_video_callerid');
                    showContact = true;
                    showDownload = true;
                    break;
                case itemCode === ADDON_MICROSOFT_TEAMS:
                    content = getLiteral('label_feature_microsoft_desc');
                    mediaSrc = getLiteral('link_video_microsoft');
                    showContact = true;
                    showDownload = true;
                    break;
                case itemCode === ADDON_ANDROID_TRACKER:
                    content = getLiteral('label_feature_android_tracker_desc');
                    mediaSrc = getLiteral('link_video_android_tracker');
                    showContact = true;
                    showDownload = true;
                    break;
                case itemCode === ADDON_SCAN_CARD:
                    content = getLiteral('label_feature_scan_card_desc');
                    mediaSrc = getLiteral('link_video_scan_card');
                    showContact = true;
                    showDownload = false;
                    break;
                case itemCode === ADDON_EMAIL_TRACKING:
                    content = getLiteral('label_unlock_email_tracking_desc');
                    mediaSrc = getLiteral('label_video_email_tracking');
                    showContact = true;
                    showDownload = false;
                    finalData = {
                        ...finalData,
                        ...trackingEmailsCardInfoAction(trackingConsentRef.current),
                    };
                    break;
                default:
                    break;
            }

            finalData = {
                ...finalData,
                title: modalTitle,
                onRequestClose: () => setModal((modal) => ({ isOpen: false, ...modal })),
                content,
            };

            if (!!showCancel) {
                finalData.cancelText = getLiteral('action_cancel');
                finalData.onCancel = () => setModal((modal) => ({ isOpen: false, ...modal }));
            }

            if (!!showContact) {
                finalData.middleButtonText = getLiteral('action_contct_us');
                finalData.onMiddleButton = () =>
                    handleContactRequest({ relatedItemLabel: title, onSuccess });
            }

            if (!!showDownload) {
                finalData.confirmText = getLiteral('action_download');
                finalData.onConfirm = () => handleDownload(itemCode);
            }

            if (!!showTryIt) {
                finalData.confirmText = getLiteral('action_try_it_one_month');
                finalData.onConfirm = () => handleEnable(itemCode, true, title);
            }

            if (!!showStart) {
                finalData.confirmText = getLiteral('label_get_started');
                finalData.onConfirm = () => handleGetStarted(itemCode);
            }

            checkURLContentType(mediaSrc)
                .then((type) => {
                    switch (type) {
                        case 'image':
                            finalData.img = { src: mediaSrc, alt: '' };
                            break;
                        case 'video':
                        default:
                            finalData.videoSrc = mediaSrc;
                            break;
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => setModal(finalData));
        },
        [
            handleContactRequest,
            handleDownload,
            handleEnable,
            handleGetStarted,
            trackingEmailsCardInfoAction,
            onSuccess,
        ],
    );

    const handleData = useCallback(
        (results) => {
            const [addOns, features, lim_max_users] = results;

            const totalUsers = lim_max_users.limitUsed;
            const sections =
                isAdmin && !isBusiness
                    ? {
                          title_addons: addOns,
                          label_features: features,
                      }
                    : { label_features: features };

            const newSchema = Object.entries(sections).reduce((obj, [key, items]) => {
                if (items?.length === 0) {
                    return obj;
                } else {
                    const newItems = items.reduce((arr, item) => {
                        const {
                            itemCode,
                            labelDescription,
                            labelHelpText2,
                            labelLearnMore,
                            labelName,
                            price,
                            statusCode = 99,
                            trialExpirationDate,
                            purchasable,
                        } = item;

                        if ([ADDON_MICROSOFT_TEAMS, ADDON_OUTLOOK].includes(itemCode)) {
                            return [...arr];
                        }

                        const title = getLiteral(labelName);
                        let badge, cta, texts, caption, description;

                        const isInFreeTrial =
                            moment(trialExpirationDate).diff(moment(), 'days') <= 30 &&
                            statusCode !== STATUS.PURCHASED;

                        const hasPrice = !isBackendFalsy(price) && purchasable;

                        const hasTrialAvailable =
                            !trialExpirationDate && statusCode === STATUS.INACTIVE;

                        description = getLiteral(labelDescription);

                        switch (statusCode) {
                            case STATUS.UNDEFINED:
                            case STATUS.INACTIVE:
                                badge = {
                                    text: getLiteral('label_card_status_tryit'),
                                    type: 'info',
                                    variant: 'inverted',
                                };
                                texts = getLiteral(labelDescription);
                                cta = [];

                                if (hasTrialAvailable) {
                                    cta.push({
                                        type: 'primary',
                                        text: getLiteral('action_try_it_one_month'),
                                        onClick: () => handleModalInfo({ itemCode, title }),
                                    });
                                }

                                if (purchasable) {
                                    cta.push({
                                        type: 'secondary',
                                        text: getLiteral('action_purchase'),
                                        onClick: () => handlePurchase({ itemCode, title }),
                                    });
                                }
                                break;
                            case STATUS.ACTIVE:
                            case STATUS.PURCHASED:
                                if (isKitDigital || isSage) {
                                    badge = {
                                        text: getLiteral('label_included'),
                                        type: 'promotion',
                                    };
                                } else {
                                    badge = {
                                        text:
                                            trialExpirationDate && statusCode !== STATUS.PURCHASED
                                                ? getLiteralWithParameters(
                                                      'label_campaigns_days_left',
                                                      [getDiffDaysFromToday(trialExpirationDate)],
                                                  )
                                                : getLiteral('label_active'),
                                        type: 'success',
                                        variant: 'inverted',
                                    };
                                    cta = [];
                                    if (
                                        purchasable &&
                                        statusCode !== STATUS.PURCHASED &&
                                        licenseEditionCode === 'starter'
                                    ) {
                                        cta.push({
                                            type: 'primary',
                                            text: getLiteral('action_purchase'),
                                            onClick: () => handlePurchase({ itemCode, title }),
                                        });
                                    }
                                    if (!isInFreeTrial) {
                                        cta.push({
                                            type: 'secondary',
                                            text: getLiteral('action_disable_permanently'),
                                            onClick: () => handleDisable(itemCode, title),
                                        });
                                    }
                                    cta = cta.length ? cta : undefined;
                                }
                                texts = getLiteral(labelHelpText2);
                                break;
                            case STATUS.EXPIRED:
                                badge = {
                                    text: getLiteral('label_card_status_buynow'),
                                    type: 'info',
                                    variant: 'inverted',
                                };
                                texts = getLiteral(labelDescription);

                                cta = [];
                                if (purchasable) {
                                    cta.push([
                                        {
                                            type: 'secondary',
                                            text: getLiteral('action_purchase'),
                                            onClick: () => handlePurchase({ itemCode, title }),
                                        },
                                    ]);
                                }

                                cta.push({
                                    type: 'secondary',
                                    text: getLiteral('action_contct_us'),
                                    onClick: () =>
                                        handleContactRequest({
                                            relatedItemLabel: title,
                                            onSuccess,
                                        }),
                                });
                                break;
                            case STATUS.FEATURE:
                                badge = {
                                    text: getLiteral('label_included'),
                                    type: 'promotion',
                                };
                                texts = getLiteral(labelDescription);

                                cta = [];

                                if (
                                    itemCode !== ADDON_SCAN_CARD &&
                                    itemCode !== ADDON_EMAIL_TRACKING
                                ) {
                                    cta.push({
                                        type: 'primary',
                                        text: getLiteral('action_download'),
                                        onClick: () => handleDownload(itemCode),
                                    });
                                }

                                if (itemCode === ADDON_EMAIL_TRACKING) {
                                    const customDescription = getLiteralWithParameters(
                                        labelDescription,
                                        [implementacion],
                                    );
                                    texts = customDescription;
                                    description = customDescription;
                                    cta = [
                                        ...trackingEmailsCardActions(trackingConsentRef.current),
                                    ];
                                }

                                cta.push({
                                    type: 'secondary',
                                    text: getLiteral('label_learn_more'),
                                    onClick: () =>
                                        handleLearnMore({
                                            itemCode,
                                            title,
                                            statusCode,
                                        }),
                                });
                                break;
                            default:
                                break;
                        }

                        if (statusCode !== STATUS.FEATURE)
                            texts = `${texts}\n\n${getLiteral(labelLearnMore)}`;

                        if (statusCode !== STATUS.FEATURE && totalUsers && hasPrice) {
                            caption = getLiteralWithParameters('label_addon_price_per_user_month', [
                                `${getNumberAsLocaleNumber(
                                    price,
                                    numberFormatOptions,
                                )}${currencySymbol}`,
                            ]);
                        }

                        const newItem = {
                            itemCode,
                            title: getLiteral(labelName),
                            icon: ICONS[itemCode],
                            badge,
                            content: {
                                price: hasPrice
                                    ? `${getNumberAsLocaleNumber(
                                          price * totalUsers,
                                          numberFormatOptions,
                                      )}${currencySymbol}`
                                    : null,
                                noPriceInfo:
                                    !hasPrice &&
                                    statusCode !== STATUS.FEATURE &&
                                    getLiteral('label_check_plans'),
                                description,
                            },
                            figure: {
                                img: PICS[itemCode],
                                caption,
                            },
                            info: {
                                texts,
                                cta,
                            },
                            statusCode,
                        };

                        return [...arr, newItem];
                    }, []);
                    return { ...obj, [key]: newItems };
                }
            }, {});

            setSchema(newSchema);
        },
        [
            currencySymbol,
            handleContactRequest,
            handleDisable,
            handleDownload,
            handleLearnMore,
            handleModalInfo,
            handlePurchase,
            implementacion,
            isAdmin,
            isBusiness,
            isKitDigital,
            isSage,
            licenseEditionCode,
            onSuccess,
            trackingEmailsCardActions,
        ],
    );

    const handleHover = useCallback((itemCode) => {
        const event = {
            [ADDON_GOALMANAGER]: KPIS.trueName,
            [ADDON_SALESORDERS]: SALESORDERS.trueName,
            [ADDON_OPPS_GEOPOS]: OPPORTUNITIES.trueName,
            [ADDON_NEWSMANAGER]: BILLBOARD.trueName,
            [ADDON_SALES_CAMPAIGNS]: CAMPAIGNS.trueName,
        }[itemCode];

        if (!event) return;

        logEvent({
            event,
            submodule: 'addons',
            functionality: 'hover',
        });
    }, []);

    const fetchData = useCallback(
        (silent) => {
            !silent && setLoading(true);

            Promise.all([getAddOns(), getFeatures(), getLimits('lim_max_users')])
                .then((results) => {
                    handleData(results);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        },
        [handleData],
    );

    useEffect(() => {
        if (!isFirstLoad.current) return;
        fetchData();
        isFirstLoad.current = false;
    }, [fetchData]);

    const getHeader = useMemo(
        () => (
            <div className="fm-am__header">
                <div className="fm-lm__header__content">
                    <Text type="h6">{getLiteral('title_addons')}</Text>
                    <Text type="body" color="neutral700">
                        {getLiteralWithParametersHtml(
                            'label_manage_your_plan_addons_description',
                            [getLiteralWithParameters('label_plan_name', [planName])],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>
                    {renderSupportChat}
                </div>
                {showComparePlans && (
                    <Button type="primary" onClick={handleUpgradePlan}>
                        {getLiteral('action_compare_your_plan')}
                    </Button>
                )}
            </div>
        ),
        [planName, renderSupportChat, showComparePlans, handleUpgradePlan],
    );

    const getSections = useMemo(() => {
        return Object.entries(schema).map(([title, items]) => (
            <div className="fm-am__section" key={title}>
                <Text type="subtitle" color="neutral900" className="fm-am__section__title">
                    {getLiteral(title)}
                </Text>
                <AddOns schema={items} onHover={handleHover} />
            </div>
        ));
    }, [handleHover, schema]);

    const renderAddOnInfo = useMemo(() => {
        const props = {
            isOpen: !!modal,
            ...modal,
        };
        return <AddOnInfo {...props} />;
    }, [modal]);

    const renderDisableAddOn = useMemo(() => {
        const props = {
            isOpen: !!modalDisable,
            ...modalDisable,
        };
        return <ModalDisableAddOn {...props} />;
    }, [modalDisable]);

    return !loading ? (
        <div className="fm-am">
            {getHeader}
            <div className="fm-am__grid">{getSections}</div>
            {renderAddOnInfo}
            {renderContactModal}
            {renderDisableAddOn}
            {renderUpgradeModal}
            {renderPurchaseModal}
        </div>
    ) : null;
};

export default memo(AddOnsManagement);

import React from 'react';
import colors from 'constants/colors';

const Document = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.5 18V1.7C2.5.8 3.2 0 4.1 0h5.7v5.1h5.6v11.2c0 .9-.7 1.7-1.6 1.7H2.5zm13-14.2h-4.4V0l4.4 3.8z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Document;

import PropTypes from 'prop-types';
import React, { Component } from 'react';

class RenderPosition extends Component {
    state = {};

    render() {
        const { styles, width, edit, value, config, rowIndex } = this.props;
        return (
            <div style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
                <div style={styles.text}>{rowIndex + 1}</div>
            </div>
        );
    }
}

export default RenderPosition;

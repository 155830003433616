import MyRawTheme from '../../styles/themes/ForceManagerTheme';
import TextStyle from '../../styles/text';

export const buttonStyle = (hover, clicked, disabled) => ({
    fontFamily: TextStyle.fontFamily,
    fontSize: 13,
    fontWeight: 300,
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: '8px 25px 8px 25px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    borderRadius: 21,
    boxShadow: disabled
        ? 'none'
        : clicked
          ? 'inset 0px -1px 3px 0px rgba(0,0,0,0.10)'
          : hover
            ? '0 1px 1px 0 rgba(33,33,33,.3)'
            : '',
});

export const buttonContent = (hover, clicked, disabled) => ({
    ...buttonStyle(hover, clicked, disabled),
    background: disabled ? '#dddddd' : '#ff8c00',
    color: '#fff',
});

export const buttonPrimary = (hover, clicked, disabled) => ({
    ...buttonStyle(hover, clicked, disabled),
    background: disabled ? '#dddddd' : 'white',
    color: 'black',
    boxShadow: 'none',
});

export const buttonSecondary = (hover, clicked, disabled) => ({
    ...buttonStyle(hover, clicked, disabled),
    background: 'TRANSPARENT',
    color: disabled ? '#dddddd' : '#ff8c00',
    border: disabled ? '1px solid #dddddd' : '1px solid #ff8c00',
});

export const buttonSecondarySmall = (hover, clicked, disabled) => ({
    ...buttonSecondary(hover, clicked, disabled),
    padding: '0px 10px 0px 10px',
    lineHeight: '19px',
    height: 20,
    boxSizing: 'border-box',
    fontSize: 10,
    borderRadius: 100,
    color: hover ? '#fff' : '#ff8c00',
    background: hover || clicked ? '#ff8c00' : 'TRANSPARENT',
    boxShadow: disabled
        ? 'none'
        : clicked
          ? 'inset 0px -1px 3px 0px rgba(0,0,0,0.24)'
          : hover
            ? ''
            : '',
});

export const buttonPrimarySmall = (hover, clicked, disabled) => ({
    ...buttonPrimary(hover, clicked, disabled),
    background: 'TRANSPARENT',
    padding: '0px 10px 0px 10px',
    lineHeight: '20px',
    color: disabled ? '#dddddd' : '#8D8D8D',
    border: '1px solid #dddddd',
    fontSize: 12,
});

export const buttonText = {
    ...TextStyle.button,
};

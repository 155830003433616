import React, { memo } from 'react';
import { Chip, Text } from 'hoi-poi-ui';

const InfoWithChipRow = memo(({ label, value }) => {
    if (!label || !value) return null;
    return (
        <div className="info-row__chip">
            <Text isTruncated>{label}:</Text>
            <Chip className="info-row__chip-value">{value}</Chip>
        </div>
    );
});

export default InfoWithChipRow;

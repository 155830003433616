import { Auth } from 'services';
import Cookies from 'js-cookie';
import { isEqual } from 'utils/objects';
import { goAfterLogin, ensureRoute, getCookieDomainName, goLogin } from 'utils/routes';
import { getNewToken } from 'services';
import { sha1FM } from 'utils/fm';

export default class SessionManager {
    initSession(name, password, userKey) {
        return new Promise((resolve, reject) => {
            userKey = userKey || sha1FM(`${name}|${password}`);
            this.removeSessionData();
            Auth.getToken(name, password)
                .then((data) => this.context.getConfig(data.token, userKey, data.refreshToken))
                .then((config) => {
                    Cookies.set('user_token', name, {
                        domain: getCookieDomainName(window.location.hostname),
                        secure: true,
                        expires: 31,
                    });

                    Cookies.set('fm_partner', config.productModeCode, {
                        domain: getCookieDomainName(window.location.hostname),
                        secure: true,
                    });

                    return this.context.initConfig(config);
                })
                .then((config) => {
                    if (!config.contracts?.length) goAfterLogin(config);
                    resolve(config);
                })
                .catch((err) => {
                    console.error(err);
                    if (err?.error) {
                        let errorLiteral;
                        switch (err.error) {
                            case 'error_implementation_blocked':
                            case 'error_login_blocked':
                                errorLiteral = 'label_user_blocked';
                                break;
                            case 'no user':
                                errorLiteral = 'helptext_password_wrong';
                                break;
                            case 'error_password_has_expired':
                                window.sessionStorage.setItem(
                                    'auth_expired',
                                    JSON.stringify({
                                        strCellPhoneNumber: name,
                                        userKey,
                                    }),
                                );
                                ensureRoute('/expired-password');
                                break;
                        }
                        reject(errorLiteral);
                    } else reject();
                });
        });
    }

    initSessionWithToken(name, password) {
        return new Promise((resolve, reject) => {
            this.context.domainManager
                .getTokenFromConfig(name, password)
                .then((config) =>
                    getNewToken(config.token).then((data) => {
                        config.token = data.token;
                        config.refreshToken = data.refreshToken;
                        return this.context.getConfig(config.token, password, config.refreshToken);
                    }),
                )
                .then((config) => {
                    Cookies.set('token', config.token, {
                        domain: getCookieDomainName(window.location.hostname),
                        secure: true,
                        expires: 31,
                    });

                    if (config.refreshToken)
                        Cookies.set('refreshToken', config.refreshToken, {
                            domain: getCookieDomainName(window.location.hostname),
                            secure: true,
                            expires: 31,
                        });

                    Cookies.set('user_token', name, {
                        domain: getCookieDomainName(window.location.hostname),
                        secure: true,
                        expires: 31,
                    });

                    Cookies.set('fm_partner', config.productModeCode, {
                        domain: getCookieDomainName(window.location.hostname),
                        secure: true,
                    });

                    return this.context.initConfig(config);
                })
                .then((config) => {
                    if (!config.contracts?.length) goAfterLogin(config);
                    resolve(config);
                })
                .catch((err) => {
                    console.error(err);
                    if (err?.error) {
                        let errorLiteral;
                        switch (err.error) {
                            case 'error_implementation_blocked':
                            case 'error_login_blocked':
                                errorLiteral = 'label_user_blocked';
                                break;
                            case 'no user':
                                errorLiteral = 'helptext_password_wrong';
                                break;
                            case 'error_password_has_expired':
                                window.sessionStorage.setItem(
                                    'auth_expired',
                                    JSON.stringify({
                                        strCellPhoneNumber: name,
                                        userKey: password,
                                    }),
                                );
                                ensureRoute('/expired-password');
                                break;
                        }
                        reject(errorLiteral);
                    } else reject();
                });
        });
    }

    removeSessionData() {
        Cookies.remove('fm_partner', {
            domain: getCookieDomainName(window.location.hostname),
        });
        Cookies.remove('token', {
            domain: getCookieDomainName(window.location.hostname),
        });
        Cookies.remove('refreshToken', {
            domain: getCookieDomainName(window.location.hostname),
        });
        Cookies.remove('user_token', {
            domain: getCookieDomainName(window.location.hostname),
        });
        this.context.setConfig(undefined);
        this.context.cacheManager.clear();
        sessionStorage.clear();
        try {
            userflow?.reset();
            window.Intercom?.('shutdown');
        } catch (e) {
            console.error(e);
        }
    }

    closeSession(success) {
        this.removeSessionData();
        success?.();
        goLogin();
    }

    setConfigWeb(name, config, partial, success, error) {
        let { components } = this.context.store.getState().config;
        if (isEqual(components[name], config)) return success(components);

        if (partial && !Array.isArray(config)) {
            components = { ...components, [name]: { ...components[name], ...config } };
        } else {
            components = { ...components, [name]: config };
        }

        this.context.domainManager.setConfiguration(
            components,
            () => {
                this.updateConfigStore({ components });
                success(components);
            },
            error,
        );
    }

    setAllConfigWeb(components) {
        return new Promise((resolve, reject) => {
            this.context.domainManager.setConfiguration(
                components,
                () => {
                    this.updateConfigStore({ components });
                    resolve(components);
                },
                reject,
            );
        });
    }

    wrapperConfig(config) {
        // this method is in web3 project, because we need the information just as there, and working
        // with web3 does not make all the changes this method do if you don't keep it synchronized
        const headerConfig = {};

        config.columnsUsuario.entity &&
            config.columnsUsuario.entity.forEach((element, index, array) => {
                headerConfig[element.name] = headerConfig[element.name]
                    ? headerConfig[element.name]
                    : [];
                element.field.map((field) => {
                    headerConfig[element.name].push(field.name);
                });
            });

        config.standardFieldsSchema =
            config.standardFieldsSchema.entity &&
            config.standardFieldsSchema.entity.reduce((obj, d) => {
                if (!obj[d.name]) obj[d.name] = [];
                obj[d.name] = obj[d.name].concat(d.field);
                return obj;
            }, {});

        const crudPermission = {};
        config.permisos.crud_permission.permission.forEach((element, index, array) => {
            crudPermission[element.entity] = element;
        });

        config.permisos.crud_permission = crudPermission;
        config.views = config.views.view;
        config.headerConfig = headerConfig;
        config.columnsUsuario = undefined;
        config.webTemplates = this.hardcodeMissingWebTemplates(
            this.buildWebTemplates(config.webTemplates),
        );

        return config;
    }

    buildWebTemplates(webTemplates) {
        if (!webTemplates) webTemplates = {};
        if (webTemplates.hasOwnProperty('webTemplate')) {
            const arrayWebTemplates = webTemplates.webTemplate;
            if (!arrayWebTemplates) return {};
            const result = {};
            for (let i = 0; i < arrayWebTemplates.length; i++) {
                const template = arrayWebTemplates[i];
                result[template.name] = template.widget;
            }
            return result;
        }
        return {};
    }

    hardcodeMissingWebTemplates(webTemplates) {
        // if we don't have webtemplate for specific entity, we should add
        // a basic template to load all the widgets
        if (webTemplates && !webTemplates.hasOwnProperty('mnSalesOrders')) {
            const widgets = [
                {
                    id: '28',
                    idwidget: '28',
                    name: '',
                    columns: '2',
                    rows: '1',
                    widgetcontent: '',
                    idreport: '',
                },
                {
                    id: '30',
                    idwidget: '30',
                    name: '',
                    columns: '2',
                    rows: '1',
                    widgetcontent: '',
                    idreport: '',
                },
                {
                    id: '29',
                    idwidget: '29',
                    name: '',
                    columns: '2',
                    rows: '1',
                    widgetcontent: '',
                    idreport: '',
                },
            ];
            webTemplates.mnSalesOrders = widgets;
        }
        if (webTemplates && !webTemplates.hasOwnProperty('mnExpediente')) {
            webTemplates.mnExpediente = [
                {
                    idwidget: '1',
                    name: 'Activities',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '6',
                    name: 'Quotes',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '31',
                    name: 'Salesorders',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '9',
                    name: 'Documents',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '10',
                    name: 'Reports',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '2',
                    name: 'Agenda',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '4',
                    name: 'Extrafields',
                    columns: '2',
                    rows: '1',
                },
            ];
        }

        if (webTemplates && !webTemplates.hasOwnProperty('mnEmpresa')) {
            webTemplates.mnEmpresa = [
                {
                    idwidget: '25',
                    name: 'Kpis',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '26',
                    name: 'Interactions',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '27',
                    name: 'Contacts',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '1',
                    name: 'Activities',
                    columns: '2',
                    rows: '2',
                },
                {
                    idwidget: '4',
                    name: 'Extrafields',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '2',
                    name: 'Agenda',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '3',
                    name: 'Opportunities',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '9',
                    name: 'Documents',
                    columns: '2',
                    rows: '1',
                },
            ];
        }
        if (webTemplates && !webTemplates.hasOwnProperty('mnContacto')) {
            webTemplates.mnContacto = [
                {
                    idwidget: '1',
                    name: 'Activities',
                    columns: '2',
                    rows: '1',
                },
                {
                    idwidget: '2',
                    name: 'Agenda',
                    columns: '2',
                    rows: '1',
                },
            ];
        }
        return webTemplates;
    }

    refreshWebTemplates(success, error) {
        this.context.domainManager.refreshWebTemplates((webTemplates) => {
            const data = this.hardcodeMissingWebTemplates(this.buildWebTemplates(webTemplates));
            this.updateConfigStore({ webTemplates: data });
            success && success(data);
        }, error);
    }

    updateSpecificPermissions(permissions) {
        const sessionData = this.context.cacheManager.getConfigStore();
        if (sessionData) {
            for (const permission of permissions) {
                sessionData.permisos[permission.key] = permission.value;
            }
            this.context.cacheManager.setConfigStore(sessionData);
        }

        return sessionData;
    }

    updateConfigStore(values) {
        let sessionData = this.context.cacheManager.getConfigStore();
        if (sessionData) {
            sessionData = {
                ...sessionData,
                ...values,
            };
            this.context.cacheManager.setConfigStore(sessionData);
        }
        return sessionData;
    }

    updateUserDataConfiguration(values) {
        const sessionData = this.context.cacheManager.getConfigStore();
        if (sessionData && sessionData.userData) {
            sessionData.userData = {
                ...sessionData.userData,
                ...values,
            };
            this.context.cacheManager.setConfigStore(sessionData);
        }
        return sessionData;
    }
}

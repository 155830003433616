import { getMomentFromDateBackend, getDifferenceDays } from 'utils/dates';
import CrudModel from './CrudModel';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteralWithParameters } from 'utils/getLiteral';
import Context from 'managers/Context';
import { CAMPAIGNS } from 'constants/Entities';

export default class CampaignModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toCrud({ data }) {
        let campaignQuestions = data.campaignQuestions ? data.campaignQuestions : [];
        campaignQuestions = campaignQuestions.filter(
            (question) => question.properties.type !== 'bool',
        );
        campaignQuestions.sort((a, b) => a.properties.order > b.properties.order);

        return {
            id: data.id,
            campaignDate: {
                campaignStartDate: data.campaignStartDate
                    ? new Date(data.campaignStartDate.time)
                    : null,
                campaignEndDate: data.campaignEndDate ? new Date(data.campaignEndDate.time) : null,
            },
            campaignQuestions,
            ownerId: data.ownerId,
            statusId: data.statusId,
            typeId: String(data.typeId),
            title: data.title,
            totalAccountsCampaign: data.totalAccountsCampaign || 0,
            accountOwners: data.teamData ? data.teamData.map((owner) => owner.salesRepName) : [],
            token: data.token,
            files: data.files || [],
            descriptionRich: {
                html: data.description,
                text: data.descriptionText,
            },
            videoUrl: data.videoUrl,
        };
    }

    static toList({ data, ignoreSegments }) {
        const state = Context.store.getState();
        const filters = state?.entityFilters?.[CAMPAIGNS.entity]?.filters || null;

        const sectionsData = {
            drafts: [],
            ongoing: [],
            ended: [],
            scheduled: [],
        };

        let segments = [];

        const newData = data.map((current) => {
            const percentageCompleted = Math.min(
                parseInt(
                    (
                        (current.totalAccountsCampaignCompleted / current.totalAccountsCampaign) *
                        100
                    ).toFixed(),
                    10,
                ) || 0,
                999,
            );

            let srcOwner = '';
            let placeholderOwner = '';

            const { src, fallbackSrc } = getSrcUserCircleAvatar(current.ownerId);
            placeholderOwner = fallbackSrc;
            srcOwner = src;

            let startDate = '';
            if (current.campaignStartDate) {
                const moment = getMomentFromDateBackend(current.campaignStartDate.timeStamp, 'X');
                const momentEnd = getMomentFromDateBackend(
                    current.campaignStartDate.timeStamp,
                    'X',
                );
                startDate = `${moment.format('DD')} ${moment.format('MMM')}.`;
                if (!moment.isSame(momentEnd, 'year')) startData += ` ${moment.year()}`;
            }

            let endDate = '';
            if (current.campaignEndDate) {
                const moment = getMomentFromDateBackend(current.campaignEndDate.timeStamp, 'X');
                endDate = `${moment.format('DD')} ${moment.format('MMM')}. ${moment.year()}`;
            }

            const campaignRangeDate = getLiteralWithParameters('label_campaigns_date_range', [
                startDate,
                endDate,
            ]);

            const rowData = {
                rawData: { ...current },
                ...current,
                accountsProgress: `${current.totalAccountsCampaignCompleted}/${current.totalAccountsCampaign}`,
                percentageCompleted,
                srcOwner,
                placeholderOwner,
                dateRange: '',
                totalAccountOwners: current.totalAccountOwnersCampaign || '0',
                campaignRangeDate,
            };

            if (!segments.includes(rowData.statusId)) {
                segments.push(rowData.statusId);
                const sectionTitle = {
                    id: `divider-${rowData.statusId}`,
                    value: rowData.statusDescription,
                    isFullWidth: true,
                };
                addDataToSection(sectionsData, rowData.statusId, sectionTitle);
                addDataToSection(sectionsData, rowData.statusId, rowData);
            } else {
                addDataToSection(sectionsData, rowData.statusId, rowData);
            }

            return rowData;
        });

        if (!ignoreSegments && !filters?.statusId) {
            return [
                ...sectionsData.drafts,
                ...sectionsData.ongoing,
                ...sectionsData.ended,
                ...sectionsData.scheduled,
            ];
        } else {
            return newData;
        }
    }

    get id() {
        return this.entity.id;
    }

    get title() {
        return this.entity.title;
    }

    get percentage() {
        const completed = parseInt(this.entity.totalaccountscampaigncompleted, 10) || '';
        const total = parseInt(this.entity.totalaccountscampaign, 10) || '';
        let percentage = 0;
        if (total && total !== 0) {
            percentage = ((completed / total) * 100).toFixed(0);
        }
        return percentage;
    }

    get description() {
        return this.entity.description;
    }

    get descriptionText() {
        return this.entity.descriptiontext;
    }

    get totalAccountsCampaign() {
        return this.entity.totalaccountscampaign;
    }

    get totalAccountsCampaignCompleted() {
        return this.entity.totalaccountscampaigncompleted;
    }

    get startDate() {
        if (!this.entity.campaignstartdate) return '';
        const moment = getMomentFromDateBackend(this.entity.campaignstartdate.timeStamp, 'X');
        const momentEnd = getMomentFromDateBackend(this.entity.campaignenddate.timeStamp, 'X');
        let date = `${moment.format('DD')} ${moment.format('MMM')}.`;
        if (!moment.isSame(momentEnd, 'year')) date += ` ${moment.year()}`;
        return date;
    }

    get endDate() {
        if (!this.entity.campaignenddate) return '';
        const moment = getMomentFromDateBackend(this.entity.campaignenddate.timeStamp, 'X');
        let date = `${moment.format('DD')} ${moment.format('MMM')}. ${moment.year()}`;
        return date;
    }

    get remainingDays() {
        if (!this.entity.campaignenddate) return '';
        const date = getMomentFromDateBackend(this.entity.campaignenddate.timeStamp, 'X');
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const diff = getDifferenceDays(date);
        return diff;
    }

    get typeDescription() {
        return this.entity.typedescription;
    }

    get teamData() {
        return this.entity.teamdata;
    }

    get status() {
        return this.entity.statusid;
    }

    get statusDescription() {
        return this.entity.statusdescription;
    }

    get attachmentCount() {
        return this.entity.attachmentcount;
    }

    get campaignTypeName() {
        return this.entity.campaigntypename;
    }

    get ownerName() {
        return this.entity.ownername;
    }

    get files() {
        return this.entity.files || [];
    }

    get videoUrl() {
        return this.entity.videourl;
    }
}

const addDataToSection = (sectionsData, statusId, data) => {
    switch (statusId) {
        case 1:
            sectionsData.drafts.push(data);
            break;
        case 2:
            sectionsData.ongoing.push(data);
            break;
        case 4:
            sectionsData.ended.push(data);
            break;
        case 3:
            sectionsData.scheduled.push(data);
            break;
        default:
            break;
    }
};

import Axios from 'axios';
import { axios } from './axios';
import { handleErrors } from './utils';
import { getLiteral } from 'utils/getLiteral';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getVisibleInOptions } from 'models/AnalyticsReportModel';

function listToTree(list) {
    let map = {},
        node,
        roots = [],
        i;

    for (i = 0; i < list.length; i += 1) {
        list[i].children = [];
        list[i].dashboards = [];
        if (list[i].isDashboard) break;
        map[list[i].id] = i;
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.isDashboard) {
            // Dashboard
            list[map[node.folderId]]?.dashboards.push(node);
        } else {
            // Folder
            if (node.parentId !== -1 && list[map[node.parentId]]) {
                list[map[node.parentId]].children.push(node);
            } else {
                roots.push(node);
            }
        }
    }
    return roots;
}

function transformGenericFields(data, visibleIn) {
    const image = getSrcUserCircleAvatar(data.salesRepIdCreated.id);
    return {
        creator: data.salesRepIdCreated.value,
        creatorSrc: image.src,
        creatorPlaceholder: image.fallbackSrc,
        dateCreated: data.dateCreated?.time,
        dateUpdated: data.dateUpdated?.time,
        visibleIn:
            data.visibleIn?.length > 0
                ? data.visibleIn.reduce((string, item) => {
                      if (string) {
                          string = `${string}; ${visibleIn[item]}`;
                      } else {
                          string = visibleIn[item];
                      }
                      return string;
                  }, '')
                : '',
    };
}

function transformWidgetFields(data, visibleInOptions) {
    let visibleIn = '';
    if (data.displayedInAccounts) {
        if (visibleIn) visibleIn = `${visibleIn}; ${visibleInOptions.accounts}`;
        else visibleIn = visibleInOptions.accounts;
    }
    if (data.displayedInOpportunities) {
        if (visibleIn) visibleIn = `${visibleIn}; ${visibleInOptions.opportunities}`;
        else visibleIn = visibleInOptions.opportunities;
    }

    return {
        visibleIn,
    };
}

let GET_ANALYTICS_REQUESTS = [];
export function getAnalytics(search) {
    if (GET_ANALYTICS_REQUESTS.length) {
        GET_ANALYTICS_REQUESTS.map((req) => req.cancel());
        GET_ANALYTICS_REQUESTS = [];
    }
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    GET_ANALYTICS_REQUESTS.push(source);
    return new Promise((resolve, reject) => {
        axios
            .get('/analytics/v1/configuration', {
                params: {
                    title: search,
                },
                cancelToken: source.token,
            })
            .then(({ data }) => {
                const visibleIn = getVisibleInOptions().reduce((obj, option) => {
                    obj[option.value] = option.label;
                    return obj;
                }, {});

                const folders =
                    data.folders?.map((folder) => ({
                        ...folder,
                        isDashboardFolder: true,
                        name: folder.title,
                        ...transformGenericFields(folder, visibleIn),
                    })) || [];

                const dashboards =
                    data.dashboards?.map((dashboard) => ({
                        ...dashboard,
                        isDashboard: true,
                        name: dashboard.title,
                        ...transformGenericFields(dashboard, visibleIn),
                    })) || [];

                const widgets = [];
                // data.widgets?.map((widget) => ({
                //     ...widget,
                //     isWidget: true,
                //     ...transformGenericFields(widget, visibleIn),
                //     ...transformWidgetFields(widget, visibleIn),
                // })) || [];

                const widgetDashboardsSorted = [...dashboards, ...widgets].sort((a, b) => {
                    const aDate = new Date(a.dateCreated || a.dateModified);
                    const bDate = new Date(b.dateCreated || b.dateModified);
                    return aDate - bDate;
                });

                let favorites = widgetDashboardsSorted.filter((item) => item.favorite);

                resolve({
                    widgets: widgets,
                    widgetDashboardsSorted,
                    dashboards,
                    folders,
                    favorites,
                    tree: {
                        id: -1,
                        name: getLiteral('label_dashboards'),
                        title: getLiteral('label_dashboards'),
                        children: listToTree([...folders, ...dashboards]),
                        dashboards: dashboards.filter((dashboard) => dashboard.folderId === -1),
                        toggled: false,
                        active: true,
                        isDashboardFolder: true,
                        shared: true,
                    },
                });
            })
            .catch(handleErrors(reject));
    });
}

export function getDashboard(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/analytics/v1/dashboards/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getWidget(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/analytics/v1/widgets/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createFolder(data) {
    return new Promise((resolve, reject) => {
        axios
            .post('/analytics/v1/folders', data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createDashboard(data) {
    return new Promise((resolve, reject) => {
        axios
            .post('/analytics/v1/dashboards', data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function updateFolder(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/analytics/v1/folders/${id}`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function updateDashboard(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/analytics/v1/dashboards/${id}`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteDashboard(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/analytics/v1/dashboards/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteFolder(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/analytics/v1/folders/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteWidget(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/analytics/v1/widgets/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getDashboardPath(id, isEditing) {
    return new Promise((resolve, reject) => {
        let url = `/analytics/v1/dashboards/${id}/path`;
        if (isEditing) url + '?edit=true';
        axios
            .get(url)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getDashboardURL(id, params = {}) {
    return new Promise((resolve, reject) => {
        let url = `/analytics/v1/dashboards/${id}/url`;
        axios
            .get(url, {
                params: {
                    domain: location.protocol + '//' + location.host,
                    ...params,
                },
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getWidgetURL(id, params = {}) {
    return new Promise((resolve, reject) => {
        let url = `/analytics/v1/widgets/${id}/url`;
        axios
            .get(url, {
                params,
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function markFavorite(id, isReport) {
    const url = isReport
        ? `/analytics/v1/dashboards/${id}/favorite`
        : `/analytics/v1/widgets/${id}/favorite`;

    return axios.put(url);
}

export function unmarkFavorite(id, isReport) {
    const url = isReport
        ? `/analytics/v1/dashboards/${id}/favorite`
        : `/analytics/v1/widgets/${id}/favorite`;

    return axios.delete(url);
}

export function moveFolder(itemId, folderId) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/analytics/v1/folders/${itemId}/move/${folderId}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function moveDashboard(itemId, folderId) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/analytics/v1/dashboards/${itemId}/move/${folderId}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function duplicateDashboard(id) {
    return new Promise((resolve, reject) => {
        axios({ url: `/analytics/v1/dashboards/${id}`, method: 'COPY' })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function duplicateWidget(id) {
    return new Promise((resolve, reject) => {
        axios({ url: `/analytics/v1/widgets/${id}`, method: 'COPY' })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

// Dashboard scheduling

export function getScheduling(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/analytics/v1/dashboards/${id}/scheduling`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createScheduling(id, body) {
    return new Promise((resolve, reject) => {
        axios
            .post(`/analytics/v1/dashboards/${id}/scheduling`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function updateScheduling(id, body) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/analytics/v1/dashboards/${id}/scheduling/${body.id}`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteScheduling(idDashboard, idScheduling) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/analytics/v1/dashboards/${idDashboard}/scheduling/${idScheduling}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';

export default class AnalyticsWidgetManager {
    getSchema = (defaultInputSchema) => {
        return [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'title',
                        type: 'text',
                        mandatory: true,
                        label: getLiteral('label_analytics_pro_widget_title'),
                    },
                    {
                        ...defaultInputSchema,
                        id: 'description',
                        type: 'textarea',
                        label: getLiteral('label_analytics_pro_widget_description'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 5,
                            rowsMax: 15,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'displayedInAccounts',
                        fieldConfiguration: 'displayedInAccounts',
                        type: 'bool',
                        label: getLiteral('label_analytics_pro_widget_display_account'),
                        description: getLiteral(
                            'label_analytics_pro_widget_display_account_description',
                        ),
                    },
                    {
                        ...defaultInputSchema,
                        id: 'displayedInOpportunities',
                        fieldConfiguration: 'displayedInOpportunities',
                        type: 'bool',
                        label: getLiteral('label_analytics_pro_widget_display_opportunity'),
                        description: getLiteral(
                            'label_analytics_pro_widget_display_opportunity_description',
                        ),
                    },
                ],
            },
            {
                title: getLiteral('label_analytics_pro_display_account_section'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'accountsOrder',
                        fieldConfiguration: 'accountsOrder',
                        type: 'number',
                        label: getLiteral('label_order'),
                    },
                    {
                        ...defaultInputSchema,
                        id: 'accountsSize',
                        fieldConfiguration: 'accountsSize',
                        type: 'size',
                        label: getLiteral('label_size'),
                    },
                ],
            },
            {
                title: getLiteral('label_analytics_pro_display_opportunity_section'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'opportunitiesOrder',
                        fieldConfiguration: 'opportunitiesOrder',
                        type: 'number',
                        label: getLiteral('label_order'),
                    },
                    {
                        ...defaultInputSchema,
                        id: 'opportunitiesSize',
                        fieldConfiguration: 'opportunitiesSize',
                        type: 'size',
                        label: getLiteral('label_size'),
                    },
                ],
            },
        ];
    };

    afterGetSchema({ schema, dependencyMap, dynamicMap }) {
        dynamicMap.displayedInAccounts = {
            accountsOrder: [true],
            accountsSize: [true],
        };
        dynamicMap.displayedInOpportunities = {
            opportunitiesOrder: [true],
            opportunitiesSize: [true],
        };
        return Promise.resolve({
            schema,
            dependencyMap,
            dynamicMap,
        });
    }

    getSpecificEntityDefaultValues = (data, id) => {
        if (!data) return;

        data.accountsSize = 'small';
        data.opportunitiesSize = 'small';

        return data;
    };

    beforeSave = (schema, data) => {
        const sizes = {
            small: {
                columns: 1,
                rows: 1,
            },
            vertical: {
                columns: 2,
                rows: 2,
            },
            horizontal: {
                columns: 3,
                rows: 1,
            },
            big: {
                columns: 3,
                rows: 2,
            },
        };

        const displayedInAccounts = getBackendBoolean(data.displayedInAccounts);
        data.displayedInAccounts = displayedInAccounts;
        if (displayedInAccounts) {
            data.accountsSizeColumns = sizes[data.accountsSize].columns;
            data.accountsSizeRows = sizes[data.accountsSize].rows;
            delete data.accountsSize;
        }

        const displayedInOpportunities = getBackendBoolean(data.displayedInOpportunities);
        data.displayedInOpportunities = displayedInOpportunities;
        if (displayedInOpportunities) {
            data.opportunitiesSizeColumns = sizes[data.opportunitiesSize].columns;
            data.opportunitiesSizeRows = sizes[data.opportunitiesSize].rows;
            delete data.opportunitiesSize;
        }

        return data;
    };
}

import {
    ENTITY_FILTERS_CHANGE,
    ENTITY_FILTERS_TOGGLE,
    ENTITY_FILTERS_HIDE,
    ENTITY_FILTERS_DRAGGING,
    ENTITY_FILTERS_CHANGE_OUTLIER,
    ENTITY_ADV_FILTERS_CHANGE,
    ENTITY_CROSS_FILTERS_CHANGE,
    ENTITY_CROSS_ADV_FILTERS_CHANGE,
    ENTITY_CROSS_FILTERS_FORCE_DISABLE,
} from 'constants/ActionTypes';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import {
    formatSelectionFilters,
    formatSelectionOperators,
    formatCrossFilters,
} from 'domain/FilterApiWrapper';
import Context from 'managers/Context';
import { ConfigActions } from 'actions';
import { FILTERS_CACHE_KEY, ENTITIES_WHITELIST } from 'constants/FilterCache';
import { inConversations } from 'containers/Activities/utils/conversations';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';

function _changeFilter({
    entity,
    filters,
    isFirstLoad,
    isInvisible,
    isPreload,
    crossEntity,
    clearAdvancedAndCrossFilters,
}) {
    const type = !!crossEntity ? ENTITY_CROSS_FILTERS_CHANGE : ENTITY_FILTERS_CHANGE;
    return {
        type,
        filters,
        entity,
        isFirstLoad,
        isInvisible,
        isPreload,
        crossEntity,
        clearAdvancedAndCrossFilters,
    };
}

function _updateEntity({ entity, refresh = true, getState, dispatch, info, isEntityList, filter }) {
    const state = getState();
    const manager = Context.entityManager.getEntitiesManager(entity);

    if (manager.initFiltersColor && info?.isAPurge) {
        manager.initFiltersColor();
    } else if (manager.setFiltersColor && info?.option?.value && filter) {
        manager.setFiltersColor(filter, info.option.value);
    }

    const newIsEntityList = typeof isEntityList !== 'boolean' ? true : isEntityList;

    if (refresh) {
        if (newIsEntityList) {
            const active = entity.entity;
            const entityList = state.entityList[active];
            const pageSize =
                entityList && entityList.count ? entityList.count : PAGINATION_TABLE_ENTITY;

            dispatch(Context.actions.EntityMapActions.changeFilter(entity));
            dispatch(Context.actions.EntityListActions.clear(entity, true));
            if (!state?.entityList?.[name]?.preventSelectionResetOnChangeFilter) {
                dispatch(Context.actions.EntityListSelectActions.unselectAll(entity));
            }

            // In Agenda entity, both initMap and init (for list) are the same query
            // if we refresh the list when we are in map section, one call cancels the other
            const manager = Context.entityManager.getEntitiesManager(entity);

            const shouldPreventRefreshListFromMap = manager?.shouldPreventRefreshListFromMap
                ? manager.shouldPreventRefreshListFromMap()
                : null;

            if (!shouldPreventRefreshListFromMap) {
                // useLazyLoad for w5 table
                // make 2 possible calls because null removes default prop values

                const useLazyLoad = entityList?.useLazyLoad || false;
                if (useLazyLoad) {
                    const lazyInfo = {
                        resetOffset: true,
                    };

                    dispatch(
                        Context.actions.EntityListActions.init(
                            entity,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                            lazyInfo,
                        ),
                    );
                } else {
                    dispatch(Context.actions.EntityListActions.init(entity, true, pageSize));
                }
            }

            dispatch(
                Context.actions.EntityListSelectActions.setShouldClearWeb5Checkboxes(entity, true),
            );
        }
    }
}

export function initFilters(entity) {
    return function () {
        return Promise.all([
            Context.entityManager.getEntityFilters(entity),
            Context.extraFieldManager.getExtraFieldsCrudSchema(entity),
        ]);
    };
}

export function saveFiltersToCache({ name, dispatch, getState }) {
    const state = getState();
    if (!state.config.userData.maintainFiltersBetweenSessions) return;
    if (!ENTITIES_WHITELIST.find((entity) => entity.entity === name)) return;

    let filtersToSave = { ...(state.entityFilters[name] || {}) };
    if (Object.keys(filtersToSave.filters).length) {
        filtersToSave.filters = Object.entries(filtersToSave.filters).reduce(
            (obj, [key, value]) => {
                if (!value?.excludeFromCache) obj[key] = value;
                return obj;
            },
            {},
        );
    }

    dispatch(ConfigActions.setConfigWeb(`${name}_${FILTERS_CACHE_KEY}`, filtersToSave));
}

export function changeFilter({
    entity,
    filter,
    value,
    refresh = true,
    completeValues = null,
    isEntityList,
    info,
    isPreload = false,
    relatedEntity,
    crossEntity,
}) {
    return function (dispatch, getState) {
        const name = entity.entity;
        let newFilters;
        const state = getState();
        let filters = state.entityFilters[name];

        filters = crossEntity
            ? (filters?.crossFilters?.[crossEntity] ?? {})
            : (filters?.filters ?? {});

        if (
            !value ||
            value.length === 0 ||
            value === '' ||
            (value.hasOwnProperty('from') && !value.from && !value.to)
        ) {
            const { [filter.id]: value, ...newFiltersRest } = filters;
            newFilters = newFiltersRest;
            if (filter.hasOwnProperty('extraFilterProps')) {
                const { [filter.extraFilterProps.id]: value, ...newFiltersWithoutExtra } =
                    newFilters;
                newFilters = newFiltersWithoutExtra;
            }
        } else {
            if (filter?.isCustomRange) {
                newFilters = {
                    ...filters,
                    [filter.id]: {
                        value,
                        label: completeValues.label,
                        completeValues,
                        ...filter,
                    },
                };
            } else {
                newFilters = {
                    ...filters,
                    [filter.id]: {
                        value,
                        completeValues,
                        ...filter,
                    },
                };
            }
        }

        // Removing dependant values
        const principalFilter = state.config.userData.principalFilter[name];
        if (
            ['singleValueList', 'relatedValueList', 'multipleValueList'].includes(
                filter.dataType,
            ) &&
            principalFilter
        ) {
            const standardFields = state.config.standardFieldsSchemaMap?.[entity.extraFieldName];
            let principal = Object.keys(newFilters).find(
                (filterKey) => newFilters[filterKey]?.fieldConfiguration === principalFilter,
            );
            principal = newFilters[principal]?.value?.[0];
            if (principal) {
                Object.keys(newFilters).forEach((key) => {
                    const filter = newFilters[key];
                    const parentField = standardFields?.[filter.fieldConfiguration]?.strParentField;
                    if (parentField === principalFilter) {
                        filter.completeValues = filter.completeValues.filter(
                            (completeValue) => !principal || completeValue.idParent === principal,
                        );
                        filter.value = filter.completeValues.map(
                            (completeValue) => completeValue.value,
                        );
                    }
                }, {});
            }
        }

        Context.entityManager.beforeOnChangeFilter({ entity, relatedEntity, refresh, crossEntity });

        dispatch(
            _changeFilter({
                entity: name,
                filters: newFilters,
                isFirstLoad: false,
                isInvisible: filter.invisible,
                isPreload,
                crossEntity,
            }),
        );
        saveFiltersToCache({ name, dispatch, getState });

        _updateEntity({
            entity: relatedEntity || entity,
            refresh,
            dispatch,
            getState,
            info,
            isEntityList,
            filter,
        });

        const newFilter = {
            entity,
            filter,
            value,
            completeValues,
            crossEntity,
        };

        Context.entityManager.afterOnChangeFilter({
            entity,
            refresh,
            filter: newFilter,
            crossEntity,
        });

        return newFilters;
    };
}

function iterateOutlierFilters({ dispatch, entity, outlierFilters }) {
    return new Promise((resolve) => {
        outlierFilters.forEach(({ filter, value }, index) => {
            if (!filter || !value || value.length === 0) {
                if (index === outlierFilters.length - 1) {
                    resolve();
                }
                return;
            }
            dispatch(
                Context.actions.EntityFiltersActions.changeOutlierFilter({ entity, filter, value }),
            );
            if (index === outlierFilters.length - 1) resolve();
        });
    });
}

// We preload the outlierFilters here outside of the Init entity flow because it offers
// the flexibility to call it only when and wherever it's needed.
// Another option would have been to include the preloadOutlierFilters promise inside
// the preloadFilters promise, so it will be executed right before the preloadFilters logic.
// But finally we opted for the flexibility it gives us to have it independent.
export function initOutlierFilters(entity) {
    return (dispatch, getState) => {
        const name = entity.entity;
        const filters = getState().entityFilters[name];
        return Context.entityManager
            .preloadOutlierFilters(entity, filters)
            .then((outlierFilters) => {
                if (outlierFilters && outlierFilters.length > 0) {
                    return iterateOutlierFilters({ dispatch, entity, outlierFilters });
                }
            });
    };
}

export function changeOutlierFilter({ entity, filter, value }) {
    return (dispatch, getState) => {
        const state = getState();
        const name = entity.entity;
        let filters = state.entityFilters?.[name]?.outlierFilters || {};

        const newFilter = {
            ...filters,
            [filter.id]: {
                value,
                ...filter,
            },
        };

        dispatch({
            type: ENTITY_FILTERS_CHANGE_OUTLIER,
            entity: name,
            outlierFilters: newFilter,
        });
    };
}

export function changeAdvancedFilter({ entity, operators, shouldUpdateEntity, crossEntity }) {
    return (dispatch, getState) => {
        const name = entity.entity;
        entity = name === ACTIVITIES.entity && inConversations() ? CONVERSATIONS : entity;
        let payload = { type: ENTITY_ADV_FILTERS_CHANGE, operators, entity: name };

        if (!!crossEntity) {
            payload = {
                ...payload,
                type: ENTITY_CROSS_ADV_FILTERS_CHANGE,
                crossEntity: crossEntity.entity || crossEntity,
            };
        }

        dispatch(payload);
        saveFiltersToCache({ name, dispatch, getState });
        shouldUpdateEntity && _updateEntity({ entity, dispatch, getState });
    };
}

export function deleteMultipleFilters({ entity, filters, refresh = true, isEntityList = true }) {
    return (dispatch, getState) => {
        const name = entity.entity;
        const state = getState();
        const entityFilters = state.entityFilters[name] || {};
        const {
            filters: oldFilters = {},
            operators: oldOperators = {},
            crossFilters: oldCrossFilters = {},
            crossOperators: oldCrossOperators = {},
        } = entityFilters;
        const isCrossFilters = !Array.isArray(filters);

        const deleteFilters = (filtersArr, filters, operators) => {
            const newFilters = Object.keys(filters).reduce((obj, key) => {
                if (!filtersArr.includes(key)) obj[key] = filters[key];
                return obj;
            }, {});

            const newOperators = Object.keys(operators).reduce((obj, key) => {
                if (!filtersArr.includes(key)) obj[key] = operators[key];
                return obj;
            }, {});

            return { newFilters, newOperators };
        };

        const processFilters = (filtersArr, currentFilters, currentOperators, crossEntity) => {
            const { newFilters, newOperators } = deleteFilters(
                filtersArr,
                currentFilters,
                currentOperators,
            );

            changeAdvancedFilter({ entity, operators: newOperators, crossEntity });
            dispatch(
                _changeFilter({
                    entity: name,
                    filters: newFilters,
                    isFirstLoad: false,
                    crossEntity,
                }),
            );
        };

        if (isCrossFilters) {
            for (const [crossEntity, crossEntityFilters] of Object.entries(filters)) {
                const isCrossEntity = name !== crossEntity;
                const currentFilters = isCrossEntity
                    ? oldCrossFilters[crossEntity] || {}
                    : oldFilters;
                const currentOperators = isCrossEntity
                    ? oldCrossOperators[crossEntity] || {}
                    : oldOperators;
                processFilters(
                    crossEntityFilters,
                    currentFilters,
                    currentOperators,
                    isCrossEntity ? crossEntity : null,
                );
            }
        } else {
            if (filters.length === 0) return;
            processFilters(filters, oldFilters, oldOperators);
        }

        saveFiltersToCache({ name, dispatch, getState });
        Context.entityManager.afterOnChangeFilter({ entity, refresh });

        if (refresh) {
            if (isEntityList) {
                const active = entity.entity;
                const entityList = state.entityList[active];
                const pageSize =
                    entityList && entityList.count ? entityList.count : PAGINATION_TABLE_ENTITY;

                dispatch(Context.actions.EntityMapActions.changeFilter(entity));
                dispatch(Context.actions.EntityListActions.clear(entity, true));
                if (!state?.entityList?.[name]?.preventSelectionResetOnChangeFilter) {
                    dispatch(Context.actions.EntityListSelectActions.unselectAll(entity));
                }

                // useLazyLoad for w5 table
                // make 2 possible calls because null removes default prop values
                const useLazyLoad = entityList?.useLazyLoad || false;
                if (useLazyLoad) {
                    const lazyInfo = {
                        resetOffset: true,
                    };

                    dispatch(
                        Context.actions.EntityListActions.init(
                            entity,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                            lazyInfo,
                        ),
                    );
                } else {
                    dispatch(Context.actions.EntityListActions.init(entity, false, pageSize));
                }

                dispatch(
                    Context.actions.EntityListSelectActions.setShouldClearWeb5Checkboxes(
                        entity,
                        true,
                    ),
                );
            }
        }
    };
}

// Set the 'FirstLoad' parameter to 'true' to make sure we load the default filter configuration.
export function clearFilters({
    entity,
    isAPurge,
    refresh = true,
    isFirstLoad = true,
    isEntityList = true,
    relatedEntity,
    clearAdvancedAndCrossFilters,
}) {
    return (dispatch, getState) => {
        const name = entity.entity;
        // Only user filters not hidden ones.
        let filters = {};
        if (!isAPurge) {
            filters = getState().entityFilters[name]?.filters || {};
            filters = Object.keys(filters).reduce((obj, filterKey) => {
                if (filters[filterKey].hideForCount && filterKey !== 'matchingName') {
                    obj[filterKey] = filters[filterKey];
                }
                return obj;
            }, {});
        }

        dispatch(
            _changeFilter({ entity: name, filters, isFirstLoad, clearAdvancedAndCrossFilters }),
        );
        saveFiltersToCache({ name, dispatch, getState });
        Context.entityManager.afterOnChangeFilter({ entity, refresh, isClear: true });

        if (refresh) {
            if (isEntityList) {
                // If we're in a relatedEntity list (p.e. CONVERSATIONS) we want to refresh the related entity list
                entity = relatedEntity || entity;
                dispatch(Context.actions.EntityListActions.clear(entity));
                dispatch(Context.actions.EntityMapActions.changeFilter(entity));
                dispatch(Context.actions.EntityListSelectActions.unselectAll(entity));
                const entityList = getState()?.entityList?.[name] || null;
                const useLazyLoad = entityList?.useLazyLoad || false;
                // useLazyLoad for w5 table
                // make 2 possible calls because null removes default prop values
                if (useLazyLoad) {
                    dispatch(
                        Context.actions.EntityListActions.init(
                            entity,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                        ),
                    );
                } else {
                    dispatch(Context.actions.EntityListActions.init(entity));
                }

                dispatch(
                    Context.actions.EntityListSelectActions.setShouldClearWeb5Checkboxes(
                        entity,
                        true,
                    ),
                );
            }
        }
    };
}

export function getCleanFilters(state, entity) {
    const entityName = entity.entity;
    let filters = state.entityFilters[entityName];
    filters = filters ? filters.filters || {} : {};
    filters = formatSelectionFilters(filters, entity);
    return filters;
}

export function getCleanOperators(state, entity) {
    const entityName = entity.entity;
    const entityFilters = state.entityFilters[entityName];
    let operators = entityFilters ? entityFilters.operators || {} : {};
    operators = formatSelectionOperators(operators);
    return operators;
}

export function getCleanCrossFilters(entity) {
    return formatCrossFilters(entity);
}

export function createView(entity, view) {
    return (dispatch, getState) => {
        const state = getState();
        const views = state.config.views || [];
        const { extraOperatorInfo, operatorInfo } =
            Context.actions.EntityFiltersActions.getCleanOperators(state, entity);
        const filter = Context.actions.EntityFiltersActions.getCleanFilters(state, entity);
        const crossFilterInfo = Context.actions.EntityFiltersActions.getCleanCrossFilters(entity);
        return Context.domainManager
            .createView({
                ...view,
                entity: entity.views,
                entityName: entity.exportEntity,
                filter,
                extraOperatorInfo,
                operatorInfo,
                crossFilterInfo,
            })
            .then((result) => {
                dispatch(Context.actions.ConfigActions.updateViews([...views, result]));
                return result;
            });
    };
}

export function removeView(view) {
    return (dispatch, getState) => {
        const views = getState().config.views.filter((v) => v.id !== view.id);
        dispatch(Context.actions.ConfigActions.updateViews(views));
        return Context.domainManager.removeView(view.id);
    };
}

export function toggleFilters(entity, state, force) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_FILTERS_TOGGLE,
            entity: entity.entity,
            state,
            force,
        });
    };
}

export function hideFilters(entity) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_FILTERS_HIDE,
            entity: entity.entity,
        });
    };
}

export function setFilterDragging(entity, filterId) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_FILTERS_DRAGGING,
            entity: entity.entity,
            filterId,
        });
    };
}

export function disableForceCrossFilters(entity) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_CROSS_FILTERS_FORCE_DISABLE,
            entity: entity.entity,
        });
    };
}

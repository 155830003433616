import Context from 'managers/Context';

import CrudModel from './CrudModel';
import { getLiteral } from 'utils/getLiteral';

export const getVisibleInOptions = () => [
    {
        value: 'accounts',
        label: getLiteral('label_accounts'),
    },
    {
        value: 'opportunities',
        label: getLiteral('label_opportunities'),
    },
];
export default class AnalyticsReportModel extends CrudModel {
    constructor(entity) {
        super(entity);
        this.entity = {
            ...this.entity,
        };
    }

    static toCrud({ data }) {
        const visibleInOptions = getVisibleInOptions();
        return {
            ...data,
            displayOnWidget: data?.visibleIn.length > 0,
            visibleIn: data?.visibleIn?.map((item) =>
                visibleInOptions.find((option) => option.value === item),
            ),
            environment: data.environment
                ? { label: data.environment.value, value: data.environment.id }
                : undefined,
            scheduling: {
                ...data.scheduling,
                pdfExportSettings: {
                    expands_table: data.scheduling?.expands_table,
                    tiles_into_dashboard: data.scheduling?.tiles_into_dashboard,
                },
                groups: data.scheduling?.groups?.map((group) => ({
                    label: group.value,
                    value: String(group.id),
                })),
                timezone: data.scheduling?.timezone
                    ? {
                          label: data.scheduling.timezone.value,
                          value: String(data.scheduling.timezone.id),
                      }
                    : null,
            },
        };
    }
}

import Immutable, { List } from 'immutable';
import moment from 'moment';

import CrudModel from './CrudModel';
import ProductRatesModel from './ProductRatesModel';
import FamilyRatesModel from './FamilyRatesModel';
import CompanyRatesModel from './CompanyRatesModel';

export default class RatesModel extends CrudModel {
    static fromCrud(entity) {
        entity = entity.toObject();
        const productRates = entity.productrates;
        const familyRates = entity.familyrates;
        const companyRates = entity.companyrates;
        const rate = new RatesModel(entity, [], [], []);
        rate.productRates = productRates ? productRates.toArray() : [];
        rate.familyRates = familyRates ? familyRates.toArray() : [];
        rate.companyRates = companyRates ? companyRates.toArray() : [];
        return rate;
    }

    static toList({ data }) {
        const newData = data.map((current, index) => {
            const obj = {
                rawData: { ...current },
                ...current,
            };
            return obj;
        });

        return newData;
    }

    constructor(entity, productRates, familyRates, companyRates) {
        super(entity);
        this.productRates = productRates
            ? productRates.map((productRate) => new ProductRatesModel(productRate, true))
            : [];
        this.familyRates = familyRates
            ? familyRates.map((familyRate) => new FamilyRatesModel(familyRate, true))
            : [];
        this.companyRates = companyRates
            ? companyRates.map((familyRate) => new CompanyRatesModel(familyRate, true))
            : [];
    }

    get crud() {
        let defaultCrud = super.crud;

        if (this.id !== '' && this.id !== '-1') {
            defaultCrud = defaultCrud.set('oldName', this.name);
        }

        defaultCrud = defaultCrud.set('productrates', List(this.productRates));
        defaultCrud = defaultCrud.set('familyrates', List(this.familyRates));
        defaultCrud = defaultCrud.set('companyrates', List(this.companyRates));
        return defaultCrud;
    }

    get saveCrud() {
        const entity = this.entity;
        const crud = {
            id: entity.id ? entity.id : '-1',
            name: entity.name,
            description: entity.description,
            idcurrency: entity.idcurrency,
            startdate: entity.startdate,
            enddate: entity.enddate,
            // idsucursal: entity.idsucursal,
            strIdEnvironment: entity.stridenvironment,
            products: [],
            families: [],
            accounts: [],
        };
        this.productRates.forEach((productRate) => {
            crud.products.push(productRate.saveCrud);
        });
        this.familyRates.forEach((familyRate) => {
            crud.families.push(familyRate.saveCrud);
        });
        this.companyRates.forEach((companyRate) => {
            crud.accounts.push(companyRate.saveCrud);
        });
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    get name() {
        return this.entity.name;
    }

    get description() {
        return this.entity.description;
    }

    get idCurrency() {
        return this.entity.idCurrency;
    }

    get startDate() {
        return moment(this.entity.startDate, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    }

    get endDate() {
        return moment(this.entity.endDate, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    }

    get idSucursal() {
        return this.entity.strIdEnvironment;
    }

    get strIdEnvironment() {
        return this.entity.strIdEnvironment;
    }
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Button, Modal } from 'hoi-poi-ui';
import { EntityCrudActions, EntityDetailActions, EntityActions } from 'actions';
import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { isEqual } from 'utils/objects';
import { getActiveCrudName } from 'utils/crud';

import UsageLimitInfo from 'containers/components/UsageLimitInfo';
import MergeFilledIcon from 'components/SvgIcons/MergeFilled';
import TooltipHoi from 'components/TooltipHoi';
import { ButtonHeader, ButtonHeaderGrey, ButtonHeaderWhite } from 'components/buttons';

const propTypes = {
    loadingSave: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    saveText: PropTypes.string,
    cancelText: PropTypes.string,
    saveButtonLight: PropTypes.bool,
    hideSave: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const activeCrud = getActiveCrudName(state);
    const data = state.entityCrud?.[activeCrud]?.data;
    const duplicateNoChanges = state.entityCrud?.[activeCrud]?.duplicateNoChanges;
    const originalData = state.entityCrud?.[activeCrud]?.originalData;
    let isEmptyCrud = false;
    let editedFields =
        data &&
        Object.keys(data).filter((key) => {
            if (data[key]) return key;
        });

    if (editedFields && editedFields.length === 0) isEmptyCrud = true;

    return {
        isConfirmModalOpen: state.entityCrud.isConfirmModalOpen,
        isExtraFieldsModalOpen: state.entityCrud.isExtraFieldsModalOpen,
        urlToOpen: state.entityCrud.urlToOpen,
        queryString: state.entityCrud.queryString,
        nextDetailEntityType: state.entityDetail.nextDetailEntityType,
        nextDetail: state.entityDetail.nextDetail,
        originalData,
        data,
        isEmptyCrud,
        duplicateNoChanges,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleConfirmModal: bindActionCreators(EntityCrudActions, dispatch).toggleConfirmModal,
        toggleExtraFieldsModal: bindActionCreators(EntityCrudActions, dispatch)
            .toggleExtraFieldsModal,
        resetNextDetail: bindActionCreators(EntityDetailActions, dispatch).resetNextDetail,
        openMergeEntityDialog: bindActionCreators(EntityActions, dispatch).openMergeEntityDialog,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class EntityCrudHeader extends PureComponent {
    onSave = (event) => {
        const { loadingSave, onSave } = this.props;

        if (loadingSave) return Promise.resolve();
        return onSave()?.catch(console.error);
    };

    onConfirm = () => {
        const { toggleConfirmModal, nextDetail, nextDetailEntityType, resetNextDetail } =
            this.props;

        resetNextDetail();
        this.props.onCancel();
        if (nextDetail) {
            ensureRoute(`/${nextDetailEntityType.entity}/${nextDetail.Id}`);
        }
        toggleConfirmModal(false);
    };

    goToSettings = () => {
        const { urlToOpen, queryString } = this.props;
        ensureRoute(urlToOpen, queryString);
    };

    checkChanges = () => {
        const { originalData, data, toggleConfirmModal } = this.props;
        isEqual(originalData, data, true) ? this.onConfirm() : toggleConfirmModal(true);
    };

    onClose = () => {
        const { toggleConfirmModal, resetNextDetail, toggleExtraFieldsModal } = this.props;
        resetNextDetail();
        toggleExtraFieldsModal(false);
        toggleConfirmModal(false);
    };

    onMerge = () => {
        const { id, openMergeEntityDialog } = this.props;
        openMergeEntityDialog(id);
    };

    render() {
        const {
            loading,
            loadingSave,
            isEmptyCrud,
            title,
            cancelText,
            saveText,
            saveButtonLight,
            isConfirmModalOpen,
            hideSave,
            isReadOnly,
            canMerge,
            id,
            version,
            duplicateNoChanges,
            isExtraFieldsModalOpen,
            entity,
        } = this.props;

        let SaveButton = saveButtonLight ? ButtonHeaderGrey : ButtonHeader;
        const disableSave = isEmptyCrud || loadingSave || isReadOnly || duplicateNoChanges;
        const showMerge = canMerge && id;
        return (
            <div className="entity-crud-header">
                <div className="entity-crud-title">
                    {!loading && title}
                    <UsageLimitInfo entity={entity} />
                </div>
                <div className="entity-crud-buttons">
                    {showMerge && (
                        <TooltipHoi placement="bottom" content={getLiteral('action_merge')}>
                            <div onClick={this.onMerge} className="entity-crud-buttons__merge">
                                <MergeFilledIcon />
                            </div>
                        </TooltipHoi>
                    )}
                    {version !== 2 && (
                        <ButtonHeaderWhite
                            onClick={this.checkChanges}
                            classes={['fm-button-header__cancel']}
                        >
                            {cancelText}
                        </ButtonHeaderWhite>
                    )}
                    {!hideSave && version !== 2 && (
                        <SaveButton
                            onClick={this.onSave}
                            classes={['fm-button-header__save']}
                            disabled={disableSave}
                        >
                            {saveText}
                        </SaveButton>
                    )}
                    {version === 2 && (
                        <Button
                            type="terciary"
                            onClick={this.checkChanges}
                            className="fm-button-header__cancel"
                        >
                            {cancelText}
                        </Button>
                    )}
                    {!hideSave && version === 2 && (
                        <Button
                            onClick={this.onSave}
                            className="fm-button-header__save"
                            isDisabled={disableSave}
                        >
                            {saveText}
                        </Button>
                    )}
                </div>
                <Modal
                    overlayClassName="entity-crud-header__overlay-cancel"
                    useCornerClose={false}
                    isOpen={isConfirmModalOpen || isExtraFieldsModalOpen}
                    onCancel={this.onClose}
                    onConfirm={isConfirmModalOpen ? this.onConfirm : this.goToSettings}
                    confirmText={getLiteral('action_discard_changes')}
                    cancelText={getLiteral('action_cancel')}
                    title={getLiteral('label_discard_unsaved_changes')}
                    size="small"
                ></Modal>
            </div>
        );
    }
}

EntityCrudHeader.propTypes = propTypes;

export default EntityCrudHeader;

import React from 'react';
import colors from 'constants/colors';

const ArrowLeft = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 9l1.058 1.058L8.25 5.873V15h1.5V5.873l4.193 4.185L15 9 9 3z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
            transform="rotate(-90 0 0) translate(-18 0)"
        />
    </svg>
);

export default ArrowLeft;

import {
    LOAD_VALUE_LIST,
    LOAD_VALUE_LIST_SUCCESS,
    LOAD_VALUE_LIST_ERROR,
    CLEAR_VALUE_LIST,
} from '../constants/ActionTypes';
import { RATES } from '../constants/Entities';
import Context from '../managers/Context';

function _showLoading(table) {
    return {
        type: LOAD_VALUE_LIST,
        valueList: table,
    };
}

function _showError(table, error) {
    return { type: LOAD_VALUE_LIST_ERROR, valueList: table, error };
}

function _setData(table, data) {
    return { type: LOAD_VALUE_LIST_SUCCESS, valueList: table, result: data };
}

function _clearData(table) {
    return { type: CLEAR_VALUE_LIST, valueList: table };
}

export function getValueList(table, callback, error) {
    return function (dispatch, getState) {
        // TODO valuelist will not need to be with immutable. But, if
        // we have an immutable, use that correctly...
        // I'm not going to touch this method because I don't want to break anything,
        // but the new method will be modified
        dispatch(_showLoading(table));
        if (!getState().valueList[table.loading]) {
            Context.valueListManager.getValueList(
                table,
                (result) => {
                    dispatch(_setData(table, result));
                    callback && callback(result);
                },
                (error) => {
                    dispatch(_showError(table, error));
                    error && error();
                },
            );
        }
    };
}

export function getValueListWithCustomCall(field, callback, error) {
    return function (dispatch, getState) {
        // TODO convert this method and getValueList in only one
        // when the component for valueList will not be so horrible
        // TODO valuelist will not need to be with immutable
        const table = field.valueListName;
        if (!getState().valueList.get(table) || !getState().valueList.get(table).get('loading')) {
            dispatch(_showLoading(table));
            const successCallback = (result) => {
                dispatch(_setData(table, result));
                callback && callback(result);
            };

            const errorCallback = (error) => {
                dispatch(_showError(table, error));
                error && error();
            };

            if (!field.isCustomValueList) {
                Context.valueListManager.getValueList(table, successCallback, errorCallback);
            } else {
                const { entity, method } = field.customValueListParameters;
                Context.entityManager
                    .getEntitiesManager(entity)
                    [method](table, successCallback, errorCallback);
            }
        }
    };
}

export function getValueListPromise(table) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(_showLoading(table));
            if (!getState().valueList[table.loading]) {
                Context.valueListManager.getValueList(
                    table,
                    (result) => {
                        dispatch(_setData(table, result));
                        resolve(result);
                    },
                    (error) => {
                        dispatch(_showError(table, error));
                        reject();
                    },
                );
            }
        });
    };
}

export function setValueList(table, values, callback) {
    return function (dispatch, getState) {
        dispatch(_setData(table, values));
        callback && callback();
    };
}

export function clearValueList() {
    return function (dispatch) {
        dispatch(_clearData());
    };
}

export function getRatesValueList(callback) {
    return function (dispatch, getState) {
        Context.entityManager.getEntitiesManager(RATES).getRatesAll((data) => {
            dispatch(_setData('tblTarifas', data));
            callback && callback(data);
        });
    };
}

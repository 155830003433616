import { IntegrationsService } from 'services';

export default class IntegrationsManager {
    state = {};

    constructor() {}

    getIntegrationsFmFields(success, error) {
        this.context.domainManager.getIntegrationsFmFields(success, error);
    }

    getIntegrationsFmUsers(success, error) {
        this.context.domainManager.getIntegrationsFmUsers(success, error);
    }

    getIntegrationsFieldsConfig(integration, success, error) {
        this.context.domainManager.getIntegrationsFieldsConfig(integration, success, error);
    }

    getIntegrationsUsersConfig(integration, success, error) {
        this.context.domainManager.getIntegrationsUsersConfig(integration, success, error);
    }

    getIntegrationsExtFields(provider, entity, success, error) {
        if (provider === 'sagex3') {
            IntegrationsService.getIntegrationsExtFields(provider, entity)
                .then((res) => success(res))
                .catch((err) => error(err));
        } else {
            this.context.domainManager.getIntegrationsExtFields(provider, entity, success, error);
        }
    }

    getIntegrationsExtUsers(provider, success, error) {
        if (provider === 'sagex3') {
            IntegrationsService.getIntegrationsExtUsers(provider)
                .then((res) => success(res))
                .catch((err) => error(err));
        } else {
            this.context.domainManager.getIntegrationsExtUsers(provider, success, error);
        }
    }

    setIntegrationsFieldsConfig(integration, data, success, error) {
        this.context.domainManager.setIntegrationsFieldsConfig(integration, data, success, error);
    }

    setIntegrationsUsersConfig(integration, data, success, error) {
        this.context.domainManager.setIntegrationsUsersConfig(integration, data, success, error);
    }

    providerLogin(provider, data, success, error) {
        IntegrationsService.providerLogin(provider, data)
            .then((res) => success(res))
            .catch((err) => error(err));
    }

    updateCronStatus(provider, success, error) {
        IntegrationsService.updateCronStatus(provider)
            .then((res) => success())
            .catch((err) => error(err));
    }
}

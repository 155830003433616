import React from 'react';
import colors from 'constants/colors';

const ExtraFields = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 4a1 1 0 1 1 0-2h15a1 1 0 0 1 0 2h-15zm0 4a1 1 0 1 1 0-2h15a1 1 0 0 1 0 2h-15zm0 4a1 1 0 1 1 0-2h15a1 1 0 0 1 0 2h-15zm0 4a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2h-15z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default ExtraFields;

import React, { memo, useCallback, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { useTheme, Avatar, Icon, Link, Loader, Tabs } from 'hoi-poi-ui';
import { EntityDetailActions, EntityCrudActions } from 'actions';
import {
    ACTIVITIES,
    COMPANIES,
    CONVERSATIONS,
    OPPORTUNITIES,
    SALESORDERS,
    CONTACTS,
    PRODUCTS,
} from 'constants/Entities';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import {
    getSrcCompanyChipAvatar,
    getSrcUserCircleAvatar,
    getSrcContactCircleAvatar,
} from 'utils/getSrcAvatar';
import { getEntityFromString } from 'utils/getEntityFromString';
import { getRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean, getWithLanguage } from 'utils/fm';
import { getMomentFromDateBackend } from 'utils/dates';
import { inConversations } from 'containers/Activities/utils/conversations';
import TabPreview from './TabPreview';

const mapStateToProps = (state) => {
    const { entityDetail } = state;
    return {
        activeKey: entityDetail.active,
        detailTabs: entityDetail.tabs || [],
        entityDetail,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setActiveTab: bindActionCreators(EntityDetailActions, dispatch).setActiveTab,
    setActiveCrud: bindActionCreators(EntityCrudActions, dispatch).setActiveCrud,
});

const EntityDetailTabs = memo(
    ({
        activeKey,
        className,
        detailTabs,
        entityDetail,
        onClose,
        postComponent,
        setActiveTab,
        onCloseTab,
        setActiveCrud,
        modalContentElement,
    }) => {
        const theme = useTheme();

        const handleClick = useCallback((e) => {
            if (e.ctrlKey) return true;
            e.preventDefault();
        }, []);

        const getTabUrl = useCallback((tab, activityType) => {
            const [entityStr, id] = tab.split('-');
            const entity = getEntityFromString(entityStr);

            let route;

            switch (true) {
                case !!activityType && inConversations():
                    route = `${CONVERSATIONS.route}${ACTIVITIES.route}/${id}/${activityType}/timeline`;
                    break;
                case !!activityType:
                    route = `${ACTIVITIES.route}/${id}/${activityType}`;
                    break;
                default:
                    route = `${entity.route}/${id}`;
                    break;
            }

            return getRoute(route);
        }, []);

        const getActivityTab = useCallback(
            (data) => {
                let iconName, title, iconColor2;
                const activityType = parseInt(data.ActivityType, 10);

                switch (activityType) {
                    case ACTIVITY_TYPE_CALL:
                        let isReceived = false;
                        let isMissed = false;

                        if (data.IsReceived === '0' || data.IsReceived === 0) isReceived = true;
                        else if (data.IsReceived === '1' || data.IsReceived === 1)
                            isReceived = false;
                        else isMissed = true;

                        if (isMissed) {
                            title = getLiteral('label_missed_call');
                            iconName = 'phonecallMissed';
                            iconColor2 = theme.colors.red500;
                        } else if (isReceived) {
                            title = getLiteral('label_callreceived');
                            iconName = 'phoneIncoming';
                            iconColor2 = theme.colors.turquoise500;
                        } else {
                            title = getLiteral('label_callmade');
                            iconName = 'phoneOutgoing';
                            iconColor2 = theme.colors.orange500;
                        }

                        break;
                    case ACTIVITY_TYPE_EMAIL:
                        const isSent = getBackendBoolean(data.IsSent);
                        iconName = isSent ? 'emailSend' : 'emailReceive';
                        iconColor2 = isSent ? theme.colors.orange500 : theme.colors.turquoise500;
                        title = isSent
                            ? getLiteral('label_emailsent')
                            : getLiteral('label_emailreceived');
                        break;
                    default:
                        const icon = {
                            [ACTIVITY_TYPE_CHECKIN]: 'accountCheckin',
                            [ACTIVITY_TYPE_OPPORTUNITY_CHECKIN]: 'opportunityCheckin',
                            [ACTIVITY_TYPE_FAST_CHECKIN]: 'fastCheckin',
                            [ACTIVITY_TYPE_VIDEO_CALL]: 'videoCamera',
                            [ACTIVITY_TYPE_VIDEO_CHECKIN]: 'videoCheckin',
                            [ACTIVITY_TYPE_WORKFLOW]: 'workflow',
                            [ACTIVITY_TYPE_MAILCHIMP]: 'mailchimp',
                            [ACTIVITY_TYPE_ZENDESK]: 'zendesk',
                            [ACTIVITY_TYPE_WHATSAPP]: 'whatsappGreen',
                        }[activityType];
                        iconName = icon || 'activities';
                        title = data.Type;
                        break;
                }
                return {
                    title,
                    icon: (
                        <Icon
                            name={iconName}
                            color={theme.colors.neutral600}
                            overrides={{ icon: { color2: iconColor2 } }}
                        />
                    ),
                    activityType,
                };
            },
            [theme],
        );

        const addRow = useCallback((rows, label, value) => {
            rows.push({ label: getLiteral(label), value });
        }, []);

        const getPopoverProps = useCallback(
            (entity, data, customFooter) => {
                let rows = [];
                let footer,
                    srcObjResponsible,
                    iconResponsible,
                    srcObjAccount,
                    iconAccount,
                    srcObjContact,
                    iconContact;

                switch (entity) {
                    case ACTIVITIES.entity:
                        if (data.IdCompany && data.CompanyName) {
                            srcObjAccount = getSrcCompanyChipAvatar(data.IdCompany);
                            iconAccount = !!srcObjAccount?.src ? (
                                <Avatar
                                    alt={data.name}
                                    src={srcObjAccount.src}
                                    placeholder={srcObjAccount.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="account" />
                            );
                            addRow(rows, 'label_account', {
                                icon: iconAccount,
                                value: data.CompanyName,
                            });
                        }
                        if (data.IDContact && data.ContactName) {
                            srcObjContact = getSrcContactCircleAvatar(data.IDContact);
                            iconContact = !!srcObjContact?.src ? (
                                <Avatar
                                    alt={data.name}
                                    src={srcObjContact.src}
                                    placeholder={srcObjContact.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="account" />
                            );
                            addRow(rows, 'label_contact', {
                                icon: iconContact,
                                value: data.CompanyName,
                            });
                        }
                        if (data.ActivityDate) {
                            const dateMoment = getMomentFromDateBackend(
                                data.ActivityDate,
                                null,
                                true,
                            );
                            const date = dateMoment.format('L H:mm A');
                            addRow(rows, 'label_date', date);
                        }
                        footer = customFooter;
                        return { rows, footer };

                    case COMPANIES.entity:
                        if (data.companyState) addRow(rows, 'label_status', data.companyState);
                        if (data.calificacion) addRow(rows, 'label_rate', data.calificacion);
                        if (data.environment) addRow(rows, 'label_environment', data.environment);
                        if (data.responsible && data.idResponsable) {
                            srcObjResponsible = getSrcUserCircleAvatar(data.idResponsable);
                            iconResponsible = !!srcObjResponsible?.src ? (
                                <Avatar
                                    alt={data.responsible}
                                    src={srcObjResponsible.src}
                                    placeholder={srcObjResponsible.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="person" />
                            );
                            addRow(rows, 'label_responsible', {
                                icon: iconResponsible,
                                value: data.responsible,
                            });
                        }

                        srcObjAccount = getSrcCompanyChipAvatar(data.id);
                        iconAccount = !!srcObjAccount?.src ? (
                            <Avatar
                                alt={data.name}
                                src={srcObjAccount.src}
                                placeholder={srcObjAccount.fallbackSrc}
                            />
                        ) : (
                            <Icon name="account" />
                        );

                        footer = { icon: iconAccount, value: data.name };
                        return { rows, footer };
                    case CONTACTS.entity:
                        if (data.idCompany && data.company) {
                            srcObjAccount = getSrcCompanyChipAvatar(data.idCompany);
                            iconAccount = !!srcObjAccount?.src ? (
                                <Avatar
                                    alt={data.name}
                                    src={srcObjAccount.src}
                                    placeholder={srcObjAccount.fallbackSrc}
                                />
                            ) : (
                                <Icon name="account" />
                            );

                            addRow(rows, 'label_account', {
                                icon: iconAccount,
                                value: data.company,
                            });
                        }
                        if (data.personalAddress || data.country || data.city) {
                            let isPostalCodeAdded = false;
                            let address = data.personalAddress ? `${data.personalAddress},` : '';
                            if (address && data.postalCode) {
                                isPostalCodeAdded = true;
                                address = `${address} ${data.postalCode},`;
                            }
                            if (data.city) address = `${address} ${data.city},`;
                            if (data.postalCode && !isPostalCodeAdded) {
                                address = `${address} ${data.postalCode},`;
                            }
                            if (data.country) address = `${address} ${data.country}`;
                            if (address.charAt(address.length - 1) === ',') {
                                return address.slice(0, -1);
                            }
                            addRow(rows, 'label_address', address);
                        }
                        if (data.email) {
                            addRow(rows, 'label_email', data.email);
                        }
                        if (data.phone) {
                            addRow(rows, 'label_phone', data.phone);
                        }
                        if (data?.id && data?.name) {
                            srcObjContact = getSrcContactCircleAvatar(data.id);
                            iconContact = !!srcObjContact?.src ? (
                                <Avatar
                                    alt={data.name}
                                    src={srcObjContact.src}
                                    placeholder={srcObjContact.fallbackSrc}
                                />
                            ) : (
                                <Icon name="person" />
                            );

                            let fullName = data.name;
                            if (data.surnames) fullName = `${fullName} ${data.surnames}`;

                            footer = {
                                icon: iconContact,
                                value: fullName,
                            };
                        }

                        return { rows, footer };
                    case OPPORTUNITIES.entity:
                        if (data.amount && data.currencySymbol) {
                            addRow(rows, 'label_value', `${data.amount} ${data.currencySymbol}`);
                        }
                        if (data.stateDescription)
                            addRow(rows, 'label_status', data.stateDescription);
                        if (data.idTypeExpediente) addRow(rows, 'label_type', data.typeExpediente);
                        if (data.commercialId && data.commercialName) {
                            srcObjResponsible = getSrcUserCircleAvatar(data.commercialId);
                            iconResponsible = !!srcObjResponsible?.src ? (
                                <Avatar
                                    alt={data.commercialName}
                                    src={srcObjResponsible.src}
                                    placeholder={srcObjResponsible.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="person" />
                            );
                            addRow(rows, 'label_responsible', {
                                icon: iconResponsible,
                                value: data.commercialName,
                            });
                        }
                        const relatedAccount =
                            data?.relatedAccounts?.length && data?.relatedAccounts[0];
                        if (relatedAccount?.idCompany && relatedAccount?.company) {
                            srcObjAccount = getSrcCompanyChipAvatar(relatedAccount.idCompany);
                            iconAccount = !!srcObjAccount?.src ? (
                                <Avatar
                                    alt={relatedAccount.company}
                                    src={srcObjAccount.src}
                                    placeholder={srcObjAccount.fallbackSrc}
                                />
                            ) : (
                                <Icon name="account" />
                            );
                            addRow(rows, 'label_account_client', {
                                icon: iconAccount,
                                value: relatedAccount.company,
                            });
                        }

                        footer = {
                            percentage: data?.probability
                                ? String(parseInt(data.probability, 10) * 10)
                                : 0,
                            value: data.name,
                        };
                        return { rows, footer };
                    case SALESORDERS.entity:
                        if (data.total) addRow(rows, 'label_value', data.total);
                        if (data.stateSalesOrder) addRow(rows, 'label_state', data.stateSalesOrder);
                        if (data.salesOrderNumber)
                            addRow(rows, 'title_salesorder', data.salesOrderNumber);
                        if (data.responsible && data.idResponsible) {
                            srcObjResponsible = getSrcUserCircleAvatar(data.idResponsible);
                            iconResponsible = !!srcObjResponsible?.src ? (
                                <Avatar
                                    alt={data.responsible}
                                    src={srcObjResponsible.src}
                                    placeholder={srcObjResponsible.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="person" />
                            );
                            addRow(rows, 'label_responsible', {
                                icon: iconResponsible,
                                value: data.responsible,
                            });
                        }
                        if (data.company && data.idCompany) {
                            srcObjAccount = getSrcCompanyChipAvatar(data.idCompany);
                            iconAccount = !!srcObjAccount?.src ? (
                                <Avatar
                                    alt={data.company}
                                    src={srcObjAccount.src}
                                    placeholder={srcObjAccount.fallbackSrc}
                                    size="small"
                                />
                            ) : (
                                <Icon name="account" />
                            );
                            addRow(rows, 'label_account', {
                                icon: iconAccount,
                                value: data.company,
                            });
                        }
                        footer = {
                            icon: <Icon name="salesOrders" />,
                            value: data.reference,
                        };
                        return { rows, footer };
                }
            },
            [addRow],
        );

        const tabs = useMemo(() => {
            const tabsArr = Object.entries(detailTabs);
            return tabsArr.map(([tab, props]) => {
                if (!entityDetail[tab]) return;
                const { data, entity, loading } = entityDetail[tab];
                const loader = <Loader size="tiny" className="fm-entity-detail__tabLoader" />;
                let icon, srcObj, title, customClass, activityType;
                let popoverProps = {};

                switch (entity) {
                    case ACTIVITIES.entity:
                        const activityTab = getActivityTab(data);
                        title = activityTab.title;
                        icon = activityTab.icon;
                        activityType = activityTab.activityType;
                        customClass = 'fm-entity-detail__tab__title--activity';
                        popoverProps = getPopoverProps(entity, data, { icon, value: title });
                        break;
                    case COMPANIES.entity:
                        title = data.name;
                        srcObj = getSrcCompanyChipAvatar(data.id);
                        icon = !!srcObj?.src ? (
                            <Avatar alt={title} src={srcObj.src} placeholder={srcObj.fallbackSrc} />
                        ) : (
                            <Icon name="account" />
                        );
                        popoverProps = getPopoverProps(entity, data);
                        break;
                    case OPPORTUNITIES.entity:
                        title = data.name;
                        icon = <Icon name="opportunities" />;
                        popoverProps = getPopoverProps(entity, data);
                        break;
                    case SALESORDERS.entity:
                        title = data.reference || data.salesOrderNumber;
                        icon = <Icon name="salesOrders" />;
                        popoverProps = getPopoverProps(entity, data);
                        break;
                    case CONTACTS.entity:
                        let fullName = data.name;
                        if (data.surnames) fullName = `${fullName} ${data.surnames}`;
                        title = fullName;
                        srcObj = getSrcContactCircleAvatar(data.id);
                        icon = !!srcObj?.src ? (
                            <Avatar
                                alt={fullName}
                                src={srcObj.src}
                                placeholder={srcObj.fallbackSrc}
                                size="small"
                            />
                        ) : (
                            <Icon name="person" />
                        );
                        popoverProps = getPopoverProps(entity, data);
                        break;
                    case PRODUCTS.entity:
                        (title = getWithLanguage(data, 'ProductModel')),
                            (icon = <Icon name="products" />);
                        break;
                    default:
                        break;
                }

                const finalIcon = loading ? loader : icon;
                const showPlaceholder = !title;
                if (showPlaceholder) title = getLiteral('label_placeholder_unamed_record');

                const finalTitle = finalIcon ? (
                    <Link
                        href={getTabUrl(tab, activityType)}
                        className={classNames('fm-entity-detail__tab__title', {
                            [customClass]: customClass,
                            'fm-entity-placeholder': showPlaceholder,
                        })}
                        onClick={handleClick}
                    >
                        {finalIcon}
                        {title}
                    </Link>
                ) : (
                    title
                );

                return {
                    key: tab,
                    title: finalTitle,
                    fixed: props.primary && tabsArr.length > 1,
                    popoverContent: (
                        <TabPreview
                            rows={popoverProps?.rows || null}
                            footer={popoverProps?.footer || null}
                        />
                    ),
                    popoverWidth: 300,
                };
            });
        }, [detailTabs, entityDetail, getActivityTab, getTabUrl, handleClick, getPopoverProps]);

        const handleOnChange = useCallback(
            (activeKey) => {
                setActiveTab({ activeKey });
                setActiveCrud(activeKey);
            },
            [setActiveCrud, setActiveTab],
        );

        const handleOnClose = useCallback(
            ({ key, activeKey, tabs: newTabs }) => {
                if (tabs.length === 1) {
                    onClose && onClose({ isFromTab: true });
                } else {
                    const finalTabs = newTabs.reduce((obj, tab) => {
                        obj[tab.key] = { primary: tab.fixed };
                        return obj;
                    }, {});
                    setActiveTab({ activeKey, deletedKey: key, tabs: finalTabs });
                    setActiveCrud(activeKey);
                    onCloseTab && onCloseTab(key);
                }
            },
            [onClose, onCloseTab, setActiveCrud, setActiveTab, tabs.length],
        );

        const showClose = useMemo(() => tabs.length > 1, [tabs.length]);

        const tabsProps = useMemo(
            () => ({
                activeKey,
                className: classNames(className, { [`${className}--showClose`]: showClose }),
                editable: true,
                alwaysShowCloseTab: true,
                postComponent,
                tabs,
                overrides: {
                    close: {
                        size: 'medium',
                        color: theme.colors.neutral700,
                    },
                    'rc-tabs': {
                        destroyInactiveTabPane: true,
                    },
                },
                onClose: handleOnClose,
                onChange: handleOnChange,
                containerElement: modalContentElement,
                popoverWidth: 250,
            }),
            [
                activeKey,
                className,
                handleOnChange,
                handleOnClose,
                postComponent,
                tabs,
                theme.colors.neutral700,
                showClose,
                modalContentElement,
            ],
        );

        return (
            <Fragment>
                {showClose && (
                    <Icon
                        name="close"
                        size="medium"
                        onClick={onClose}
                        className="fm-entity-detail__closeAll"
                    />
                )}
                <Tabs {...tabsProps} />
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetailTabs);

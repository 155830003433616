import { getElapsedTime, getDateFromBackendISO, getMomentFromDateBackend } from 'utils/dates';
import moment from 'moment';
import Context from 'managers/Context';
import { getBackendBoolean, parseBooleanForBackend } from 'utils/fm';
import {
    fromExtraFields,
    fromExtraFieldsEndpoint,
    fromExtraFieldsV2,
    fromExtraFieldsEndpointV2,
    getActiveCrud,
    toCrudV2SelectValue,
} from 'utils/crud';
import UtilFormat from 'utils/UtilFormat';
import CrudModel from './CrudModel';

export const ACTIVITY_TYPE_CALL = 1;
export const ACTIVITY_TYPE_EMAIL = 2;
export const ACTIVITY_TYPE_ANNOTATION = 3;
export const ACTIVITY_TYPE_CHECKIN = 4;
export const ACTIVITY_TYPE_OPPORTUNITY_CHECKIN = 5;
export const ACTIVITY_TYPE_FAST_CHECKIN = 6;
export const ACTIVITY_TYPE_WORKFLOW = 11;
export const ACTIVITY_TYPE_VIDEO_CALL = 12;
export const ACTIVITY_TYPE_VIDEO_CHECKIN = 13;
export const ACTIVITY_TYPE_MAILCHIMP = 10;
export const ACTIVITY_TYPE_ZENDESK = 9;
export const ACTIVITY_TYPE_SALESFORCE = 7;
export const ACTIVITY_TYPE_WHATSAPP = 14;
//export const ACTIVITY_TYPE_HUBSPOT = 8; // To implement in the future

//Type of checkIn within type 4 ACTIVITY_TYPE_CHECKIN
export const ACTIVITY_ID_TYPE_CHECKIN_COMPANY = 1;
export const ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY = 2;
export const ACTIVITY_ID_TYPE_CHECKIN_FAST = 3;

export const IMPLEMENTATIONS_WITH_TIMELINE = {
    6003: [],
    8004: [],
};

export const mappedActivityTypeIds = {
    [ACTIVITY_TYPE_CALL]: 'callType',
    [ACTIVITY_TYPE_CHECKIN]: 'listCheckInType',
    [ACTIVITY_TYPE_VIDEO_CHECKIN]: 'videoCallCheckInType',
    [ACTIVITY_TYPE_FAST_CHECKIN]: 'fastCheckIn',
    [ACTIVITY_TYPE_OPPORTUNITY_CHECKIN]: 'opportunityCheckIn',
    [ACTIVITY_TYPE_EMAIL]: 'email',
    [ACTIVITY_TYPE_ANNOTATION]: 'type',
    [ACTIVITY_TYPE_VIDEO_CALL]: 'videoCallType',
    [ACTIVITY_TYPE_WHATSAPP]: 'whatsappType',
};

export const activityTypesWithoutSubfilters = {};

export default class ActivityModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toCrudFromCustomInit(data) {
        const {
            CompanyName,
            ContactName,
            Id,
            IdCompany,
            IDContact,
            IdOpportunity,
            OpportunityName,
            ActivityDate,
            Accounts,
        } = data;

        const activityType = parseInt(data.ActivityType, 10);
        const isEmail = activityType === ACTIVITY_TYPE_EMAIL;

        let companyObj = null;
        let contactObj = null;
        let opportunityObj = null;
        let momentGestionTime = '';
        const companyId = IdCompany && parseInt(IdCompany, 10);
        const contactId = IDContact && parseInt(IDContact, 10);
        const opportunityId = IdOpportunity && parseInt(IdOpportunity, 10);

        if (companyId > 0) {
            companyObj = {
                value: IdCompany,
                label: CompanyName || '',
            };
        } else if (Accounts?.length) {
            companyObj = {
                value: String(Accounts?.[0]?.Id),
                label: Accounts?.[0]?.Name,
            };
        }

        if (contactId > 0) {
            contactObj = {
                value: IDContact,
                label: ContactName || '',
            };
        }

        if (opportunityId > 0) {
            opportunityObj = {
                value: IdOpportunity,
                label: OpportunityName || '',
            };
        }

        // If is Email the object received is different
        if (isEmail && data.Opportunity?.Id) {
            opportunityObj = { value: data.Opportunity.Id, label: data.Opportunity.Name };
        }

        if (ActivityDate) {
            momentGestionTime = getMomentFromDateBackend(
                ActivityDate,
                'DD-MM-YYYYThh:mm:ss',
                true,
            ).toDate();
        }

        let finalObj = {
            id: Id,
            gestionTimeHour: momentGestionTime,
            activityType: data.ActivityType,
            groupEmails: isEmail,
            canDelete:
                activityType === ACTIVITY_TYPE_ANNOTATION ||
                activityType === ACTIVITY_TYPE_CHECKIN ||
                activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
                activityType === ACTIVITY_TYPE_FAST_CHECKIN ||
                activityType === ACTIVITY_TYPE_VIDEO_CALL ||
                activityType === ACTIVITY_TYPE_VIDEO_CHECKIN ||
                activityType === ACTIVITY_TYPE_WHATSAPP,
        };

        if (companyObj) finalObj.idEmpresa = companyObj;
        if (contactObj) finalObj.idContacto = contactObj;
        if (opportunityObj) finalObj.idExpediente = opportunityObj;

        return finalObj;
    }

    static standardToCrud(data, mappedSchema) {
        const {
            idEmpresa,
            empresa,
            idExpediente,
            expediente,
            gestionTime,
            idContacto,
            contacto,
            contactIdList,
            contactList,
            isReadOnly,
            isGeoCoded,
            idUser,
            userName,
            _idComercial,
            _comercial,
            ...rest
        } = data;

        let ownerObj = null;
        if (_idComercial && _idComercial !== '-1') {
            ownerObj = {
                value: _idComercial,
                label: _comercial || '',
            };
        }

        let companyObj = null;
        if (idEmpresa && idEmpresa !== '-1') {
            companyObj = {
                value: idEmpresa,
                label: empresa || '',
            };
        }

        let contactObj = null;
        if (idContacto && idContacto !== '-1') {
            contactObj = {
                value: idContacto,
                label: contacto || '',
            };
        }

        let opportunityObj = null;
        if (idExpediente && idExpediente !== '-1') {
            opportunityObj = {
                value: idExpediente,
                label: expediente || '',
            };
        }

        let momentGestionTime = '';
        if (gestionTime) {
            momentGestionTime = getMomentFromDateBackend(gestionTime);
            momentGestionTime = momentGestionTime.toDate();
        }

        let finalContactList = [];
        if (contactIdList?.length && contactList?.length) {
            let newContactIdList = contactIdList.split(';');
            newContactIdList.pop();
            let newContactList = contactList.split(';');
            newContactList.pop();

            finalContactList = newContactIdList.reduce((arr, current, index) => {
                arr.push({
                    label: newContactList[index],
                    value: current,
                });
                return arr;
            }, []);
        }

        let idContacto2 = finalContactList[0] || null;
        let idContacto3 = finalContactList[1] || null;
        let idContacto4 = finalContactList[2] || null;
        let idContacto5 = finalContactList[3] || null;

        const activityType = parseInt(rest.activityType, 10);

        let finalData = {
            ...rest,
            _idComercial: ownerObj,
            idEmpresa: companyObj,
            idExpediente: opportunityObj,
            gestionTime: momentGestionTime,
            gestionTimeHour: momentGestionTime,
            isReadOnly: getBackendBoolean(isReadOnly),
            isGeoCoded: getBackendBoolean(isGeoCoded),
            canDelete:
                activityType === ACTIVITY_TYPE_ANNOTATION ||
                activityType === ACTIVITY_TYPE_CHECKIN ||
                activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
                activityType === ACTIVITY_TYPE_FAST_CHECKIN ||
                activityType === ACTIVITY_TYPE_VIDEO_CALL ||
                activityType === ACTIVITY_TYPE_VIDEO_CHECKIN ||
                activityType === ACTIVITY_TYPE_WHATSAPP,
        };

        if (idContacto) finalData.idContacto = contactObj;
        if (idContacto2) finalData.idContacto2 = idContacto2;
        if (idContacto3) finalData.idContacto3 = idContacto3;
        if (idContacto4) finalData.idContacto4 = idContacto4;
        if (idContacto5) finalData.idContacto5 = idContacto5;

        return finalData;
    }

    static toCrudV1(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);
        let extraFields = [];

        if (mappedSchema) {
            extraFields =
                !data.Extrafields?.ExtraFieldUnit && data.extraFields
                    ? fromExtraFieldsEndpoint(data.extraFields || [], mappedSchema)
                    : fromExtraFields(
                          data.Extrafields && data.Extrafields.ExtraFieldUnit
                              ? data.Extrafields.ExtraFieldUnit
                              : [],
                          mappedSchema,
                      );
        }

        return { ...baseData, ...extraFields };
    }

    static toCrudV2(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);

        const v2Data = toCrudV2SelectValue(baseData, [
            'idEmpresa',
            'idContacto',
            'idContacto2',
            'idContacto3',
            'idContacto4',
            'idContacto5',
            'idExpediente',
            '_idComercial',
            'idTipoGestion',
        ]);

        let extraFields = [];

        if (mappedSchema) {
            extraFields =
                !data.Extrafields?.ExtraFieldUnit && data.extraFields
                    ? fromExtraFieldsEndpointV2(data.extraFields || [], mappedSchema)
                    : fromExtraFieldsV2(
                          data.Extrafields && data.Extrafields.ExtraFieldUnit
                              ? data.Extrafields.ExtraFieldUnit
                              : [],
                          mappedSchema,
                      );
        }

        return { ...baseData, v2Data, ...extraFields };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toList({ data }) {
        const newData = data.map((current) => {
            return this.toDetail(current);
        });

        return newData;
    }

    static toDetail(data) {
        let finalData = this.toCrud({ data });
        finalData.ActivityType = finalData.ActivityType
            ? parseInt(finalData.ActivityType, 10)
            : null;
        finalData.IdTipoCheckin = finalData.idTipoCheckin
            ? parseInt(finalData.IdTipoCheckin, 10)
            : null;

        finalData.Workflow = finalData.Workflow ? JSON.parse(finalData.Workflow) : null;

        let finalContactList = [];

        if (data?.contactIdList?.length && data?.contactList?.length) {
            let newContactIdList = data?.contactIdList.split(';');
            newContactIdList.pop();
            let newContactList = data?.contactList.split(';');
            newContactList.pop();

            finalContactList = newContactIdList.reduce((arr, current, index) => {
                arr.push({
                    label: newContactList[index],
                    value: current,
                });
                return arr;
            }, []);
        }

        if (data?.AttachmentName) finalData.contactList = finalContactList;
        finalData.IsReceived =
            data.IsReceived && !isNaN(data.IsReceived)
                ? parseInt(data.IsReceived, 10)
                : data.IsReceived;
        finalData.Duration = data.Duration ? parseInt(data.Duration, 10) : 0;
        finalData.IsSent = getBackendBoolean(data.IsSent);
        finalData.AttachmentNames = data.AttachmentName
            ? finalData.AttachmentName.split(',')
            : null;
        finalData.AttachmentSizes = data.AttachmentSize
            ? finalData.AttachmentSize.split(',')
            : null;
        finalData.AttachmentProviderIds = data.AttachmentProviderId
            ? finalData.AttachmentProviderId.split(',')
            : null;
        finalData.timeFromISO = getDateFromBackendISO(data?.ActivityDate, 'LT');
        finalData.dateFromISO = getDateFromBackendISO(data?.ActivityDate, 'L');
        finalData.elapsedTimeFromISO = getElapsedTime(moment.utc(data?.ActivityDate));
        finalData.CheckinMinutes = data?.CheckinMinutes;
        finalData.isEditable =
            this.activityType === ACTIVITY_TYPE_ANNOTATION ||
            this.activityType === ACTIVITY_TYPE_CHECKIN ||
            this.activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
            this.activityType === ACTIVITY_TYPE_FAST_CHECKIN ||
            this.activityType === ACTIVITY_TYPE_WHATSAPP;

        if (data.Attachments?.length) {
            finalData.attachments = data.Attachments.map((current, index) => {
                let format = current.FileName.split('.');
                format = format[format.length - 1] || '';
                const sizeFormatted = !isNaN(parseInt(current.Size, 10))
                    ? UtilFormat.formatFileSize(current.Size)
                    : current.Size;
                const providerId = current.ExtID;

                return {
                    name: current.FileName,
                    providerId,
                    size: current.Size,
                    sizeFormatted,
                    format,
                };
            });
        }

        return finalData;
    }

    static toWidget({ data }) {
        return this.toList({ data });
    }

    static toSave(data) {
        if (!data.id) return data;

        const state = Context.store.getState();
        const crud = getActiveCrud(state);
        const mappedSchema = crud.mappedSchema;

        // Not all the fields we receive in the detail need to be send when updating
        // Specially for the checkin activities
        const finalData = Object.keys(data).reduce((obj, key) => {
            if (!mappedSchema[key]) {
                obj[key] = data[key];
                //Despite Backend sends lat and lon separated by ',' they need to receive it separated by '.'
                if ((key === 'lat' || key === 'lon') && obj[key]) {
                    obj[key] = obj[key].replace(',', '.');
                }
                return obj;
            }
            const field = mappedSchema[key];
            if (field.type === 'bool') {
                obj[key] = parseBooleanForBackend(data[key]);
            } else {
                obj[key] = data[key];
            }
            return obj;
        }, {});

        delete finalData.gestionTimeHour;

        return finalData;
    }

    get timestamp() {
        return parseInt(this.entity.activitydate_timestamp, 10);
    }

    get type() {
        return this.entity.type;
    }

    get idUser() {
        return parseInt(this.entity.iduser, 10);
    }

    get activityOwner() {
        return this.entity.activityowner;
    }

    get idActivityOwner() {
        return parseInt(this.entity.idactivityowner, 10);
    }

    get isReceived() {
        return parseInt(this.entity.isreceived, 10);
    }

    get id() {
        return parseInt(this.entity.id, 10);
    }

    get idType() {
        return parseInt(this.entity.idtype, 10);
    }

    get activityType() {
        return parseInt(this.entity.activitytype, 10);
    }

    get attachmentNames() {
        if (this.entity.attachmentname) {
            return this.entity.attachmentname.split(',');
        }
    }

    get attachmentProviderIds() {
        if (this.entity.attachmentproviderid) {
            return this.entity.attachmentproviderid.split(',');
        }
    }

    get attachmentSizes() {
        if (this.entity.attachmentsize) {
            return this.entity.attachmentsize.split(',');
        }
    }

    get attachmentUrls() {
        if (this.entity.attachmenturls) {
            return this.entity.attachmenturls;
        }
    }

    get isSent() {
        return this.entity.issent === '1';
    }

    get idTipoCheckin() {
        return this.entity.idtipocheckin ? parseInt(this.entity.idtipocheckin, 10) : null;
    }

    get idCompany() {
        return this.entity.idcompany ? parseInt(this.entity.idcompany, 10) : null;
    }

    get companyName() {
        return this.entity.companyname;
    }

    get idContact() {
        return this.entity.idcontact ? parseInt(this.entity.idcontact, 10) : null;
    }

    get contactName() {
        return this.entity.contactname;
    }

    get customSubject() {
        return this.entity.customsubject;
    }

    get customBody() {
        return this.entity.custombody;
    }

    get contactRole() {
        return this.entity.contactrole;
    }

    get idOpportunity() {
        return this.entity.idexpediente
            ? parseInt(this.entity.idexpediente, 10)
            : this.entity.idopportunity
              ? parseInt(this.entity.idopportunity, 10)
              : null;
    }

    get opportunityName() {
        return this.entity.expediente || this.entity.opportunity;
    }

    get userName() {
        return this.entity.username;
    }

    get duration() {
        return parseInt(this.entity.duration, 10);
    }

    get phoneNumber() {
        return this.entity.phonenumber;
    }

    get description() {
        return this.entity.description;
    }

    get subject() {
        return this.entity.subject;
    }

    get body() {
        return this.entity.body;
    }

    get timezone() {
        return this.entity.timezone;
    }

    get contactList() {
        const list = this.entity.contactlist ? this.entity.contactlist.split(';') : [];
        if (list.length > 0 && !list[list.length - 1]) list.pop();
        return list;
    }

    get contactIdList() {
        const list = this.entity.contactidlist ? this.entity.contactidlist.split(';') : [];
        if (list.length > 0 && !list[list.length - 1]) list.pop();
        return list;
    }

    get workFlow() {
        try {
            let workFlow = this.entity.workflow;
            if (workFlow) workFlow = JSON.parse(workFlow);
            return workFlow;
        } catch (ex) {
            console.warn('error parsing workFlow object from backend');
            return null;
        }
    }

    get fmProviderId() {
        return this.entity.fmproviderid;
    }

    get timeStart() {
        return this.entity.timestart;
    }

    get timeEnd() {
        return this.entity.timeend;
    }

    get timelineMessages() {
        return this.entity.timelinemessages;
    }

    get timelineLastMessage() {
        return this.entity.timelinelastmessage;
    }

    get recordingUrl() {
        return this.entity.recordingurl;
    }

    get isReadOnly() {
        return !(
            !this.entity.isreadonly ||
            this.entity.isreadonly === '0' ||
            this.entity.isreadonly.toLowerCase() === 'false'
        );
    }

    get videoCallTimeStart() {
        return this.entity.videocalltimestart;
    }

    get videoCallTimeEnd() {
        return this.entity.videocalltimeend;
    }

    get WhatsappMessage() {
        return this.entity.WhatsappMessage;
    }
}

import React, { memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Input } from 'hoi-poi-ui';

import { DOCUMENTS } from 'constants/Entities';
import { DocumentActions } from 'actions';

import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { validateUrl } from 'utils/links/checkUrls';
import { isEmptyObject } from 'utils/objects';

function mapStateToProps(state) {
    return {
        ...state.documents.linkDocuments,
        folderSelected: state.documents.foldersTree?.folderSelected?.id,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSave: bindActionCreators(DocumentActions, dispatch).createLink,
        onUpdate: bindActionCreators(DocumentActions, dispatch).updateLink,
        onCancel: bindActionCreators(DocumentActions, dispatch).showLinkAction.bind(null, false),
    };
}

const DocumentsLinkModal = memo(({ folderSelected, show, onCancel, onSave, fetchData }) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setName('');
                setUrl('');
                setErrors(null);
            }, 500);
        }
    }, [show]);

    const onChangeName = useCallback(
        (value, e) => {
            setErrors({ ...errors, name: null });
            setName(value);
        },
        [errors],
    );

    const onChangeUrl = useCallback(
        (value, e) => {
            setErrors({ ...errors, url: null });
            setUrl(value);
        },
        [errors],
    );

    const onConfirm = useCallback(() => {
        let newErrors = {};
        if (!name) newErrors.name = getLiteral('warning_empty_folder_name');
        if (!url) {
            newErrors.url = getLiteral('warning_empty_folder_link');
        } else if (!validateUrl(url)) newErrors.url = getLiteral('label_url_invalid');
        if (Object.keys(newErrors).length > 0) setErrors(newErrors);
        else {
            logEvent({
                event: DOCUMENTS.trueName,
                functionality: 'createLink',
            });
            onSave(name, url, isEmptyObject(folderSelected) ? -1 : folderSelected, () => {
                console.log('nome ejecuto');
                fetchData?.();
            });
            setErrors(null);
        }
    }, [fetchData, folderSelected, name, onSave, url]);

    const hasErrors = errors?.name || errors?.url;

    return (
        <Modal
            className="fm-documents__add-link-modal"
            title={getLiteral('action_add_link')}
            isOpen={show}
            cancelText={getLiteral('action_cancel')}
            onRequestClose={onCancel}
            onCancel={onCancel}
            confirmText={getLiteral('action_save')}
            onConfirm={onConfirm}
            isConfirmDisabled={hasErrors}
            isConfirmLoading={false}
            size="small"
        >
            <Input
                className="fm-documents__add-link-modal__name"
                label={getLiteral('label_name')}
                placeholder={getLiteral('placeholder_link_name')}
                onChange={onChangeName}
                onEnter={onConfirm}
                value={name}
                error={errors?.name}
                isRequired
                isFullWidth
            />
            <Input
                className="fm-documents__add-link-modal__url"
                label={getLiteral('label_url')}
                placeholder={getLiteral('placeholder_link_url')}
                onChange={onChangeUrl}
                onEnter={onConfirm}
                value={url}
                error={errors?.url}
                isRequired
                isFullWidth
            />
        </Modal>
    );
});

DocumentsLinkModal.propTypes = {
    show: PropTypes.bool,
    folder: PropTypes.object,
    onSave: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsLinkModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { accepts } from 'utils/files';
import { EntityFiltersActions, DocumentActions, EntityListActions } from 'actions';
import { DOCUMENT_SECTIONS } from 'constants/ActionTypes';
import { DOCUMENTS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchDocuments from './components/SearchDocuments';
import { getLiteral } from '../../utils/getLiteral';

import CONFIG from './TableConfig';

const propTypes = {
    canCreate: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canDelete: PropTypes.bool,
    loadingAction: PropTypes.bool,
    isLoading: PropTypes.bool,
    folderSelected: PropTypes.object,
    folderSelectedArr: PropTypes.array,
    section: PropTypes.number,
    selectedRow: PropTypes.object,
    canEditDocument: PropTypes.bool,
    canDeleteDocument: PropTypes.bool,
    canEditFolder: PropTypes.bool,
    canDeleteFolder: PropTypes.bool,
    clearFilters: PropTypes.func,
    changeFilter: PropTypes.func,
    selectSection: PropTypes.func,
    setFolderSelected: PropTypes.func,
    showCreateFolder: PropTypes.func,
    showDeleteFolder: PropTypes.func,
    downloadDocument: PropTypes.func,
    downloadDocumentLink: PropTypes.func,
};

const mapStateToProps = (state) => {
    const permissions = state.config.permission;
    const permissionsDocuments = permissions.crud_permission.DOCUMENT;
    const permissionsFolders = permissions.crud_permission.DOCUMENTLIBRARY;
    const loading = state.documents.downloadLoading;
    const canCreate = permissionsDocuments?.create || permissionsFolders?.create || false;
    const canUpdate = permissionsDocuments?.update || false;
    const canDelete = permissionsDocuments?.delete || false;
    const loadingAction = loading;
    const foldersTree = state.documents.foldersTree;

    const filterFolderSelected =
        state.entityFilters[DOCUMENTS.entity] && state.entityFilters[DOCUMENTS.entity].filters;
    let section = state.documents.section;
    if (filterFolderSelected && !filterFolderSelected.folder) {
        if (filterFolderSelected.followingItem && filterFolderSelected.followingItem.value) {
            section = DOCUMENT_SECTIONS.FAVORITE;
        } else {
            section = DOCUMENT_SECTIONS.RECENTS;
        }
    }

    const isLoading =
        state.entityList.documents && state.entityList.documents.loading
            ? state.entityList.documents.loading
            : false;
    const selectedRow =
        state.entityList.documents && state.entityList.documents.selectedRow
            ? state.entityList.documents.selectedRow
            : null;
    const folderPermission = state.config.permission.crud_permission.DOCUMENTLIBRARY;
    let selection = state.entityListSelect[DOCUMENTS.entity];

    return {
        canCreate,
        canUpdate,
        canDelete,
        loadingAction,
        folderSelected: foldersTree.folderSelected,
        folderSelectedArr: foldersTree.folderSelectedArr,
        foldersTreeData: foldersTree.data,
        section,
        isLoading,
        selectedRow,
        canCreateDocument:
            state.config.permission.documentos_manager &&
            state.config.permission.crud_permission.DOCUMENT.create,
        canEditDocument: state.config.permission.documentos_manager && canUpdate,
        canDeleteDocument: state.config.permission.documentos_manager && canDelete,
        canCreateFolder: state.config.permission.documentos_manager && folderPermission.create,
        canEditFolder: state.config.permission.documentos_manager && folderPermission.update,
        canDeleteFolder: state.config.permission.documentos_manager && folderPermission.delete,
        selectedDocuments: selection && selection.total ? selection.total : 0,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(DocumentActions, dispatch).selectSection,
        setFolderSelected: bindActionCreators(DocumentActions, dispatch).setFolderSelected,
        showCreateFolder: bindActionCreators(DocumentActions, dispatch).showCreateFolder,
        showDeleteFolder: bindActionCreators(DocumentActions, dispatch).showDeleteFolder,
        downloadDocument: bindActionCreators(DocumentActions, dispatch).downloadDocument,
        downloadDocumentLink: bindActionCreators(DocumentActions, dispatch).downloadDocumentLink,
        massiveUpdateDocumentsAndFolders: bindActionCreators(DocumentActions, dispatch)
            .massiveUpdateDocumentsAndFolders,
        addMultipleDocumentsToUpload: bindActionCreators(DocumentActions, dispatch)
            .addMultipleDocumentsToUpload,
        init: bindActionCreators(EntityListActions, dispatch).init,
        showLinkAction: bindActionCreators(DocumentActions, dispatch).showLinkAction,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.bulkOperationsLiterals = {
            labelDeleteTextInfo: 'label_delete_fields_info',
            labelActionCantUndone: 'label_action_cant_undone',
            labelLoadingConfirmDeleteModalTitle: 'label_bulk_loading_delete',
            labelWriteXToConfirm: 'label_type_confirmation',
            labelWriteDelete: 'action_delete',
            inconsistency: 'label_bulk_inconsistency_documents_desc',
            confirmInfoNumDeleted: 'confirm_info_num_account_deleted',
            entityNameSingular: 'label_document',
            entityNamePlural: 'cfm_label_documents',
            confirmDeleteModalTitle: 'title_delete_documents',
            labelTitleMenuTotalSelected: 'label_num_documents_selected',
            labelTitleMenuTotalSelectedSingular: 'label_num_documents_selected_singular',
            labelSuccessDeleteSingular: 'success_delete_document',
            labelSuccessDeletePlural: 'success_bulk_delete_documents',
        };

        this.addButtonOptions = [];
        if (props.canCreateDocument) {
            this.addButtonOptions.push({
                title: getLiteral('action_upload_document'),
                icon: 'document',
                onClick: this.handleOpenAddDocument,
            });

            this.addButtonOptions.push({
                title: getLiteral('action_add_link'),
                icon: 'link',
                onClick: () => props.showLinkAction(true),
            });
        }

        if (props.canCreateFolder) {
            this.addButtonOptions.push({
                title: getLiteral('action_add_folder'),
                icon: 'folder',
                onClick: () => {
                    let { showCreateFolder, folderSelected } = props;
                    showCreateFolder(true, folderSelected.id);
                },
            });
        }
    }

    allFilesAccepted(files) {
        return files.every((file) => accepts(file, this.props.accept));
    }

    uploadFile = (files) => {
        const { addMultipleDocumentsToUpload, folderSelected, init } = this.props;

        addMultipleDocumentsToUpload(files, folderSelected.id, DOCUMENTS.entity).then(() => {
            init(DOCUMENTS, false, null, null, null, true);
        });
    };

    onSelectFile = (event) => {
        event.preventDefault();

        // Reset the counter along with the drag on a drop.
        this.enterCounter = 0;

        this.setState({
            isDragActive: false,
            isDragReject: false,
        });

        const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        const max = droppedFiles.length;
        const files = [];

        for (let i = 0; i < max; i++) {
            const file = droppedFiles[i];
            file.preview = window.URL.createObjectURL(file);
            files.push(file);
        }

        if (this.allFilesAccepted(files)) {
            if (this.uploadFile) {
                this.uploadFile(files, event);
            }
        }
    };

    handleOpenAddDocument = () => {
        if (this.fileInputEl) {
            this.fileInputEl.value = null;
            this.fileInputEl.click();
        }
    };

    handleClickBreadcrumb = (folder) => {
        const { clearFilters, changeFilter, selectSection, setFolderSelected, isLoading } =
            this.props;

        const folderId = folder.id;
        const filter = {
            id: 'folder',
            hideForCount: true,
        };

        if (!isLoading) {
            clearFilters({ entity: DOCUMENTS, isAPurge: true, refresh: false });
            changeFilter({ entity: DOCUMENTS, filter, value: folderId, refresh: true });
            selectSection(DOCUMENT_SECTIONS.NORMAL);
            setFolderSelected(folder);
        }
    };

    handleOpenDialogEdit = () => {
        return () => {
            const { selectedRow, showCreateFolder } = this.props;

            let crudAction = 'update';
            let elementType;

            if (selectedRow.is_folder === '1') {
                elementType = 'folder';
            }
            if (selectedRow.is_folder === '0') {
                elementType = 'document';
            }

            showCreateFolder(
                true,
                selectedRow.node_id,
                DOCUMENTS,
                selectedRow,
                crudAction,
                elementType,
            );
        };
    };

    customBulkDelete = ({ success, error }) => {
        const { massiveUpdateDocumentsAndFolders } = this.props;
        return massiveUpdateDocumentsAndFolders()
            .then(success)
            .catch((err) => {
                console.error(err);
                error(
                    err?.ErrorDetail?.ErrorCode === '1'
                        ? getLiteral('label_documents_delete_no_permissions')
                        : null,
                );
            });
    };

    render() {
        const {
            canCreate,
            folderSelectedArr,
            section,
            selectedDocuments,
            canDeleteFolder,
            canDeleteDocument,
        } = this.props;

        const showBreadcrumb = section === DOCUMENT_SECTIONS.NORMAL ? true : false;
        const showSectionTitle =
            section === DOCUMENT_SECTIONS.RECENTS || section === DOCUMENT_SECTIONS.FAVORITE
                ? true
                : false;

        let sectionTitle = '';
        if (section === DOCUMENT_SECTIONS.RECENTS) {
            sectionTitle = 'label_recents';
        }
        if (section === DOCUMENT_SECTIONS.FAVORITE) {
            sectionTitle = 'label_favourite_male_pl';
        }
        const cells = ['addDocument', 'addLink', 'addFolder'];

        const config = CONFIG();

        return (
            <div className="documents-header">
                <EntityMenu
                    entity={DOCUMENTS}
                    canCreate={canCreate}
                    canMassiveUpdate={false}
                    canMassiveDelete={canDeleteDocument || canDeleteFolder}
                    withBreadcrumb={showBreadcrumb}
                    withSectionTitle={showSectionTitle}
                    sectionTitle={sectionTitle}
                    folderSelectedArr={folderSelectedArr}
                    onClickBreadcrumb={this.handleClickBreadcrumb}
                    searchComponent={<SearchDocuments />}
                    withBulkOperations
                    bulkOperationsLiterals={this.bulkOperationsLiterals}
                    bulkSelectedEntities={selectedDocuments}
                    customBulkDelete={this.customBulkDelete}
                    addButtonOptions={this.addButtonOptions}
                    help={config.help}
                />
                <input
                    style={{ display: 'none' }}
                    type="file"
                    multiple={true}
                    ref={(el) => (this.fileInputEl = el)}
                    onChange={this.onSelectFile}
                />
            </div>
        );
    }
}

Menu.propTypes = propTypes;

export default Menu;

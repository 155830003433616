import Immutable, { List, Map } from 'immutable';

import {
    ADD_TAB_DETAIL,
    HIDE_DETAIL,
    LOAD_WIDGET_DETAIL,
    SUCCESS_WIDGET_DETAIL,
    ERROR_WIDGET_DETAIL,
    REMOVE_TAB_DETAIL,
    SELECT_TAB_DETAIL,
    NEW_WIDGET_DETAIL,
    OBJECT_TAB_DETAIL,
    UPDATE_TABS,
    ENTITY_DOES_NOT_EXISTS_SHOW,
    ENTITY_DOES_NOT_EXISTS_HIDE,
    ERROR_OBJECT_TAB_DETAIL,
} from '../constants/ActionTypes';

import { trackEvent } from 'utils/tracking';
import { EVENT_TYPES } from 'constants/Constants';

import Context from '../managers/Context';
import { intercomHideAndShow } from 'utils/intercom';

function _addTab(type, id) {
    return { type: ADD_TAB_DETAIL, entityType: type, id };
}

function _removeTab(index) {
    return { type: REMOVE_TAB_DETAIL, index };
}

function _selectTab(index) {
    return { type: SELECT_TAB_DETAIL, index };
}

function _hideDetail() {
    return { type: HIDE_DETAIL };
}

function _showLoading(tabIndex, widget, fetchData) {
    return { type: LOAD_WIDGET_DETAIL, tabIndex, widget, fetchData };
}

function _successDetail(tabIndex, widget, data) {
    return { type: SUCCESS_WIDGET_DETAIL, tabIndex, widget, data };
}

function _errorDetail(tabIndex, widget) {
    return { type: ERROR_WIDGET_DETAIL, tabIndex, widget };
}

function _newDetail(type, id) {
    return { type: NEW_WIDGET_DETAIL, entityType: type, id };
}

function _addObjectDetail(tabIndex, object) {
    return { type: OBJECT_TAB_DETAIL, tabIndex, entityObject: object };
}

function _errorObjectDetail(tabIndex) {
    return { type: ERROR_OBJECT_TAB_DETAIL, tabIndex };
}

function _updateTabs(tabs) {
    return { type: UPDATE_TABS, tabs };
}

function _showDialogEntityDoesNotExist(entity) {
    return { type: ENTITY_DOES_NOT_EXISTS_SHOW, entity };
}

function _hideDialogEntityDoesNotExist() {
    return { type: ENTITY_DOES_NOT_EXISTS_HIDE };
}

function getEntityObject(dispatch, index, type, id) {
    Context.entityManager.getEntitiesManager(type).getEntity(
        id,
        (response) => {
            dispatch(_addObjectDetail(index, response));
        },
        (error) => {
            dispatch(_errorObjectDetail(index));
        },
    );
}

export function newDetailTab(type, id) {
    return function (dispatch, getState) {
        trackEvent({
            entity: type,
            entityId: id,
            idEventType: EVENT_TYPES.read,
        });
        dispatch(_newDetail(type, id));
        getEntityObject(dispatch, 0, type, id);
    };
}

export function refreshData(id, type) {
    return function (dispatch, getState) {
        const array = getState().detail.tabs;
        array.forEach((tab, index) => {
            const isEntity =
                typeof type === 'object'
                    ? tab.get('entityType') === type
                    : tab.get('entityType').entity === type;
            if (parseInt(tab.get('id')) === id && isEntity) {
                dispatch(newDetailTab(tab.get('entityType'), tab.get('id')));
                const widgetObject = tab.get('widgets').toJSON();
                Object.keys(widgetObject).forEach((widget) => {
                    dispatch(fetchData(index, widget, widgetObject[widget].fetchData));
                });
            }
        });
    };
}

export function addTab(type, id) {
    return function (dispatch, getState) {
        const array = getState().detail.tabs;
        let _index = -1;
        array.forEach((tab, index, array) => {
            if (tab.get('entityType') === type && tab.get('id') === id) {
                _index = index;
            }
        });
        if (_index === -1) {
            dispatch(_addTab(type, id));
            getEntityObject(dispatch, array.size, type, id);
        } else {
            dispatch(selectTab(_index));
        }
    };
}

export function removeTab(index) {
    return function (dispatch) {
        dispatch(_removeTab(index));
    };
}

export function selectTab(index) {
    return function (dispatch) {
        dispatch(_selectTab(index));
    };
}

export function hideDetail(type, entityObject) {
    return function (dispatch) {
        intercomHideAndShow('show');
        dispatch(_hideDetail());
    };
}

export function fetchData(tabIndex, widget, fetchDataFunction) {
    return function (dispatch, getState) {
        const widgetInfo = getState().detail.tabs.get(tabIndex).get('widgets').get(widget);
        if (!widgetInfo || !widgetInfo.loading) {
            dispatch(_showLoading(tabIndex, widget, fetchDataFunction));
            fetchDataFunction(
                (response) => {
                    response = response instanceof Array ? List(response) : Map(response);
                    getState().detail.tabs.size > 0 &&
                        dispatch(_successDetail(tabIndex, widget, response));
                },
                (error) => {
                    getState().detail.tabs.size > 0 &&
                        dispatch(_errorDetail(tabIndex, widget, 'ERROR'));
                },
            );
        }
    };
}

export function refreshDetailActive() {
    return function (dispatch, getState) {
        const detailSelected = getState().detail.select;
        if (detailSelected !== -1) {
            const tabSelected = getState().detail.tabs.get(detailSelected);
            if (!tabSelected) return;
            dispatch(refreshData(tabSelected.get('id'), tabSelected.get('entityType')));
        }
    };
}

export function updateTabs(tabs) {
    return function (dispatch, getState) {
        dispatch(_updateTabs(tabs));
    };
}

export function showDialogEntityDoesNotExist(entity) {
    return function (dispatch) {
        dispatch(_showDialogEntityDoesNotExist(entity));
    };
}

export function hideDialogEntityDoesNotExist() {
    return function (dispatch) {
        dispatch(_hideDialogEntityDoesNotExist());
    };
}

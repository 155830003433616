let USERLOCALE = 'en-US';

export const setupUserLocaleNumber = (locale) => {
    if (locale === 'es') locale = 'es-ES';
    if (locale === 'en') locale = 'en-US';
    if (locale === 'en-US') return; // default locale for the library
    locale = locale.split('-');
    if (locale.length > 1) {
        locale[1] = locale[1].toUpperCase();
        locale = locale.join('-');
    }
    USERLOCALE = locale;
};

export const truncate = (number, decimals) => {
    if (!number) return number;
    if (!decimals) return number;
    if (isNaN(number)) return number;

    const calcDec = Math.pow(10, decimals);
    return Math.trunc(number * calcDec) / calcDec;
};

export const getNumberAsLocaleNumber = (value, options) => {
    try {
        if (!value) return value;
        if (isNaN(value)) {
            value = value.replace(',', '.');
            if (isNaN(value)) return value;
        }
        options = { useGrouping: true, ...options };
        return Number(value).toLocaleString(USERLOCALE, options);
    } catch (ex) {
        console.warn('something wrong happens when formatting value ', value);
        // it's a minor issue, in case of an exception here, returning the value without format
        return value;
    }
};

export const randomId = () => {
    return Math.floor(Math.random() * 10000);
};

export const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1e18, symbol: 'E' },
        { value: 1e15, symbol: 'P' },
        { value: 1e12, symbol: 'T' },
        { value: 1e9, symbol: 'G' },
        { value: 1e6, symbol: 'M' },
        { value: 1e3, symbol: 'k' },
        { value: 1, symbol: '' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.find((item) => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

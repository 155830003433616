import React, { PureComponent } from 'react';
import ButtonWarning from './ButtonWarning';

class ButtonWarningSmall extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return (
            <ButtonWarning
                {...props}
                classes={['fm-button__warning fm-button__small', ...classes]}
                loadingColor="white"
            />
        );
    }
}

export default ButtonWarningSmall;

import {
    LOAD_CONFIG_SUCCESS,
    LOAD_CONFIG_ERROR,
    LOAD_CONFIG_COMPLETE_LOGIN,
    LOAD_CONFIG_WEB_SUCCESS,
    CLEAR,
    REFRESH_WEBTEMPLATES,
    UPDATE_CONFIG_VIEWS,
    UPDATE_CONFIG_PARTIAL,
} from 'constants/ActionTypes';
import moment from 'moment';
import Context from '../managers/Context';

function _showError(error) {
    return { type: LOAD_CONFIG_ERROR, error };
}

function _setData(data) {
    return { type: LOAD_CONFIG_SUCCESS, config: data };
}

function _clear() {
    return { type: CLEAR };
}
function _setConfigWeb(config) {
    return { type: LOAD_CONFIG_WEB_SUCCESS, configWeb: config };
}

function _refreshWebTemplates(data) {
    return {
        type: REFRESH_WEBTEMPLATES,
        webTemplates: data,
    };
}

export function login(name, password, userKey) {
    return function () {
        return Context.sessionManager.initSession(name, password, userKey);
    };
}

export function loginWithConfig(name, password) {
    return function () {
        return Context.sessionManager.initSessionWithToken(name, password);
    };
}

export function completeLogin() {
    return function (dispatch) {
        Context.sessionManager.updateConfigStore({ contracts: [] });
        dispatch({ type: LOAD_CONFIG_COMPLETE_LOGIN });
    };
}

export function setDataConfig(config) {
    return function (dispatch) {
        dispatch(_setData({ data: config }));
    };
}

export function setConfigWeb(name, config, partial = false) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            Context.sessionManager.setConfigWeb(
                name,
                config,
                partial,
                (config) => {
                    dispatch(_setConfigWeb(config));
                    resolve(config);
                },
                (error) => {
                    dispatch(_setConfigWeb(config));
                    reject(config);
                },
            );
        });
    };
}

export function logout() {
    return (dispatch) => {
        Context.sessionManager.closeSession(
            () => {
                dispatch(_clear());
            },
            (error) => {
                console.error(error);
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function refreshWebTemplates(callback) {
    return (dispatch, getState) => {
        Context.sessionManager.refreshWebTemplates(
            (data) => {
                dispatch(_refreshWebTemplates(data));
                if (callback && typeof callback === 'function') {
                    callback();
                }
            },
            (error) => {
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function updateSpecificPermissions(permissions) {
    return (dispatch) => {
        // permissions must be an array of objects key-value, key the name of the permission in config and value a boolean
        // i.e. [{key: "useWatson", value: !active}]
        const config = Context.sessionManager.updateSpecificPermissions(permissions);
        if (config) {
            dispatch(_setData({ data: config }));
        }
    };
}

export function setDisclaimer(
    permission,
    updatePermissions = true,
    customPermission, // Custom permission value for config, for when the value to be stored is different than the value sent to setDisclaimer
) {
    return (dispatch) => {
        return Context.domainManager
            .setDisclaimer({
                configItemKey: permission.key,
                accepted: permission.value,
            })
            .then((response) => {
                if (updatePermissions) {
                    dispatch(updateSpecificPermissions([customPermission || permission]));
                }
                return response;
            });
    };
}

export function updateConfigStore(values) {
    return (dispatch) => {
        // values must be an object to use with an spread operator in sessionManager
        const config = Context.sessionManager.updateConfigStore(values);
        if (config) {
            dispatch(_setData({ data: config }));
        }
    };
}

export function updateUserDataConfiguration(values) {
    return (dispatch) => {
        // values must be an object to use with an spread operator in sessionManager
        const config = Context.sessionManager.updateUserDataConfiguration(values);
        if (config) {
            dispatch(_setData({ data: config }));
        }
    };
}

export function setEndWalkThroughDate() {
    return () => {
        return new Promise((resolve, reject) => {
            const dateNow = moment().utc().format('DD/MM/YYYY HH:mm:ss');
            Context.domainManager.setEndWalkThroughDate(
                dateNow,
                () => {
                    resolve();
                },
                () => {
                    reject();
                },
            );
        });
    };
}

export function updateViews(views) {
    return function (dispatch) {
        Context.cacheManager
            .updateViews(views)
            .then(() => {
                dispatch({
                    type: UPDATE_CONFIG_VIEWS,
                    views,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };
}

export function updateConfig(config) {
    return (dispatch) => {
        dispatch({ type: UPDATE_CONFIG_PARTIAL, config });
        Context.sessionManager.updateConfigStore({ ...config });
    };
}

export function refreshWeb4FieldsConfig() {
    return (dispatch) => {
        const sections = [
            'customFieldsSchemas',
            'readonlyfields',
            'mandatoryfields',
            'defaultvaluefields',
            'standardFieldsSchemas',
        ];
        return Context.domainManager
            .getPartialConfig(sections)
            .then((config) => {
                config.standardFieldsSchema =
                    config.standardFieldsSchema.entity &&
                    config.standardFieldsSchema.entity.reduce((obj, d) => {
                        if (!obj[d.name]) obj[d.name] = [];
                        obj[d.name] = obj[d.name].concat(d.field);
                        return obj;
                    }, {});
                Context.generateCustomConfig(config, false);
                const newConfig = {
                    standardFieldsSchema: config.standardFieldsSchema,
                    standardFieldsSchemaMap: config.standardFieldsSchemaMap,
                    extraFields: config.customFields.entity,
                    extraFieldsSchemaMap: config.extraFieldsSchemaMap,
                    defaultvaluefields: config.defaultvaluefields,
                    mandatoryFields: config.mandatoryFields,
                    readonlyfields: config.readonlyfields,
                    customFields: config.customFields, // preserve raw values too because config restores...
                };
                dispatch({ type: UPDATE_CONFIG_PARTIAL, config: newConfig });
                Context.sessionManager.updateConfigStore(newConfig);
            })
            .catch((e) => {
                console.error(e);
            });
    };
}

import React from 'react';
import colors from 'constants/colors';

const Recent = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 9H5a1 1 0 1 0 0 2h5a1 1 0 0 0 1-1V5a1 1 0 0 0-2 0v4zm0-9c4.988 0 9 4.012 9 9s-4.012 9-9 9-9-4.012-9-9 4.012-9 9-9z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default Recent;

import React, { PureComponent } from 'react';
import ButtonOutline from './ButtonOutline';

class ButtonOutlineBig extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return <ButtonOutline {...props} classes={['fm-button__big', ...classes]} />;
    }
}

export default ButtonOutlineBig;

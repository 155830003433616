import React from 'react';
import colors from 'constants/colors';

const Download = ({ color = '$fmDefaultIcons' }) => (
    <svg
        viewBox={`0 0 18 18`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M8.407 14.052L5.233 10.88a.793.793 0 1 1 1.122-1.123l1.82 1.82V.794a.793.793 0 1 1 1.587 0v10.782l1.82-1.82a.793.793 0 1 1 1.122 1.123L9.53 14.052a.729.729 0 0 1-.122.1c-.019.013-.04.022-.061.033-.025.014-.05.028-.075.04-.027.01-.054.017-.08.025-.024.006-.045.015-.069.02a.782.782 0 0 1-.31 0c-.024-.005-.045-.014-.068-.02-.027-.008-.054-.014-.08-.025-.027-.012-.05-.026-.076-.04-.02-.011-.041-.02-.061-.033a.762.762 0 0 1-.121-.1zM17.25 16c.414 0 .75.448.75 1s-.336 1-.75 1H.75C.336 18 0 17.552 0 17s.336-1 .75-1h16.5z"
                id="aDownload"
            />
        </defs>
        <use
            fill={colors[color] ? colors[color] : '#788590'}
            xlinkHref="#aDownload"
            fillRule="evenodd"
        />
    </svg>
);

export default Download;

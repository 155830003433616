import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { PATH_IMAGE } from 'constants/Environment';
import { PROVIDER_ID_ZOOM, PROVIDER_ID_TEAMS } from 'constants/Constants';
import './style.scss';

const ProviderInfo = ({ provider, onClick }) => {
    let logoSrc = provider.logo;
    let logoClassName = ['logo'];
    // provider.id is provider.ProviderId, but transformed in IntegrationModel
    switch (provider.id) {
        case PROVIDER_ID_ZOOM:
            logoSrc = `${PATH_IMAGE}zoom_integration.svg`;
            break;
        case PROVIDER_ID_TEAMS:
            logoSrc = `${PATH_IMAGE}microsoft_teams_integration.svg`;
            logoClassName.push('logo-with-background logo-teams');
            break;
        default:
            break;
    }
    const literalActive = provider.active ? 'label_active' : 'label_inactive';
    return (
        <div className="provider-grid" onClick={onClick}>
            <div className="column left">
                <img className={logoClassName.join(' ')} src={logoSrc} />
            </div>
            <div className="column middle">
                <div className="name">{getLiteral(provider.nameLabel)}</div>
                <div className="description">{getLiteral(provider.descriptionShort)}</div>
            </div>
            <div className="column right">
                <div className={provider.active ? 'active' : 'inactive'}>
                    {getLiteral(literalActive)}
                </div>
            </div>
        </div>
    );
};

export default memo(ProviderInfo);

import React from 'react';
import colors from 'constants/colors';

const ProgressCheck = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                id="progresscheck-a"
                d="M.994 4.214L0 4.976l3.786 4.338 7.651-8.54L10.454 0 3.8 7.428z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <rect
                width="26"
                height="26"
                rx="13"
                stroke={colors[color] ? colors[color] : '#788590'}
                strokeWidth="1.586"
                transform="translate(3 3)"
            />
            <g transform="translate(10.704 11.667)">
                <mask id="progresscheck-b" fill="#fff">
                    <use xlinkHref="#progresscheck-a" />
                </mask>
                <use
                    fill={colors[color] ? colors[color] : '#788590'}
                    fillRule="nonzero"
                    xlinkHref="#progresscheck-a"
                />
                <g mask="url(#progresscheck-b)" fill={colors[color] ? colors[color] : '#788590'}>
                    <path d="M0-1.481h11.951V9.567H0z" />
                </g>
            </g>
        </g>
    </svg>
);

export default ProgressCheck;

import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'hoi-poi-ui';
import { COMPANIES } from 'constants/Entities';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { getCompanyDetailUrl } from 'utils/getUrl';

const CompanyField = memo(({ data, onClick }) => {
    const { hasTabsEnabled, openTab } = useEntityDetail();

    const handleOnClick = useCallback(
        (e) => {
            if (!hasTabsEnabled || e.ctrlKey) return true;

            e.stopPropagation();
            e.preventDefault();

            onClick && onClick();

            openTab({
                entity: COMPANIES,
                id: data.IdCompany,
                hasCrudInDetail: true,
                toTab: false,
            });
        },
        [data.IdCompany, hasTabsEnabled, onClick, openTab],
    );

    const component = useMemo(() => {
        if (!data.empresa) return null;

        return (
            <Link
                className="fm-agenda-small-detail__link"
                href={getCompanyDetailUrl(data.IdCompany)}
                target="blank"
                onClick={handleOnClick}
            >
                {data.empresa}
            </Link>
        );
    }, [data, handleOnClick]);

    if (!data.IdCompany || data.IdCompany === '-1') return null;

    return <DetailField iconProps={{ name: 'accounts' }} component={component} />;
});

export default CompanyField;

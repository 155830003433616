import { Map } from 'immutable';
import Context from 'managers/Context';
import { isEmpty } from 'utils/text';

export default class CrudManager {
    initCrud(entityConfig, id, callbackSuccess, callbackError) {
        const config = this.context.cacheManager.getConfigStore();
        const entityManager = this.context.entityManager.getEntitiesManager(entityConfig);
        if (entityManager.beforeCrud) entityManager.beforeCrud(id);
        entityManager.getCrudSchema(
            config,
            entityConfig,
            (schema) => {
                if (id) {
                    entityManager.getEntity(
                        id,
                        (crudModel) => {
                            const entityCrud = crudModel.crud;
                            const config = getConfigDependence(schema, entityCrud);
                            callbackSuccess(schema, entityCrud, config.dynamic, config.dependence);
                        },
                        callbackError,
                    );
                } else {
                    let promise;
                    if (entityManager.loadValuesList) {
                        promise = new Promise((resolve, reject) => {
                            entityManager.loadValuesList(resolve, reject);
                        });
                    } else {
                        promise = new Promise((resolve, reject) => {
                            resolve();
                        });
                    }
                    Promise.all([promise])
                        .then((result) => {
                            let entityCrud = getDefaultEntityCrud(schema);
                            if (entityManager.setDefaultValueStandartFields) {
                                entityCrud =
                                    entityManager.setDefaultValueStandartFields(entityCrud);
                            }

                            if (entityManager.getDefaultEntityCrudHardCode) {
                                entityCrud = entityManager.getDefaultEntityCrudHardCode(
                                    entityCrud,
                                    result[0],
                                );
                            }

                            const config = getConfigDependence(schema, entityCrud);
                            callbackSuccess(schema, entityCrud, config.dynamic, config.dependence);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            },
            callbackError,
            !id,
        );
    }

    initCustomFieldsCrud(entityCrud, entity, fromEntity, fromEntityId, fromEntityName, params) {
        const entityManager = this.context.entityManager.getEntitiesManager(entity);
        if (entityManager.initCustomFieldsCrud2) {
            entityCrud = entityManager.initCustomFieldsCrud2(
                entityCrud,
                fromEntity,
                fromEntityId,
                fromEntityName,
                params,
            );
        }
        return entityCrud;
    }

    duplicateCrud(entityConfig, id, callbackSuccess, callbackError) {
        const config = this.context.cacheManager.getConfigStore();
        const entityManager = this.context.entityManager.getEntitiesManager(entityConfig);
        entityManager.getCrudSchema(
            config,
            entityConfig,
            (schema) => {
                entityManager.getEntity(
                    id,
                    (crudModel) => {
                        let entityCrud = crudModel.crud;
                        entityCrud = entityCrud.delete('id');
                        entityCrud = entityManager.duplicateCrud
                            ? entityManager.duplicateCrud(entityCrud)
                            : entityCrud;
                        const config = getConfigDependence(schema, entityCrud);
                        callbackSuccess(schema, entityCrud, config.dynamic, config.dependence);
                    },
                    callbackError,
                );
            },
            callbackError,
        );
    }

    getCrudSchema(config, entityConfig, crudEntitySchema, success, error, isCreate) {
        let configurationFields = config.standardFieldsSchemaMap[entityConfig.extraFieldName];

        this.context.extraFieldManager.getExtraFieldSchema(
            entityConfig,
            (extraFieldSchema) => {
                extraFieldSchema = setSectionVisibility(
                    extraFieldSchema,
                    this.context.store.getState(),
                    isCreate,
                );
                let mandatoryFieldsEntity = config.mandatoryFields.entity
                    ? config.mandatoryFields.entity.find(
                          findExtraFieldEntity(entityConfig.customField, 'name'),
                      )
                    : undefined;
                let defaultFieldsEntity = config.defaultvaluefields.entity
                    ? config.defaultvaluefields.entity.find(
                          findExtraFieldEntity(entityConfig.customField, 'name'),
                      )
                    : undefined;
                let readOnlyFieldsEntity = config.readonlyfields.entity
                    ? config.readonlyfields.entity.find(
                          findExtraFieldEntity(entityConfig.customField, 'name'),
                      )
                    : undefined;
                mandatoryFieldsEntity = mandatoryFieldsEntity
                    ? mandatoryFieldsEntity.mandatoryfield
                    : [];
                defaultFieldsEntity = defaultFieldsEntity
                    ? defaultFieldsEntity.defaultvaluefield
                    : [];
                readOnlyFieldsEntity = readOnlyFieldsEntity
                    ? readOnlyFieldsEntity.readonlyfield
                    : [];
                const customFieldsSchema = getCustomFieldConfig(
                    entityConfig,
                    mandatoryFieldsEntity,
                    defaultFieldsEntity,
                    readOnlyFieldsEntity,
                    crudEntitySchema,
                    configurationFields,
                );
                success(customFieldsSchema.concat(extraFieldSchema));
            },
            error,
        );
    }

    // TODO REFACTOR
    getCrudSchema2(config, entityConfig, crudEntitySchema, success) {
        let mandatoryFieldsEntity = config.mandatoryFields.entity
            ? config.mandatoryFields.entity.find(
                  findExtraFieldEntity(entityConfig.customField, 'name'),
              )
            : undefined;
        let defaultFieldsEntity = config.defaultvaluefields.entity
            ? config.defaultvaluefields.entity.find(
                  findExtraFieldEntity(entityConfig.customField, 'name'),
              )
            : undefined;
        let readOnlyFieldsEntity = config.readonlyfields.entity
            ? config.readonlyfields.entity.find(
                  findExtraFieldEntity(entityConfig.customField, 'name'),
              )
            : undefined;
        mandatoryFieldsEntity = mandatoryFieldsEntity ? mandatoryFieldsEntity.mandatoryfield : [];
        defaultFieldsEntity = defaultFieldsEntity ? defaultFieldsEntity.defaultvaluefield : [];
        readOnlyFieldsEntity = readOnlyFieldsEntity ? readOnlyFieldsEntity.readonlyfield : [];
        const customFieldsSchema = getCustomFieldConfig2(
            entityConfig,
            mandatoryFieldsEntity,
            defaultFieldsEntity,
            readOnlyFieldsEntity,
            crudEntitySchema,
        );

        success(customFieldsSchema);
    }

    updateField(fieldId, value, callbackSuccess, extraInfo) {
        let { schema, entityCrud, entityType, errors } = this.context.store.getState().crud;
        const entityManager = this.context.entityManager.getEntitiesManager(entityType);
        const oldValue = entityCrud.get(fieldId.toLowerCase());
        entityCrud = entityCrud.set(fieldId.toLowerCase(), value);
        errors = errors.delete(fieldId.toLowerCase());
        if (entityManager.updateField) {
            entityCrud = entityManager.updateField(
                entityCrud,
                fieldId,
                value,
                oldValue,
                callbackSuccess,
                extraInfo,
            );
        }

        const config = getConfigDependence(schema, entityCrud);

        // remove selected values of fields that depends on the field changed
        entityCrud = checkDependantValues(fieldId, schema, entityCrud);

        callbackSuccess(entityCrud, config.dynamic, config.dependence, errors);
    }

    deleteField(fieldId, callbackSuccess) {
        let { schema, entityCrud, entityType, errors } = this.context.store.getState().crud;
        const entityManager = this.context.entityManager.getEntitiesManager(entityType);
        const oldValue = entityCrud.get(fieldId.toLowerCase());
        entityCrud = entityCrud.set(fieldId.toLowerCase(), null);
        errors = errors.delete(fieldId.toLowerCase());
        if (entityManager.deleteField) {
            entityCrud = entityManager.deleteField(entityCrud, fieldId, oldValue, callbackSuccess);
        }
        const config = getConfigDependence(schema, entityCrud);

        callbackSuccess(entityCrud, config.dynamic, config.dependence, errors);

        schema.form.forEach((element) => {
            element.tabFields.forEach((field) => {
                if (
                    field.parentField &&
                    field.parentField.toLowerCase() === fieldId.toLowerCase()
                ) {
                    if (typeof field.id === 'object') {
                        Object.keys(field.id).forEach((key) => {
                            this.deleteField(field.id[key], callbackSuccess);
                        });
                    } else {
                        this.deleteField(field.id, callbackSuccess);
                    }
                }
            });
        });
    }

    saveCrud(
        entityCrud,
        schema,
        entityType,
        dynamic,
        callbackSuccess,
        callbackErrorFields,
        callbackError,
    ) {
        const entityManager = this.context.entityManager.getEntitiesManager(entityType);
        const errorsObject = entityManager.getErrors
            ? entityManager.getErrors(schema, entityCrud, dynamic)
            : this.getErrors(schema, entityCrud, dynamic);
        const errors = errorsObject.errors;
        if (errors.size > 0) {
            callbackErrorFields(errors, errorsObject.firstErrorField);
        } else {
            const id = entityCrud.get('id');
            if (id) {
                entityManager.updateEntity(
                    id,
                    entityCrud,
                    (response) => {
                        entityManager.refreshData();
                        this.context.store.dispatch(
                            this.context.actions.DetailActions.refreshData(id, entityType),
                        );
                        this.context.store.dispatch(
                            this.context.actions.EntityActions.updateEntity(
                                entityType,
                                id,
                                entityCrud.toJSON(),
                            ),
                        );
                        callbackSuccess(response);
                    },
                    callbackError,
                );
            } else {
                entityManager.createEntity(
                    entityCrud,
                    (response) => {
                        entityManager.refreshData();
                        callbackSuccess(response);
                    },
                    callbackError,
                );
            }
        }
    }

    showPreview(entityCrud, schema, entityType, dynamic, callbackSuccess, callbackErrors) {
        // check the errors in the same way as saveCrud, but the callback will be only for show the preview
        const entityManager = this.context.entityManager.getEntitiesManager(entityType);
        const errorsObject = entityManager.getErrors
            ? entityManager.getErrors(schema, entityCrud, dynamic)
            : this.getErrors(schema, entityCrud, dynamic);
        const errors = errorsObject.errors;
        if (errors.size > 0) {
            callbackErrors(errors, errorsObject.firstErrorField);
        } else {
            callbackSuccess();
        }
    }

    saveCrudWithoutForm(id, entityType, updateArray, callbackSuccess, errorFields, errorServer) {
        this.initCrud(
            entityType,
            id,
            (schema, dataInit, dynamic, dependence) => {
                updateArray.forEach((update) => {
                    dataInit = dataInit.set(update.field, update.value);
                });
                this.saveCrud(
                    dataInit,
                    schema,
                    entityType,
                    dynamic,
                    (id) => {
                        callbackSuccess();
                    },
                    (errors) => errorFields(schema, dataInit, dynamic, dependence, errors),
                    errorServer,
                );
            },
            errorServer,
        );
    }

    getErrors(schema, entity, dynamic) {
        let errors = new Map();
        let firstErrorField = null;
        schema.forEach((element) => {
            element.tabFields.forEach((field) => {
                if (field.dataType === 'break') return;
                let id = typeof field.id === 'object' ? field.id.id : field.id;
                id = id.toLowerCase();
                const readOnly = field.readOnly && entity.get('id');

                if (
                    field.mandatory &&
                    !readOnly &&
                    isEmpty(entity.get(id)) &&
                    (isEmpty(field.TiposEntity) ||
                        field.strTiposEntity === -1 ||
                        field.strTiposEntity === '-1' ||
                        (!isEmpty(field.TiposEntity) &&
                            !isTiposValues(
                                dynamic.get(field.TiposEntity.toLowerCase()),
                                field.strTiposEntity,
                            )))
                ) {
                    // check mandatory, si no es readonly si esta relleno y si es un campo dinamico no esta visible por no estar seleccionado el que depende
                    errors = errors.set(id.toLowerCase(), '  ');
                    if (!firstErrorField) {
                        firstErrorField = id.toLowerCase();
                    }
                }
            });
        });
        return { errors, firstErrorField };
    }

    deleteCrud(entity, id, success, error) {
        this.context.domainManager.deleteEntity(
            entity.entity,
            id,
            (response) => {
                this.context.entityManager.getEntitiesManager(entity).refreshData(true, id);
                this.context.store.dispatch(this.context.actions.DetailActions.hideDetail());
                this.context.store.dispatch(
                    this.context.actions.EntityListActions.init(entity, true),
                );
                success();
            },
            error,
        );
    }
}

export function isTiposValues(value, strValues) {
    if (!value) return true;
    const strValue = value.toString();
    return !strValues
        .split(';')
        .filter((v) => !!v)
        .includes(strValue);
}

export const isFormFieldHidden = (field, state, create) => {
    const { strTiposEntity, TiposEntity, readOnly, mandatory } = field;
    const isCreate = create === undefined ? state.crud.entityCrud.get('id') === undefined : create;
    const hideReadOnly = readOnly && !(isCreate && mandatory);
    const isTiposEntity =
        strTiposEntity !== undefined &&
        TiposEntity !== undefined &&
        strTiposEntity !== -1 &&
        strTiposEntity !== '-1' &&
        isTiposValues(state.crud.dynamic.get(TiposEntity.toLowerCase()), strTiposEntity);
    return isTiposEntity || hideReadOnly;
};

export const setSectionVisibility = (schema, state, isCreate) =>
    schema.map((section) => {
        section.visible =
            section.tabFields.findIndex(
                (field, previous) => !isFormFieldHidden(field, state, isCreate),
            ) > -1;
        return section;
    });

const getConfigDependence = (schema, crudEntity) => {
    let dependenceEntity = Map();
    let dynamicEntity = Map();
    schema.form.forEach((element) => {
        element.tabFields.forEach((field) => {
            dependenceEntity = dependenceList(field, dependenceEntity, crudEntity);
            dynamicEntity = dynamicList(field, dynamicEntity, crudEntity);
        });
    });
    return { dependence: dependenceEntity, dynamic: dynamicEntity };
};

const dependenceList = (field, dependenceEntity, crudEntity) => {
    if (
        field.dataType === 'singleValueList' ||
        field.dataType === 'relatedValueList' ||
        field.dataType === 'companyValueList'
    ) {
        let id = typeof field.id === 'object' ? field.id.id : field.id;
        id = id.toLowerCase();
        let idValue = crudEntity.get(id);
        idValue = typeof idValue === 'object' && idValue !== null ? idValue.value : idValue;
        dependenceEntity = dependenceEntity.set(id, idValue);
    }
    return dependenceEntity;
};

// PARA OCULTAR DINAMICAMENTE //server
const dynamicList = (field, dynamicEntity, crudEntity) => {
    if (field.dataType === 'singleValueList') {
        if (field.standard) {
            const idValue = crudEntity.get(field.id.id.toLowerCase());
            const fieldName = field.fieldConfiguration || field.server;
            dynamicEntity = dynamicEntity.set(fieldName.toLowerCase(), parseInt(idValue, 10));
        } else {
            const idValue = crudEntity.get(field.id.toLowerCase());
            if (idValue) {
                dynamicEntity = dynamicEntity.set(
                    field.id.toLowerCase(),
                    parseInt(idValue.value, 10),
                );
            }
        }
    }

    if (field.standard && field.dataType === 'bool') {
        const checkValue = crudEntity.get(field.id);
        dynamicEntity = dynamicEntity.set(field.server.toLowerCase(), checkValue);
    }
    return dynamicEntity;
};

const checkDependantValues = (fieldId, schema, crudEntity) => {
    schema.form.forEach((element) => {
        element.tabFields.forEach((field) => {
            if (field.parentField === fieldId) {
                crudEntity = crudEntity.set(field.id.toLowerCase(), null);
            }
        });
    });
    return crudEntity;
};

const getDefaultEntityCrud = (schema) => {
    let crudEntity = Map();
    schema.form.forEach((element) => {
        element.tabFields.forEach((field) => {
            if (field.dataType === 'break') {
                return;
            }
            const id = typeof field.id === 'object' ? field.id.id : field.id;
            let value = field.default_value !== '' ? field.default_value : null;

            if (!field.standard && field.dataType === 'singleValueList') {
                value =
                    field.default_value !== ''
                        ? { value: field.default_value, description: '' }
                        : null;
            }
            crudEntity = crudEntity.set(id.toLowerCase(), value);
        });
    });
    return crudEntity;
};

const Find = (array, isFind) => {
    let result = false;
    array.map((value) => {
        if (isFind(value)) {
            result = true;
        }
    });
    return result;
};

const checkMandatory = (field) => (data) =>
    data.toUpperCase() === field.server.toUpperCase() ||
    (field.fieldConfiguration && data.toUpperCase() === field.fieldConfiguration.toUpperCase());

const checkDefault = (field) => (data) =>
    data.field.toUpperCase() === field.server.toUpperCase() ||
    (field.fieldConfiguration &&
        data.field.toUpperCase() === field.fieldConfiguration.toUpperCase());

const getCustomFieldConfig = (
    entityConfig,
    mandatoryFields,
    defaultFieldsEntity,
    readOnlyFieldsEntity,
    crudEntitySchema,
    configurationFields,
) => {
    mandatoryFields = mandatoryFields.map((mandatoryField) => mandatoryField.field);
    readOnlyFieldsEntity = readOnlyFieldsEntity.map(
        (readOnlyFieldsEntity) => readOnlyFieldsEntity.field,
    );

    return crudEntitySchema.map((section) => {
        section.tabFields = section.tabFields.map((field) => {
            field.mandatory = field.mandatory || Find(mandatoryFields, checkMandatory(field));
            field.readOnly = field.readOnly || Find(readOnlyFieldsEntity, checkMandatory(field));
            field.hidden = configurationFields
                ? configurationFields[field.fieldConfiguration || field.server].isHidden
                : false;
            field.default_value = defaultFieldsEntity.filter(checkDefault(field));
            field.default_value =
                field.default_value.length === 0 ? '' : field.default_value[0].value;
            field.standard = true;
            return field;
        });
        return section;
    });
};
// TODO REFACTOR
const getCustomFieldConfig2 = (
    entityConfig,
    mandatoryFields,
    defaultFieldsEntity,
    readOnlyFieldsEntity,
    crudEntitySchema,
) => {
    mandatoryFields = mandatoryFields.map((mandatoryField) => mandatoryField.field);
    readOnlyFieldsEntity = readOnlyFieldsEntity.map(
        (readOnlyFieldsEntity) => readOnlyFieldsEntity.field,
    );
    return crudEntitySchema.map((field) => {
        field.mandatory = Find(mandatoryFields, checkMandatory(field));
        field.readOnly = field.readOnly || Find(readOnlyFieldsEntity, checkMandatory(field));
        field.default_value = defaultFieldsEntity.filter(checkDefault(field));
        field.default_value = field.default_value.length === 0 ? '' : field.default_value[0].value;
        field.standard = true;
        return field;
    });
};

function findExtraFieldEntity(extraField, field) {
    return function checkEntity(entity) {
        return entity[field].toString() === extraField;
    };
}

function findExtraFieldGroup(tab) {
    return function checkEntity(entity) {
        return entity.idTab.toString() === tab['-id'];
    };
}

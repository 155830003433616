export default class MergeManager {
    updateEntityMerge = (sourceId, targetId, entityType, entity, success, error) => {
        const serverEntity = {};
        const mergeSchema = this.context.entityManager
            .getEntitiesManager(entityType)
            .getMergeEntitySchema();
        mergeSchema.forEach((element) => {
            if (element.hasOwnProperty('tabFields')) {
                element.tabFields.forEach((field) => {
                    const id = typeof field.id === 'string' ? field.id : field.id.id;
                    if (field.hasOwnProperty('server')) {
                        serverEntity[field.server] = entity[id];
                    } else {
                        serverEntity[id] = entity[id];
                    }
                });
            }
        });
        serverEntity.extraFields = entity.extraFields;
        this.context.domainManager.updateEntity(
            entityType.entity,
            targetId,
            serverEntity,
            (response) => {
                this.mergeEntities(sourceId, targetId, entityType.entity, success, error);
            },
            error,
        );
    };

    mergeEntities(sourceId, targetId, entity, success, error) {
        const data = { sourceId, targetId };
        this.context.domainManager.mergeEntities(data, entity, success, error);
    }

    getMergeCandidates(sourceId, entity, success, error) {
        this.context.domainManager.getMergeCandidates(sourceId, entity, success, error);
    }
}

import React, { memo, useCallback, useEffect, useMemo, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast, BadgeNotification, Icon, Tabs, Tooltip } from 'hoi-poi-ui';
import { CLEAR, SNOOZE_NOTIFICATIONS, KILL_NOTIFICATIONS } from 'constants/ActionTypes';
import { EntityExtraFieldsActions, EntityListActions, EntityExportActions } from 'actions';
import { ACTIVITIES, OPPORTUNITIES, COMPANIES, CONVERSATIONS } from 'constants/Entities';
import EntityExport from 'containers/components/modals/EntityExport';
import MenuHoi from 'components/MenuHoi';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { setSnoozeNotifications } from 'utils/notifications';
import { logEvent } from 'utils/tracking';
import useExportProps from '../../hooks/ExportProps';
import { getEnableTimeLine, getActivitiesNotificationsBadge } from '../../utils/timeline';
import { successToast } from 'utils/toast';

import './styles.scss';

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const canExport = permission.exportActividades || false;
    const entityFilters = state.entityFilters[ACTIVITIES.entity];
    const filters = entityFilters?.filters || {};
    const operators = entityFilters?.operators || {};
    const visibleFieldsOpportunities =
        state.config?.components?.opportunities_list?.visibles || null;
    const visibleFieldsCompanies = state.config?.components?.companies_list?.visibles || null;
    const messages = state.messages?.unread?.all?.entities;
    const activitiesNotificationsBadge = messages ? getActivitiesNotificationsBadge(messages) : 0;
    const snoozed = state.messages?.snoozeNotifications?.[ACTIVITIES.entity];
    const exportOverrides = state.entityExport.overrides;
    const lastExport = state.config.components.activities_export || null;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    return {
        activitiesNotificationsBadge,
        canExport,
        filters,
        operators,
        visibleFields: {
            [OPPORTUNITIES.entity]:
                (visibleFieldsOpportunities &&
                    Object.keys(visibleFieldsOpportunities).reduce((arr, key) => {
                        if (visibleFieldsOpportunities[key]) arr.push(key);
                        return arr;
                    }, [])) ||
                [],
            [COMPANIES.entity]:
                (visibleFieldsCompanies &&
                    Object.keys(visibleFieldsCompanies).reduce((arr, key) => {
                        if (visibleFieldsCompanies[key]) arr.push(key);
                        return arr;
                    }, [])) ||
                [],
        },
        enableTimeLine: getEnableTimeLine(),
        snoozed,
        exportOverrides,
        lastExport,
        canConfig: permission?.manageExtraFields && isAdmin,
    };
};

const mapDispatchToProps = (dispatch) => ({
    initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
    setExportOverrides: bindActionCreators(EntityExportActions, dispatch).setExportOverrides,
    getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch).getExtraFieldSchema,
    standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
        .standardFieldsConfiguration,
});

const ActivitiesTabs = memo(
    ({
        activeTab,
        activitiesNotificationsBadge,
        canExport,
        filters,
        getExtraFieldSchema,
        initExport,
        onChange,
        operators,
        standardFieldsConfiguration,
        visibleFields,
        enableTimeLine,
        snoozed,
        exportOverrides,
        setExportOverrides,
        lastExport,
        canConfig,
        ...otherProps
    }) => {
        const activeFilters = useRef({});

        useEffect(() => {
            // We had to use a ref to store the active filters because onInitExport
            // wasn't reacting to the state update
            activeFilters.current = filters;
        }, [filters]);

        const entityExportProps = useExportProps({
            getExtraFieldSchema,
            filters: activeFilters.current,
            visibleFields,
            standardFieldsConfiguration,
            lastExport,
        });

        const tabs = useMemo(() => {
            let badgeContent = null;

            const tabsArr = [
                {
                    key: ACTIVITIES.trueName,
                    title: (
                        <Fragment>
                            <Icon name="activities" />
                            <span>{getLiteral('title_activities')}</span>
                        </Fragment>
                    ),
                    route: ACTIVITIES.route,
                },
            ];

            if (enableTimeLine) {
                switch (true) {
                    case activitiesNotificationsBadge && activitiesNotificationsBadge < 100:
                        badgeContent = activitiesNotificationsBadge;
                        break;
                    case activitiesNotificationsBadge >= 100:
                        badgeContent = '+99';
                    default:
                        break;
                }

                tabsArr.push({
                    key: CONVERSATIONS.trueName,
                    title: (
                        <Fragment>
                            <Icon name="chat" />
                            <span>{getLiteral('label_my_conversations')}</span>
                            {badgeContent && (
                                <BadgeNotification size="tiny" className="badge--unread">
                                    {badgeContent}
                                </BadgeNotification>
                            )}
                        </Fragment>
                    ),
                    route: `${CONVERSATIONS.route}${ACTIVITIES.route}`,
                });
            }

            return tabsArr;
        }, [activitiesNotificationsBadge, enableTimeLine]);

        const onInitExport = useCallback(() => {
            const filtersForExport = activeFilters.current;
            const newValue = exportOverrides?.activityType?.split(',');
            if (newValue)
                filtersForExport.activityType = {
                    id: 'activityType',
                    dataType: 'singleValueList',
                    value: newValue,
                };
            return Promise.resolve(initExport(ACTIVITIES, filtersForExport, operators));
        }, [exportOverrides, initExport, operators]);

        const handleOnChange = useCallback(
            (key) => {
                const newRoute = tabs.find((tab) => tab.key === key)?.route;
                ensureRoute(newRoute);
                onChange && onChange(key);
                if (key === CONVERSATIONS.trueName) {
                    logEvent({
                        event: ACTIVITIES.trueName,
                        submodule: 'timelineMyConversations',
                        functionality: 'listView',
                    });
                }
            },
            [onChange, tabs],
        );

        const handleEnableNotifications = useCallback(() => {
            setSnoozeNotifications({
                entity: ACTIVITIES,
                action: CLEAR,
            });
            successToast({
                text: getLiteral('succes_notifications_activated_successfully'),
            });
        }, []);

        const handleSnoozeNotifications = useCallback(() => {
            setSnoozeNotifications({
                entity: ACTIVITIES,
                action: SNOOZE_NOTIFICATIONS,
            });
            successToast({
                text: getLiteral('succes_notifications_silenced_successfully'),
            });
        }, []);

        const handleKillNotifications = useCallback(() => {
            setSnoozeNotifications({
                entity: ACTIVITIES,
                action: KILL_NOTIFICATIONS,
            });
            successToast({
                text: getLiteral('succes_notifications_deactivated_successfully'),
            });
        }, []);

        const dropdownMenu = useMemo(() => {
            let menuOptions = [];
            let tooltipContent = getLiteral('label_mute_notifications');

            if (canExport && activeTab === ACTIVITIES.trueName) {
                menuOptions.push({
                    icon: 'downloadAlt',
                    title: getLiteral('action_export_excel'),
                    onClick: onInitExport,
                });
                tooltipContent = getLiteral('label_export_and_mute_notifications');
            }

            if (snoozed) {
                menuOptions = [
                    ...menuOptions,
                    {
                        icon: 'bellEnable',
                        title: getLiteral('action_activate_notifications'),
                        onClick: handleEnableNotifications,
                    },
                ];
            } else {
                menuOptions = [
                    ...menuOptions,
                    {
                        icon: 'bellSnooze',
                        title: getLiteral('action_mute_notification_1_hour'),
                        onClick: handleSnoozeNotifications,
                    },
                    {
                        icon: 'bellDisable',
                        title: getLiteral('action_deactivate_notifications'),
                        onClick: handleKillNotifications,
                    },
                ];
            }

            if (canConfig) {
                menuOptions.push({
                    title: getLiteral('action_add_custom_field'),
                    icon: 'plus',
                    id: 'add_custom_field',
                    color: 'orange500',
                    onClick: () => {
                        ensureRoute('/settings/fields', '?tab=Gestiones&showCrud=true');
                        logEvent({
                            event: ACTIVITIES.trueName,
                            submodule: 'threeDots',
                            functionality: 'createField',
                        });
                    },
                });
            }

            return (
                <MenuHoi items={menuOptions}>
                    <Tooltip content={tooltipContent} placement="left">
                        <span className="fm-activites-tabs__dropdown-toggle">
                            <Icon name="moreHoriz" size="large" />
                        </span>
                    </Tooltip>
                </MenuHoi>
            );
        }, [
            canExport,
            activeTab,
            snoozed,
            canConfig,
            onInitExport,
            handleEnableNotifications,
            handleSnoozeNotifications,
            handleKillNotifications,
        ]);

        const postComponent = useMemo(() => {
            switch (true) {
                case enableTimeLine:
                    return dropdownMenu;
                case !enableTimeLine && canExport && activeTab === ACTIVITIES.trueName:
                    return (
                        <Tooltip placement="top" content={getLiteral('action_export_excel')}>
                            <span>
                                <Icon name="downloadAlt" onClick={onInitExport} />
                            </span>
                        </Tooltip>
                    );
                default:
                    return null;
            }
        }, [enableTimeLine, dropdownMenu, canExport, activeTab, onInitExport]);

        const tabsProps = useMemo(() => {
            return {
                tabs,
                activeKey: activeTab,
                onChange: handleOnChange,
                postComponent,
                ...otherProps,
            };
        }, [tabs, activeTab, handleOnChange, postComponent, otherProps]);

        const finalEntityExportProps = useMemo(() => {
            const finalProps = {
                ...entityExportProps,
                onChangeSelectedSchema: onInitExport,
                onClose: () => setExportOverrides(null),
            };
            return finalProps;
        }, [entityExportProps, onInitExport, setExportOverrides]);

        return (
            <Fragment>
                <Tabs {...tabsProps} />
                {entityExportProps?.areRelatedLoaded && (
                    <EntityExport {...finalEntityExportProps} />
                )}
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesTabs);

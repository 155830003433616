import React from 'react';

const Reports = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.173.5c.01.001.014.014.014.12V17.5H15.25l.005-12.724c-1.091-.802-3.168-2.899-4.23-4.276H2.173zM15.25 17.67v.008-.008zM2.173.5h-.007.007z"
                stroke="#69B32D"
                fill="#69B32D"
            />
            <path
                d="M7.324 8.291a.408.408 0 01-.417-.398c0-.22.185-.4.417-.4.231 0 .416.18.416.4a.408.408 0 01-.416.398zm0-1.36a.97.97 0 00-.98.962c0 .532.44.96.98.96a.97.97 0 00.978-.96.97.97 0 00-.978-.962zM4.533 11.556a.408.408 0 01-.416-.399c0-.219.185-.399.416-.399.232 0 .417.18.417.4a.408.408 0 01-.417.398zm0-1.36a.97.97 0 00-.978.961c0 .533.44.961.978.961a.97.97 0 00.98-.96.97.97 0 00-.98-.962zM10.114 9.924a.408.408 0 01-.416-.4c0-.218.184-.398.416-.398.231 0 .416.18.416.399a.408.408 0 01-.416.399zm0-1.36a.97.97 0 00-.979.96c0 .534.44.962.979.962a.97.97 0 00.979-.961.97.97 0 00-.98-.962zM12.904 6.66a.408.408 0 01-.416-.4c0-.218.184-.399.416-.399.232 0 .416.18.416.4a.408.408 0 01-.416.398zm0-1.361a.97.97 0 00-.979.961c0 .533.44.962.979.962a.97.97 0 00.979-.962.97.97 0 00-.979-.961z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M12.756 6.854l-.429-.364-2.066 2.441.43.364zM7.176 8.487l-.43-.364-2.065 2.44.429.364zM9.465 9.52l.303-.475L7.975 7.9l-.303.474z"
            />
        </g>
    </svg>
);

export default Reports;

if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        const list = Object(this);
        const length = list.length >>> 0;
        const thisArg = arguments[1];
        let value;

        for (let i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}

if (typeof Object.assign !== 'function') {
    Object.assign = function (target, varArgs) {
        // .length of function is 2

        if (target === null) {
            // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
        }

        const to = Object(target);

        for (let index = 1; index < arguments.length; index++) {
            const nextSource = arguments[index];

            if (nextSource !== null) {
                // pasamos si es undefined o null
                for (const nextKey in nextSource) {
                    // Evita un error cuando 'hasOwnProperty' ha sido sobrescrito
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    };
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (stringBuscada, posicion) {
        posicion = posicion || 0;
        return this.indexOf(stringBuscada, posicion) === posicion;
    };
}

const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
// const keys = Reflect.ownKeys; // Reflect is not supported in IE
// Reflect.ownKeys polyfill https://github.com/glenjamin/Reflect.ownKeys
let keys;
if (typeof Reflect === 'object' && typeof Reflect.ownKeys === 'function') {
    keys = Reflect.ownKeys;
} else if (typeof Object.getOwnPropertySymbols === 'function') {
    keys = function Reflect_ownKeys(o) {
        return Object.getOwnPropertyNames(o).concat(Object.getOwnPropertySymbols(o));
    };
} else {
    keys = Object.getOwnPropertyNames;
}

if (!Object.values) {
    Object.values = function values(O) {
        return reduce(
            keys(O),
            (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []),
            [],
        );
    };
}

if (!Object.entries) {
    Object.entries = function entries(O) {
        return reduce(
            keys(O),
            (e, k) => concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []),
            [],
        );
    };
}

import React from 'react';
import colors from 'constants/colors';

//Has fixed height and width because shouldn't be editable
const ChevronRight10px = ({ color = '$fmDefaultIcons' }) => (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.586 5L2.374 8.67a.5.5 0 1 0 .752.66l3.496-3.995a.499.499 0 0 0 0-.67L3.126.671a.5.5 0 1 0-.752.658L5.586 5z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default ChevronRight10px;

import React from 'react';

const ZipFile = () => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.166 0c-.264 0-.478.21-.478.62v17.059c0 .11.214.321.478.321h13.106c.264 0 .478-.21.478-.321l.006-13.165C14.775 3.89 12.324 1.431 11.274 0H2.166z"
                fill="#FFE797"
            />
            <path
                d="M6.252 16.431h1.71v.444H5.578v-.36l1.655-2.391H5.59v-.448h2.318v.351l-1.656 2.404zm2.885.444h-.554v-3.2h.554v3.2zm1.352-1.189v1.189h-.556v-3.2h1.224c.357 0 .641.094.851.28.21.186.316.432.316.738 0 .314-.103.557-.31.732-.205.174-.493.261-.864.261h-.661zm0-.446h.668c.198 0 .348-.046.452-.14.104-.092.156-.227.156-.402a.553.553 0 0 0-.158-.415.61.61 0 0 0-.435-.159h-.683v1.116z"
                fill="#695E3D"
            />
            <path
                fill="#998A5A"
                d="M7.313 1.125h1.125V2.25H7.312zM8.438 0h1.124v1.125H8.438zM8.438 2.25h1.124v1.125H8.438zM7.313 3.375h1.125V4.5H7.312zM8.438 4.5h1.124v1.125H8.438zM7.313 5.625h1.125V6.75H7.312zM8.438 6.75h1.124v1.125H8.438zM7.313 9h1.125v1.125H7.312zM7.313 7.875h1.125V9H7.312zM8.438 9h1.124v1.125H8.438z"
            />
        </g>
    </svg>
);

export default ZipFile;

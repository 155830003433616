import { NODE_ENV, URL_DOWNLOAD_DOCUMENT, URL_IMAGE, B2_URL } from 'constants/Environment';

export default class ConstantsManager {
    constructor() {}

    isLive() {
        return NODE_ENV === 'production';
    }

    getUrlDownloadDocument() {
        const config = this.context.store.getState().config;
        if (this.isLive() && config) {
            if (config.serverAssignment) {
                return config.serverAssignment.urlDownloadFile || URL_DOWNLOAD_DOCUMENT;
            }
        } else {
            return URL_DOWNLOAD_DOCUMENT;
        }
    }

    getUrlImage() {
        const config = this.context.store.getState().config;
        if (this.isLive() && config) {
            if (config.serverAssignment) {
                return config.serverAssignment.urlGetImage || `${URL_IMAGE}GetImage.ashx`;
            }
        } else {
            return `${URL_IMAGE}GetImage.ashx`;
        }
    }

    getUrlUserPicture() {
        const config = this.context.store.getState().config;
        if (this.isLive() && config) {
            if (config.serverAssignment) {
                return (
                    config.serverAssignment.urlGetUserPicture || `${URL_IMAGE}GetUserPicture.ashx`
                );
            }
        } else {
            return `${URL_IMAGE}GetUserPicture.ashx`;
        }
    }

    getUrlSalesforceCallback() {
        const config = this.context.store.getState().config;
        if (this.isLive() && config) {
            if (config.serverAssignment) {
                return (
                    `${config.serverAssignment.nameHandlers}/ZendeskAuth.ashx` ||
                    `${B2_URL}/ZendeskAuth.ashx`
                );
            }
        } else {
            return `${B2_URL}/ZendeskAuth.ashx`;
        }
    }

    getUrlZendeskCallback() {
        const config = this.context.store.getState().config;
        if (this.isLive() && config) {
            if (config.serverAssignment) {
                return (
                    `${config.serverAssignment.nameHandlers}/SalesforceAuth.ashx` ||
                    `${B2_URL}/SalesforceAuth.ashx`
                );
            }
        } else {
            return `${B2_URL}/SalesforceAuth.ashx`;
        }
    }
}

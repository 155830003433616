import React, { memo } from 'react';
import { connect } from 'react-redux';
import { SALESORDERS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchSalesOrder from './components/SearchSalesOrders';

function mapStateToProps(state) {
    let canCreate = state.config.permission.crud_permission[SALESORDERS.permission].create || false;
    return {
        canCreate,
    };
}

const Menu = memo(({ canCreate, config }) => {
    return (
        <div>
            <div className="entity-menu-container">
                <EntityMenu
                    entity={SALESORDERS}
                    withFilters={true}
                    canCreate={canCreate}
                    quickFilters={config.quickFilters}
                    searchComponent={<SearchSalesOrder />}
                    help={config.help}
                />
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(Menu);

import React from 'react';
import colors from 'constants/colors';

//Has fixed height and width because shouldn't be editable
const ChevronDown10px = ({ color = '$fmDefaultIcons' }) => (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 5.836l3.67-3.212a.5.5 0 0 1 .66.752L5.334 6.872a.499.499 0 0 1-.67 0L.671 3.376a.5.5 0 0 1 .658-.752L5 5.836z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default ChevronDown10px;

import React, { PureComponent } from 'react';
import ButtonOutlineWidget from './ButtonOutlineWidget';

class ButtonOutlineWidgetDefault extends PureComponent {
    state = {};

    render() {
        let { classNames = [], children, ...props } = this.props;
        return (
            <ButtonOutlineWidget
                classNames={['fm-button__outline-widget-default', ...classNames]}
                {...props}
            >
                {children}
            </ButtonOutlineWidget>
        );
    }
}

export default ButtonOutlineWidgetDefault;

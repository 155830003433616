import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactContentLoader from 'react-content-loader';

const propTypes = {
    animate: PropTypes.bool,
    ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    speed: PropTypes.number,
    loaderClassName: PropTypes.string,
    classes: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    rtl: PropTypes.bool,
    preserveAspectRatio: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    primaryOpacity: PropTypes.string,
    secondaryOpacity: PropTypes.string,
};

const ContentLoader = memo(
    ({
        classes,
        loaderClassName,
        animate = true,
        speed,
        width,
        height,
        rtl,
        preserveAspectRatio,
        primaryColor,
        secondaryColor,
        primaryOpacity,
        secondaryOpacity,
        children,
    }) => {
        const classNames = ['fm-contant-loader'];
        if (classes) classNames = [...classNames, ...classes].join(' ');

        return (
            <div className={classNames}>
                <ReactContentLoader
                    className={loaderClassName}
                    animate={animate}
                    speed={speed}
                    height={height}
                    width={width}
                    rtl={rtl}
                    preserveAspectRatio={preserveAspectRatio}
                    backgroundColor={primaryColor}
                    foregroundColor={secondaryColor}
                    backgroundOpacity={primaryOpacity}
                    foregroundOpacity={secondaryOpacity}
                >
                    {children}
                </ReactContentLoader>
            </div>
        );
    },
);

ContentLoader.propTypes = propTypes;

export default ContentLoader;

import CrudModel from './CrudModel';
import Context from '../managers/Context';

export default class SalesOrderProductModel extends CrudModel {
    static fromProduct(product, symbolCurrency, schema) {
        const object = {
            id: -1 * Number(product.id),
            idproducto: product.id,
            model: product.name,
            idsalesorder: -1,
            quantity: product.quantity
                ? product.quantity
                : product.recommendedQuantity === -1
                  ? 1
                  : product.recommendedQuantity,
            price: product.price,
            discount1: product.getDiscount(1),
            discount2: product.getDiscount(2),
            discount3: product.getDiscount(3),
            discount4: product.getDiscount(4),
            finalprice: product.finalprice,
            idThumbnail: product.thumbnail,
            description: product.description,
            imageList: product.imageList,
        };

        if (schema) {
            // set the default value for the discounts in product lines for sales-orders
            const discount1 = schema.discount1;
            const discount2 = schema.discount2;
            const discount3 = schema.discount3;
            const discount4 = schema.discount4;

            if (
                !product.discount1 &&
                product.discount1 !== '0' &&
                discount1 &&
                discount1.defaultValue
            )
                object.discount1 = discount1.defaultValue;
            if (
                !product.discount2 &&
                product.discount2 !== '0' &&
                discount2 &&
                discount2.defaultValue
            )
                object.discount2 = discount2.defaultValue;
            if (
                !product.discount3 &&
                product.discount3 !== '0' &&
                discount3 &&
                discount3.defaultValue
            )
                object.discount3 = discount3.defaultValue;
            if (
                !product.discount4 &&
                product.discount4 !== '0' &&
                discount4 &&
                discount4.defaultValue
            )
                object.discount4 = discount4.defaultValue;
        }

        return new SalesOrderProductModel(object);
    }

    constructor(entity) {
        super(entity);

        this.entity.discount1 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.discount1,
        );
        this.entity.discount2 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.discount2,
        );
        this.entity.discount3 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.discount3,
        );
        this.entity.discount4 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.discount4,
        );
    }

    formatDiscount(discount) {
        if (typeof discount === 'string') {
            return !discount ? 0 : Number(discount.replace(',', '.'));
        }
        return !discount ? 0 : Number(discount);
    }

    get name() {
        return this.entity.model;
    }

    get idproducto() {
        return this.entity.idproducto;
    }

    /* get description(){
		return this.entity.description!==""?this.entity.description: "-";
	} */

    get price() {
        return Number(`${this.entity.price || 0}`.replace(',', '.'));
    }

    set price(str) {
        this.entity.price = str;
    }

    get quantity() {
        return Number(`${this.entity.quantity}`.replace(',', '.'));
    }

    set quantity(str) {
        this.entity.quantity = str;
    }

    get finalprice() {
        let totalDiscount = 1;
        if (this.haveDiscount()) {
            for (let i = 1; i <= 4; i++) {
                if (this.getDiscount(i) !== 0) {
                    totalDiscount *= this.addDiscount(this.getDiscount(i));
                }
            }
            totalDiscount = 1 - (1 - totalDiscount);
        }
        return this.price * this.quantity * totalDiscount;
    }

    get dbFinalPrice() {
        return this.entity.finalprice;
    }

    haveDiscount() {
        let result = false;
        for (let i = 1; i <= 4; i++) {
            if (this.getDiscount(i) !== 0) {
                result = true;
            }
        }
        return result;
    }

    addDiscount(discount) {
        return 1 - Number(discount) / 100;
    }

    getDiscount(num) {
        return this.formatDiscount(this.entity[`discount${num}`]);
    }

    get thumbnail() {
        return this.entity.idthumbnail;
    }

    get discount1() {
        return this.entity.discount1;
    }

    set discount1(str) {
        this.entity.discount1 = Number(str);
    }

    get discount2() {
        return this.formatDiscount(this.entity.discount2);
    }

    set discount2(str) {
        this.entity.discount2 = Number(str);
    }

    get discount3() {
        return this.entity.discount3;
    }

    set discount3(str) {
        this.entity.discount3 = Number(str);
    }

    get discount4() {
        return this.entity.discount4;
    }

    set discount4(str) {
        this.entity.discount4 = Number(str);
    }

    set description(str) {
        this.entity.description = str;
    }

    get description() {
        return this.entity.description;
    }

    createCrud(schema) {
        const entity = this.entity;
        const crud = {
            id: entity.id >= 0 ? `${entity.id}` : undefined,
            idProduct: entity.idproducto,
            quantity: this.quantity,
            price: this.price,
            discount1: this.formatDiscount(this.discount1),
            discount2: this.formatDiscount(this.discount2),
            discount3: this.formatDiscount(this.discount3),
            discount4: this.formatDiscount(this.discount4),
            finalPrice: this.finalprice,
            description: this.description,
            extraFields: this.saveExtraFieldsCrud(schema),
        };
        return crud;
    }

    get saveCrud() {
        // Jose model deprecated
        const entity = this.entity;
        const crud = {
            entityId: entity.id >= 0 ? `${entity.id}` : undefined,
            idProduct: entity.idproducto,
            quantity: this.quantity,
            price: this.price,
            discount1: this.formatDiscount(discount1),
            discount2: this.formatDiscount(discount2),
            discount3: this.formatDiscount(discount3),
            discount4: this.formatDiscount(discount4),
            finalPrice: this.finalprice,
            description: this.description,
            extraFields: this.saveExtraFieldsCrud,
        };
        return crud;
    }

    get immutable() {
        return Object.assign(Object.create(this), this);
    }

    getDuplicate() {
        const copy = Object.assign(Object.create(this), this);
        copy.entity.id = -1;
        return copy;
    }

    get imageList() {
        return this.entity.imagelist;
    }
}

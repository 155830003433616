import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Context from 'managers/Context';
import moment from 'moment';
import { ConfigActions } from 'actions';
import { ButtonPrimarySmall, ButtonDefaultSmall } from 'components/buttons';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { Oval, ChevronLeft10px, ChevronRight10px } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { TOUR_MODES } from 'constants/Constants';

const propTypes = {
    step: PropTypes.number,
    handleClickPrev: PropTypes.func,
    handleClickNext: PropTypes.func,
    updateConfigStore: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    const { updateConfigStore } = bindActionCreators(ConfigActions, dispatch);
    const { updateUserDataConfiguration } = bindActionCreators(ConfigActions, dispatch);
    return {
        updateConfigStore,
        updateUserDataConfiguration,
    };
}

@connect(null, mapDispatchToProps)
class Footer extends PureComponent {
    state = {};

    handleClickDone = () => {
        const { handleCloseModal, updateUserDataConfiguration } = this.props;
        const dateNow = moment().utc().format('DD/MM/YYYY HH:mm:ss');
        logEvent({
            event: 'signup',
            functionality: 'endTour',
        });
        handleCloseModal();
        updateUserDataConfiguration && updateUserDataConfiguration({ tourMode: 0 });
        Context.domainManager.setCompleteTour(
            () => {},
            () => {},
        );
        Context.domainManager.setEndWalkThroughDate(
            dateNow,
            () => {},
            () => {},
        );
    };

    renderRightButton = () => {
        const { step, handleClickNext, tourMode } = this.props;

        const lastStep = tourMode === TOUR_MODES.lite ? 2 : 3;

        if (step === lastStep) {
            return (
                <ButtonPrimarySmall onClick={this.handleClickDone}>
                    <Literal literal={getLiteral('label_tour_end_1_title')} />
                </ButtonPrimarySmall>
            );
        } else {
            return (
                <ButtonPrimarySmall onClick={handleClickNext}>
                    <Literal literal={getLiteral('action_next')} />
                    <div className="onboarding-footer-chevron right">
                        <ChevronRight10px color="$fmWhite" />
                    </div>
                </ButtonPrimarySmall>
            );
        }
    };

    render() {
        const { step, handleClickPrev, tourMode } = this.props;

        return (
            <div className="onboarding-footer">
                <div className="onboarding-footer-content">
                    <div className="onboarding-footer-buttons">
                        <ButtonDefaultSmall
                            disabled={step === 1}
                            classes={step === 1 ? ['fm-button__on-boarding-hidden'] : []}
                            onClick={handleClickPrev}
                        >
                            <div className="onboarding-footer-chevron left">
                                <ChevronLeft10px />
                            </div>
                            <Literal literal={getLiteral('action_back')} />
                        </ButtonDefaultSmall>
                        {this.renderRightButton()}
                    </div>
                    <div className="onboarding-footer-ovals">
                        <div className="onboarding-footer-oval">
                            <Oval color={step === 1 ? '$fmOrange' : '$fmPlaceholders'} />
                        </div>
                        <div className="onboarding-footer-oval">
                            <Oval color={step === 2 ? '$fmOrange' : '$fmPlaceholders'} />
                        </div>
                        {tourMode === TOUR_MODES.extended && (
                            <div className="onboarding-footer-oval">
                                <Oval color={step === 3 ? '$fmOrange' : '$fmPlaceholders'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

Footer.propTypes = propTypes;

export default Footer;

import { memo, useEffect } from 'react';
import Cookies from 'js-cookie';
import { createHashHistory } from 'history';
import { useLocation, useParams } from 'react-router-dom';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { intercomHideAndShow } from 'utils/intercom';
import { userflowHideAndShow } from 'utils/userflow';
import * as GlobalToasts from 'lib/GlobalToasts';

let previousEntityMatch = null;
const DEFAULT_ACCESS_PAGES = {
    1: '/companies',
    2: '/contacts',
    3: '/activities',
    4: '/agenda',
    5: '/documents',
    6: '/sales-orders',
    7: '/opportunities',
    9: '/metrics/reports',
    10: '/products',
    11: '/metrics/sfm',
};

export const COOKIE_ROUTER_CURRENT_HASH = 'router-current-hash';
export const hashHistory = createHashHistory();
export const hashStack = [];

export const RouteOnMount = memo(({ title, onMount, element = null }) => {
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        const path = location.pathname;
        if (!window.location.hash.includes(path)) return;
        if (title && window.location.hash === `#${path}`) document.title = getLiteral(title);

        // Cleaning other entities crud when page jump
        const entityMatch = path?.split('/')[1];
        if (previousEntityMatch && previousEntityMatch !== entityMatch) {
            Context.store.dispatch(Context.actions.EntityActions.closeAll());
        }
        previousEntityMatch = entityMatch;

        onMount && onMount(params);

        window.Intercom && window.Intercom('update');
        intercomHideAndShow();
        userflowHideAndShow();
    }, [location, onMount, params, title]);

    return element;
});

export const getEnvironmentRoute = (route, queryString) => {
    let url = `#${route}`;

    if (queryString) {
        url = `${queryString}${url}`;
    }

    return `/${url}`;
};

export const getRoute = (route, queryString) => {
    if (!route) return;
    let finalRoute = `#${route}`;

    if (queryString) {
        finalRoute = `${queryString}${finalRoute}`;
    }

    return `/${finalRoute}`;
};

export const ensureRoute = (route, queryString) => {
    if (!route) return;
    if (`#${route}` === window.location.hash) return;
    hashHistory.push({
        pathname: route,
        search: queryString,
    });
};

export const backRoute = (id) => {
    const regexToMatch = new RegExp(`(/edit|/new).*`);
    const regexToMatchWithoutId = new RegExp(`/(${id}/edit|${id}/new).*`);
    const currentRoute = getCurrentPath();
    if (id) {
        return currentRoute.replace(regexToMatchWithoutId, ``);
    }
    return currentRoute.replace(regexToMatch, ``);
};

export const backRouteWorkflow = () => {
    const regexToMatch = new RegExp(`(/workflow).*`);
    const currentRoute = getCurrentPath();
    return currentRoute.replace(regexToMatch, ``);
};

export const EXTERNAL_ROUTES_HASH = [
    '/login',
    '/signup',
    '/logout',
    '/recover-password',
    '/change-password',
    '/expired-password',
    '/contracts-validation',
    '/signature-confirm',
    '/accept-invite',
    '/error',
    '/onboarding',
    '/dev',
    '/auth',
];

export const isExternalRoute = (hash) => {
    let currentHash = hash || window.location.hash;
    currentHash = currentHash.replace('#', '');
    if (currentHash === '/') return true;
    return EXTERNAL_ROUTES_HASH.find((route) => currentHash.startsWith(route));
};

export const hasPartnerThemeEnabled = (hash) => isExternalRoute(hash);

export const goAfterLogin = (config) => {
    // From local/web4 history router
    let routerCurrentHash = Cookies.get('router-current-hash');
    if (routerCurrentHash && !isExternalRoute(routerCurrentHash)) {
        Cookies.remove('router-current-hash', {
            domain: getCookieDomainName(window.location.hostname),
        });
        window.location.href = getRoute(routerCurrentHash.replace('#', ''), window.location.search);
        return;
    }

    // Default page
    if (!config) config = Context.config;
    let defaultAccessPage = config?.userData?.defaultAccessPage || '1';
    window.location.href = getRoute(
        DEFAULT_ACCESS_PAGES[defaultAccessPage],
        window.location.search,
    );
};

export const goLogin = () => {
    hashHistory.push({
        pathname: '/login',
    });
};

export const goLogout = () => {
    hashHistory.push({
        pathname: '/logout',
    });
};

export const setCookie = (hash) => {
    Cookies.set(COOKIE_ROUTER_CURRENT_HASH, hash, {
        expires: 31,
        domain: getCookieDomainName(window.location.hostname),
    });
};

export const getCurrentPath = () => window.location.hash?.replace('#', '');

// Global listen
hashHistory.listen(() => {
    const path = window.location.hash;
    if (!isExternalRoute(path)) setCookie(path);
    else intercomHideAndShow('hide');
    if (GlobalToasts.checkShowToasts()) GlobalToasts.saveToasts();
});

// First time
const firstHash = window.location.hash;
if (firstHash) {
    if (!isExternalRoute(firstHash)) setCookie(firstHash);
}

export function getCookieDomainName(hostName) {
    if (hostName === 'localhost') return hostName;
    return `.${hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)}`;
}

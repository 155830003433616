import {
    EMAIL_EDITOR_CANCEL,
    EMAIL_EDITOR_CLOSE,
    EMAIL_EDITOR_INIT,
} from '../constants/ActionTypes';

const initialState = {
    isOpen: false,
    overwriteContent: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EMAIL_EDITOR_INIT:
            return {
                ...state,
                isOpen: true,
                ...action.payload,
            };
        case EMAIL_EDITOR_CLOSE:
            return {
                ...state,
                isOpen: false,
            };
        case EMAIL_EDITOR_CANCEL:
            return initialState;
        default:
            return state;
    }
}

export default ({ text, color }) => ({
    inputStyle: {
        ...text.text_md,
        width: 265,
        color: 'rgb(143, 143, 143)',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    clearStyle: {
        margin: '1px 3px 0px 10px',
    },
});

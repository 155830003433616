import Context from 'managers/Context';
import { NEW_API_URL } from 'constants/Environment';

let scriptLoaded = false;

// Documentation available here: https://developer.nylas.com/docs/scheduler/customization/embedding-scheduler

export function loadScript() {
    (function () {
        let script = document.createElement('script');
        let parent = document.getElementsByTagName('script')[0].parentNode;
        script.async = 0;
        script.src = 'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js';
        parent.appendChild(script);
    })();
}

export function show(theme, eventTitle, thanksText) {
    if (!scriptLoaded) {
        loadScript();
        scriptLoaded = true;
    }

    let instance = nylas.scheduler.show({
        locale: Context.config?.userData?.langISOInterface,
        iframeDomain: NEW_API_URL + '/v1/callback/nylas/schedule/resources',
        auth: {
            accessToken: Context?.config?.token,
        },
        behavior: {
            displayOnly: ['event-info', 'calendars', 'opening-hours'],
        },
        style: {
            tintColor: theme.colors.orange500,
            backgroundColor: 'white',
        },
        defaults: {
            event: {
                title: eventTitle,
                duration: 30,
            },
            appearance: {
                color: theme.colors.orange500,
                logo: 'https://www.forcemanager.com/wp-content/uploads/positive.svg',
                company_name: 'ForceManager CRM',
                thank_you_text: thanksText,
            },
        },
    });

    instance.addEventListener('close', () => {
        console.log('Nylas scheduler editor closed');
    });
}

import React from 'react';
import colors from 'constants/colors';

const Checked = ({ color = '$fmTurquoise' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <rect width="18" height="18" fill="#f00" fillOpacity=".1" opacity="0" />
            <rect
                x="2"
                y="2"
                width="14"
                height="14"
                rx="3"
                fill={colors[color] ? colors[color] : '#00A8BE'}
            />
            <polyline
                points="5.0097 9.0109 7.0432 12.016 12.994 6.0054"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export default Checked;

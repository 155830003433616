import React from 'react';
import colors from 'constants/colors';

const Email = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2 5.6L9 10.1 1.8 5.6V3.8L9 8.3l7.2-4.5v1.8zm0-3.6H1.8C.81 2 0 2.81 0 3.8v10.8c0 .99.81 1.8 1.8 1.8h14.4c.99 0 1.8-.81 1.8-1.8V3.8c0-.99-.81-1.8-1.8-1.8z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Email;

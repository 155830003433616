import React, { PureComponent } from 'react';
import Button from './Button';

class ButtonWarning extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return (
            <Button {...props} classes={['fm-button__warning', ...classes]} loadingColor="white" />
        );
    }
}

export default ButtonWarning;

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import Button from 'components/Button';
import ProviderInactive from '../ProviderInactive';
import stylejs from '../../styles';
import '../style.scss';

const ProviderActivation = ({ provider, onClick }) => {
    return (
        <div>
            <ProviderInactive provider={provider} />
            <div className="activate-btn">
                <Button
                    className="btn-primary"
                    style={stylejs.btnCustomActivate}
                    text={<Literal literal="action_activate_provider" />}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

export default ProviderActivation;

import Context from 'managers/Context';
import UtilFormat from 'utils/UtilFormat';
import { getBackendBoolean } from 'utils/fm';
import {
    getMomentFromDateBackend,
    getDateMomentFromBackendTimestamp,
    formatDate,
} from 'utils/dates';
import { formatAddress } from 'utils/addresses';
import {
    toDate,
    toDecimal,
    toNumber,
    fromExtraFields,
    fromExtraFieldsEndpoint,
    fromExtraFieldsV2,
    fromExtraFieldsEndpointV2,
    toFloat,
    toCrudV2SelectValue,
} from 'utils/crud';
import {
    getSrcCompanyChipAlternative,
    getSrcUserCircleAvatar,
    getSrcContactCircleAvatar,
} from 'utils/getSrcAvatar';
import CrudModel from './CrudModel';

export default class OpportunityModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static standardToCrud(data, mappedSchema) {
        let comercialMultipleObj = {
            idComercial: {
                value: data.IdComercial,
                label: data.Comercial,
            },
        };
        if (data.ownersIds && data.ownersIds.length > 0) {
            // Got to do this because we receive: ownersIds: "1;2;3;"
            const idNames = ['idComercial2', 'idComercial3', 'idComercial4', 'idComercial5'];
            const ids = data.ownersIds.split(';');
            const names = data.ownersNames.split(';');

            ids.forEach((id, index) => {
                // -1 because we receive a string of ids and when it's converted to array, the last element only contains ';'
                if (ids.length - 1 === index) {
                    return false;
                }
                comercialMultipleObj = {
                    ...comercialMultipleObj,
                    [idNames[index]]: {
                        value: id,
                        label: names[index],
                    },
                };
            });
        }

        let contactMultipleObj = {
            value: '',
            label: '',
        };

        if (data.ContactIdList && data.ContactIdList.length > 0) {
            // Got to do this because we receive: ContactIdList: "1;2;3"
            // Backend missed the final semicolon they add in ownersIds ;)
            const idNames = ['idContact', 'idContact2', 'idContact3', 'idContact4', 'idContact5'];
            const ids = data.ContactIdList.split(';');
            const names = data.ContactList.split(';');

            ids.forEach((id, index) => {
                if (ids.length === index) {
                    return false;
                }
                contactMultipleObj = {
                    ...contactMultipleObj,
                    [idNames[index]]: {
                        value: id,
                        label: names[index],
                    },
                };
            });
        }

        let {
            IdOpportunityType,
            IdTypeExpediente,
            IdEnvironment,
            IdCurrency,
            Amount,
            Probability,
            Close,
            ExpectedClose,
            IdCompany,
            Company,
            IdCompanyIntermediate,
            CompanyIntermediate,
            IdCompanyOther,
            CompanyOther,
            CP,
            Latitude,
            Longitude,
            ...other
        } = data;

        return {
            ...Object.keys(other).reduce((obj, key) => {
                obj[key.charAt(0).toLowerCase() + key.slice(1)] = other[key];
                return obj;
            }, {}),
            ...comercialMultipleObj,
            ...contactMultipleObj,
            state: IdOpportunityType,
            type: IdTypeExpediente,
            environment: IdEnvironment,
            currency: IdCurrency,
            amount: toDecimal(Amount),
            probability: toNumber(Probability),
            closeDate: Close ? toDate(Close) : null,
            expectedCloseDate: ExpectedClose ? toDate(ExpectedClose) : null,
            idEmpresa1: IdCompany
                ? {
                      value: IdCompany,
                      label: Company,
                  }
                : undefined,
            idEmpresa2: IdCompanyIntermediate
                ? {
                      value: IdCompanyIntermediate,
                      label: CompanyIntermediate,
                  }
                : undefined,
            idEmpresa3: IdCompanyOther
                ? {
                      value: IdCompanyOther,
                      label: CompanyOther,
                  }
                : undefined,
            cp: CP,
            isGeoLocated: !!(data.IsGeoCoded === 'True' && data.Latitude && data.Longitude),
            latitude: toFloat(Latitude),
            longitude: toFloat(Longitude),
            isReadOnly: getBackendBoolean(data.IsReadOnly),
        };
    }

    static toCrudV1(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);
        let extraFields =
            !data.Extrafields.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpoint(data.extraFields || [], mappedSchema)
                : fromExtraFields(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        return {
            ...baseData,
            ...extraFields,
        };
    }

    static toCrudV2(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);

        const v2Data = toCrudV2SelectValue(baseData, [
            'environment',
            'type',
            'state',
            'currency',
            'idCountry',
        ]);

        let extraFields =
            !data.Extrafields.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpointV2(data.extraFields || [], mappedSchema)
                : fromExtraFieldsV2(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        return {
            ...baseData,
            ...v2Data,
            ...extraFields,
        };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toDetail(data) {
        const ownersIdList = data.ownersIds ? data.ownersIds.split(';') : [];
        if (ownersIdList.length > 0 && !ownersIdList[ownersIdList.length - 1]) ownersIdList.pop();

        const ownersList = data.ownersNames ? data.ownersNames.split(';') : [];
        if (ownersList.length > 0 && !ownersList[ownersList.length - 1]) ownersList.pop();

        const contactsIdList =
            data.ContactIdList && data.ContactIdList.length > 0
                ? data.ContactIdList.split(';')
                : [];
        const contactsList =
            data.ContactList && data.ContactList.length > 0 ? data.ContactList.split(';') : [];

        return {
            id: data.Id,
            name: data.Reference,
            extraFields: data.extraFields,
            isFollowing:
                data.FollowingItem && data.FollowingItem !== -1 && data.FollowingItem !== '-1',
            createdDate: getDateMomentFromBackendTimestamp(data.Created_timestamp),
            lastModifiedDate: getDateMomentFromBackendTimestamp(data.Modified_timestamp),
            lastModifiedStatusDate: data.fModificadoidEstadoObra
                ? getMomentFromDateBackend(data.fModificadoidEstadoObra)
                : null,
            userModified: data.ModifiedUser,
            commercialId: data.IdComercial,
            commercialName: data.Comercial,
            comments: data.Comments,
            amount: data.Amount,
            probability: data.Probability,
            currencySymbol: data.SymbolCurrency,
            stateDescription: data.OpportunityType,
            state: data.IdOpportunityType,
            typeExpediente: data.TypeExpediente,
            idTypeExpediente: data.IdTypeExpediente,
            expectedClose: data.ExpectedClose,
            environment: data.Environment,
            isGeoLocated: !!(data.IsGeoCoded === 'True' && data.Latitude && data.Longitude),
            latitude: toFloat(data.Latitude),
            longitude: toFloat(data.Longitude),
            fullAddress: formatAddress(
                data.Address1,
                data.Address2,
                data.City,
                data.Province,
                data.CP,
                data.Country,
                data.IdCountry,
            ),
            companyDescription: data.Company,
            companyId: data.IdCompany,
            companyType: data.CompanyType,
            closingDate: data.Close,
            intermediaryDescription: data.CompanyIntermediate,
            intermediaryId: data.IdCompanyIntermediate,
            intermediaryType: data.IntermediateCompanyType,
            intermediary2Description: data.CompanyOther,
            intermediary2Id: data.IdCompanyOther,
            intermediary2Type: data.OtherCompanyType,
            companyFullAddress: OpportunityModel.formatCompanyAddress(
                data.CityCompany,
                data.ProvinceCompany,
                data.CountryCompany,
            ),
            intermediaryFullAddress: OpportunityModel.formatCompanyAddress(
                data.CityCompanyIntermediate,
                data.ProvinceCompanyIntermediate,
                data.CountryCompanyIntermediate,
            ),
            intermediary2FullAddress: OpportunityModel.formatCompanyAddress(
                data.CityCompanyOther,
                data.ProvinceCompanyOther,
                data.CountryCompanyOther,
            ),
            numRelatedAccounts:
                (data.IdCompany ? 1 : 0) +
                (data.IdCompanyIntermediate ? 1 : 0) +
                (data.IdCompanyOther ? 1 : 0),
            isWon: data.IsWin === '1',
            isLost: data.IsLost === '1',
            ownersList,
            ownersIdList,
            contactsIdList,
            contactsList,
            relatedAccounts: data.relatedAccounts,
            isReadOnly: getBackendBoolean(data.IsReadOnly),
            idTarifa: data.IdTarifa,
            tarifa: data.Tarifa,
        }; // For ExtraField widget // For widgets too
    }

    static toList({ data }) {
        const newData = data.map((current) => {
            let isFollowing = false;

            if (Number.isInteger(current.FollowingItem)) {
                isFollowing = current.FollowingItem > 0;
            } else {
                isFollowing = !(
                    current.FollowingItem === 'false' ||
                    current.FollowingItem === 'False' ||
                    current.FollowingItem === '0' ||
                    current.FollowingItem === '-1'
                );
            }

            let srcComercial = '';
            let placeholderComercial = '';
            const comercialImage = getSrcUserCircleAvatar(current.IdComercial);
            placeholderComercial = comercialImage.fallbackSrc;
            srcComercial = comercialImage.src;

            let srcCompany = '';
            let placeholderCompany = '';

            const companyLogo = getSrcCompanyChipAlternative(current.CompanyIdLogo);
            placeholderCompany = companyLogo.fallbackSrc;
            srcCompany = companyLogo.src;

            let srcCompanyIntermediate = '';
            let placeholderCompanyIntermediate = '';
            const IdCompanyIntermediateImage = getSrcCompanyChipAlternative(
                current.IntermediateCompanyIdLogo,
            );
            placeholderCompanyIntermediate = IdCompanyIntermediateImage.fallbackSrc;
            srcCompanyIntermediate = IdCompanyIntermediateImage.src;

            let srcCompanyOther = '';
            let placeholderCompanyOther = '';
            const IdCompanyOtherImage = getSrcCompanyChipAlternative(current.OtherCompanyIdLogo);
            placeholderCompanyOther = IdCompanyOtherImage.fallbackSrc;
            srcCompanyOther = IdCompanyOtherImage.src;

            const probability = parseInt(current.Probability, 10) * 10;

            const ownersIds = current.ownersIds ? current.ownersIds.split(';') : [];
            const ownersNames = current.ownersNames ? current.ownersNames.split(';') : [];

            const ownersSufix = [2, 3, 4, 5];
            let owners = ownersIds.reduce((obj, item, index) => {
                const { src, fallbackSrc } = getSrcUserCircleAvatar(item);
                obj[`ownerId${ownersSufix[index]}`] = item || '';
                obj[`ownerName${ownersSufix[index]}`] = ownersNames[index] || '';
                obj[`ownerSrc${ownersSufix[index]}`] = src || '';
                obj[`ownerPlaceholder${ownersSufix[index]}`] = fallbackSrc || '';
                return obj;
            }, {});

            const contactsIds = current.ContactIdList ? current.ContactIdList.split(';') : [];
            const contactsNames = current.ContactList ? current.ContactList.split(';') : [];

            const contactsSuffix = [1, 2, 3, 4, 5];
            const contacts = contactsIds.reduce((obj, item, index) => {
                const { src, fallbackSrc } = getSrcContactCircleAvatar(item);
                obj[`contactId${contactsSuffix[index]}`] = item || '';
                obj[`contactName${contactsSuffix[index]}`] = contactsNames[index] || '';
                obj[`contactSrc${contactsSuffix[index]}`] = src || '';
                obj[`contactPlaceholder${contactsSuffix[index]}`] = fallbackSrc || '';
                return obj;
            }, {});

            const expirationDays = UtilFormat.getDifferentDay(current.ExpectedClose);

            let obj = {
                rawData: { ...current },
                ...current,
                isFollowing,
                srcCompany,
                placeholderCompany,
                srcComercial,
                placeholderComercial,
                probability,
                srcCompanyIntermediate,
                placeholderCompanyIntermediate,
                srcCompanyOther,
                placeholderCompanyOther,
                isGeoCoded: getBackendBoolean(current.IsGeoCoded),
                isWin: getBackendBoolean(current.IsWin),
                isLost: getBackendBoolean(current.IsLost),
                expirationDays,
                currencySymbol: Context.store.getState().config.userData.currencySymbol,
                ...owners,
                ...contacts,
            };

            return obj;
        });

        return newData || [];
    }

    get id() {
        return this.entity.id;
    }

    get isFollowing() {
        return (
            this.entity.followingitem &&
            this.entity.followingitem !== -1 &&
            this.entity.followingitem !== '-1'
        );
    }

    get probability() {
        return this.entity.probability;
    }

    get lastModifiedDate() {
        return getDateMomentFromBackendTimestamp(this.entity.modified_timestamp);
    }

    get commercialId() {
        return this.entity.idcomercial;
    }

    get commercialName() {
        return this.entity.comercial;
    }

    get comments() {
        return this.entity.comments;
    }

    get amount() {
        return this.entity.amount;
    }

    get currencySymbol() {
        return this.entity.symbolcurrency;
    }

    get stateDescription() {
        return this.entity.opportunitytype;
    }

    get state() {
        return this.entity.idopportunitytype;
    }

    get typeExpediente() {
        return this.entity.typeexpediente;
    }

    get expectedClose() {
        if (!this.entity.expectedclose) return;
        const moment = getMomentFromDateBackend(this.entity.expectedclose);
        return formatDate(moment);
    }

    get environment() {
        return this.entity.environment;
    }

    get isGeoLocated() {
        return this.entity.isgeocoded === 'True' && this.latitude && this.longitude;
    }

    get latitude() {
        return this.entity.latitude ? this.entity.latitude.replace(',', '.') : null;
    }

    get longitude() {
        return this.entity.longitude ? this.entity.longitude.replace(',', '.') : null;
    }

    get fullAddress() {
        return formatAddress(
            this.entity.address1,
            this.entity.address2,
            this.entity.city,
            this.entity.province,
            this.entity.cp,
            this.entity.country,
            this.entity.idcountry,
        );
    }

    get companyDescription() {
        return this.entity.company;
    }

    get companyId() {
        return this.entity.idcompany;
    }

    get companyType() {
        return this.entity.companytype;
    }

    get intermediaryDescription() {
        return this.entity.companyintermediate;
    }

    get intermediaryId() {
        return this.entity.idcompanyintermediate;
    }

    get intermediaryType() {
        return this.entity.intermediatecompanytype;
    }

    get intermediary2Description() {
        return this.entity.companyother;
    }

    get intermediary2Id() {
        return this.entity.idcompanyother;
    }

    get intermediary2Type() {
        return this.entity.othercompanytype;
    }

    get companyFullAddress() {
        return OpportunityModel.formatCompanyAddress(
            this.entity.citycompany,
            this.entity.provincecompany,
            this.entity.countrycompany,
        );
    }

    get intermediaryFullAddress() {
        return OpportunityModel.formatCompanyAddress(
            this.entity.citycompanyintermediate,
            this.entity.provincecompanyintermediate,
            this.entity.countrycompanyintermediate,
        );
    }

    get intermediary2FullAddress() {
        return OpportunityModel.formatCompanyAddress(
            this.entity.citycompanyother,
            this.entity.provincecompanyother,
            this.entity.countrycompanyother,
        );
    }

    static formatCompanyAddress(city, province, country) {
        let result = city || '';
        if (province && province.length > 0) {
            if (result.length > 0) {
                result += ' ';
            }
            result += province;
        }
        if (country && country.length > 0) {
            if (result.length > 0) {
                result += ', ';
            }
            result += country;
        }
        return result;
    }

    get numRelatedAccounts() {
        return (
            (this.companyId ? 1 : 0) +
            (this.intermediaryId ? 1 : 0) +
            (this.intermediary2Id ? 1 : 0)
        );
    }

    get extraFields() {
        return this.entity.extrafields || [];
    }

    get isWon() {
        return this.entity.iswin === '1';
    }

    get isLost() {
        return this.entity.islost === '1';
    }

    get ownersList() {
        const list = this.entity.ownersnames ? this.entity.ownersnames.split(';') : [];
        if (list.length > 0 && !list[list.length - 1]) list.pop();
        return list;
    }

    get ownersIdList() {
        const list = this.entity.ownersids ? this.entity.ownersids.split(';') : [];
        if (list.length > 0 && !list[list.length - 1]) list.pop();
        return list;
    }
}

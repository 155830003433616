export default ({ text, color, sizes, components }) => ({
    content: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    header: {
        ...text.text_md_hint,
        // height: 25,
        backgroundColor: '#ffffff',
        color: '#636363',
        textAlign: 'left',
        fontWeight: '400',
    },
    headerContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        boxSizing: 'border-box',
        display: 'block',
        padding: '8px',
        width: '100%',
    },
    headerText: {
        padding: '5px 5px 5px 0px',
        position: 'relative',
        top: -2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
    },

    text: {
        ...text.text_md,
        position: 'relative',
    },
    checkbox: {
        ...text.text_md,
        paddingLeft: 0,
        height: 44,
    },
    rowText: {
        padding: '5px 10px',
        verticalAlign: 'middle',
        borderBottom: '1px solid #ddd',
        color: 'red',
    },
    text2: {
        ...text.text_xs,
        lineHeight: '13px',
    },

    text3: {
        ...text.big_text_md,
    },

    link: {
        ...text.link_md,
    },
    rowAdd: {
        ...text.text_md,
        padding: 10,
        verticalAlign: 'middle',
        borderBottom: '1px solid #ddd',
        background: '#f8f8f8',
        cursor: 'pointer',
        width: '100%',
    },
    scrollContent: {
        height: '50vh',
        borderBottom: '1px solid #ddd',
    },
    inputStyle: components.inputStyle,
    hintStyle: components.hintStyle,
    subtotal: {
        ...text.text_md,
        fontWeight: 400,
        height: 30,
    },
    addDto: {
        ...text.link_md,
        height: 40,
    },
    descuento: {
        ...text.text_md,
        height: 48,
        marginRight: 10,
    },
    descuento2: {
        ...text.text_md,
        height: 48,
        lineHeight: '40px',
    },

    emptyList: {
        ...text.text_md,
        width: '100%',
        height: 300,
        textAlign: 'center',
        display: 'table',
        backgroundColor: 'white',
        color: 'rgb(143, 143, 143)',
        // marginTop: -21,
    },
    renderMoreInfo: {
        display: 'inline-block',
        position: 'absolute',
        right: 0,
        zIndex: 15,
        top: 3,
    },
    checkBoxText: {
        marginLeft: -20,
        position: 'relative',
        top: 11,
        ...text.text_md,
    },
    headerCheck: {
        padding: '25px 20px 10px 25px',
    },
});

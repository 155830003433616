import { COMPANIES_CHANGE_CONTENT } from 'constants/ActionTypes';
import { COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';

const getManager = () => Context.entityManager.getEntitiesManager(COMPANIES);

export function changeSection(section) {
    return function (dispatch) {
        dispatch({
            type: COMPANIES_CHANGE_CONTENT,
            section,
        });
    };
}

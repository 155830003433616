import Context from '../managers/Context';
import { SET_NOTIFICATIONS_UNREAD_0, LOAD_GENERAL_NOTIFICATIONS } from 'constants/ActionTypes';

export function getUnreadNotifications(params) {
    return (dispatch, getState) => {
        Context.domainManager
            .getUnreadNotifications(params)
            .then((data) => {
                dispatch({ type: LOAD_GENERAL_NOTIFICATIONS, data });
            })
            .catch(() => {
                console.error('error getting unread notifications for user');
            });
    };
}

export function markNotificationsAsRead() {
    return (dispatch, getState) => {
        const unread = getState().notification?.unread?.notifications || 0;
        if (unread !== 0) {
            dispatch({ type: SET_NOTIFICATIONS_UNREAD_0 });
        }
    };
}

import React from 'react';
import colors from 'constants/colors';

//Has fixed height and width because shouldn't be editable
const ChevronUp10px = ({ color = '$fmDefaultIcons' }) => (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 3.664l3.67 3.212a.5.5 0 0 0 .66-.752L5.334 2.628a.499.499 0 0 0-.67 0L.671 6.124a.5.5 0 0 0 .658.752L5 3.664z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default ChevronUp10px;

const values = {
    CONFIG_TOKEN: 'config_web4',
    VALUE_LIST_TOKEN: 'values',
    SERVER_LIST_TOKEN: 'SERVER_LIST_TOKEN',
    LITERALS: 'fm_newLiterals',
    DOCUMENTS: 'documents',
    RATES2: 'rates2',
    REPORTS: 'reports',
    KPI_TYPES: 'KPI_TYPES',
};

export default class CacheManager {
    constructor(store) {
        this.store = store;
        this.listeners = {};
    }

    setConfig(config) {
        this.setConfigStore(config);
    }

    setConfigAsync(config) {
        this.setConfigStore(config);
        return Promise.resolve();
    }

    keys() {
        return values;
    }

    _set(key, data) {
        this.store.setItem(key, JSON.stringify(data));
    }

    _get(key) {
        return JSON.parse(this.store.getItem(key));
    }

    _remove(key) {
        this.store.removeItem(key);
    }

    getConfigStore() {
        return this._get(values.CONFIG_TOKEN);
    }

    setConfigStore(config) {
        this._set(values.CONFIG_TOKEN, config);
        this.notify(values.CONFIG_TOKEN, config);
    }

    removeConfigStore() {
        this._remove(values.CONFIG_TOKEN);
    }

    getLiteralsStore() {
        return this._get(values.LITERALS);
    }

    setLiteralsStore(data) {
        this._set(values.LITERALS, data);
    }

    removeLiteralsStore() {
        this._remove(values.LITERALS);
    }

    updateLiterals(key, data) {
        const literals = this.getLiteralsStore();
        literals[key] = data[key];
        this.setLiteralsStore(literals);
        return literals;
    }

    getValueListDefaultStore() {
        const val = this._get(values.VALUE_LIST_TOKEN);
        return val || {};
    }

    getValueListStore(table) {
        const values = this.getValueListDefaultStore();
        return values[table];
    }

    setValueListStore(table, data) {
        const val = this.getValueListDefaultStore();
        val[table] = data;
        this._set(values.VALUE_LIST_TOKEN, val);
    }

    removeValueListStore(table) {
        this.setValueListStore(table, undefined);
    }

    removeValueListStore() {
        this._remove(values.VALUE_LIST_TOKEN);
    }

    setDocumentList(data) {
        return this._set(values.DOCUMENTS, data);
    }

    getDocumentList() {
        return this._get(values.DOCUMENTS);
    }

    setRateList2(data) {
        return this._set(values.RATES2, data);
    }

    getRateList2() {
        return this._get(values.RATES2);
    }

    removeRateList2() {
        this._remove(values.RATES2);
    }

    removeComponents() {
        this._remove(values.COMPONENTS);
    }

    getReportsdefault() {
        const val = this._get(values.REPORTS);
        return val || {};
    }

    getReports(table) {
        const values = this.getReportsdefault();
        return values[table];
    }

    setReports(table, data) {
        const val = this.getReportsdefault();
        val[table] = data;
        this._set(values.REPORTS, val);
    }

    removeReports(table) {
        this.setReports(table, undefined);
    }

    removeReportsDefault() {
        this._remove(values.REPORTS);
    }

    clear() {
        this.removeLiteralsStore();
        this.removeConfigStore();
        this.removeValueListStore();
        this.removeServerList();
        this.removeRateList2();
        this.removeReportsDefault();
        this.removeComponents();
    }

    getListener(store) {
        if (!this.listeners[store]) {
            this.listeners[store] = [];
        }
        return this.listeners[store];
    }

    setListener(store, func) {
        const listener = this.getListener(store);
        const index = listener.indexOf(func);
        index === -1 && listener.push(func);
    }

    clearListener(func) {
        let listener = this.getListener(store);
        const index = listener.indexOf(func);
        if (index !== -1) {
            listener = listener.splice(1, index, func);
        }
    }

    notify(store, object) {
        const listener = this.getListener(store);
        listener.forEach((listener) => {
            listener(object);
        });
    }

    updateViews(views) {
        return new Promise((resolve, reject) => {
            const sessionData = this.context.cacheManager.getConfigStore();
            if (sessionData) {
                sessionData.views = views;
                this.context.cacheManager.setConfigStore(sessionData);
            }
            resolve(sessionData);
        });
    }

    getServerListDefault() {
        return this._get(values.SERVER_LIST_TOKEN) || {};
    }

    getServerList(list) {
        const lists = this.getServerListDefault();
        return lists[list];
    }

    setServerList(list, data) {
        const lists = this.getServerListDefault();
        lists[list] = data;
        this._set(values.SERVER_LIST_TOKEN, lists);
    }

    removeServerList() {
        this._remove(values.SERVER_LIST_TOKEN);
    }

    removeSpecificServerList(list) {
        const lists = this.getServerListDefault();
        delete lists[list];
        this._set(values.SERVER_LIST_TOKEN, lists);
    }

    getKpiTypesDefault() {
        return this._get(values.KPI_TYPES) || {};
    }

    getKpiTypes(entity) {
        const types = this.getKpiTypesDefault();
        return types[entity];
    }

    setKpiTypes(entity, data) {
        const types = this.getKpiTypesDefault();
        types[entity] = data;
        this._set(values.KPI_TYPES, types);
    }
}

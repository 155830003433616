import React, { PureComponent } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { PATH_IMAGE } from 'constants/Environment';

class StepTwo extends PureComponent {
    state = {};

    handleRedirectGooglePlay = () => {
        return () => {
            window.open(getLiteral('label_link_install_ForceManger_Google_Play'), '_blank');
        };
    };

    handleRedirectAppStore = () => {
        return () => {
            window.open(getLiteral('label_link_install_ForceManger_App_Store'), '_blank');
        };
    };

    render() {
        const { step, is5500 } = this.props;

        const stepStyles = {
            1: '670px',
            2: '0px',
            3: '-670px',
        };

        return (
            <div className="onboarding-step" style={{ left: stepStyles[step] }}>
                <div className="onboarding-media-container">
                    <img src={`${PATH_IMAGE}img-onboarding1.png`} />
                </div>
                <div className="onboarding-content">
                    <div className="onboarding-content-header">
                        {getLiteral(
                            is5500
                                ? 'label_welcome_mobile_app_5500'
                                : 'label_welcome_cards_mobile_app',
                        )}
                    </div>
                    <div className="onboarding-content-text">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getLiteral(
                                    is5500
                                        ? 'label_welcome_mobile_description_5500'
                                        : 'label_welcome_cards_mobile_app_body',
                                ),
                            }}
                        />
                    </div>
                    <div className="onboarding-stores-buttons">
                        <div
                            className="onboarding-store-button"
                            onClick={this.handleRedirectGooglePlay()}
                        >
                            <img src={`${PATH_IMAGE}img-google-play.png`} />
                        </div>
                        <div
                            className="onboarding-store-button"
                            onClick={this.handleRedirectAppStore()}
                        >
                            <img src={`${PATH_IMAGE}img-app-store.png`} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StepTwo;

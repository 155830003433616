import React from 'react';
import colors from 'constants/colors';

const Bell = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.8 17.1c-.3-.3-.3-.7.1-.9.4-.2.9-.2 1.2.1.3.3.7.4 1.1.4.3 0 .7-.1 1-.4.3-.3.8-.3 1.2-.1.4.2.4.6.1.9-.6.6-1.4.9-2.3.9-1 0-1.8-.3-2.4-.9zm10.3-4.3c1.2 0 1.2 2.2 0 2.2H.9c-1.2 0-1.2-2.2 0-2.2.5 0 1.2-1.2 1.2-2v-.1l.4-2.9C2.9 2.8 5.4 0 9 0c3.7 0 6.4 2.9 6.8 7.6l.4 3c-.1 0 .5 2.2.9 2.2zm-3-2.1l-.4-2.9C13.5 4.1 11.6 2 9 2 6.5 2 4.8 4 4.5 7.9l-.3 2.8v.1c0 .7-.3 1.5-.7 2.2h11.1c-.3-.7-.5-1.5-.5-2.3 0 .1 0 0 0 0z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Bell;

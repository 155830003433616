import React, { memo, useCallback, useReducer, useEffect, useRef } from 'react';
import { Modal } from 'hoi-poi-ui';

import MultiSelectField from 'containers/components/Fields/MultiSelect';
import SelectField from 'containers/components/Fields/Select';
import TextField from 'containers/components/Fields/Text';
import BoolField from 'containers/components/Fields/Bool';

import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const initialState = {
    id: null,
    errors: {},
    isOpen: false,
    isLoading: true,
    parentId: null,
    title: null,
    modalTitle: null,
    shared: true,
    securityLevel: null,
    environment: null,
};

const SECURITY_LEVEL_OPTIONS = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
];

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                parentId: action.parentId,
                title: action.title,
                modalTitle: action.modalTitle,
                label: action.label,
                shared: action.shared,
                securityLevel: action.securityLevel,
                environment: action.environment,
                errors: {},
                isOpen: true,
                isLoading: false,
                isOwner: action.isOwner,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'changeTitle':
            return {
                ...state,
                title: action.title,
            };
        case 'changeShared':
            return {
                ...state,
                shared: action.shared,
            };
        case 'change':
            return {
                ...state,
                [action.name]: action.value,
            };
        case 'setErrors':
            return { ...state, isLoading: false, errors: action.errors };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const FolderForm = memo(
    ({
        onRef,
        onSave,
        onCancel,
        showEnvironments,
        showLevels,
        showPrivacy,
        createFolder,
        updateFolder,
        parseDataToSave,
        isOpen,
        folder,
    }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const prevIsOpenRef = useRef(false);

        useEffect(() => {
            onRef &&
                onRef({
                    open(folder) {
                        let finalFolder = { ...folder };
                        // Backend reformat
                        finalFolder.environment = finalFolder.environment?.map((item) => ({
                            label: item.value,
                            value: item.id?.toString() || '',
                        }));
                        finalFolder.securityLevel = SECURITY_LEVEL_OPTIONS.find(
                            (option) => option.value === folder.securityLevel,
                        );
                        dispatch({
                            type: 'init',
                            ...finalFolder,
                        });
                    },
                });
        }, [onRef]);

        useEffect(() => {
            if (typeof isOpen === 'boolean' && isOpen && prevIsOpenRef.current !== isOpen) {
                prevIsOpenRef.current = isOpen;
                let finalFolder = { ...folder };
                finalFolder.securityLevel = SECURITY_LEVEL_OPTIONS.find(
                    (option) => option.value === folder.securityLevel,
                );
                dispatch({
                    type: 'init',
                    ...finalFolder,
                });
            }
            if (typeof isOpen === 'boolean' && !isOpen && prevIsOpenRef.current !== isOpen) {
                prevIsOpenRef.current = isOpen;
                dispatch({ type: 'close' });
            }
        });

        const onChangeName = useCallback((title) => {
            let finalTitle = title;
            if (finalTitle?.length <= 1) finalTitle = finalTitle.trim();
            dispatch({ type: 'changeTitle', title: finalTitle });
            if (finalTitle) dispatch({ type: 'setErrors', errors: { error: '' } });
        }, []);

        const onChangeSwitch = useCallback((value) => {
            dispatch({ type: 'changeShared', shared: !value });
        }, []);

        const onChange = useCallback((name) => {
            return (value) => {
                dispatch({ type: 'change', name, value });
            };
        }, []);

        const onInnerCancel = useCallback(() => {
            dispatch({ type: 'close' });
            onCancel && onCancel();
        }, [onCancel]);

        const onInnerSave = useCallback(() => {
            if (!state.title) {
                dispatch({
                    type: 'setErrors',
                    errors: { error: getLiteral('helptext_required_field') },
                });
                return;
            }

            let newItem = {
                title: state.title,
                parentId: state.parentId,
                shared: state.shared,
                environment:
                    !state.shared && showPrivacy
                        ? []
                        : state.environment?.map((i) => parseInt(i?.value, 10)) || [],
                securityLevel: !state.shared && showPrivacy ? 0 : state.securityLevel?.value || 0,
            };

            if (parseDataToSave) newItem = parseDataToSave(newItem);

            let pr = state.id ? () => updateFolder(state.id, newItem) : () => createFolder(newItem);
            dispatch({ type: 'setLoading', isLoading: true });
            pr()
                .then((item) => {
                    dispatch({ type: 'setErrors', errors: {} });
                    dispatch({ type: 'close' });
                    successToast({
                        text: getLiteral('label_analyticspro_toast_ok'),
                    });
                    onSave && onSave({ ...newItem, id: item.code }, !state.id);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({
                        text: getLiteral('label_analyticspro_toast_ko'),
                    });
                    dispatch({ type: 'setLoading', isLoading: false });
                });
        }, [onSave, state, createFolder, updateFolder, parseDataToSave, showPrivacy]);

        const canShowEnvironments = showPrivacy
            ? showEnvironments && state?.shared
            : showEnvironments;
        const canShowLevels = showPrivacy ? showLevels && state?.shared : showLevels;

        return (
            <Modal
                className="fm-crud-form__modal"
                useCornerClose={false}
                isOpen={state.isOpen}
                onCancel={onInnerCancel}
                onConfirm={onInnerSave}
                confirmText={getLiteral('action_save')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={state.isLoading}
                title={state.modalTitle}
            >
                <div className="fm-crud-form fm-crud-form--version-2">
                    <div className="fm-crud-form-tab">
                        <TextField
                            label={state.label}
                            hint={getLiteral('placeholder_text_field')}
                            onChange={onChangeName}
                            value={state?.title}
                            error={state.errors.error}
                            type="text"
                            autoFocus={true}
                            mandatory
                        />
                        {showPrivacy && (
                            <BoolField
                                label={getLiteral('label_analytics_pro_private_folder')}
                                hint={getLiteral('label_analytics_pro_private_folder_desc')}
                                onChange={onChangeSwitch}
                                value={!state?.shared}
                            />
                        )}
                        {canShowEnvironments && (
                            <MultiSelectField
                                label={getLiteral('label_analytics_pro_folder_branch_visibility')}
                                onChange={onChange('environment')}
                                value={state?.environment}
                                list="tblSucursales"
                            />
                        )}
                        {canShowLevels && (
                            <SelectField
                                label={getLiteral('label_analytics_pro_security_level_visibility')}
                                description={getLiteral(
                                    'label_analytics_pro_security_level_visibility_desc',
                                )}
                                options={SECURITY_LEVEL_OPTIONS}
                                onChange={onChange('securityLevel')}
                                value={state?.securityLevel}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        );
    },
);

export default FolderForm;

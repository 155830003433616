import Context from 'managers/Context';
import { URL_IMAGE, PATH_IMAGE } from 'constants/Environment';
import { isBackendFalsy } from 'utils/fm';
import Cookies from 'js-cookie';

const getUserConfigData = () => {
    let config = {
        token: Context?.config?.token,
        strCellPhoneNumber: Context?.config?.userData?.user,
        impersonatedUser: Context?.config?.userData?.impersonatedUser,
        imageTimestamp: Cookies.get('imageTimestamp'),
    };

    config.userPictureUrl = `${URL_IMAGE}GetUserPicture.ashx?userKey=JWT ${config.token}&strCellPhoneNumber=${config.strCellPhoneNumber}&getbytes=true`;
    config.imageUrl = `${URL_IMAGE}GetImage.ashx?userKey=JWT ${config.token}&strCellPhoneNumber=${config.strCellPhoneNumber}&getbytes=true`;

    config.imageUrl = `${config.imageUrl}&impersonatedUser=${config.impersonatedUser}`;
    config.userPictureUrl = `${config.userPictureUrl}&impersonatedUser=${config.impersonatedUser}`;

    return config;
};

//Circle Avatar
export const getSrcUserCircleAvatar = (id, fallbackSrc) => {
    const { userPictureUrl, imageTimestamp } = getUserConfigData();
    const src = !isBackendFalsy(id)
        ? `${userPictureUrl}&idUser=${id}&timeStamp=${imageTimestamp}`
        : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder_user.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcContactCircleAvatar = (id, fallbackSrc) => {
    const { imageUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${imageUrl}&idContact=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder_user.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcCompanyCircleAvatar = (id, fallbackSrc) => {
    const { imageUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${imageUrl}&idAccount=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder-account.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcProductCircleAvatar = (id, fallbackSrc, width, height) => {
    const { imageUrl } = getUserConfigData();
    let src = !isBackendFalsy(id) ? `${imageUrl}&idImage=${id}` : undefined;
    if (width && height && src) src += `&width=${width}&height=${height}`;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}no_image.png`;

    return {
        src,
        fallbackSrc,
    };
};

//Chip Avatar (Squared)
export const getSrcUserChipAvatar = (id, fallbackSrc) => {
    const { userPictureUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${userPictureUrl}&idUser=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder-user-chip.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcContactChipAvatar = (id, fallbackSrc, contactName) => {
    const { imageUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${imageUrl}&idContact=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder-user-chip.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcCompanyChipAvatar = (id, fallbackSrc) => {
    const { imageUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${imageUrl}&idAccount=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder-account-chip.svg`;

    return {
        src,
        fallbackSrc,
    };
};

export const getSrcCompanyChipAlternative = (id, fallbackSrc) => {
    const { imageUrl } = getUserConfigData();
    const src = !isBackendFalsy(id) ? `${imageUrl}&idImage=${id}` : undefined;
    fallbackSrc = fallbackSrc || `${PATH_IMAGE}placeholder-account-chip.svg`;

    return {
        src,
        fallbackSrc,
    };
};

import React, { Component } from 'react';
import ForceManagerTheme from './themes/ForceManagerTheme';
import { mergeStyles } from '../utils/mergeStyles';

export const withStyles = (styles) => (DecoratedComponent) => (props) => {
    const newStyles = mergeStyles(styles(ForceManagerTheme), props.styles);
    // const newProps = {...props};
    // newProps.styles = undefined;

    return <DecoratedComponent {...props} ref={props.reference} styles={newStyles} />;
};

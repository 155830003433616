import React, { PureComponent } from 'react';
import './styles.scss';

class WarningAdvice extends PureComponent {
    state = {};

    render() {
        const { className, children } = this.props;
        return <div className={`fm-advice fm-advice-warning ${className}`}>{children}</div>;
    }
}

export default WarningAdvice;

import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Avatar, Menus, Icon, Text, Link, useTheme } from 'hoi-poi-ui';

import Context from 'managers/Context';
import { ADMIN_URL } from 'constants/Environment';
import { getLiteral as t } from 'utils/getLiteral';
import { isProfessionalPlan, isPerformancePlan } from 'utils/fm';
import { goLogout, ensureRoute } from 'utils/routes';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { logEvent } from 'utils/tracking';

import './styles.scss';

function mapStateToProps(state) {
    return {
        idUser: state.config?.userData?.idUsuario || -1,
        userData: state.config?.userData,
        permission: state.config?.permission,
        productModeCode: state.config?.productModeCode,
    };
}

const ProfileMenu = memo((props) => {
    const { idUser, userData, permission, productModeCode } = props;
    const { src, fallbackSrc } = getSrcUserCircleAvatar(idUser);

    const theme = useTheme();
    const completeName = `${userData.nombre} ${userData.apellidos}`;
    const supportURL = userData?.support || 'url_support_request';
    const knowledgeBase = userData?.baseKnowledge || 'url_knowledge_base';

    const getItems = useMemo(() => {
        const items = [
            {
                title: <Text bold>{completeName}</Text>,
                infoItem: true,
            },
        ];
        if (permission.canUpdateProfile) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Text>{t('title_myprofile')}</Text>,
                icon: <Icon name="person" size="medium" color={theme.colors.neutral600} />,
                onClick: () => ensureRoute('/settings'),
            });
        }
        if (
            permission.canConfigImplementation &&
            (productModeCode === 'business' || isProfessionalPlan() || isPerformancePlan()) &&
            userData.idSetupRole > 0
        ) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Text>{t('action_go_to_admin')}</Text>,
                icon: <Icon name="settings" size="medium" color={theme.colors.neutral600} />,
                onClick: () => window.open(`${ADMIN_URL}?token=${Context?.config?.token}`, '_self'),
            });
        }
        if (permission.administrarIntegracion) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Text>{t('title_third_party_integration')}</Text>,
                icon: <Icon name="widget" size="medium" color={theme.colors.neutral600} />,
                onClick: () => {
                    logEvent({
                        event: 'settingsMenu',
                        functionality: 'thirdPartyIntegrations',
                    });
                    ensureRoute('/settings/integrations');
                },
            });
        }
        if (permission.enableHelpCenterOption) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Link underline>{t('label_knowledge_base')}</Link>,
                icon: (
                    <Icon name="helpCenterFilled" size="medium" color={theme.colors.neutral600} />
                ),
                onClick: () => window.open(t(knowledgeBase)),
            });
        }
        if (permission.enableWebinarOption) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Link underline>{t('label_join_webinar')}</Link>,
                icon: <Icon name="contactSupport" size="medium" color={theme.colors.neutral600} />,
                onClick: () => window.open(t('url_support_webminar')),
            });
        }

        if (window.Intercom) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Link underline>{t('title_contact_support')}</Link>,
                icon: <Icon name="webinar" size="medium" color={theme.colors.neutral600} />,
                onClick: () => window.Intercom('showNewMessage'),
            });
        }

        if (permission.referralProgram) {
            items.push({
                className: 'fm-settings-menu__submenu__item',
                title: <Text color="blue500">{t('label_referral_program')}</Text>,
                icon: <Icon name="favorite" size="medium" color={theme.colors.neutral600} />,
                onClick: () => window.open(t('label_referral_program_url')),
            });
        }
        items.push({
            className: 'fm-settings-menu__submenu__item fm-settings-menu__submenu__logout',
            title: <Text>{t('action_disconnect')}</Text>,
            icon: <Icon name="exitToApp" size="medium" color={theme.colors.neutral600} />,
            onClick: () => goLogout(),
        });
        return items;
    }, [
        completeName,
        permission.canUpdateProfile,
        permission.canConfigImplementation,
        permission.administrarIntegracion,
        permission.enableHelpCenterOption,
        permission.enableWebinarOption,
        permission.referralProgram,
        productModeCode,
        userData.idSetupRole,
        theme.colors.neutral600,
        knowledgeBase,
        supportURL,
    ]);

    return (
        <Menus
            classes={{
                menu: 'fm-settings-menu',
                subMenu: 'fm-settings-menu__submenu',
                menuItem: 'fm-settings-menu__submenu__item',
            }}
            icon={
                <Avatar
                    className="fm-settings-menu__avatar"
                    src={src}
                    placeholder={fallbackSrc}
                    size="large"
                />
            }
            items={getItems}
        />
    );
});

export default connect(mapStateToProps, null)(ProfileMenu);

import Axios from 'axios';

let ready = false;

const instance = createInstance({
    baseURL: 'https://integration.forcemanager.net',
});

export function createInstance(config) {
    return Axios.create({
        timeout: 30000,
        withCredentials: false,
        maxContentLength: 128 * 1024 * 1024,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=0, no-cache, must-revalidate, proxy-revalidate',
            'Access-Control-Allow-Origin': '*',
        },
        ...config,
    });
}

function setConfig(config) {
    return new Promise((resolve, reject) => {
        if (!ready) {
            let token = sessionStorage.getItem('integrationsToken');

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                instance.defaults.headers['Authorization'] = `Bearer ${token}`;
            }
            ready = true;
            resolve(config);
        } else {
            resolve(config);
        }
    });
}

// Headers
instance.setToken = (token) => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// Interceptors
instance.interceptors.request.use(
    (config) => {
        return setConfig(config);
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        const token = response.data.token;
        if (token) {
            instance.defaults.headers['Authorization'] = `Bearer ${token}`;
            sessionStorage.setItem('integrationsToken', token);
        }
        return response;
    },
    (error) => {
        const { response } = error;

        if (response && response.status === 401 && response.data && response.data.code === '2') {
            console.warn('Expired integrations token');
        }

        return Promise.reject(error);
    },
);

export const axios = instance;

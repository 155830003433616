import React from 'react';
import colors from 'constants/colors';

const Oval = ({ color = '$fmDefaultIcons' }) => (
    <svg
        viewBox={`0 0 18 18`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <circle id="oval-a" cx="9" cy="9" r="9" />
        </defs>
        <use
            fill={colors[color] ? colors[color] : '#788590'}
            xlinkHref="#oval-a"
            fillRule="evenodd"
        />
    </svg>
);

export default Oval;

import {
    USERS_TREE_LOADING,
    USERS_TREE_LOADED,
    USERS_TREE_ERROR,
    USERS_TREE_SET_USER_SELECTED,
    USERS_TREE_REFRESH_SELECTED_USERS_ARR,
} from 'constants/ActionTypes';
import Context from '../managers/Context';

const getManager = () => Context.usersManager;

let getUsersTreePromise;

function setLoading() {
    return {
        type: USERS_TREE_LOADING,
    };
}

function setError(error) {
    return {
        type: USERS_TREE_ERROR,
    };
}

export function getUsersTree(user, force) {
    return function (dispatch, getState) {
        if (user && (!user.id || !user.name)) return;
        const userData = getState().config.userData;
        if (!user) {
            user = {
                id: userData.idUsuario,
                name: `${userData.nombre} ${userData.apellidos}`,
            };
        }
        // this promise is something due to kpi's crud, which need user's tree loaded
        if (getUsersTreePromise) return getUsersTreePromise;
        getUsersTreePromise = new Promise((resolve, reject) => {
            const usersTree = getState.users ? getState.users.usersTree : null;

            if (force || (!usersTree.data && !usersTree.loading)) {
                dispatch(setLoading());
                dispatch(setSelectedUser(user));
                dispatch(refreshSelectedUsers(null));
                getManager().getUsersTree(
                    user.id,
                    (data) => {
                        dispatch({
                            type: USERS_TREE_LOADED,
                            data,
                        });
                        resolve(data);
                        getUsersTreePromise = null;
                    },
                    (error) => {
                        dispatch(setError());
                        reject();
                        getUsersTreePromise = null;
                    },
                );
            }
        });
        return getUsersTreePromise;
    };
}

export function setSelectedUser(user) {
    return function (dispatch, getState) {
        const { nombre, apellidos, idUsuario } = getState().config.userData;
        let fullName = nombre;
        if (apellidos) fullName = `${nombre} ${apellidos}`;

        const initialSelectedUser = {
            id: idUsuario,
            name: fullName,
        };

        dispatch({
            type: USERS_TREE_SET_USER_SELECTED,
            user: user || initialSelectedUser,
        });
    };
}

export function refreshSelectedUsers(user) {
    return function (dispatch, getState) {
        const userData = getState().config.userData;
        const userRoot = {
            id: userData.idUsuario,
            name: `${userData.nombre} ${userData.apellidos}`,
        };
        const selectedUsersArr = getState().users.usersTree.selectedUsersArr;
        const usersTreeData = getState().users.usersTree.data;
        const newSelectedUsersArr = buildSelectedUsers(
            userRoot,
            selectedUsersArr,
            usersTreeData,
            user,
        );
        dispatch({
            type: USERS_TREE_REFRESH_SELECTED_USERS_ARR,
            selectedUsersArr: newSelectedUsersArr,
        });
    };
}

const buildSelectedUsers = (userRoot, selectedUsersArr, usersTreeData, user) => {
    let newSelectedUsersArr = [];

    if (!user) {
        newSelectedUsersArr = [userRoot];
    } else if (selectedUsersArr[0].id === user.id) {
        const newArr = selectedUsersArr.filter((current) => current.id === user.id);
        newSelectedUsersArr = newArr;
    } else {
        let shouldLoop = true;

        for (let i = 0; shouldLoop === true; i++) {
            if (newSelectedUsersArr.length === 0) {
                newSelectedUsersArr = [user];
            } else {
                newSelectedUsersArr = [
                    usersTreeData[newSelectedUsersArr[0].idParent],
                    ...newSelectedUsersArr,
                ];
            }
            shouldLoop = newSelectedUsersArr[0].idParent !== userRoot.id;
        }
        newSelectedUsersArr = [userRoot, ...newSelectedUsersArr];
    }

    return newSelectedUsersArr;
};

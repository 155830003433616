import React from 'react';
import colors from 'constants/colors';

const OpportunityCheckIn = ({ color = '$fmDefaultIcons', color2 = '$fmOrange' }) => (
    <svg viewBox={`0 0 32 32`}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M24.222 18a5.621 5.621 0 0 0-1.222.962V18h1.222zm-1.245 9H6.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .155.1.29.25.363a5.333 5.333 0 0 0-.762 2.752c0 1.026.535 2.46 1.49 4.305zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 1 1-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 1 1-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z"
                fill={colors[color] ? colors[color] : '#788590'}
            />
            <path
                d="M31.729 22.695c0 2.469-4.62 9.238-4.62 9.238s-4.62-6.77-4.62-9.238c0-2.47 2.067-4.47 4.62-4.47 2.551 0 4.62 2 4.62 4.47zm-2.773 0c0-.987-.827-1.789-1.848-1.789-1.02 0-1.847.802-1.847 1.789 0 .987.827 1.787 1.847 1.787s1.848-.8 1.848-1.787z"
                fill={colors[color2] ? colors[color2] : '#ff8c00'}
            />
        </g>
    </svg>
);

export default OpportunityCheckIn;

import React from 'react';
import colors from 'constants/colors';

const Checked = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <path
                    d="M8,8 L8,1 C8,0.44771525 8.44771525,1.01453063e-16 9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 L10,8 L17,8 C17.5522847,8 18,8.44771525 18,9 C18,9.55228475 17.5522847,10 17,10 L10,10 L10,17 C10,17.5522847 9.55228475,18 9,18 C8.44771525,18 8,17.5522847 8,17 L8,10 L1,10 C0.44771525,10 6.76353751e-17,9.55228475 0,9 C-6.76353751e-17,8.44771525 0.44771525,8 1,8 L8,8 Z"
                    id="Mask"
                    fill={colors[color] ? colors[color] : '#E1E4E7'}
                />
                <polygon
                    fillOpacity="0.1"
                    fill={colors[color] ? colors[color] : '#E1E4E7'}
                    opacity="0"
                    points="0 0 18 0 18 18 0 18"
                />
            </g>
        </g>
    </svg>
);

export default Checked;

import React, { PureComponent } from 'react';
import Button from './Button';
class ButtonPrimarySmall extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return (
            <Button
                {...props}
                classes={['fm-button__primary fm-button__small', ...classes]}
                loadingColor="white"
            />
        );
    }
}

export default ButtonPrimarySmall;

export default ({ text, color }) => ({
    headerContent: {
        height: 50,
        padding: '0px 16px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#f8f8f8',
        display: 'inline-block',
        width: '100%',
    },
    header: {
        ...text.big_text_xl,
    },
    headerLeft: {
        display: 'inline-block',
        width: 'calc(100% - 20px)',
    },
    headerSearch: {
        display: 'inline-block',
        boxSizing: 'border-box',
        // width:'calc(100% - 20px)',
        width: '100%',
        border: '1px #ddd solid',
        backgroundColor: '#FFFFFF',
        borderRadius: '50px',
        padding: '0px 10px',
        height: 30,
        margin: '9px 0px',
    },
    searchBox: {
        width: 'calc(100% - 35px)',
        top: -11,
        position: 'relative',
    },
    closeStyle: {
        top: 19,
    },
    headerRight: {
        display: 'inline-block',
        width: 20,
    },
    inputStyle: {
        ...text.text_md,
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    iconContainer: {
        display: 'inline-block',
        position: 'relative',
        top: -6,
    },
    iconStyle: {
        // marginRight:16,
        position: 'relative',
        top: 0,
        cursor: 'pointer',
    },
    bodyContent: {
        height: 'calc(100% - 51px)',
        borderBottom: '1px solid #ddd',
    },
    sectionSearch: {
        boxSizing: 'border-box',
        marginTop: 40,
    },
    sectionText: {
        ...text.text_md,
        fontWeight: 400,
        paddingLeft: 16,
        marginBottom: 5,
    },
    text1: {
        ...text.text_md,
        fontWeight: 400,
    },
    text2: {
        ...text.text_xs,
    },
    link: {
        ...text.link_md,
    },
    row: {
        cursor: 'pointer',
        padding: '10px 0px 0px 16px',
        ':hover': {
            backgroundColor: '#f0f0f0',
        },
    },
});

import moment from 'moment';
import numeral from 'numeral';
import Context from '../managers/Context';
import { convertMomentLocale } from 'utils/dates';

import 'moment/locale/es';
import 'moment/locale/es-mx';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/pt-br';
import 'moment/locale/da';

const EURO = {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    currency: {
        symbol: '€',
    },
};

const DOLLAR = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    currency: {
        symbol: '$',
    },
};

const MXN = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    currency: {
        symbol: '$',
    },
};

const UK = {
    delimiters: {
        thousands: ',',
        decimal: '.',
    },
    currency: {
        symbol: '£',
    },
};

numeral.register('locale', 'es', EURO);
numeral.register('locale', 'es-ES', EURO);
numeral.register('locale', 'es-MX', MXN);
numeral.register('locale', 'de', EURO);
numeral.register('locale', 'de-DE', EURO);
numeral.register('locale', 'fr', EURO);
numeral.register('locale', 'fr-FR', EURO);
numeral.register('locale', 'it', EURO);
numeral.register('locale', 'it-IT', EURO);
numeral.register('locale', 'pt', EURO);
numeral.register('locale', 'pt-PT', EURO);
numeral.register('locale', 'pt-BR', EURO);
numeral.register('locale', 'ru', EURO);
numeral.register('locale', 'ru-RU', EURO);
// numeral.register('locale', 'en',DOLLAR);
numeral.register('locale', 'en-us', DOLLAR);
numeral.register('locale', 'en-gb', UK);
numeral.register('locale', 'da', UK);
numeral.register('locale', 'da-dk', UK);

// switch between locales
class UtilFormat {
    setConfig(store) {
        this._locale = store ? store.userData.locale : 'es-ES';
        this._localeDate = convertMomentLocale(store ? store.userData.locale : 'es-ES');

        this._localeDecimalFormat = store ? store.userData.locale : 'es-ES';
        this._momentFormat = moment.localeData(this._localeDate).longDateFormat('L');
        this._momentTimeFormat = moment.localeData(this._localeDate).longDateFormat('LT');
        this._momentDateTimeFormat = `${this._momentFormat} ${this._momentTimeFormat}`;

        this._currency = store ? store.userData.currencySymbol : '€';
        Context.cacheManager.setListener(
            Context.cacheManager.keys.CONFIG_TOKEN,
            this.onChangeConfiguration,
        );

        moment.locale(this._localeDate);
        moment.updateLocale(this._localeDate, {
            week: {
                dow: 1,
            },
        });
        numeral.locale(this._localeDecimalFormat);

        this._lengthSystem = store ? store.userData._lengthSystem : 'metric';
    }

    onChangeConfiguration(configuration) {
        this._locale = configuration.userData.locale;
        this._localeDate = convertMomentLocale(configuration.userData.locale || 'es-ES');
        this._localeDecimalFormat = configuration.userData.locale;

        this._momentFormat = moment.localeData(this._localeDate).longDateFormat('L');
        this._momentTimeFormat = moment.localeData(this._localeDate).longDateFormat('LT');
        this._momentDateTimeFormat = `${this._momentFormat} ${this._momentTimeFormat}`;

        this._currency = configuration.userData.currencySymbol;
        moment.locale(this._localeDate);
        moment.updateLocale(this._localeDate, {
            week: {
                dow: 1,
            },
        });
        numeral.locale(this._localeDecimalFormat);
    }

    getNowMoment(format) {
        format = format || this._momentDateTimeFormat;
        return moment().format(format);
    }

    formatStringDate(element) {
        return this.getStringChangeFormat(element, 'DD/MM/YYYY hh:mm:ss', 'L');
    }

    formatStringToDate(element, format) {
        format = format || 'YYYY-MM-DD';
        return moment(element, format)._d;
    }

    formatStringToDate(element) {
        return this.getStringChangeFormat(date, 'DD/MM/YYYY hh:mm:ss', 'L');
    }

    formatDateToString(date) {
        const value = moment(date).locale(this._localeDate).format('L');
        return value === 'Invalid date' ? '-' : value;
    }

    getHourFromMillis(date) {
        return this.getStringChangeFormat(date, 'DD/MM/YYYY hh:mm:ss', 'HH:mm');
    }

    getStringToFormat(date) {
        return this.getStringChangeFormat(date, 'DD/MM/YYYY hh:mm:ss', 'LL');
    }

    getDurationDiffFromNow(date) {
        const end = moment();
        const diff = moment.duration(moment().diff(moment(date, 'DD/MM/YYYY hh:mm:ss')));
        if (diff.asSeconds() > 0 && diff.asSeconds() <= 59) {
            return `${Math.floor(diff.asSeconds())} segundos`;
        }
        if (diff.asMinutes() > 0 && diff.asMinutes() <= 59) {
            return `${Math.floor(diff.asMinutes())} minutos`;
        }
        if (diff.asHours() > 0 && diff.asHours() <= 24) {
            return `${Math.floor(diff.asHours())} horas`;
        }
        if (diff.asDays() > 0 && diff.asDays() <= 7) {
            return `${Math.floor(diff.asDays())} dias`;
        }
        if (diff.asWeeks() > 1 && diff.asWeeks() <= 4) {
            return `${Math.floor(diff.asWeeks())} semanas`;
        }
        if (diff.asMonths()) {
            return `${Math.floor(diff.asMonths())} meses`;
        }
    }

    formatDateToStringServer(date, format) {
        format = format || 'DD/MM/YYYY HH:mm';
        const value = moment(date).format(format);
        return value === 'Invalid date' ? '-' : value;
    }

    // method copied to utils/dates, without using .locale part
    getStringChangeFormat(date, formatIn, formatOut) {
        const value = moment(date, formatIn).locale(this._localeDate).format(formatOut);
        return value === 'Invalid date' ? '-' : value;
    }

    getDatefromString(date) {
        // return moment(date)._d;
        return moment(date, this._momentFormat).toDate();
    }

    getStringfromDate(date, format = 'L') {
        const value = moment(date, format).locale(this._localeDate).format('L');
        return value;
    }

    // method copied to utils/dates to join all the staff related to dates there
    getSectionTime(create) {
        const day = this.getDifferentDay(create);
        if (day === 0) {
            return 'common_today';
        }
        if (day < 0 && day >= -1) {
            return 'common_yesterday';
        }
        if (day < -1 && day >= -7) {
            return 'common_this_week';
        }
        if (day < -7 && day > -31) {
            return 'common_this_month';
        }
        if (day <= -31) {
            return 'label_over_month';
        }
        return null;
    }

    getSectionTimeToday(create) {
        const duration = moment.duration(moment().diff(moment(create)));
        let hours = duration.hours();
        let minutes = duration.minutes();
        if (hours && minutes > 30) {
            hours++;
            minutes = 0;
        }

        let timetoEvent;
        if (hours > 0) {
            if (hours === 1) timetoEvent = { literal: 'placeholder_elapsed_one_hour_ago' };
            else timetoEvent = { literal: 'placeholder_elapsed_hours_ago', value: hours };
        } else if (minutes > 0) {
            if (minutes === 1) timetoEvent = { literal: 'placeholder_elapsed_one_minute_ago' };
            else timetoEvent = { literal: 'placeholder_elapsed_minutes_ago', value: minutes };
        } else {
            timetoEvent = { literal: 'placeholder_elapsed_less_than_minute' };
        }
        return timetoEvent;
    }

    getDateParts = (dateStr) => {
        const parts = {};
        const date = moment(dateStr);
        parts.dayOfWeekLiteral = this.getDayOfWeekLiteral(date.day());
        parts.monthLiteral = this.getMonthLiteral(date.month());
        parts.dayOfMonth = date.format('DD');
        parts.year = date.format('YYYY');
        parts.isToday = date.isSame(moment(), 'day');
        parts.isYesterday = date.isSame(moment().subtract(1, 'days').startOf('day'), 'day');
        return parts;
    };

    getDayOfWeekLiteral(day) {
        return [
            'common_sunday',
            'common_monday',
            'common_tuesday',
            'common_wednesday',
            'common_thursday',
            'common_friday',
            'common_saturday',
        ][day];
    }

    getMonthLiteral(month) {
        return [
            'common_january',
            'common_february',
            'common_march',
            'common_april',
            'common_may',
            'common_june',
            'common_july',
            'common_august',
            'common_september',
            'common_october',
            'common_november',
            'common_december',
        ][month];
    }

    getSectionTimeSalesOrder(create) {
        const day = this.getDifferentDay(create);
        if (day === 0) {
            return 'common_today';
        }
        if (day < 0) {
            return 'placeholder_expired_female';
        }
        if (day > 0 && day <= 1) {
            return '_Mañana';
        }
        if (day > 1 && day <= 5) {
            return '_Esta semana';
        }
        if (day > 5 && day <= 11) {
            return '_La próxima semana';
        }
        if (day >= 6 && day < 31) {
            return '_Este mes';
        }
        if (day >= 31) {
            return '_Próximos meses';
        }
        return null;
    }

    // method copied to utils/dates to join all the staff related to dates there
    getDifferentDay(create) {
        if (moment(create, 'DD/MM/YYYY').isValid()) {
            return moment(create, 'DD/MM/YYYY hh:mm:ss')
                .startOf('day')
                .diff(moment().startOf('day'), 'days');
        }
        return null;
    }

    getCurrencyFormat(symbolCurrency, price) {
        return symbolCurrency ? `${price} ${symbolCurrency}` : price;
    }

    getCurrencyFormatLocale(currencySymbol, amount) {
        amount = this.formatNumberWithSeparator(this.getDecimalFormat(amount));
        return currencySymbol &&
            (this._localeDecimalFormat === 'en-US' ||
                this._localeDecimalFormat === 'en-GB' ||
                this._localeDecimalFormat === 'th-TH')
            ? `${currencySymbol} ${amount}`
            : `${amount} ${currencySymbol}`;
    }

    formatCurrency(text, currencyAux) {
        text = this.formatNumberWithSeparator(this.getDecimalFormat(text));
        return `${text} ${currencyAux || this._currency}`;
    }

    getDecimalFormat(text) {
        if (typeof text === 'string') {
            text = Number(text.replace(',', '.'));
        }
        const number = !isNaN(parseFloat(text)) ? numeral(parseFloat(text.toFixed(2))) : numeral(0);
        let format = '0.00';
        switch (this._locale) {
            case 'es':
                format = '0.00';
                break;
        }
        return number.format(format);
    }

    getPercentageFormat(text) {
        // That's how the value is displayed whenever you are not inside of the input
        if (typeof text === 'string') {
            text = !text ? 0 : Number(text.replace(',', '.'));
        }
        const number = !isNaN(parseFloat(text)) ? numeral(parseFloat(text.toFixed(2))) : numeral(0);

        const locale = this._localeDecimalFormat;
        if (locale === 'es-ES') {
            number._value = `${number._value}`.replace('.', ',');
        }
        return `${number._value}%`;
    }

    getNumberFormat(text) {
        const number = !isNaN(Number(text)) ? numeral(Number(text)) : numeral(0);
        return number.value();
    }

    getDateFormatLocale() {
        return this._momentFormat;
    }

    getDateTimeFormatLocale() {
        return this._momentDateTimeFormat;
    }

    getTimeFormatLocale() {
        return this._momentTimeFormat;
    }

    unescapeHTML(value) {
        if (typeof value === 'string' || value instanceof String) {
            let escapedValue = value;
            escapedValue = escapedValue.replace(/&amp;/g, '&');
            escapedValue = escapedValue.replace(/&quot;/g, '"');
            escapedValue = escapedValue.replace(/&apos;/g, "'");
            escapedValue = escapedValue.replace(/&lt;/g, '<');
            escapedValue = escapedValue.replace(/&gt;/g, '>');
            return escapedValue;
        }
        return value;
    }

    escapeHTML(value) {
        if (typeof value === 'string' || value instanceof String) {
            let escapedValue = value;
            escapedValue = escapedValue.replace(/&/g, '&amp;');
            escapedValue = escapedValue.replace(/\"/g, '&quot;');
            escapedValue = escapedValue.replace(/'/g, '&apos;');
            escapedValue = escapedValue.replace(/</g, '&lt;');
            escapedValue = escapedValue.replace(/>/g, '&gt;');
            return escapedValue;
        }
        return value;
    }

    getLocaleDecimalFormat(text) {
        // Thats when you are inside of the input
        const lastChar = text.slice(-1);
        // const isDecimal = lastChar === "," || lastChar === ".";
        let numberFormat = `${text.replace(',', '.')}`.replace(/[^-.,0-9\s]/g, '');
        const number = Number(numberFormat);
        numberFormat = number <= 100 ? numberFormat : '100';
        if (
            this._localeDecimalFormat === 'en-US' ||
            this._localeDecimalFormat === 'en-GB' ||
            this._localeDecimalFormat === 'th-TH'
        ) {
            numberFormat = numberFormat.replace(',', '.');
        } else {
            numberFormat = numberFormat.replace('.', ',');
        }
        return numberFormat;
    }

    getLocaleDecimalFormatPlannings(text) {
        // Thats when you are inside of the input
        const lastChar = text.slice(-1);
        // const isDecimal = lastChar === "," || lastChar === ".";
        let numberFormat = `${text.replace(',', '.')}`.replace(/[^-.,0-9\s]/g, '');
        const number = Number(numberFormat);
        if (
            this._localeDecimalFormat === 'en-US' ||
            this._localeDecimalFormat === 'en-GB' ||
            this._localeDecimalFormat === 'th-TH'
        ) {
            numberFormat = numberFormat.replace(',', '.');
        } else {
            numberFormat = numberFormat.replace('.', ',');
        }
        return numberFormat;
    }

    getServerDecimalFormat(text) {
        let numberFormat = `${text}`.replace(/[^-.,0-9\s]/g, '');
        numberFormat = numberFormat.replace(',', '.');
        return numberFormat;
    }

    getLocaleDecimalNumberFormat(number) {
        // for constructors of models, created for the discounts :-)
        if (number && !isNaN(number)) {
            number = number.toString();
            if (
                this._localeDecimalFormat === 'en-US' ||
                this._localeDecimalFormat === 'en-GB' ||
                this._localeDecimalFormat === 'th-TH'
            ) {
                number = number.replace(',', '.');
            } else {
                number = number.replace('.', ',');
            }
            return number;
        }
        return number;
    }

    formatAmountCurrency(amount, existCurrency) {
        // 3436219.60€
        let number;
        if (existCurrency) {
            number = Number(amount);
            number = Math.round(number * 100) / 100; // TWO DECIMALS
            // return this.shortenLargeNumber(number,2)+" "+existCurrency;
            return `${this.formatLargeNumber(number)} ${existCurrency}`;
        }
        let currency = `${amount}`.slice(-1);
        if (isNaN(Number(currency))) {
            number = `${amount}`.trim().slice(0, -1);
        } else {
            currency = this._currency;
            number = `${amount}`.trim();
        }
        if (number === 'null') {
            return `${0} ${currency}`;
        }
        number = Number(number);
        number = Math.round(number * 100) / 100; // TWO DECIMALS
        // return this.shortenLargeNumber(number,2)+" "+currency;
        return `${this.formatLargeNumber(number)} ${currency}`;
    }

    shortenLargeNumber(num, digits) {
        const units = ['K', 'M', 'B'];
        let decimal;

        for (let i = units.length - 1; i >= 0; i--) {
            decimal = Math.pow(1000, i + 1);

            if (num <= -decimal || num >= decimal) {
                return +(num / decimal).toFixed(digits) + units[i];
            }
        }

        return num;
    }

    formatLargeNumber(number) {
        if (isNaN(number)) return number;
        number = parseFloat(number);
        const isNegative = number < 0;
        number = Math.abs(number);
        if (number <= 9999) return number;

        let digits = 0;
        if (number >= 10000 && number < 100000) {
            digits = 1;
        } else if (number >= 100000 && number < 1000000) {
            digits = 0;
        } else if (number >= 1000000 && number < 100000000) {
            digits = 1;
        }

        const units = ['K', 'M', 'B'];
        let decimal;

        let unitsIndex = units.length - 1;

        let finalValue = '';
        for (let i = units.length - 1; i >= 0; i--) {
            decimal = Math.pow(1000, i + 1);

            if (number <= -decimal || number >= decimal) {
                unitsIndex = i;
                finalValue = (number / decimal).toFixed(digits);

                // 999500 will be 999.5k, but when rounded, it's 1000, so we need the next letter
                // similar thing happen in others ranges
                if (finalValue === '1000') {
                    if (finalValue * decimal === 1000000) digits = 1;
                    unitsIndex++;
                    finalValue = (finalValue / 1000).toFixed(digits);
                } else if (finalValue === '100.0' && unitsIndex === 1) {
                    if (finalValue * decimal === 100000000) digits = 0;
                    finalValue = (number / decimal).toFixed(digits);
                    // unitsIndex++;
                    // finalValue = ((finalValue * decimal) / (Math.pow(1000, unitsIndex))).toFixed(digits);
                }
                break;
            }
        }

        if (isNegative) finalValue *= -1;
        finalValue = this.getLocaleDecimalNumberFormat(finalValue);

        return finalValue + units[unitsIndex];
    }

    isOnDate(startDateString, endDateString) {
        if (
            (startDateString === null && endDateString === null) ||
            endDateString === '01/01/1900 00:00:00'
        ) {
            return true;
        }
        const startDateNum = this.getDifferentDay(startDateString);
        const endDateNum = this.getDifferentDay(endDateString);
        return startDateNum <= 0 && endDateNum >= 0;
    }

    formatTimeStampDateLocal(strDate) {
        return moment(strDate, 'DD/MM/YYYY HH:mm:ss').format(this._momentFormat);
    }

    formatTimeStampDateTimeLocal(strDate) {
        return moment(strDate, 'DD/MM/YYYY HH:mm:ss').format(this._momentDateTimeFormat);
    }

    formatTimeStampTimeLocal(strDate) {
        return moment(strDate, 'DD/MM/YYYY HH:mm:ss').format(this._momentDateTimeFormat);
    }

    // Method used to generate a file download
    saveAs(uri, filename) {
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link); // Firefox requires the link to be in the body
            link.download = filename;
            link.href = uri;
            link.click();
            document.body.removeChild(link); // remove the link when done
        } else {
            location.replace(uri);
        }
    }

    formatStringByInputFormat(inputDate, inputFormat, withTime) {
        if (inputDate) {
            const format = withTime
                ? this.getDateTimeFormatLocale()
                : this.getDateTimeFormatLocale();
            return moment(inputDate, inputFormat).format(format);
        }
        return '';
    }

    formatFileSize(fileSize) {
        if (isNaN(fileSize)) {
            return;
        }
        fileSize = parseFloat(fileSize);
        const units = ['B', 'KB', 'MB'];
        const unitsDecimals = {
            B: 0,
            KB: 0,
            MB: 1,
        };
        let size = fileSize;

        let indexUnits = 0;
        while (size > 1024 && indexUnits < 2) {
            size /= 1024;
            indexUnits++;
        }
        const decimals = units?.[indexUnits] ? unitsDecimals[units[indexUnits]] : 0;
        size = size.toFixed(decimals);
        return `${size} ${units[indexUnits]}`;
    }

    mbToBytes(mb) {
        if (isNaN(mb)) {
            console.error('mb must be a number');
            return 0;
        }
        return mb * 1024 * 1024;
    }

    formatNumberWithSeparator(number) {
        let parts;
        let formatedNumber;
        if (
            this._localeDecimalFormat === 'en-US' ||
            this._localeDecimalFormat === 'en-GB' ||
            this._localeDecimalFormat === 'th-TH'
        ) {
            parts = number.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            formatedNumber = parts.join('.');
        } else {
            parts = number.toString().split(',');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            formatedNumber = parts.join(',');
        }
        return formatedNumber;
    }

    // method copied to utils/dates, without using .locale part
    formatDateHourToday(date, formatIn, formatOut) {
        formatIn = formatIn || 'DD/MM/YYYY hh:mm:ss';
        formatOut = formatOut || 'L';
        if (moment(date, formatIn).isValid() && this.getDifferentDay(date) === 0) {
            return moment(date, formatIn).locale(this._localeDate).format('LT');
        }
        return this.getStringChangeFormat(date, formatIn, formatOut);
    }

    isEmptyObject(obj) {
        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // if is a number as is really a number return false
        if (typeof obj === 'number' && !isNaN(obj)) return false;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== 'object') return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }

        return true;
    }

    formatGenericField({ value, type }) {
        let result;
        if (!value || !type) return value;
        if (type === 'text') {
            result = String(value);
        } else if (type === 'dateTime') {
            result = this.formatDateHourToday(
                value,
                'DD/MM/YYYY HH:mm:ss',
                this.getDateTimeFormatLocale(),
            );
        } else if (type === 'date') {
            result = this.formatDateHourToday(
                value,
                'DD/MM/YYYY HH:mm:ss',
                this.getDateFormatLocale(),
            );
        } else if (type === 'currency') {
            result = this.formatAmountCurrency(value);
        } else if (type === 'float') {
            result = this.getDecimalFormat(value);
        } else {
            result = value;
        }

        return result;
    }

    static addAddressField(address, field, commaSeparator = true) {
        if (field && field.trim().length > 0) {
            if (address && address.length > 0) {
                if (commaSeparator) address += ', ';
                else address += ' ';
            }
            address += field;
        }
        return address;
    }

    getDistanceWithUnit(distanceInMeters) {
        if (!isNaN(parseFloat(distanceInMeters)) && isFinite(distanceInMeters)) {
            if (this._lengthSystem === 'imperial') {
                if (distanceInMeters <= 1609) {
                    return `${Math.floor(distanceInMeters * 1.09361).toString()}yd.`;
                }
                return `${Math.floor(distanceInMeters * 0.000621371).toString()}mi.`;
            }
            if (distanceInMeters <= 1000) {
                return `${distanceInMeters}m.`;
            }
            return `${Math.floor(distanceInMeters * 0.001).toString()}km.`;
        }
        return null;
    }
}

const StoreSingleton = (function () {
    let instance;

    function createInstance() {
        const object = new UtilFormat();
        return object;
    }

    return {
        getInstance() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default StoreSingleton.getInstance();

import React, { memo, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions, KpisActions } from 'actions';
import { Conditions } from '@web/web5';
import {
    getEmptyConditions,
    mapConditions,
    mapFieldsValues,
    mapToJsonLogic,
} from '@web/web5/dist/components/Conditions';
import { EntitiesDb } from 'constants/Entities';
import { isEmptyObject } from 'utils/objects';
import { findFieldConfiguration } from 'utils/fm';

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const ConditionsField = memo(
    ({
        conditions,
        fields,
        listMapFields,
        onChange,
        isReadOnly,
        entity,
        kpiId,
        filterListByKpi,
        getList,
    }) => {
        const conditionsRef = useRef(conditions);
        const [stateConditions, setStateConditions] = useState(null);
        const [selectedKpiId, setSelectedKpiId] = useState(kpiId);

        const getListCallback = useCallback(
            (field) => {
                if (field === 'null' || !entity) return Promise.resolve();
                const entityConstant = EntitiesDb[entity.toLowerCase()];
                if (!entityConstant) return Promise.resolve();
                const { configField } = findFieldConfiguration(field, entityConstant);
                if (!configField || !configField.valueListName) return Promise.resolve();
                return getList(configField.valueListName);
            },
            [entity, getList],
        );

        const handleOnChange = useCallback(
            (conditions) => {
                setStateConditions(conditions);
                onChange && onChange(conditions);
            },
            [onChange],
        );

        const options = useMemo(() => {
            return {
                listOptions: {
                    customGetList: (list, field) =>
                        new Promise((resolve, reject) => {
                            getList(list)
                                .then((data) => {
                                    const options = filterListByKpi(data, list, field).reduce(
                                        (obj, option) => {
                                            obj.push({
                                                ...option,
                                                description: option.label,
                                                id: parseInt(option.value, 10),
                                            });
                                            return obj;
                                        },
                                        [],
                                    );
                                    resolve({ data: options });
                                })
                                .catch(reject);
                        }),
                },
            };
        }, [filterListByKpi, getList]);

        useEffect(() => {
            if (kpiId !== selectedKpiId) {
                setStateConditions(getEmptyConditions());
            }
        }, [kpiId, selectedKpiId]);

        useEffect(() => {
            const mapValues = mapFieldsValues(conditionsRef.current);
            Promise.all(mapValues.map((field) => getListCallback(Object.keys(field)[0])))
                .then((result) => {
                    const listMap = mapValues.reduce((obj, field, index) => {
                        const key = Object.keys(field)[0];
                        const value = field[key];
                        if (result[index]) {
                            const valueListValue = result[index].find(
                                (valueListValue) => parseInt(valueListValue.value, 10) === value,
                            );
                            if (valueListValue) {
                                return {
                                    ...obj,
                                    [key]: {
                                        ...obj[key],
                                        [value]: valueListValue.label,
                                    },
                                };
                            }
                        }
                        return obj;
                    }, {});
                    if (listMap && !isEmptyObject(listMap)) {
                        setStateConditions(
                            mapConditions(mapToJsonLogic(conditionsRef.current), listMap),
                        );
                    } else {
                        setStateConditions(conditionsRef.current);
                    }
                })
                .catch(console.error);
        }, [getListCallback]);

        return (
            <Conditions
                conditions={stateConditions || getEmptyConditions()}
                fields={fields}
                listMapFields={listMapFields}
                onChange={handleOnChange}
                isReadOnly={isReadOnly}
                options={options}
            />
        );
    },
);

export default connect(null, mapDispatchToProps)(ConditionsField);

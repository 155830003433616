import React, { memo, useCallback, useMemo, useState, useRef, Fragment, useEffect } from 'react';
import { Link, Button, SearchBar } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import Context from 'managers/Context';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import { SmallArrow } from 'components/SvgIcons';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import ProductLinesTable from './ProductLinesTable';
import DiscountRow from './DiscountRow';
import AddProductsModal from './AddProductsModal';
import { SALESORDERS } from 'constants/Entities';
import SearchRow from 'components/SearchRow';
import { getNumberAsCurrency } from 'utils/currency';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        standardFieldsSchemaSalesOrders:
            state.config?.standardFieldsSchemaMap?.[SALESORDERS.extraFieldName],
        quoteProductByEnvironment: state?.config?.permission?.quoteProductByEnvironment,
    };
};

const ProductLines = memo(
    ({
        changeField,
        data,
        errors,
        field,
        getError,
        standardFieldsSchemaSalesOrders,
        quoteProductByEnvironment,
    }) => {
        const tableRef = useRef(null);
        const widgetRef = useRef(null);
        const innerProductsSearch = useRef(null);
        const addProductsModalRef = useRef(null);

        const [showDiscounts, setShowDiscounts] = useState(false);
        const [checkedProducts, setCheckedProducts] = useState([]);
        const initialDiscountsShow = useRef({
            discount1: !!data.discount1,
            discount2: !!data.discount2,
            discount3: !!data.discount3,
            discount4: !!data.discount4,
        });
        const [numOfDiscounts, setNumOfDiscounts] = useState(() => {
            if (initialDiscountsShow.current.discount4) return 4;
            if (initialDiscountsShow.current.discount3) return 3;
            if (initialDiscountsShow.current.discount2) return 2;
            if (initialDiscountsShow.current.discount1) return 1;
            return 0;
        });

        useEffect(() => {
            if (errors.products) widgetRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, [errors]);

        const openAddProductModal = useCallback(() => {
            addProductsModalRef.current.open();
        }, []);

        const onAddProduct = useCallback(
            (product, quantity) => {
                changeField('product')({ product, quantity });
            },
            [changeField],
        );

        const onAddProducts = useCallback(
            (products) => {
                changeField('product')(products);
            },
            [changeField],
        );

        const subtotalClassName = useMemo(() => {
            let classNames = ['sales-order-product-lines-widget__content__subtotal'];
            if (showDiscounts)
                classNames.push(
                    'sales-order-product-lines-widget__content__subtotal--show-discounts',
                );
            return classNames.join(' ');
        }, [showDiscounts]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const tableErrors = useMemo(() => {
            if (!errors.products || Object.keys(errors.products).length === 0) return null;
            const humanErrors = Object.keys(errors.products).reduce((newErrors, errorKey) => {
                newErrors[errorKey] = {
                    rows:
                        Object.keys(errors.products[errorKey]?.rows).reduce((errorRow, rowKey) => {
                            const row = errors.products[errorKey]?.rows[rowKey];
                            errorRow[rowKey] = {
                                error: getError(row.error),
                                colId: row.colId,
                            };
                            return errorRow;
                        }, {}) || {},
                };
                return newErrors;
            }, {});
            return humanErrors;
        }, [errors, getError]);

        const handleSetCheckedProducts = useCallback(
            (checkedRows) => {
                setCheckedProducts(checkedRows);
            },
            [setCheckedProducts],
        );

        const tableContainer = useMemo(() => {
            return (
                <div className="sales-order-product-lines__crud-widget__content__table">
                    <ProductLinesTable
                        data={data.products}
                        handleOnRef={handleOnRef}
                        tableRef={tableRef}
                        crudSchema={field.schema}
                        errors={tableErrors}
                        changeField={changeField}
                        handleSetCheckedProducts={handleSetCheckedProducts}
                    />
                </div>
            );
        }, [data, handleOnRef, field, tableErrors, changeField, handleSetCheckedProducts]);

        const toggleDiscounts = useCallback(() => {
            setShowDiscounts(!showDiscounts);
        }, [showDiscounts]);

        const addDiscount = useCallback(() => {
            setNumOfDiscounts(numOfDiscounts + 1);
        }, [numOfDiscounts]);

        const onDeleteProductLines = useCallback(() => {
            changeField('removeProducts')(checkedProducts);
            setCheckedProducts([]);
            if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
        }, [checkedProducts, changeField]);

        const loadProductOptions = useCallback(
            (searchText) => {
                return Context.entityManager
                    .getEntitiesManager(SALESORDERS)
                    .getSalesOrdersProductLines(
                        '-1',
                        searchText,
                        data.idRate || '-1',
                        data.idCompany?.value || '-1',
                        quoteProductByEnvironment ? data.idBranch || '-1' : '-1',
                    )
                    .then((results) => {
                        innerProductsSearch.current = [];
                        return results.reduce((obj, result) => {
                            result?.products?.forEach((product) => {
                                if (product.isProductFolder) return obj;
                                innerProductsSearch.current.push(product);
                                obj.push({
                                    label: product.name,
                                    value: product.id,
                                    description: product.description,
                                    price: getNumberAsCurrency(
                                        product.finalprice,
                                        data?.currencySymbol,
                                        true,
                                    ),
                                });
                            });
                            return obj;
                        }, []);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            [data],
        );

        const onClickProduct = useCallback(
            (value) => {
                const product = innerProductsSearch.current?.find((p) => p.id === value);
                onAddProduct(product, 1);
                logEvent({
                    event: SALESORDERS.trueName,
                    functionality: 'productQuickEntry',
                });
            },
            [onAddProduct],
        );

        const customProductOption = useCallback(
            (item, getMatchingCharacters) => {
                if (!item.label || !item.value) return;

                const subtitles = Object.keys(item).reduce((arr, key) => {
                    if (key !== 'label' && key !== 'value') {
                        arr.push(item[key]);
                    }
                    return arr;
                }, []);

                return (
                    <SearchRow
                        className="fm-row-search__products"
                        onClick={() => onClickProduct(item.value)}
                        title={item.label}
                        subtitles={subtitles}
                        getMatchingCharacters={getMatchingCharacters}
                    />
                );
            },
            [onClickProduct],
        );

        const handleProductsModalOnRef = useCallback((ref) => {
            addProductsModalRef.current = ref;
        }, []);

        return (
            <WidgetLayout
                className="sales-order-product-lines__crud-widget"
                isDynamic={true}
                type="generalInfo"
                data={data}
            >
                <WidgetHeaderLayout>
                    <div ref={widgetRef} className="sales-order-product-lines__crud-widget__header">
                        <div className="sales-order-product-lines__crud-widget__header__title">
                            {checkedProducts.length === 0 && (
                                <Fragment>
                                    {getLiteral('common_lines_saleorders')}
                                    {data.products && (
                                        <span className="sales-order-product-lines__crud-widget__header__counter">
                                            ({data.products?.length || 0})
                                        </span>
                                    )}
                                </Fragment>
                            )}
                            {checkedProducts.length > 0 && (
                                <Fragment>
                                    <div className="sales-orders-product-lines__crud-widget__header__title-delete-number">
                                        {getLiteralWithParameters('label_lines_selected', [
                                            checkedProducts.length,
                                        ])}
                                    </div>
                                    <Button
                                        size="small"
                                        color="primary"
                                        type="secondary"
                                        onClick={onDeleteProductLines}
                                    >
                                        {getLiteral('action_delete')}
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                        <div className="sales-order-product-lines__crud-widget__header__actions">
                            <Button
                                size="small"
                                color="primary"
                                type="secondary"
                                onClick={openAddProductModal}
                            >
                                {getLiteral('label_add_lines')}
                            </Button>
                        </div>
                    </div>
                </WidgetHeaderLayout>
                <WidgetContentLayout>
                    <div className="sales-order-product-lines__crud-widget__content">
                        {tableContainer}
                        <div className="sales-order-product-lines__product-fuzzy">
                            <SearchBar
                                placeholder={getLiteral('placeholder_add_product')}
                                loadOptions={loadProductOptions}
                                customOption={customProductOption}
                                actions={[
                                    {
                                        label: getLiteral('label_see_all_products'),
                                        onClick: openAddProductModal,
                                    },
                                ]}
                                defaultSearch=""
                                size="small"
                                isFuzzy
                            />
                        </div>
                        <div className="sales-order-product-lines__crud-widget__footer">
                            <div className={subtotalClassName}>
                                <div className="sales-order-product-lines__crud-widget__content__subtotal__label">
                                    {getLiteral('label_subtotal')}
                                </div>
                                <div className="sales-order-product-lines__crud-widget__content__subtotal__value">
                                    {data.subtotal}
                                </div>
                                <div
                                    className="sales-order-product-lines__crud-widget__content__subtotal__chevron"
                                    onClick={toggleDiscounts}
                                >
                                    <SmallArrow />
                                </div>
                            </div>
                            {showDiscounts && (
                                <div className="sales-order-product-lines__crud-widget__content__discounts">
                                    {numOfDiscounts >= 1 && (
                                        <DiscountRow
                                            label="label_salesorder_discount1"
                                            value={data.discount1}
                                            onChange={changeField('discount1')}
                                            isReadOnly={
                                                standardFieldsSchemaSalesOrders.discount1.readOnly
                                            }
                                        />
                                    )}
                                    {numOfDiscounts >= 2 && (
                                        <DiscountRow
                                            label="label_salesorder_discount2"
                                            value={data.discount2}
                                            onChange={changeField('discount2')}
                                            isReadOnly={
                                                standardFieldsSchemaSalesOrders.discount2.readOnly
                                            }
                                        />
                                    )}
                                    {numOfDiscounts >= 3 && (
                                        <DiscountRow
                                            label="label_salesorder_discount3"
                                            value={data.discount3}
                                            onChange={changeField('discount3')}
                                            isReadOnly={
                                                standardFieldsSchemaSalesOrders.discount3.readOnly
                                            }
                                        />
                                    )}
                                    {numOfDiscounts >= 4 && (
                                        <DiscountRow
                                            label="label_salesorder_discount4"
                                            value={data.discount4}
                                            onChange={changeField('discount4')}
                                            isReadOnly={
                                                standardFieldsSchemaSalesOrders.discount4.readOnly
                                            }
                                        />
                                    )}
                                    {numOfDiscounts < 4 && (
                                        <Link
                                            className="sales-order-product-lines__crud-widget__content__discounts__add-discount"
                                            type="caption"
                                            onClick={addDiscount}
                                        >
                                            {getLiteral('action_add_discount')}
                                        </Link>
                                    )}
                                </div>
                            )}
                            <div className="sales-order-product-lines__crud-widget__content__total">
                                <div className="sales-order-product-lines__crud-widget__content__total__label">
                                    {getLiteral('label_total')}
                                </div>
                                <div className="sales-order-product-lines__crud-widget__content__total__value">
                                    {data.total}
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddProductsModal
                        onRef={handleProductsModalOnRef}
                        idRate={data.idRate || '-1'}
                        idCompany={data.idCompany?.value || '-1'}
                        idEntorno={quoteProductByEnvironment ? data.idBranch || '-1' : '-1'}
                        currencySymbol={data.currencySymbol}
                        onAddProducts={onAddProducts}
                    />
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

export default connect(mapStateToProps)(ProductLines);

export default ({ text, color }) => ({
    title: {
        ...text.text_md,
        marginRight: 16,
        width: '150px',
        display: 'inline-block',
        marginTop: '16px',
        boxSizing: 'border-box',
    },
    inputStyle: {
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    fieldContainer: {
        minHeight: '44px',
        margin: '0 20px',
    },
});

export const EntityTypes = {};
export const Entities = {};
export const EntitiesTrueName = {};
export const EntitiesAllProps = [];
export const EntitiesDb = {}; // use it getting the match using lower case text

const configEntity = ({
    trueName,
    entity,
    route,
    redux,
    customField,
    extraFieldName,
    extraFieldTab,
    views,
    permission,
    entityDocuments,
    objectTypeId,
    icon,
    useNewApi,
    endpoint,
    exportEntity,
    workFlow,
    duplicate,
    updateAsMassive,
    dbName,
    withoutRedirect,
    countName,
    checkDuplicates,
    withCustomizeLinkOnLists,
    addExtraFieldsButtonOnCrud,
    generalPermission,
    importExport,
}) => {
    const e = {
        trueName,
        entity,
        route,
        redux,
        customField,
        extraFieldName,
        extraFieldTab,
        views,
        permission,
        entityDocuments,
        objectTypeId,
        icon,
        useNewApi,
        endpoint,
        exportEntity,
        workFlow,
        duplicate,
        updateAsMassive,
        dbName,
        withoutRedirect,
        countName,
        checkDuplicates,
        withCustomizeLinkOnLists,
        addExtraFieldsButtonOnCrud,
        route,
        generalPermission,
        importExport,
    };
    if (objectTypeId) EntityTypes[objectTypeId] = e;
    if (dbName) EntitiesDb[dbName.toLowerCase()] = e;
    if (entity) {
        const values = Object.keys(e).map((key) => e[key]);
        EntitiesAllProps.push({
            values,
            entity: e,
        });
        if (entity === 'salesOrders' && customField === 'tblsalesorderslines') {
            Entities.salesOrdersLines = e;
            EntitiesTrueName.salesOrdersLines = e;
        } else {
            Entities[entity] = e;
            EntitiesTrueName[trueName] = e;
        }
    }
    return e;
};

export const COMPANIES = configEntity({
    trueName: 'accounts',
    entity: 'companies',
    route: '/companies',
    redux: 'company',
    customField: 'empresa',
    extraFieldName: 'EMPRESA',
    extraFieldTab: 'Empresas',
    views: 'ACCOUNT',
    permission: 'EMPRESA',
    entityDocuments: 'mnEmpresa',
    objectTypeId: 2,
    icon: 'entity-icon icon-accounts',
    useNewApi: null,
    endpoint: null,
    exportEntity: 'empresa',
    workFlow: 'accounts',
    duplicate: 'accounts',
    updateAsMassive: false,
    dbName: 'Empresas',
    countName: 'empresa',
    checkDuplicates: true,
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
    generalPermission: 'empresas',
    importExport: 'accounts',
});

export const CONTACTS = configEntity({
    trueName: 'contacts',
    entity: 'contacts',
    route: '/contacts',
    redux: 'contact',
    customField: 'contacto',
    extraFieldName: 'CONTACTO',
    extraFieldTab: 'Contactos',
    views: 'CONTACT',
    permission: 'CONTACTO',
    entityDocuments: 'mnContacto',
    objectTypeId: 3,
    icon: 'entity-icon icon-placeholder-user',
    useNewApi: null,
    endpoint: null,
    exportEntity: 'contacto',
    workFlow: 'contacts',
    duplicate: 'contacts',
    updateAsMassive: false,
    dbName: 'Contactos',
    countName: 'contacto',
    checkDuplicates: true,
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
    generalPermission: 'contactos',
    importExport: 'contacts',
});

export const OPPORTUNITIES = configEntity({
    trueName: 'opportunities',
    entity: 'opportunities',
    route: '/opportunities',
    redux: 'opportunity',
    customField: 'expediente',
    extraFieldName: 'EXPEDIENTE',
    extraFieldTab: 'Expedientes',
    views: 'OPPORTUNITY',
    permission: 'EXPEDIENTE',
    entityDocuments: 'mnExpediente',
    objectTypeId: 1,
    icon: 'entity-icon icon-opportunity',
    useNewApi: null,
    endpoint: null,
    exportEntity: 'expediente',
    workFlow: 'opportunities',
    duplicate: 'opportunities',
    updateAsMassive: false,
    dbName: 'Expedientes',
    countName: 'expediente',
    checkDuplicates: false,
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
    generalPermission: 'expedientes',
    importExport: 'opportunities',
});

export const AGENDA = configEntity({
    trueName: 'calendar',
    entity: 'agenda',
    route: '/agenda',
    redux: 'agenda',
    customField: 'agenda',
    extraFieldName: 'AGENDA',
    extraFieldTab: 'tblAgenda',
    views: 'AGENDA',
    permission: 'AGENDA',
    entityDocuments: null,
    objectTypeId: 10,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'agenda',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'tblAgenda',
    checkDuplicates: false,
    generalPermission: 'calendar',
    importExport: 'tblAgenda',
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
});

export const TASKS = configEntity({
    trueName: 'tasks',
    entity: 'tasks',
    route: '/agenda',
    redux: 'task',
    customField: 'agenda',
    extraFieldName: 'AGENDA',
    extraFieldTab: 'tblAgenda',
    views: '-',
    permission: 'AGENDA',
    entityDocuments: 'tasks',
    objectTypeId: 10,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'agenda',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'tblAgenda',
    countName: 'agenda',
    checkDuplicates: false,
    generalPermission: 'calendar',
    importExport: 'tblAgenda',
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
});

// Why SALES_FORCE?
export const SALES_FORCE = configEntity({
    trueName: 'users',
    entity: 'users',
    route: '/users',
    redux: '_',
    customField: 'usuarios',
    extraFieldName: 'USER',
    extraFieldTab: '-',
    views: '',
    permission: 'USER',
    entityDocuments: 'mnUsuarios',
    objectTypeId: 5,
    icon: 'entity-icon icon-opportunity',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'users',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const USERS = configEntity({
    trueName: 'users',
    entity: 'users',
    route: '/users',
    redux: '_',
    customField: 'usuarios',
    extraFieldName: 'USER',
    extraFieldTab: 'tblUsuarios',
    views: '',
    permission: 'USER',
    entityDocuments: 'mnUsuarios',
    objectTypeId: 5,
    icon: 'entity-icon icon-opportunity',
    useNewApi: null,
    endpoint: 'users/v1',
    exportEntity: null,
    workFlow: 'users',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
    importExport: 'users',
});

export const ACTIVITIES = configEntity({
    trueName: 'activities',
    entity: 'activities',
    route: '/activities',
    redux: 'activity',
    customField: 'gestion',
    extraFieldName: 'GESTION',
    extraFieldTab: 'Gestiones',
    views: 'ACTIVITY',
    permission: 'GESTION',
    entityDocuments: 'mnGestion',
    objectTypeId: 4,
    icon: 'entity-icon icon-annotation',
    useNewApi: null,
    endpoint: null,
    exportEntity: 'actividad',
    workFlow: 'activities',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'Gestiones',
    countName: 'actividad',
    checkDuplicates: false,
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
    generalPermission: 'gestiones',
    importExport: 'Gestiones',
});

export const DOCUMENTS = configEntity({
    trueName: 'documents',
    entity: 'documents',
    route: '/documents',
    redux: 'documents',
    customField: 'documento',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'DOCUMENT',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'documents',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const FOLDER_DOCUMENTS = configEntity({
    trueName: 'folder_documents',
    entity: 'folder_documents',
    route: '/folder-documents',
    redux: 'documents',
    customField: 'documento',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'DOCUMENTLIBRARY',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'folder_documents',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const PRODUCTS = configEntity({
    trueName: 'products',
    entity: 'products',
    route: '/products',
    redux: 'products',
    customField: 'producto',
    extraFieldName: 'PRODUCTO',
    extraFieldTab: 'Productos',
    views: '-',
    permission: 'PRODUCTO',
    entityDocuments: 'mnProducto',
    objectTypeId: 6,
    icon: 'entity-icon icon-product',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'products',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'Productos',
    checkDuplicates: false,
    countName: 'productos',
    generalPermission: 'tarifa',
    importExport: 'Productos',
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
});

export const REPORTS = configEntity({
    trueName: 'reports',
    entity: 'reports',
    route: '/reports',
    redux: 'reports',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'reports',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const NOTIFICATIONS = configEntity({
    trueName: 'notifications',
    entity: 'notifications',
    route: '/notifications',
    redux: 'notifications',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'notifications',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const SALESORDERS = configEntity({
    trueName: 'salesorders',
    entity: 'salesOrders',
    route: '/sales-orders',
    redux: 'salesOrders',
    customField: 'tblSalesOrders',
    extraFieldName: 'SALESORDERS',
    extraFieldTab: 'tblSalesOrders',
    views: 'SALESORDERS',
    permission: 'SALESORDERS',
    entityDocuments: 'mnSalesOrders',
    objectTypeId: 55,
    icon: 'entity-icon icon-salesorders',
    useNewApi: null,
    endpoint: null,
    exportEntity: 'salesOrders',
    workFlow: 'salesOrders',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'tblSalesOrders',
    countName: 'salesorders',
    checkDuplicates: false,
    generalPermission: 'salesOrders',
    importExport: 'tblSalesOrders',
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
});

export const SALESORDERSLINES = configEntity({
    trueName: 'salesordersLines',
    entity: 'salesOrdersLines',
    route: '/sales-orders',
    redux: 'salesOrders',
    customField: 'tblsalesorderslines',
    extraFieldName: 'SALESORDERSLINES',
    extraFieldTab: 'tblSalesOrdersLines',
    views: 'SALESORDERSLINES',
    permission: 'SALESORDERSLINES',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'salesOrders',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'tblSalesOrdersLines',
    checkDuplicates: false,
    withCustomizeLinkOnLists: true,
    addExtraFieldsButtonOnCrud: true,
});

export const RATES = configEntity({
    trueName: 'rates',
    entity: 'rates',
    route: '/rates',
    redux: 'rates',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'RATES',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'rates',
    duplicate: null,
    updateAsMassive: false,
    dbName: 'tbltarifas',
    checkDuplicates: false,
});

export const KPIS = configEntity({
    trueName: 'kpis',
    entity: 'kpis',
    route: '/kpis',
    redux: 'kpis',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'KPIS',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: true,
    endpoint: 'goals/v1',
    exportEntity: null,
    workFlow: 'kpis',
    duplicate: true,
    updateAsMassive: true,
    checkDuplicates: false,
});

export const PHONE_CALL_LOGS = configEntity({
    trueName: 'phoneCalls',
    entity: 'phoneCallLogs',
    route: '/phone-calls',
    redux: 'phoneCallLogs',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'PHONE_CALL_LOGS',
    entityDocuments: 'mnPhoneCallLog',
    objectTypeId: 15,
    icon: 'entity-icon icon-call',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'phoneCallLogs',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const BILLBOARD = configEntity({
    trueName: 'newsmanager',
    entity: 'billboard',
    route: '/news',
    redux: 'billboard',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'BILLBOARD',
    entityDocuments: 'mnBillboard',
    objectTypeId: 59,
    icon: 'entity-icon icon-billboard',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'billboard',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const GESTION = configEntity({
    trueName: '-',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: 'mnGestion',
    objectTypeId: 4,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const MAILS = configEntity({
    trueName: 'mails',
    entity: 'mails',
    route: '-',
    redux: 'mails',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'MAILS',
    entityDocuments: 'mnMail',
    objectTypeId: 7,
    icon: 'entity-icon icon-email',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'mails',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const INTERNET_EMAIL = configEntity({
    trueName: 'internetEmails',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: 'mnInternetEmail',
    objectTypeId: 23,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const VIDEO_CALL_LOG = configEntity({
    trueName: '-',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: 'mnGestion',
    objectTypeId: 4,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const WHATSAPP_ACTIVITY = configEntity({
    trueName: '-',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: null,
    objectTypeId: 14,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const VIDEO_CHECK_IN = configEntity({
    trueName: '-',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: 'mnGestion',
    objectTypeId: 4,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const CUSTOM_ACTIVITY = configEntity({
    trueName: '-',
    entity: '-',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: 'mnCustomActivity',
    objectTypeId: 63,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const FORMS = configEntity({
    trueName: 'forms',
    entity: 'forms',
    route: '-',
    redux: 'forms',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'FORMS',
    entityDocuments: 'mnForms',
    objectTypeId: 57,
    icon: 'entity-icon icon-form',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'forms',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const FORMS_COMPLETED = configEntity({
    trueName: 'formsCompleted',
    entity: 'formsCompleted',
    route: '-',
    redux: 'formsCompleted',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'FORMS_COMPLETED',
    entityDocuments: 'mnFormsCompleted',
    objectTypeId: 58,
    icon: 'entity-icon icon-form-completed',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'formsCompleted',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const CAMPAIGNS = configEntity({
    trueName: 'campaigns',
    entity: 'campaigns',
    route: '/campaigns',
    redux: null,
    customField: null,
    extraFieldName: 'campaigns',
    extraFieldTab: null,
    views: null,
    permission: 'CAMPAIGNS',
    entityDocuments: 'mnCampaigns',
    objectTypeId: null,
    icon: null,
    useNewApi: true,
    endpoint: 'campaigns/v1/campaign',
    exportEntity: 'campaign',
    workFlow: 'campaigns',
    duplicate: null,
    updateAsMassive: false,
    countName: 'campaigns',
    checkDuplicates: false,
});

export const TBLSALES = configEntity({
    trueName: 'sales',
    entity: 'sales',
    route: '-',
    redux: 'sales',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: 'SALES',
    entityDocuments: 'mnSales',
    objectTypeId: 61,
    icon: 'entity-icon icon-sales',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: 'sales',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const VIDEOCALLS = configEntity({
    trueName: 'videoCalls',
    entity: 'videoCalls',
    route: '-',
    redux: 'videoCalls',
    customField: 'videoCalls',
    extraFieldName: 'GESTION',
    extraFieldTab: 'Gestiones',
    views: null,
    permission: 'VIDEOCALLS',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: null,
    duplicate: null,
    updateAsMassive: false,
    dbName: 'VideoCalls',
    checkDuplicates: false,
});

export const ANALYTICS = configEntity({
    trueName: 'analytics',
    entity: 'analytics',
    route: '/analytics',
    redux: null,
    customField: null,
    extraFieldName: null,
    extraFieldTab: null,
    views: null,
    permission: null,
    entityDocuments: null,
    objectTypeId: null,
    icon: 'analytics',
    useNewApi: false,
    endpoint: null,
    exportEntity: null,
    workFlow: null,
    duplicate: null,
    updateAsMassive: false,
    dbName: null,
    checkDuplicates: false,
});

export const ANALYTICS_REPORT = configEntity({
    trueName: 'analytics_report',
    entity: 'analytics_report',
    route: '/metrics',
    redux: null,
    customField: null,
    extraFieldName: null,
    extraFieldTab: null,
    views: null,
    permission: null,
    entityDocuments: null,
    objectTypeId: null,
    icon: 'analytics',
    useNewApi: true,
    endpoint: 'analytics/v1/dashboards',
    exportEntity: null,
    workFlow: null,
    duplicate: null,
    updateAsMassive: false,
    dbName: null,
    withoutRedirect: true,
    checkDuplicates: false,
});

export const ANALYTICS_WIDGET = configEntity({
    trueName: 'analytics_widget',
    entity: 'analytics_widget',
    route: '/analytics',
    redux: null,
    customField: null,
    extraFieldName: null,
    extraFieldTab: null,
    views: null,
    permission: null,
    entityDocuments: null,
    objectTypeId: null,
    icon: 'analytics',
    useNewApi: true,
    endpoint: 'analytics/v1/widgets',
    exportEntity: null,
    workFlow: null,
    duplicate: null,
    updateAsMassive: false,
    dbName: null,
    withoutRedirect: true,
    checkDuplicates: false,
});

export const CONVERSATIONS = configEntity({
    trueName: 'conversations',
    entity: 'conversations',
    route: '/conversations',
    redux: 'conversations',
    customField: null,
    extraFieldName: null,
    extraFieldTab: null,
    views: null,
    permission: null,
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: null,
    duplicate: null,
    updateAsMassive: false,
    dbName: null,
    countName: 'conversations',
    checkDuplicates: false,
});

export const METRICS = configEntity({
    trueName: 'metrics',
    entity: 'metrics',
    route: '/metrics',
    redux: 'metrics',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: null,
    objectTypeId: null,
    icon: null,
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

export const FIELDS = configEntity({
    trueName: 'fields',
    entity: 'fields',
    route: '-',
    redux: '-',
    customField: '-',
    extraFieldName: '-',
    extraFieldTab: '-',
    views: '-',
    permission: '-',
    entityDocuments: null,
    objectTypeId: null,
    icon: '-',
    useNewApi: null,
    endpoint: null,
    exportEntity: null,
    workFlow: '-',
    duplicate: null,
    updateAsMassive: false,
    checkDuplicates: false,
});

import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Text, Link, Icon, Button } from 'hoi-poi-ui';
import classnames from 'classnames';

import { RouteOnMount, ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

import ContentLayout from 'containers/components/layouts/ContentLayout';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';

import RatesManagement from './RatesManagement';
import CompanyManagement from './CompanyManagement';
import IntegrationsManagement from './IntegrationsManagement';
import SignatureManager from './SignatureManager';
import ApiKeyManagement from './ApiKeyManagement';
import ProfileSettings from './profileSettingsManager';
import VideoCallsAccount from './VideoCallsAccount';
import NylasSynchronization from './NylasSynchronization';
import PricingPlans from './PricingPlans';
import ProductsImport from './ProductsImport';
import DeleteSampleData from './DeleteSampleData';
import Billing from './Billing';
import Import from './Import';
import LimitsManagement from './LimitsManagement';
import AddOnsManagement from './AddOnsManagement';
import FieldsManagement from './FieldsManagement';
import ValuesListManagement from './ValuesListManagement';
import PersonalizeEnvironment from './PersonalizeEnvironment';
import UsersManagement from './UsersManagement';
import EnvironmentsManagement from './EnvironmentsManagement';

import './styles.scss';

const Settings = memo(() => {
    const location = useLocation();
    const login = useSelector((state) => state.config.login);
    const {
        isAdmin,
        isBusiness,
        isSMB,
        viewAddonsConfiguration,
        viewLimitsConfiguration,
        canUpdateProfile,
        manageUsersFromWeb,
        administrarInformacionDeMiEmpresa,
        subscriptionModeCode,
        licenseEditionCode,
        environmentManagement,
        isDeletedWalkThroughData,
        rates,
        syncEmailWithNylas,
        syncCalendarWithNylas,
        enableVideoCalls,
        enableAddonsSignatureManager,
        administrarIntegracion,
        bulk_insert_products,
        enableHelpCenterOption,
        manageExtraFields,
    } = useSelector((state) => ({
        isAdmin: state.config?.permission?.canConfigImplementation,
        isBusiness: state.config?.productModeCode === 'business',
        isSMB: state.config?.productModeCode === 'smb',
        viewAddonsConfiguration: state.config?.permission?.viewAddonsConfiguration,
        viewLimitsConfiguration: state.config?.permission?.viewLimitsConfiguration,
        canUpdateProfile: state.config?.permission?.canUpdateProfile,
        manageUsersFromWeb: state.config?.userData?.manageUsersFromWeb,
        administrarInformacionDeMiEmpresa:
            state.config?.permission?.administrarInformacionDeMiEmpresa,
        subscriptionModeCode: state.config?.subscriptionModeCode,
        licenseEditionCode: state.config?.licenseEditionCode,
        environmentManagement: state.config?.permission?.environmentManagement,
        isDeletedWalkThroughData: state.config?.userData?.isDeletedWalkThroughData,
        rates: state.config?.permission?.rates,
        syncEmailWithNylas: state?.config?.userData?.syncEmailWithNylas,
        syncCalendarWithNylas: state?.config?.userData?.syncCalendarWithNylas,
        enableVideoCalls: state?.config?.permission.enableVideoCalls,
        enableAddonsSignatureManager: state.config?.permission?.enableAddonsSignatureManager,
        administrarIntegracion: state.config?.permission?.administrarIntegracion,
        bulk_insert_products: state.config?.permission?.bulk_insert_products,
        enableHelpCenterOption: state.config?.permission?.enableHelpCenterOption,
        manageExtraFields: state.config.permission?.manageExtraFields,
    }));

    const { termsAndConditions, privacyPolicy, baseKnowledge } = useSelector((state) => ({
        termsAndConditions: state?.config?.userData?.termsAndConditions,
        privacyPolicy: state?.config?.userData?.privacyPolicy,
        baseKnowledge: state?.config?.userData?.baseKnowledge,
    }));

    const SETTINGS_PAGES = useMemo(
        () => [
            {
                name: 'title_manage_your_plan',
                icon: 'workspace',
                items: [
                    {
                        name: 'title_addons',
                        path: '#/settings/addons',
                        show: viewAddonsConfiguration,
                    },
                    {
                        name: 'title_usage',
                        path: '#/settings/limits',
                        show: viewLimitsConfiguration && !isBusiness && isAdmin,
                    },
                    {
                        name: 'title_user_management',
                        path: '#/settings/users',
                        show: manageUsersFromWeb && !isBusiness && isAdmin,
                    },
                ],
            },
            {
                name: 'title_settings',
                icon: 'settings',
                items: [
                    { name: 'title_myprofile', path: '#/settings/profile', show: canUpdateProfile },
                    {
                        name: 'title_personalize_environment',
                        path: '#/settings/personalize-environment',
                        show: !isBusiness && isAdmin,
                    },
                    {
                        name: 'title_my_account',
                        path: '#/settings/company',
                        show: administrarInformacionDeMiEmpresa,
                    },
                    {
                        name: 'title_billing_management',
                        path: '#/settings/billing',
                        show:
                            isAdmin &&
                            !isBusiness &&
                            subscriptionModeCode !== 'free_trial' &&
                            licenseEditionCode !== 'kitdigital_starter' &&
                            !licenseEditionCode.includes('sage'),
                    },
                    {
                        name: 'title_import',
                        path: '#/settings/import',
                        show:
                            isAdmin &&
                            (subscriptionModeCode === 'free_trial' ||
                                isSMB ||
                                [
                                    'starter',
                                    'essential',
                                    'kitdigital_starter',
                                    'sage_starter',
                                    'sage50_starter',
                                    'sage50uk_starter',
                                ].includes(licenseEditionCode)),
                    },
                    {
                        name: 'label_import_products_title',
                        path: '#/settings/products-import',
                        show: !isBusiness && isAdmin && bulk_insert_products,
                    },
                    {
                        name: 'title_environment_management',
                        path: '#/settings/environments',
                        show: !isBusiness && isAdmin && environmentManagement,
                    },
                    {
                        name: 'title_customize_fields',
                        path: '#/settings/fields',
                        show: manageExtraFields && isAdmin,
                    },
                    {
                        name: 'cfm_title_value_list',
                        path: '#/settings/values-list',
                        show: manageExtraFields && isAdmin,
                    },
                    {
                        name: 'label_delete_sample_data',
                        path: '#/settings/delete-sample-data',
                        show: !isBusiness && isAdmin && !isDeletedWalkThroughData,
                    },
                    {
                        name: 'label_rates_management',
                        path: '#/settings/rates',
                        show: rates,
                    },
                ],
            },
            {
                name: 'label_tools',
                icon: 'tool',
                items: [
                    {
                        name: 'title_email_sync',
                        path: '#/settings/nylas',
                        show: syncEmailWithNylas || syncCalendarWithNylas,
                    },
                    {
                        name: 'label_integration_section_video_conferencing',
                        path: '#/settings/video-calls',
                        show: enableVideoCalls,
                    },
                ],
            },
            {
                name: 'title_integrations',
                icon: 'widget',
                items: [
                    {
                        name: 'label_signaturit',
                        path: '#/settings/signature',
                        show:
                            enableAddonsSignatureManager &&
                            subscriptionModeCode !== 'free_trial' &&
                            !['starter', 'essential'].includes(licenseEditionCode),
                    },
                    {
                        name: 'title_api_key_management',
                        path: '#/settings/api',
                        show: administrarIntegracion,
                    },
                    {
                        name: 'title_third_party_integration',
                        path: '#/settings/integrations',
                        show: administrarIntegracion,
                    },
                ],
            },
            {
                name: 'title_support',
                icon: 'support',
                items: [
                    {
                        name: 'label_knowledge_base',
                        path: getLiteral(baseKnowledge),
                        show: enableHelpCenterOption,
                        target: '_blank',
                    },
                    {
                        name: 'label_terms_conditions',
                        path: getLiteral(termsAndConditions),
                        show: true,
                        target: '_blank',
                    },
                    {
                        name: 'label_privacy',
                        path: getLiteral(privacyPolicy),
                        show: true,
                        target: '_blank',
                    },
                ],
            },
        ],
        [
            administrarInformacionDeMiEmpresa,
            administrarIntegracion,
            baseKnowledge,
            bulk_insert_products,
            canUpdateProfile,
            enableAddonsSignatureManager,
            enableHelpCenterOption,
            enableVideoCalls,
            environmentManagement,
            isAdmin,
            isBusiness,
            isDeletedWalkThroughData,
            isSMB,
            licenseEditionCode,
            manageExtraFields,
            manageUsersFromWeb,
            privacyPolicy,
            rates,
            subscriptionModeCode,
            syncCalendarWithNylas,
            syncEmailWithNylas,
            termsAndConditions,
            viewAddonsConfiguration,
            viewLimitsConfiguration,
        ],
    );

    const menuItems = useMemo(() => {
        return SETTINGS_PAGES.filter((page) => page?.items?.some((item) => item.show)).map(
            (page) => {
                return (
                    <>
                        <Text
                            key={page.name}
                            className="fm-settings__menu-section"
                            type="subtitle1"
                            bold
                        >
                            <Icon name={page.icon} />
                            {getLiteral(page.name)}
                        </Text>
                        {page?.items
                            .filter((item) => item.show)
                            .map((item) => {
                                return (
                                    <Link
                                        key={item.name}
                                        href={item.path}
                                        className={classnames('fm-settings__menu-item', {
                                            ['fm-settings__menu-item--active']:
                                                item.path.includes(location.pathname) &&
                                                location.pathname !== '/pathname' &&
                                                location.pathname !== '/settings',
                                        })}
                                        color="neutral900"
                                        target={item.target}
                                        isTruncated
                                        useTooltip
                                    >
                                        <div className="fm-settings__menu-item-bar"></div>
                                        {getLiteral(item.name)}
                                    </Link>
                                );
                            })}
                    </>
                );
            },
        );
    }, [SETTINGS_PAGES, location.pathname]);

    if (!login) return null;

    return (
        <ContentLayout
            header={
                <Header>
                    <HeaderSection>
                        <HeaderTitle title="title_settings" />
                    </HeaderSection>
                    <HeaderSectionDefault />
                </Header>
            }
        >
            <div className="fm-settings">
                <div className="fm-settings__menu">
                    <div className="fm-settings__menu-items">{menuItems}</div>
                    {isSMB && (
                        <Button
                            className="fm-settings__upgrade-btn"
                            href="#/settings/pricing"
                            size="big"
                            isFullWidth
                        >
                            {getLiteral(
                                subscriptionModeCode === 'free_trial'
                                    ? 'action_upgrade_now'
                                    : 'action_upgrade_plan',
                            )}
                        </Button>
                    )}
                </div>
                <div className="fm-settings__content">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        ensureRoute('/settings/profile');
                                    }}
                                />
                            }
                        />
                        <Route path="/profile" element={<ProfileSettings />} />
                        <Route path="/company" element={<CompanyManagement />} />
                        <Route path="/integrations" element={<IntegrationsManagement />} />
                        <Route path="/api" element={<ApiKeyManagement />} />
                        <Route path="/signature" element={<SignatureManager />} />
                        <Route path="/video-calls" element={<VideoCallsAccount />} />
                        <Route path="/nylas" element={<NylasSynchronization />} />
                        <Route path="/pricing" element={<PricingPlans />} />
                        <Route path="/products-import" element={<ProductsImport />} />
                        <Route path="/delete-sample-data" element={<DeleteSampleData />} />
                        <Route path="/billing" element={<Billing />} />
                        <Route path="/import" element={<Import />} />
                        <Route path="/limits" element={<LimitsManagement />} />
                        <Route path="/addons" element={<AddOnsManagement />} />
                        <Route path="/fields" element={<FieldsManagement />} />
                        <Route path="/values-list" element={<ValuesListManagement />} />
                        <Route path="/rates" element={<RatesManagement />} />
                        <Route
                            path="/personalize-environment"
                            element={<PersonalizeEnvironment />}
                        />
                        <Route path="/users" element={<UsersManagement withDeleteAccount />} />
                        <Route path="/environments" element={<EnvironmentsManagement />} />
                    </Routes>
                </div>
            </div>
        </ContentLayout>
    );
});

export default Settings;

import { PATH_IMAGE } from 'constants/Environment';
import lottieSageLoader from './lottieFiles/sage_crm_loader.json';
import lottieFMLoader from './lottieFiles/fm_loader.json';
import lottieFMGenerating from './lottieFiles/fm_generating.json';
import lottieSageGenerating from './lottieFiles/sage_generating.json';

import DanaDefault from './icons/DanaDefault';
import DanaSage from './icons/DanaSage';

const softland_crm = {
    id: 'softland_crm',
    urlIdentifier: 'softland',
    title: 'Softland',
    favicon: `${PATH_IMAGE}softland_favicon.png`,
    spinnerGif: `${PATH_IMAGE}softland_spinner.gif`,
    logo: `${PATH_IMAGE}softland_logo.png`,
    iframeURL: 'https://softland.la/softlandcrm/login/',
    signupURL: null,
    appURL: 'http://onelink.to/53mv49',
    downloadBannerLogo: `${PATH_IMAGE}DownloadApp.svg`,
    showEmptyScreenVideo: false,
    settings: {
        showComparePlans: true,
    },
    DanaIcon: <DanaDefault />,
};

const forcemanager = {
    id: 'forcemanager',
    urlIdentifier: 'forcemanager',
    title: 'ForceManager',
    favicon: `${PATH_IMAGE}fm_favicon.ico`,
    loaderLottie: lottieFMLoader,
    danaLoaderLottie: lottieFMGenerating,
    logo: `${PATH_IMAGE}logo.svg`,
    bottomLoaderLogo: `${PATH_IMAGE}logo.svg`,
    iframeURL: 'login_page_marketing_iframe_link',
    signupURL: 'https://www.forcemanager.com/signup/',
    appURL: 'https://onelink.to/qupted',
    showEmptyScreenVideo: true,
    downloadBannerLogo: `${PATH_IMAGE}DownloadApp.svg`,
    theme: {
        danabox: {
            boxShadow: '0px 4px 14px 0px rgba(255, 149, 0, 0.10)',
            background:
                'linear-gradient(white, white) padding-box, linear-gradient(to right, #F76F73, #FE9C3B) border-box',
        },
    },
    settings: {
        showComparePlans: true,
    },
    DanaIcon: <DanaDefault />,
};

const sage_crm = {
    id: 'sage_crm',
    urlIdentifier: 'sage',
    logo: `${PATH_IMAGE}sage_logo_new.svg`,
    mainLogoWidth: '150px',
    bottomLoaderLogo: `${PATH_IMAGE}sage_logo_new.svg`,
    errorImage: `${PATH_IMAGE}sage-error.svg`,
    title: 'Sage CRM',
    favicon: `${PATH_IMAGE}sage_dark_favicon.png`,
    loaderLottie: lottieSageLoader,
    danaLoaderLottie: lottieSageGenerating,
    appURL: 'https://onelink.to/qupted',
    downloadBannerLogo: `${PATH_IMAGE}DownloadAppSage.svg`,
    showEmptyScreenVideo: false,
    theme: {
        danabox: {
            boxShadow: '0px 4px 14px 0px rgba(69, 234, 58, 0.10)',
            background:
                'linear-gradient(white, white) padding-box, linear-gradient(to right, #45EA3A, #255BC7) border-box',
        },
        colors: {
            orange100: '#D3E6FC',
            orange200: '#a8cbf9',
            orange300: '#79a8ee',
            orange400: '#5687dd',
            orange500: '#255bc7',
            orange600: '#1b46ab',
            orange700: '#12338f',
            orange900: '#07185f',
            green100: '#dbfdca',
            green200: '#aefb96',
            green300: '#77f461',
            green400: '#45ea3a',
            green500: '#00dc06',
            green600: '#00bd15',
            green700: '#009e20',
            green900: '#006928',
        },
    },
    settings: {
        inviteForm: {
            title: 'label_accept_invitation_title_sagesales',
            privacyPolicyUrl: 'label_privacy_policy_url_sagesales',
            termsAndConditionsUrl: 'label_terms_and_conditions_url_sagesales',
            accountUrl: 'label_account_url_sagesales',
            hasNewsletter: false,
        },
        onboardingWizard: {
            summary: {
                users: {
                    text: 'label_sage_summary_1',
                    text2: 'label_sage_summary_1_variable',
                },
                accountTypes: {
                    text: 'label_sage_summary_2',
                    text2: 'label_sage_summary_2_variable',
                },
                orderStatus: {
                    text: 'label_sage_summary_3',
                    text2: 'label_sage_summary_3_variable',
                },
                opportunityStatus: {
                    text: 'label_sage_summary_4',
                    text2: 'label_sage_summary_4_variable',
                },
            },
        },
        showComparePlans: false,
    },
    showEmptyScreenVideo: false,
    DanaIcon: <DanaSage />,
};

export const partnersObject = { softland_crm, forcemanager, sage_crm };
export const partnersArray = [softland_crm, sage_crm];

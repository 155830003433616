import React from 'react';
import colors from 'constants/colors';

const ThumbUp = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.88 13.231c.667 0 1.206.534 1.206 1.192 0 .659-.54 1.192-1.206 1.192h-.804a1.2 1.2 0 0 1 1.206 1.193c0 .659-.54 1.192-1.206 1.192H8.848C5.63 18 6.434 16.41 0 16.41V9.257h2.413c2.815 0 6.032-2.56 6.032-6.359 0-1.257 2.413-1.415 2.413.969 0 1.59-.804 4.595-.804 4.595h6.435a1.2 1.2 0 0 1 1.206 1.192 1.2 1.2 0 0 1-1.206 1.193h-.804a1.2 1.2 0 0 1 1.206 1.192 1.2 1.2 0 0 1-1.206 1.192h-.805"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default ThumbUp;

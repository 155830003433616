import React, { PureComponent } from 'react';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { getLiteral } from 'utils/getLiteral';
import { PATH_IMAGE } from 'constants/Environment';

class StepThree extends PureComponent {
    state = {};

    render() {
        const { step, is5500 } = this.props;

        const stepStyles = {
            1: '1340px',
            2: '670px',
            3: '0px',
        };

        return (
            <div className="onboarding-step" style={{ left: stepStyles[step] }}>
                <div className="onboarding-media-container">
                    <img src={`${PATH_IMAGE}img-onboarding2.png`} />
                </div>
                <div className="onboarding-content">
                    <div className="onboarding-content-header">
                        {sessionStorage.getItem('fm_deletedWalkThroughData')
                            ? getLiteral('label_welcome_cards_with_no_sample_data')
                            : getLiteral(
                                  is5500
                                      ? 'label_loading_your_data_5500'
                                      : 'label_welcome_cards_with_sample_data',
                              )}
                    </div>
                    <div className="onboarding-content-text">
                        {sessionStorage.getItem('fm_deletedWalkThroughData')
                            ? getLiteral('label_welcome_cards_with_no_sample_data_body')
                            : getLiteral(
                                  is5500
                                      ? 'label_loading_your_data_description_5500'
                                      : 'label_welcome_cards_with_sample_data_body',
                              )}
                    </div>
                </div>
            </div>
        );
    }
}

export default StepThree;

import React, { PureComponent } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { EmoHeart } from 'components/SvgIcons';

class StepOne extends PureComponent {
    state = {};

    render() {
        const { step, is5500 } = this.props;

        const stepStyles = {
            1: '0px',
            2: '-670px',
            3: '-1340px',
        };

        const literal = getLiteral('label_welcome_cards_love_field_sales');
        const literalSplitted = literal.split('%@');

        return (
            <div className="onboarding-step step-one" style={{ left: stepStyles[step] }}>
                <div className="onboarding-media-container">
                    <iframe
                        src={getLiteral('label_welcome_cards_video')}
                        width="670"
                        height="377"
                        frameBorder="0"
                        allowFullScreen
                    />
                </div>
                <div className="onboarding-content">
                    <div className="onboarding-content-header">
                        {getLiteral(
                            is5500 ? 'label_welcome_fm_5500' : 'label_welcome_cards_welcome',
                        )}
                    </div>
                    {!is5500 && (
                        <div className="onboarding-content-text">
                            <div className="text">{literalSplitted[0]}</div>
                            <div className="onboarding-content-emoji">
                                <EmoHeart color="$fmOrange" />
                            </div>
                            <div className="text">{literalSplitted[1]}</div>
                        </div>
                    )}
                    {is5500 && (
                        <div className="onboarding-content-text">
                            <div className="text">
                                {getLiteral('label_welcome_fm_description_5500')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default StepOne;

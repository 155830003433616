import React from 'react';
import colors from 'constants/colors';

const ZoomList = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.37 17.5l.226.011a.68.68 0 01.662.663l.012.225-2.697 2.697h2.023l.224.01c.371.023.635.293.663.664l.011.224h-3.37l-.225-.01a.687.687 0 01-.663-.664l-.011-.224 2.696-2.697H2.9l-.225-.011a.7.7 0 01-.663-.663L2 17.5h3.37zm14.832 0c.995 0 1.798.803 1.798 1.798v2.696l-.225-.01a.687.687 0 01-.663-.664l-.01-.224v-1.574l-.012-.224a.932.932 0 00-.107-.45.904.904 0 00-.78-.45.89.89 0 00-.776.445c-.079.135-.101.286-.112.455l-.012.224v2.472l-.224-.01a.691.691 0 01-.663-.664l-.012-.224v-1.574l-.01-.224c-.012-.169-.034-.315-.113-.45a.895.895 0 00-1.55 0c-.08.13-.102.281-.113.45l-.011.224v1.574l-.011.224a.68.68 0 01-.663.663l-.225.011V17.5c.225 0 .618.112.792.309a1.8 1.8 0 012.354.298 1.8 1.8 0 011.348-.607zm-11.685 0l.192.008c.512.043 1.008.26 1.398.65a2.251 2.251 0 010 3.18 2.251 2.251 0 01-3.18 0 2.246 2.246 0 011.59-3.837v-.001zm4.719 0c.579 0 1.152.22 1.59.657a2.251 2.251 0 010 3.18 2.251 2.251 0 01-3.18 0 2.246 2.246 0 011.59-3.837zm-3.764 1.298a1.343 1.343 0 00-1.905 0 1.343 1.343 0 000 1.904 1.343 1.343 0 001.905 0 1.353 1.353 0 000-1.904zm4.719 0a1.343 1.343 0 00-1.904 0 1.343 1.343 0 000 1.904 1.343 1.343 0 001.904 0 1.353 1.353 0 000-1.904zM13.25 2C15.325 2 17 3.608 17 5.6l3.5-2.52c.613-.444 1.5-.024 1.5.72v8.4c0 .744-.887 1.164-1.5.72L17 10.4v1.8c0 .996-.837 1.8-1.875 1.8H5.75C3.675 14 2 12.392 2 10.4V3.8C2 2.804 2.837 2 3.875 2h9.375z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default ZoomList;

import Context from 'managers/Context';
import {
    INTEGRATIONS_ERROR,
    INTEGRATIONS_ERROR_RESET,
    INTEGRATIONS_PROVIDER,
    ACTIVATION_OAUTH,
    ACTIVATION_OAUTH_FINISH,
    ACTIVATION_LOGIN,
    ACTIVATION_LOGIN_FINISH,
    ACTIVATION_LOGIN_CANCEL,
    ACTIVATION_CHECK,
    ACTIVATION_CHECK_VALID,
    ACTIVATION_CHECK_INVALID,
    ACTIVATION_ERROR,
    ACTIVATION_SET_UNACTIVE,
    INTEGRATIONS_CLEAR,
    CREATE_OR_UPDATE_JOB_SUCCESS,
    CREATE_OR_UPDATE_JOB_ERROR,
    PAUSE_JOB_SUCCESS,
    PAUSE_JOB_ERROR,
    RESUME_JOB_SUCCESS,
    RESUME_JOB_ERROR,
    PAUSING_JOB,
    RESUMING_JOB,
    GET_INTEGRATIONS_FM_FIELDS_SUCCESS,
    GET_INTEGRATIONS_FM_USERS_SUCCESS,
    GET_INTEGRATIONS_EXT_FIELDS_SUCCESS,
    GET_INTEGRATIONS_EXT_USERS_SUCCESS,
    GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS,
    GET_INTEGRATIONS_USERS_CONFIG_SUCCESS,
    ADD_INTEGRATIONS_FIELDS_CONFIG,
    ADD_INTEGRATIONS_USERS_CONFIG,
    ADD_INTEGRATIONS_USERS_AUTO_CONFIG,
    DELETE_INTEGRATIONS_USERS_CONFIG,
    GET_CRON_STATUS,
    GET_CRON_STATUS_SUCCESS,
    GET_CRON_STATUS_ERROR,
    GET_SYNC_LOGS,
    GET_SYNC_LOGS_SUCCESS,
    GET_SYNC_LOGS_ERROR,
    GET_SYNC_STATS,
    GET_SYNC_STATS_SUCCESS,
    GET_SYNC_STATS_ERROR,
    INTEGRATIONS_STEPPER_CANCEL,
    INTEGRATIONS_STEPPER_RESET,
    INTEGRATIONS_STEPPER_NEXT_LOADING,
    INTEGRATIONS_STEPPER_NEXT_SUCCESS,
    INTEGRATIONS_STEPPER_NEXT_ERROR,
    INTEGRATIONS_STEPPER_SET_STEPS,
    INTEGRATIONS_STEPPER_SET_STAGE,
} from 'constants/ActionTypes';
import SageUtils from 'containers/settings/deprecatedIntegrationsManagement/utils/SageUtils';
import { normalize, sort } from 'utils/arrays';

function _showError(error) {
    return {
        type: INTEGRATIONS_ERROR,
        payload: { error },
    };
}

function _setProviderState(provider) {
    return {
        type: INTEGRATIONS_PROVIDER,
        payload: { provider },
    };
}

function _getIntegrationsFmFieldsSuccess(data) {
    return {
        type: GET_INTEGRATIONS_FM_FIELDS_SUCCESS,
        payload: { data },
    };
}

function _getIntegrationsFmUsersSuccess(data) {
    return {
        type: GET_INTEGRATIONS_FM_USERS_SUCCESS,
        payload: { data },
    };
}

function _getIntegrationsExtFieldsSuccess(entity, data) {
    return {
        type: GET_INTEGRATIONS_EXT_FIELDS_SUCCESS,
        payload: { entity, data },
    };
}

function _getIntegrationsExtUsersSuccess(data) {
    return {
        type: GET_INTEGRATIONS_EXT_USERS_SUCCESS,
        payload: { data },
    };
}

function _getIntegrationsFieldsConfigSuccess(data) {
    return {
        type: GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS,
        payload: { data },
    };
}

function _getIntegrationsUsersConfigSuccess(data) {
    return {
        type: GET_INTEGRATIONS_USERS_CONFIG_SUCCESS,
        payload: { data },
    };
}

function _integrationsStepperNextLoading() {
    return {
        type: INTEGRATIONS_STEPPER_NEXT_LOADING,
    };
}

function _integrationsStepperNextSuccess(stage) {
    if (stage) {
        return {
            type: INTEGRATIONS_STEPPER_NEXT_SUCCESS,
            payload: { stage },
        };
    }
    return {
        type: INTEGRATIONS_STEPPER_RESET,
    };
}

function _integrationsStepperNextError(error) {
    return {
        type: INTEGRATIONS_STEPPER_NEXT_ERROR,
        payload: { error },
    };
}

function _activationOAuth() {
    return {
        type: ACTIVATION_OAUTH,
    };
}

function _activationOAuthFinish() {
    return {
        type: ACTIVATION_OAUTH_FINISH,
    };
}

function _activationLogin() {
    return {
        type: ACTIVATION_LOGIN,
    };
}

function _activationLoginFinish() {
    return {
        type: ACTIVATION_LOGIN_FINISH,
    };
}

function _activationCheck() {
    return {
        type: ACTIVATION_CHECK,
    };
}

function _activationCheckValid() {
    return {
        type: ACTIVATION_CHECK_VALID,
    };
}

function _activationCheckInvalid() {
    return {
        type: ACTIVATION_CHECK_INVALID,
    };
}

function _activationError() {
    return {
        type: ACTIVATION_ERROR,
    };
}

function _disableIntegration() {
    return {
        type: ACTIVATION_SET_UNACTIVE,
    };
}

function _createOrUpdateJobSuccess() {
    return {
        type: CREATE_OR_UPDATE_JOB_SUCCESS,
    };
}

function _pauseJobSuccess() {
    return {
        type: PAUSE_JOB_SUCCESS,
    };
}

function _resumeJobSuccess() {
    return {
        type: RESUME_JOB_SUCCESS,
    };
}

function _createOrUpdateJobError(error) {
    return {
        type: CREATE_OR_UPDATE_JOB_ERROR,
        payload: { error },
    };
}

function _pauseJobError(error) {
    return {
        type: PAUSE_JOB_ERROR,
        payload: { error },
    };
}

function _resumeJobError(error) {
    return {
        type: RESUME_JOB_ERROR,
        payload: { error },
    };
}

function _pausingJob() {
    return {
        type: PAUSING_JOB,
    };
}

function _resumingJob() {
    return {
        type: RESUMING_JOB,
    };
}

function _getCronStatus() {
    return {
        type: GET_CRON_STATUS,
    };
}

function _getCronStatusSuccess(data) {
    return {
        type: GET_CRON_STATUS_SUCCESS,
        payload: { data },
    };
}

function _getCronStatusError(error) {
    return {
        type: GET_CRON_STATUS_ERROR,
        payload: { error },
    };
}

function _getSyncroLogs() {
    return {
        type: GET_SYNC_LOGS,
    };
}

function _getSyncroLogsSuccess(data) {
    return {
        type: GET_SYNC_LOGS_SUCCESS,
        payload: { data },
    };
}

function _getSyncroLogsError(error) {
    return {
        type: GET_SYNC_LOGS_ERROR,
        payload: { error },
    };
}

function _getSyncroStats() {
    return {
        type: GET_SYNC_STATS,
    };
}

function _getSyncroStatsSuccess(data) {
    return {
        type: GET_SYNC_STATS_SUCCESS,
        payload: { data },
    };
}

function _getSyncroStatsError(error) {
    return {
        type: GET_SYNC_STATS_ERROR,
        payload: { error },
    };
}

export function oAuthLogin(iduser, implementation) {
    return function (dispatch) {
        dispatch(_activationOAuth());
        const child = window.open(
            SageUtils.getSageUrl(iduser, implementation),
            '_blank',
            'width:550,height:700,modal=yes,fullscreen=no,alwaysRaised=yes',
        );
        const timer = setInterval(() => {
            if (child.closed) {
                clearInterval(timer);
                dispatch(_activationOAuthFinish());
            }
        }, 500);
    };
}

export function fillLogin() {
    return function (dispatch) {
        dispatch(_activationLogin());
    };
}

export function providerLogin(provider, data) {
    return function (dispatch) {
        dispatch(_activationCheck());
        Context.integrationsManager.providerLogin(
            provider,
            data,
            (res) => {
                dispatch(_activationLoginFinish());
            },
            (error) => {
                console.warn('providerLogin error', error);
                dispatch(_activationError());
            },
        );
    };
}

export function providerCancelLogin() {
    return {
        type: ACTIVATION_LOGIN_CANCEL,
    };
}

export function resetError() {
    return {
        type: INTEGRATIONS_ERROR_RESET,
    };
}

export function integrationsStepperCancel() {
    return {
        type: INTEGRATIONS_STEPPER_CANCEL,
    };
}

export function integrationsStepperSetSteps(steps) {
    return {
        type: INTEGRATIONS_STEPPER_SET_STEPS,
        payload: { steps },
    };
}

export function integrationsStepperSetStage(stage) {
    return {
        type: INTEGRATIONS_STEPPER_SET_STAGE,
        payload: { stage },
    };
}

export function integrationsStepperReset() {
    return {
        type: INTEGRATIONS_STEPPER_RESET,
    };
}

export function getProvider(provider) {
    return function (dispatch) {
        dispatch(_setProviderState(provider));
    };
}

export function activationCheck(providerId, provider, data) {
    return function (dispatch) {
        dispatch(_activationCheck());
        switch (providerId) {
            case 19:
                Context.integrationsManager.providerLogin(
                    provider,
                    data,
                    () => {
                        dispatch(_activationCheckValid());
                    },
                    () => {
                        dispatch(_activationCheckInvalid());
                    },
                    (error) => {
                        dispatch(_activationError());
                    },
                );
                break;
            default:
                Context.settingsManager.validateOauth(
                    providerId,
                    () => {
                        dispatch(_setIntegrationActive(providerId));
                    },
                    () => {
                        dispatch(_activationCheckInvalid());
                    },
                    (error) => {
                        dispatch(_activationError());
                    },
                );
                break;
        }
    };
}

export function _setIntegrationActive(providerId) {
    return function (dispatch) {
        Context.settingsManager.setActivation(
            providerId,
            () => {
                dispatch(_activationCheckValid());
                // this.getTab1Data(providerId, )
            },
            (error) => {
                dispatch(_activationError());
            },
        );
    };
}

export function disableIntegration(providerId) {
    return function (dispatch) {
        Context.settingsManager.disableIntegration(
            providerId,
            () => {
                dispatch(_disableIntegration());
            },
            (error) => {
                console.warn('error isactive');
            },
        );
    };
}

export function clear() {
    return {
        type: INTEGRATIONS_CLEAR,
    };
}

export function setInitialIntegrationsFieldsConfig(data) {
    return {
        type: GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS,
        payload: { data },
    };
}

export function addIntegrationsFieldsConfig(entity, field, data) {
    return {
        type: ADD_INTEGRATIONS_FIELDS_CONFIG,
        payload: { entity, field, data },
    };
}

export function addIntegrationsUsersConfig(data) {
    return {
        type: ADD_INTEGRATIONS_USERS_CONFIG,
        payload: { data },
    };
}

export function addIntegrationsUsersAutoConfig(data) {
    return {
        type: ADD_INTEGRATIONS_USERS_AUTO_CONFIG,
        payload: { data },
    };
}

export function deleteIntegrationsUsersConfig(index) {
    return {
        type: DELETE_INTEGRATIONS_USERS_CONFIG,
        payload: { index },
    };
}

export function getIntegrationsFmFields() {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsFmFields(
            (data) => {
                data = data.reduce((accum, el) => {
                    if (!accum[el.RestAPITableName]) accum[el.RestAPITableName] = [];
                    accum[el.RestAPITableName].push(el);
                    return accum;
                }, {});
                dispatch(_getIntegrationsFmFieldsSuccess(data));
            },
            (error) => {
                dispatch(_integrationsStepperNextError('GET_INTEGRATIONS_FM_FIELDS'));
                console.warn('Get integrations FM fields:', error);
            },
        );
    };
}

export function getIntegrationsFmUsers() {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsFmUsers(
            (data) => {
                data = sort(data, 'name');
                dispatch(_getIntegrationsFmUsersSuccess(data));
            },
            (error) => {
                dispatch(_integrationsStepperNextError('GET_INTEGRATIONS_FM_USERS'));
                console.warn('Get integrations FM users:', error);
            },
        );
    };
}

export function setIntegrationsFieldsConfig(integration, data, stage) {
    return function (dispatch) {
        dispatch(_integrationsStepperNextLoading());
        Context.integrationsManager.setIntegrationsFieldsConfig(
            integration,
            data,
            () => {
                dispatch(_integrationsStepperNextSuccess(stage));
            },
            (error) => {
                dispatch(_integrationsStepperNextError('SET_INTEGRATIONS_FIELDS_CONFIG'));
                console.warn('Set integrations fields config:', error);
            },
        );
    };
}

export function setIntegrationsUsersConfig(integration, data, stage) {
    return function (dispatch) {
        dispatch(_integrationsStepperNextLoading());
        Context.integrationsManager.setIntegrationsUsersConfig(
            integration,
            data,
            () => {
                dispatch(_integrationsStepperNextSuccess(stage));
            },
            (error) => {
                dispatch(_integrationsStepperNextError('SET_INTEGRATIONS_USERS_CONFIG'));
                console.warn('Set integrations users config:', error);
            },
        );
    };
}

function createOrUpdateJobSuccess(dispatch) {
    Context.settingsManager.refreshWebTemplates(
        () => {},
        (error) => {
            console.warn('error refresh web4', error);
        },
    );
    dispatch(_createOrUpdateJobSuccess());
}

function createOrUpdateJobError(dispatch, error) {
    dispatch(_createOrUpdateJobError('CREATE_OR_UPDATE_JOB'));
    console.warn('Create or update job Error:', error);
}

export function createOrUpdateJob(providerId, provider) {
    return function (dispatch) {
        switch (providerId) {
            case 19:
                Context.integrationsManager.updateCronStatus(
                    provider,
                    () => {
                        createOrUpdateJobSuccess(dispatch);
                    },
                    (error) => {
                        createOrUpdateJobError(dispatch, error);
                    },
                );
                break;
            default:
                Context.settingsManager.createOrUpdateJob(
                    providerId,
                    null,
                    () => {
                        createOrUpdateJobSuccess(dispatch);
                    },
                    (error) => {
                        createOrUpdateJobError(dispatch, error);
                    },
                );
        }
    };
}

export function getCronStatus(providerId) {
    return function (dispatch) {
        dispatch(_getCronStatus());
        Context.settingsManager.getCronStatus(
            providerId,
            (data) => {
                dispatch(_getCronStatusSuccess(data));
            },
            (data) => {
                dispatch(_getCronStatusSuccess(data));
            },
            (error) => {
                dispatch(_getCronStatusError(error));
            },
        );
    };
}

export function pauseCron(providerId) {
    return function (dispatch) {
        dispatch(_pausingJob());
        Context.settingsManager.pauseIntegration(
            providerId,
            () => {
                dispatch(_pauseJobSuccess());
            },
            () => {
                dispatch(_pauseJobError('cron: nok'));
                console.warn('Pause job Error:', 'cron: nok');
            },
            (error) => {
                dispatch(_pauseJobError(error));
                console.warn('Pause job Error:', error);
            },
        );
    };
}

export function resumeCron(providerId) {
    return function (dispatch) {
        dispatch(_resumingJob());
        Context.settingsManager.resumeIntegration(
            providerId,
            () => {
                dispatch(_resumeJobSuccess());
            },
            () => {
                dispatch(_resumeJobError('cron: nok'));
                console.warn('Resume job Error:', 'cron: nok');
            },
            (error) => {
                dispatch(_resumeJobError(error));
                console.warn('Resume job Error:', error);
            },
        );
    };
}

export function getIntegrationsExtFields(integration, extEntity, entity) {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsExtFields(
            integration,
            extEntity,
            (data) => {
                data = data.reduce((accum, el) => {
                    return [
                        ...accum,
                        {
                            type: el.type,
                            label: el.Name,
                            value: el.Id,
                            picklistValues: null,
                        },
                    ];
                }, []);
                data = sort(data, 'label');
                dispatch(_getIntegrationsExtFieldsSuccess(entity, data));
            },
            (error) => {
                dispatch(_showError('GET_INTEGRATIONS_EXT_FIELDS'));
                console.warn('Get integration fields config:', error);
            },
        );
    };
}

export function getIntegrationsExtUsers(integration) {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsExtUsers(
            integration,
            (data) => {
                data = sort(data.records, 'Name', 'Id');
                dispatch(_getIntegrationsExtUsersSuccess(data));
            },
            (error) => {
                dispatch(_showError('GET_INTEGRATIONS_EXT_USERS'));
                console.warn('Get integration users Error:', error);
            },
        );
    };
}

export function getIntegrationsFieldsConfig(integration) {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsFieldsConfig(
            integration,
            (data) => {
                const normData = {};
                data.Mapping.entityConfiguration.map((el) => {
                    if (el.outgoing) {
                        el.fieldRelation = normalize(el.fieldRelation, 'externalField');
                        normData[el.forcemanagerEntity] = el;
                    } else {
                        el.fieldRelation = normalize(el.fieldRelation, 'forcemanagerField');
                        normData[el.forcemanagerEntity] = el;
                    }
                });
                dispatch(_getIntegrationsFieldsConfigSuccess(normData));
            },
            (error) => {
                dispatch(_showError('GET_INTEGRATIONS_EXT_FIELDS_CONFIG'));
                console.warn('Get integration fields config Error:', error);
            },
        );
    };
}

export function getIntegrationsUsersConfig(integration) {
    return function (dispatch) {
        Context.integrationsManager.getIntegrationsUsersConfig(
            integration,
            (data) => {
                dispatch(_getIntegrationsUsersConfigSuccess(data));
            },
            (error) => {
                dispatch(_showError('GET_INTEGRATIONS_EXT_USERS_CONFIG'));
                console.warn('Get integration users config Error:', error);
            },
        );
    };
}

export function getSyncroStats(provider) {
    return function (dispatch) {
        dispatch(_getSyncroStats());
        Context.settingsManager.getSyncroStats(
            provider,
            (data) => {
                dispatch(_getSyncroStatsSuccess(data));
            },
            () => {
                dispatch(_getSyncroStatsError());
            },
            (error) => {
                console.warn('getSyncroStats error', error);
                dispatch(_getSyncroStatsError());
            },
        );
    };
}

export function getSyncroLogs(provider) {
    return function (dispatch) {
        dispatch(_getSyncroLogs());
        Context.settingsManager.getSyncroLogs(
            provider,
            (data) => {
                dispatch(_getSyncroLogsSuccess(data));
            },
            () => {
                dispatch(_getSyncroLogsError());
            },
            (error) => {
                console.warn('getSyncroLogs error', error);
                dispatch(_getSyncroLogsError());
            },
        );
    };
}

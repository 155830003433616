import {
    LOAD_LITERALS,
    LOAD_LITERALS_SUCCESS,
    LOAD_LITERALS_ERROR,
    CLEAR_LITERALS,
    CLEAR,
} from '../constants/ActionTypes';
import Context from '../managers/Context';

let defaultState;

const initialState = {
    loading: false,
    error: false,
    literals: {},
};

function initState(initialState) {
    if (!defaultState) {
        const initialStateCache = Context.cacheManager.getLiteralsStore();
        if (initialStateCache) {
            defaultState = {
                ...initialState,
                literals: initialStateCache,
            };
        } else {
            defaultState = initialState;
        }
    }
    return defaultState;
}

export default function reducer(state = initState(initialState), action) {
    const newState = state.list;
    switch (action.type) {
        case LOAD_LITERALS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case LOAD_LITERALS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                literals: action.literals,
            };
        case LOAD_LITERALS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case CLEAR:
        case CLEAR_LITERALS:
            return initialState;
        default:
            return state;
    }
}

import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tour } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { setNewToursObj } from 'utils/tours';

const TOUR_NAME = 'bulkActionsTour';

const EntityTypeSidebarTour = memo(({ entity, hasSchedule, hasEmails }) => {
    const { tourDisabled } = useSelector((state) => ({
        tourDisabled: state.config.components?.tours?.[entity.entity]?.[TOUR_NAME]?.disabled,
    }));
    const steps = useMemo(() => {
        if (hasSchedule && !hasEmails) {
            return [
                {
                    selector: '.bulk-operation-schedule > svg > path',
                    title: getLiteral('label_promotion_schedule'),
                    description: getLiteral('label_promotion_schedule_body'),
                    link: getLiteral('label_promotion_scheduler_learn_more'),
                    event: 'tour:end',
                },
            ];
        } else if (!hasSchedule && hasEmails) {
            return [
                {
                    selector: '.bulk-operation-send-emails > svg > path',
                    title: getLiteral('label_promotion_masive_emails'),
                    description: getLiteral('label_promotion_masive_emails_body'),
                    link: getLiteral('label_promotion_masive_emails_learn_more'),
                    event: 'tour:end',
                },
            ];
        } else if (hasSchedule && hasEmails) {
            return [
                {
                    selector: '.fm-entity-bulk-operations__actions',
                    title: getLiteral('label_promotion_masive_emails_schedule'),
                    description: getLiteral('label_promotion_masive_emails_schedule_body'),
                    event: 'tour:end',
                },
            ];
        }
    }, []);

    const onCloseTour = useCallback(() => {
        setNewToursObj({ entity: entity, tourName: TOUR_NAME });
    }, [entity]);

    if (tourDisabled) return null;
    return <Tour steps={steps} delay={600} onClose={onCloseTour} />;
});

export default EntityTypeSidebarTour;

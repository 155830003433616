export default ({ text, color }) => ({
    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        border: '1px solid #ddd',
        borderTop: 0,
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative',
    },
    header: {
        ...text.text_md,
        height: 'auto',
        padding: '5px 20px',
        backgroundColor: '#f8f8f8',
        position: 'relative',
        zIndex: 10,
        borderBottom: 'none',
        lineHeight: '30px',
        color: 'black',
        overflow: 'auto',
    },
    headerTabs: {
        borderBottom: '1px solid #B5BDC5',
        height: '40px',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    section: {
        padding: '16px 16px 0px 16px',
        ...text.text_md,
    },
    text: {
        ...text.text_md,
    },
    textDesc: {
        ...text.text_md,
        color: 'black',
        fontWeight: 400,
    },
    textBig: {
        ...text.big_text_lg,
        padding: '0px 16px 16px 16px',
    },
    companyInfo: {
        ...text.text_md,
        background: '#f5f2db',
        padding: 16,
        border: '1px solid #d2c484',
    },
    tab: {
        ...text.text_md,
        lineHeight: '30px',
        float: 'left',
        padding: '5px 60px 5px 20px',
        backgroundColor: '#f8f8f8',
        borderBottom: '1px solid #B5BDC5',
        borderRight: '1px solid transparent',
        borderLeft: '1px solid transparent',
        height: '40px',
        boxSizing: 'border-box',
    },
    tabActive: {
        ...text.text_md,
        lineHeight: '30px',
        backgroundColor: 'white',
        borderRight: '1px solid #B5BDC5',
        borderBottom: 'none',
    },
    tabActiveInner: {
        borderLeft: '1px solid #B5BDC5',
    },
    tabContent: {
        cursor: 'pointer',
    },
});

import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Radio } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import { PAGINATION_TABLE_TASKS } from 'constants/Environment';
import { TASKS } from 'constants/Entities';
import { AGENDA_TASKS_COMPLETED_FLAG, AGENDA_TASK } from 'constants/Constants';

import Context from 'managers/Context';
import { EntityModalActions } from 'actions';
import AgendaModel from 'models/AgendaModel';

import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import {
    formatDateToBackendUTC,
    getActivityDateFormat,
    getPreviousAndNextDate,
    getDateToTimezone,
} from 'utils/dates';
import { successToast, errorToast } from 'utils/toast';

import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={117} height={101} fill="none">
        <path
            fill="#E9ECEE"
            d="M75.416 87.068c-.093.08-.19.16-.288.237H63.236a13.23 13.23 0 0 1-1.647-2.37c-.976-1.77-1.313-4.088-1.313-6.45a29.845 29.845 0 0 1 .136-2.75 39.26 39.26 0 0 1 .407-3.021c.287-1.692.67-3.365 1.15-5.011l.046.012c.088.024.299.083.609.176a46.182 46.182 0 0 1 4.606 1.658 33.995 33.995 0 0 1 4.525 2.285l.268.166v.008l-.195 6.345 1.74-5.282a16.472 16.472 0 0 1 1.283 1.09 9.793 9.793 0 0 1 1.86 2.333c2.604 4.723 1.51 8.195-1.294 10.574Z"
        />
        <path
            fill="#fff"
            d="M73.74 87.305h-.316a6.321 6.321 0 0 0-.01-.237c-.128-2.386-1.146-5.022-2.532-7.565-.05-.093-.1-.188-.153-.28a43.758 43.758 0 0 0-2.379-3.782 63.854 63.854 0 0 0-3.056-4.018c-.068-.083-.137-.165-.205-.245a65.816 65.816 0 0 0-3.073-3.463l-.163-.168c-.034-.037-.055-.057-.06-.061l.112-.112v-.003l.114-.111c.003.005.072.07.192.197.102.104.242.25.413.434a70.59 70.59 0 0 1 2.932 3.338 66.05 66.05 0 0 1 2.464 3.178 49.71 49.71 0 0 1 .796 1.127 43.628 43.628 0 0 1 2.195 3.508l.156.281c1.595 2.925 2.453 5.516 2.564 7.745l.01.237Z"
        />
        <path
            fill="#fff"
            d="M67.099 69.189a8.474 8.474 0 0 1-1.746 1.796c-.086.063-.174.129-.264.193a8.66 8.66 0 0 1-.762.477 8.482 8.482 0 0 1-3.627 1.068l-.053.321c.039-.002.079-.004.118-.008a8.779 8.779 0 0 0 3.716-1.101 9.06 9.06 0 0 0 1.075-.706 8.842 8.842 0 0 0 1.844-1.912l-.301-.128ZM71.863 71.61c-.035.075-.072.15-.108.224a8.551 8.551 0 0 1-3.493 3.657 8.536 8.536 0 0 1-7.849.244l-.087-.042-.029.341.085.04a8.841 8.841 0 0 0 8.434-.54 8.86 8.86 0 0 0 3.318-3.753l-.271-.172Z"
        />
        <path
            fill="#fff"
            d="M74.639 73.69a8.598 8.598 0 0 1-4.219 5.714 8.553 8.553 0 0 1-10.14-1.352c-.022-.02-.044-.043-.066-.065l.001.442.061.056a8.867 8.867 0 0 0 10.89.839 8.926 8.926 0 0 0 3.685-5.163c.019-.071.037-.143.054-.216-.086-.087-.176-.17-.266-.255ZM66.346 57.49H10.015V14.983h56.33v42.505Z"
        />
        <path
            fill="#E9ECEE"
            d="M23.665 23.855a2.249 2.249 0 0 0 2.242-2.255 2.249 2.249 0 0 0-2.242-2.255 2.249 2.249 0 0 0-2.242 2.255 2.249 2.249 0 0 0 2.242 2.255ZM28.843 20.096a.373.373 0 0 0-.372.376.376.376 0 0 0 .372.376H56.13a.373.373 0 0 0 .373-.376.377.377 0 0 0-.373-.376H28.844ZM28.843 22.352a.372.372 0 0 0-.374.375.377.377 0 0 0 .373.376h17.25a.372.372 0 0 0 .374-.376.378.378 0 0 0-.23-.347.37.37 0 0 0-.144-.028H28.844Z"
        />
        <path
            fill="#E9ECEE"
            d="M66.346 57.49H10.015V14.983h56.33v42.505Zm-55.7-.635h55.07V15.618h-55.07v41.237Z"
        />
        <path
            fill="#FF8C00"
            d="M13.163 27.946v25.282h50.034V27.946H13.163Zm49.794 6.26H50.749v-6.018h12.208v6.018Zm-24.656 0v-6.018h12.207v6.018H38.301Zm12.207.242v6.018H38.301v-6.018h12.207Zm-12.448-.242H25.852v-6.018H38.06v6.018Zm0 .242v6.018H25.852v-6.018H38.06Zm-12.448 6.018H13.404v-6.018h12.208v6.018Zm0 .242v6.018H13.404v-6.018h12.208Zm.24 0H38.06v6.018H25.852v-6.018Zm12.208 6.26v6.018H25.852v-6.018H38.06Zm.24 0h12.208v6.018H38.301v-6.018Zm0-.242v-6.018h12.208v6.018H38.301Zm12.449-6.018h12.208v6.018H50.749v-6.018Zm0-.242v-6.018h12.208v6.018H50.749ZM25.612 28.188v6.018H13.404v-6.018h12.208Zm-12.208 18.78h12.208v6.018H13.404v-6.018Zm37.345 6.018v-6.018h12.208v6.018H50.749Z"
        />
        <path
            fill="#76BDE8"
            d="m33.703 34.748-4.979-.113c-.498-1.94-.274-3.788.124-5.49l4.978.113a11.024 11.024 0 0 0-.123 5.49Z"
        />
        <path
            fill="#fff"
            d="m29.7 30.358.004-.19 2.579.092-.003.19-2.58-.092ZM29.69 31.12l.003-.19 2.579.093-.003.19-2.58-.093ZM29.679 31.882l.003-.19 2.579.092-.003.191-2.579-.093ZM29.668 32.644l.003-.19 2.58.092-.004.19-2.579-.092ZM29.657 33.406l.003-.19 2.58.093-.003.19-2.58-.093Z"
        />
        <path
            fill="#E9ECEE"
            d="m22.878 48.78-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m18.876 44.39.003-.19 2.58.092-.004.19-2.579-.092ZM18.865 45.152l.003-.19 2.579.092-.003.19-2.579-.092ZM18.854 45.914l.003-.19 2.58.092-.004.19-2.579-.092ZM18.843 46.676l.003-.19 2.58.092-.003.19-2.58-.092ZM18.833 47.438l.002-.19 2.58.093-.003.19-2.58-.093Z"
        />
        <path
            fill="#E9ECEE"
            d="m48.857 42.369-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m44.855 37.979.003-.19 2.58.092-.003.19-2.58-.092ZM44.844 38.74l.003-.19 2.58.093-.003.19-2.58-.092ZM44.834 39.503l.002-.19 2.58.092-.003.19-2.58-.092ZM44.823 40.265l.002-.19 2.58.092-.003.19-2.58-.092ZM44.812 41.027l.003-.19 2.579.092-.003.191-2.579-.093Z"
        />
        <path
            fill="#76BDE8"
            d="m46.692 41.159-4.978-.113c-.498-1.94-.274-3.788.123-5.49l4.979.113a11.023 11.023 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m42.69 36.77.003-.191 2.58.092-.003.19-2.58-.092ZM42.68 37.531l.002-.19 2.58.093-.003.19-2.58-.093ZM42.669 38.293l.003-.19 2.579.093-.003.19-2.58-.093ZM42.658 39.056l.003-.191 2.579.093-.003.19-2.579-.093ZM42.647 39.818l.003-.191 2.579.093-.003.19-2.579-.092Z"
        />
        <path
            fill="#76BDE8"
            d="m65.054 52.209-4.978-.113c-.498-1.94-.274-3.788.123-5.49l4.979.113a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m61.052 47.82.003-.191 2.58.092-.004.191-2.579-.093ZM61.041 48.581l.003-.19 2.58.093-.004.19-2.579-.093ZM61.03 49.343l.003-.19 2.58.093-.004.19-2.579-.093ZM61.02 50.106l.002-.191 2.58.093-.003.19-2.58-.092ZM61.009 50.868l.002-.19 2.58.092-.003.19-2.58-.092Z"
        />
        <path
            fill="#E9ECEE"
            d="m59.682 35.716-4.979-.114c-.498-1.94-.273-3.787.124-5.49l4.978.114a11.025 11.025 0 0 0-.123 5.49Z"
        />
        <path
            fill="#fff"
            d="m55.68 31.326.003-.19 2.579.092-.003.19-2.58-.092ZM55.67 32.088l.002-.19 2.579.092-.003.19-2.579-.092ZM55.658 32.85l.003-.19 2.58.092-.004.19-2.579-.092ZM55.648 33.612l.002-.19 2.58.092-.003.19-2.58-.092ZM55.636 34.374l.003-.19 2.58.092-.003.19-2.58-.092Z"
        />
        <path
            fill="#FFC5AC"
            d="M64.786 48.163a1.208 1.208 0 0 0 .796-.645 1.223 1.223 0 0 0 .02-1.03l3.139-2.952-2.172-.525-2.648 2.884a1.23 1.23 0 0 0 .006 2.168c.265.138.57.174.859.1Z"
        />
        <path
            fill="#FF8C00"
            d="m66.407 45.785-1.464-2.076 5.5-5.72 1.276-9.016a3.078 3.078 0 0 1 1.42-2.179 3.037 3.037 0 0 1 4.33 1.25c.358.728.416 1.57.158 2.34l-3.257 9.721-7.963 5.68Z"
        />
        <path fill="#FFC5AC" d="M76.732 84.202h-1.625l-.773-6.304h2.398v6.304Z" />
        <path
            fill="#37526C"
            d="M77.606 87.306h-1.153l-.206-1.095-.527 1.095H72.66a.685.685 0 0 1-.656-.486.696.696 0 0 1 .266-.775l2.442-1.696v-1.107l2.57.154.323 3.91Z"
        />
        <path fill="#FFC5AC" d="m86.758 82.539-1.427.781-3.676-5.166 2.107-1.153 2.996 5.538Z" />
        <path
            fill="#37526C"
            d="m89 84.845-1.012.554-.701-.863.057 1.215-2.686 1.47a.684.684 0 0 1-.808-.11.693.693 0 0 1-.135-.809l1.34-2.664-.527-.972 2.33-1.1 2.143 3.279ZM77.416 81.957h-3.358V69.93l-2.08-7.842a45.141 45.141 0 0 1-1.167-17.213l.003-.024.018-.015c.053-.045 5.375-4.47 8.741-1.085 2.269 2.282.137 10.487.018 10.938v14.145l-2.174 13.124Z"
        />
        <path
            fill="#FF8C00"
            d="m69.788 47.059 1.083-10.887c-.14-.297-2.165-2.177-.048-5.484l2.286-5.397 4.281-.067.018.018a8.683 8.683 0 0 1 2.162 3.627c.413 1.37.482 2.82.201 4.223l-1.261 6.345 1.093 4.397-9.815 3.225Z"
        />
        <path fill="#37526C" d="m75.413 69.991 6.9 9.76 2.646-1.892-5.762-11.91-3.784 4.042Z" />
        <path
            fill="#FFC5AC"
            d="M74.85 24.194c2.183 0 3.953-1.78 3.953-3.976 0-2.196-1.77-3.976-3.954-3.976-2.183 0-3.953 1.78-3.953 3.976 0 2.196 1.77 3.976 3.953 3.976Z"
        />
        <path
            fill="#273C50"
            d="m73.878 34.6 1.082 6.532-1.082 5.443h-2.165l.984-5.444 1.18-6.532Z"
            opacity={0.1}
        />
        <path
            fill="#FFC5AC"
            d="M74.91 52.184a1.216 1.216 0 0 0 .4-.948 1.223 1.223 0 0 0-.464-.917l1.387-4.091-2.161.565-.986 3.799a1.229 1.229 0 0 0 .21 1.61 1.211 1.211 0 0 0 1.614-.018Z"
        />
        <path
            fill="#FF8C00"
            d="m75.228 49.317-2.264-1.138 2.174-7.65-3.095-8.559a3.083 3.083 0 0 1 .232-2.594 3.06 3.06 0 0 1 2.132-1.48 3.04 3.04 0 0 1 2.273.532 3.072 3.072 0 0 1 1.236 1.99l1.677 10.118-4.365 8.781Z"
        />
        <path
            fill="#A9B1B9"
            d="M106.378 87.161a.149.149 0 0 1-.042.102.141.141 0 0 1-.101.042H28.383a.143.143 0 0 1-.143-.144.144.144 0 0 1 .143-.144h77.852a.143.143 0 0 1 .143.144Z"
        />
        <path
            fill="#37526C"
            d="M90.225 20.023c-.499.815-1.555 1.174-2.492 1.01-.938-.162-1.752-.77-2.353-1.512-.602-.74-1.018-1.613-1.427-2.476-.41-.863-.824-1.736-1.422-2.48-.598-.743-1.408-1.356-2.344-1.526-.51-.093-1.086-.032-1.458.33a1.432 1.432 0 0 0-.39 1.008 1.042 1.042 0 0 0-1.293.544 1.057 1.057 0 0 0-.1.501l-.04-.001c-1.744-.997-4.39-.418-4.39.67-.957 0-2.126.95-2.126 2.394 1.978 0 3.142 2.55 1.987 4.377-.298.466-.626.912-.984 1.334h2.474c.208-.3.333-.649.363-1.012.091.33.145.67.162 1.012 0 0 6.758.876 5.014-5.898a.703.703 0 0 0 .327-.1c.263-.16.406-.497.431-.84l.195.053c.465.126.948.29 1.267.652.54.613.426 1.544.622 2.338.236.959.943 1.733 1.728 2.326.843.637 1.828 1.121 2.878 1.221 1.05.1 2.167-.22 2.894-.988.728-.768.981-2.005.477-2.937Z"
        />
    </svg>
);

const TaskDashboardWidget = memo(() => {
    const dispatch = useDispatch();
    const agendaPermission = useSelector(
        (state) => state?.config?.permission?.crud_permission?.AGENDA,
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncCalendarWithNylas = useSelector(
        (state) => state.config?.userData?.syncCalendarWithNylas || false,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const idTimezone = useSelector((state) => state?.config?.userData?.idTimeZone);
    const userName = useSelector(
        (state) => `${state?.config?.userData?.nombre} ${state?.config?.userData?.apellidos}`,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();
    const [checkeds, setCheckeds] = useState({});
    const [timezone, setTimezone] = useState();

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);
        setCheckeds({});

        let filters = {
            fini: {
                id: 'fini',
                value: '',
            },
            fini: {
                id: 'ffin',
                value: '',
            },
            taskFlag: {
                id: 'taskFlag',
                value: '1',
            },
            completed: {
                id: 'completed',
                value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
            },
            useUTCDates: {
                id: 'useUTCDates',
                value: true,
            },
            newAttendees: {
                id: 'newAttendees',
                value: true,
            },
            filterTaskByDate: {
                value: true,
                id: 'filterTaskByDate',
            },
            idusuario: {
                value: [idUser],
                id: 'idusuario',
            },
        };

        let startDate = moment();
        let endDate = moment();
        switch (filter?.value) {
            case 'all':
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
            case 'today':
                startDate.startOf('day');
                endDate.add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'upcoming':
                const futureDate = startDate.clone();
                futureDate.endOf('day');
                futureDate.add(50, 'years');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(futureDate.toDate()),
                };
                break;
            case 'overdue':
                endDate.subtract(1, 'minutes');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(new Date(null)),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'completed':
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.completed,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
        }

        Context.domainManager.getEntityList(
            TASKS.entity,
            0,
            PAGINATION_TABLE_TASKS,
            filters,
            '',
            '',
            '',
            false,
            (result) => {
                setLoading(false);
                setList(result.map((item) => AgendaModel.standardToCrud(item)));
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [filter, idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(TASKS, true));
        return subscribe(`${ENTITY_MODAL_UPDATE}--${TASKS.entity}`, () => getList());
    }, [dispatch, getList]);

    useEffect(() => {
        Context.serverListManager.getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(userTimezone).length) {
                setTimezone(userTimezone);
            }
        });
    }, []);

    useEffect(() => {
        if (!timezone) return;
        getList();
    }, [getList, timezone]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'list',
        });
        window.open(getRoute(TASKS.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        const defaultRange = getPreviousAndNextDate(60);
        const hini = defaultRange.previous;
        const fini = new Date(hini);

        dispatch(
            EntityModalActions.init({
                entity: TASKS,
                data: {
                    user: {
                        label: userName,
                        value: idUser,
                    },
                    fini,
                    hini,
                    idTimezone,
                    isCompletado: '0',
                    isTarea: '1',
                },
                labels: {
                    title: getLiteral('action_create_task'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_create'),
                },
                hideDelete: true,
                crudTab: AGENDA_TASK,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'create',
        });
    }, [dispatch, idTimezone, idUser, userName]);

    const onClick = useCallback(
        (item) => {
            dispatch(
                EntityModalActions.init({
                    entity: TASKS,
                    id: item.Id,
                    labels: {
                        title: getLiteral('title_edit_task'),
                        success: getLiteral('succes_entityupdatedsuccessfully'),
                        error: getLiteral('error_generalerror'),
                        deleteModalTitle: getLiteral('title_delete_task'),
                        successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                    },
                    hideDelete: !agendaPermission?.delete,
                    disableConfirm: !agendaPermission?.update,
                    crudTab: AGENDA_TASK,
                }),
            );

            logEvent({
                event: 'dashboard',
                submodule: TASKS.trueName,
                functionality: 'detailView',
            });
        },
        [agendaPermission?.delete, agendaPermission?.update, dispatch],
    );

    const onChangeFilter = useCallback(
        (filter) => {
            setFilter(filter || taskTypes[0]);
            logEvent({
                event: 'dashboard',
                submodule: TASKS.trueName,
                functionality: 'filter',
            });
        },
        [taskTypes],
    );

    const onChangeTask = useCallback(
        (task) => {
            const taskChecked = checkeds.hasOwnProperty(task.Id)
                ? !checkeds?.[task.Id]
                : !getBackendBoolean(task.finalizada);

            setCheckeds((current) => {
                return {
                    ...current,
                    [task.Id]: taskChecked,
                };
            });

            Context.entityManager.getEntitiesManager(TASKS).setTaskCompleted(
                task.Id,
                taskChecked,
                () => {
                    if (taskChecked) {
                        successToast({ text: getLiteral('success_task_completed') });
                    }
                },
                (error) => {
                    console.error(error);
                    errorToast({ text: getLiteral('error_task_completed') });
                    setCheckeds((current) => {
                        return {
                            ...current,
                            [task.Id]: !taskChecked,
                        };
                    });
                },
            );
        },
        [checkeds],
    );

    const taskTypes = useMemo(() => {
        return [
            {
                label: getLiteral('label_all_tasks'),
                value: 'all',
            },
            {
                label: getLiteral('label_today'),
                value: 'today',
            },
            {
                label: getLiteral('label_upcoming'),
                value: 'upcoming',
            },
            {
                label: getLiteral('label_overdue'),
                value: 'overdue',
            },
            {
                label: getLiteral('label_tasks_completed'),
                value: 'completed',
            },
        ];
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];
        if (agendaPermission?.create)
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_create_task')}
                </Button>,
            );

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncCalendarWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        logEvent({
                            event: 'dashboard',
                            submodule: TASKS.trueName,
                            functionality: 'syncEmailCal',
                        });
                        ensureRoute('/settings/nylas');
                    }}
                >
                    {getLiteral('action_sync_cal')}
                </Button>,
            );
        }

        return actions;
    }, [agendaPermission, onAdd, syncCalendarWithNylas, nylasConfig]);

    const rowList = useMemo(() => {
        if (!timezone) return null;
        return list?.map((i) => {
            const initialDate = i.fini || null;
            const dateMoment = getDateToTimezone({
                date: initialDate,
                timezone: timezone.idianazone,
                returnMoment: true,
            });

            return (
                <DashboardWidgetRow
                    item={i}
                    onClick={(e) => onClick(i)}
                    leftColumn={
                        <Radio
                            onChange={(e) => {
                                e.stopPropagation();
                                onChangeTask(i);
                            }}
                            checked={
                                checkeds.hasOwnProperty(i.Id)
                                    ? checkeds?.[i.Id]
                                    : getBackendBoolean(i.finalizada)
                            }
                        />
                    }
                    title={i.Asunto || '-'}
                    subtitle={getActivityDateFormat(dateMoment)}
                />
            );
        });
    }, [checkeds, list, onChangeTask, onClick, timezone]);

    return (
        <DashboardWidget
            title={getLiteral('title_tasks')}
            // Actions
            onViewMore={onViewMore}
            onAdd={agendaPermission?.create ? onAdd : undefined}
            addTooltip={getLiteral('action_create_task')}
            // Filter
            filterLabel={getLiteral('label_task_type')}
            filterOptions={taskTypes}
            filterDefaultValue={taskTypes[0]}
            onChangeFilter={onChangeFilter}
            // State
            isLoading={loading}
            isEmpty={list?.length === 0}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_screen_tasks')}
            emptySubtitle={getLiteral('label_empty_screen_tasks_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {rowList}
        </DashboardWidget>
    );
});

export default TaskDashboardWidget;

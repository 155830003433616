import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { Shapes } from '../containers/settings/CompanyManagement/components/CompanyLogo/Logo';
import { CompanySettingsModel } from '../models';
import IntegrationModel from '../models/IntegrationModel';

export default class SettingsManager {
    getCompanySettingsSchema() {
        return [
            {
                description: '_INFORMATION',
                show: true,
                tabFields: [
                    {
                        id: { id: 'idenvironment', description: 'environmentName' },
                        server: 'environmentsId',
                        description: '',
                        dataType: 'singleValueList',
                        valueListName: 'tblSucursales',
                        hideClear: true,
                    },

                    {
                        id: 'reportimg',
                        server: 'reportImg',
                        type: Shapes.SQUARE,
                        rounded: false,
                        title: getLiteral('label_report_logo'),
                        description: getLiteral('helptext_logo_report'),
                        tooltip: 'label_logo_report_tooltip',
                        dataType: 'logo',
                    },
                    {
                        id: 'companyimg',
                        server: 'companyImg',
                        type: Shapes.CIRCLE,
                        rounded: true,
                        title: getLiteral('label_company_logo'),
                        description: getLiteral('helptext_logo_company'),
                        tooltip: 'label_logo_company_tooltip',
                        dataType: 'logo',
                    },
                    {
                        id: 'hr',
                        dataType: 'hr',
                    },
                    {
                        id: 'name',
                        server: 'Name',
                        description: getLiteral('label_name'),
                        dataType: 'text',
                    },
                    {
                        id: 'vatnumber',
                        server: 'vatNumber',
                        description: getLiteral('label_vat_number'),
                        dataType: 'text',
                    },
                    {
                        id: 'phone',
                        server: 'Phone',
                        description: getLiteral('label_phone'),
                        dataType: 'text',
                    },
                    {
                        id: 'address1',
                        server: 'AdressLine1',
                        description: getLiteral('label_adress_line1'),
                        dataType: 'text',
                    },
                    {
                        id: 'address2',
                        server: 'AdressLine2',
                        description: getLiteral('label_adress_line2'),
                        dataType: 'text',
                    },
                    {
                        id: 'city',
                        server: 'Town',
                        description: getLiteral('label_city_postal_code'),
                        dataType: 'text',
                    },
                    {
                        id: 'region',
                        server: 'Region',
                        description: getLiteral('label_provinceregion'),
                        dataType: 'text',
                    },
                    {
                        id: { id: 'idCountry', description: 'countryName' },
                        server: 'Country',
                        description: getLiteral('label_country'),
                        dataType: 'singleValueList',
                        valueListName: 'tblCountries',
                        hideClear: false,
                    },
                    {
                        id: 'legaltext',
                        server: 'LegalText',
                        description: getLiteral('label_legal_text'),
                        multiLine: true,
                        tooltip: 'helptext_legal_text',
                        dataType: 'text',
                    },
                ],
            },
        ];
    }

    getCompanySettings(success, error) {
        this.context.domainManager.getCompanySettings((data) => {
            let tblSucursales = this.context.store.getState().valueList.get('tblSucursales');
            tblSucursales = tblSucursales.toJS();
            if (data.Result[0]) {
                const matchingEnvironment = tblSucursales.data.filter(
                    (elem) => elem['-id'] === String(data.Result[0].idEnvironment),
                );
                if (matchingEnvironment.length > 0) {
                    data.Result[0].environmentName = matchingEnvironment[0].descripcion;
                } else {
                    data.Result[0].environmentName = '';
                }
            }
            data = new CompanySettingsModel(data.Result[0]);
            success(data);
        }, error);
    }

    getCompanySettingsEnvironment(idEnvironment, tblSucursales, success, error) {
        this.context.domainManager.getCompanySettingsEnvironment(
            idEnvironment,
            (data) => {
                if (data.Result) {
                    const matchingEnvironment = tblSucursales.filter(
                        (elem) => elem['-id'] === String(idEnvironment),
                    );
                    if (data.Result.length > 0) {
                        if (matchingEnvironment.length > 0) {
                            data.Result[0].environmentName = matchingEnvironment[0].descripcion;
                            data.Result[0].idEnvironment = parseInt(matchingEnvironment[0]['-id']);
                        } else {
                            data.Result[0].environmentName = '';
                        }
                        data = new CompanySettingsModel(data.Result[0]);
                        success(data);
                    } else {
                        // we need to show the crud empty
                        const model = new CompanySettingsModel();
                        if (matchingEnvironment.length > 0) {
                            model.environmentName = matchingEnvironment[0].descripcion;
                            model.idEnvironment = parseInt(matchingEnvironment[0]['-id']);
                        }
                        success(model);
                    }
                } else {
                    error('unknown');
                }
            },
            error,
        );
    }

    setCompanySettingsEnvironment(idEnvironment, entity, success, error) {
        entity = entity.save;
        this.context.domainManager.setCompanySettingsEnvironment(
            idEnvironment,
            entity,
            success,
            error,
        );
    }

    setCompanySettings(entity, success, error) {
        entity = entity.save;
        this.context.domainManager.setCompanySettings(entity, success, error);
    }

    getTblSucursales(success, error) {
        this.context.domainManager.getValueList(
            'tblSucursales',
            (response) => {
                let resultValue = response.data.tblSucursales;
                resultValue.constructor === Object ? (resultValue = [resultValue]) : resultValue;
                success(resultValue);
            },
            error,
        );
    }

    getIntegrations(success, error) {
        this.context.domainManager.getIntegrations((data) => {
            if (Array.isArray(data)) {
                data = data.map((provider) => new IntegrationModel(provider));
                success(data);
            } else {
                error();
            }
        }, error);
    }

    setIntegrations(id, model, success, error) {
        model = model.save;
        this.context.domainManager.setIntegrations(id, model, success, error);
    }

    validateOauth(providerId, okCallback, nokCallback, error) {
        const properties = {
            validauth: true,
        };
        this.context.domainManager.getIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (data && data.Result && data.Result.validauth === 'ok') {
                    okCallback();
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getCronStatus(providerId, okCallback, nokCallback, error) {
        const properties = {
            cronstatus: true,
        };
        this.context.domainManager.getIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (data && data.Result && data.Result.cronstatus) {
                    if (data.Result.cronstatus === 'enabled') {
                        okCallback(data.Result.cronstatus);
                    } else {
                        nokCallback(data.Result.cronstatus);
                    }
                }
            },
            error,
        );
    }

    mismatchedTickets(okCallback, nokCallback, error) {
        this.context.domainManager.mismatchedTickets((response) => {
            if (response && response.success && response.data) {
                okCallback(response.data);
            } else {
                nokCallback();
            }
        }, error);
    }

    setActivation(providerId, okCallback, error) {
        const properties = {
            active: true,
        };
        Context.domainManager.setIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (data && data.Result && data.Result.active === 'ok') {
                    okCallback();
                } else {
                    error();
                }
            },
            error,
        );
    }

    disableIntegration(providerId, okCallback, error) {
        const properties = {
            cron: false,
            widget: false,
            active: false,
            deletedata: true,
            deleteConfiguration: true,
        };

        this.context.domainManager.setIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (
                    data &&
                    data.Result &&
                    data.Result.active === 'ok' &&
                    data.Result.cron === 'ok'
                ) {
                    okCallback();
                }
            },
            error,
        );
    }

    pauseIntegration(providerId, okCallback, nokCallback, error) {
        const properties = {
            cron: 'pause',
        };

        this.context.domainManager.setIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (data && data.Result && data.Result.cron === 'ok') {
                    okCallback();
                }
            },
            (data) => {
                if (data && data.Result && data.Result.cron === 'nok') {
                    nokCallback();
                }
            },
            error,
        );
    }

    resumeIntegration(providerId, okCallback, nokCallback, error) {
        const properties = {
            cron: 'resume',
        };

        this.context.domainManager.setIntegrationProperties(
            providerId,
            properties,
            (data) => {
                if (data && data.Result && data.Result.cron === 'ok') {
                    okCallback();
                }
            },
            (data) => {
                if (data && data.Result && data.Result.cron === 'nok') {
                    nokCallback();
                }
            },
            error,
        );
    }

    setUsersProviderRoles(providerName, properties, okCallback, error) {
        this.context.domainManager.setUsersProviderRoles(
            providerName,
            properties,
            (data) => {
                okCallback();
            },
            error,
        );
    }

    getUsersProviderRoles(providerName, offset, maxResults, okCallback, nokCallback, error) {
        this.context.domainManager.getUsersProviderRoles(
            providerName,
            offset,
            maxResults,
            (response) => {
                if (response && response.Result && response.Result.length > 0) {
                    okCallback(response.Result);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getBlackListedEmails(offset, maxResults, okCallback, nokCallback, error) {
        this.context.domainManager.getBlackListedEmails(
            offset,
            maxResults,
            (response) => {
                if (response && response.Result) {
                    okCallback(response);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    setConfigItemStatus(properties, okCallback, error) {
        this.context.domainManager.setConfigItemStatus(
            properties,
            (data) => {
                okCallback();
            },
            error,
        );
    }

    refreshWebTemplates(success, error) {
        this.context.domainManager.refreshWebTemplates(success, error);
    }

    sageSyncroStats(okCallback, nokCallback, error) {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const d = date.getDate();
        const properties = {
            syncroDate: `${y}-${m}-${d}`,
        };
        this.context.domainManager.sageSyncroStats(
            properties,
            (response) => {
                if (response && response.success && response.data) {
                    okCallback(response);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getFmAndSalesforceUsers(okCallback, nokCallback, error) {
        this.context.domainManager.getSalesforceUsers((response) => {
            if (response && response.success && response.data) {
                okCallback(response.data);
            } else {
                nokCallback();
            }
        }, error);
    }

    setSalesforceFMUserRelation(properties, okCallback, error) {
        this.context.domainManager.insertMatchedEntities(
            properties,
            (data) => {
                okCallback();
            },
            error,
        );
    }

    createOrUpdateJob(idprovider, properties, okCallback, error) {
        this.context.domainManager.integrationJobActions(
            idprovider,
            properties,
            (data) => {
                okCallback();
            },
            error,
        );
    }

    setProviderUserConfiguration(idprovider, properties, okCallback, error) {
        this.context.domainManager.setProviderUserConfiguration(
            idprovider,
            properties,
            (data) => {
                okCallback();
            },
            error,
        );
    }

    getSalesforceSyncroLogs(okCallback, nokCallback, error) {
        this.context.domainManager.getSalesforceSyncLog((response) => {
            if (response && response.success && response.data) {
                okCallback(response.data);
            } else {
                nokCallback();
            }
        }, error);
    }

    getSyncroLogs(provider, okCallback, nokCallback, error) {
        this.context.domainManager.getSyncroLogs(
            provider,
            (response) => {
                if (response && response.success && response.data) {
                    okCallback(response.data);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getSyncroStats(provider, okCallback, nokCallback, error) {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const properties = {
            syncroDate: `${y}-${m}-${d}`,
        };
        this.context.domainManager.getSyncroStats(
            provider,
            properties,
            (response) => {
                if (response && response.success && response.data) {
                    okCallback(response);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getExcelExportToken(entity, okCallback, nokCallback, error) {
        this.context.domainManager.getExcelExportToken(
            entity,
            (response) => {
                if (response && response.token) {
                    okCallback(response.token);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    startExcelExport(entity, token, isDownload, okCallback, nokCallback, error) {
        this.context.domainManager.startExcelExport(
            entity,
            token,
            isDownload,
            (response) => {
                if (response && response.data) {
                    okCallback(response.data);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getExcelExportStatus(token, okCallback, nokCallback, error) {
        this.context.domainManager.getExcelExportStatus(
            token,
            (response) => {
                if (response && response.data) {
                    okCallback(response.data);
                } else {
                    nokCallback();
                }
            },
            error,
        );
    }

    getCancelSubscriptionReasons(success, error) {
        this.context.domainManager.getCancelSubscriptionReasons((data) => {
            success(data);
        }, error);
    }

    saveCancelSubscription(reason, success, error) {
        this.context.domainManager.saveCancelSubscription(
            reason,
            (data) => {
                success(data);
            },
            error,
        );
    }

    startUpgrade(accountCode, planCode) {
        return this.context.domainManager.startUpgrade({
            AccountCode: accountCode,
            PlanCode: planCode,
        });
    }

    upgradeAccount(accountCode, planCode) {
        return this.context.domainManager.upgradeAccount({
            AccountCode: accountCode,
            PlanCode: planCode,
        });
    }

    sendEmailType(data) {
        return this.context.domainManager.sendEmailType(data);
    }
}

import React, { PureComponent } from 'react';
import ButtonBulkOutline from './ButtonBulkOutline';

class ButtonBulkDeleteOutline extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return <ButtonBulkOutline {...props} classes={['fm-button__bulk-delete', ...classes]} />;
    }
}

export default ButtonBulkDeleteOutline;

import React, { memo, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, Icon, Link } from 'hoi-poi-ui';
import { DanaActions } from 'actions';
import Comment from '../Comment';
import { getLiteral } from 'utils/getLiteral';
import { ChatGPTService } from 'services';
import { getPartner } from 'lib/partners';
import Feedback from '../Feedback';

import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        danaVote: bindActionCreators(DanaActions, dispatch).danaVote,
    };
};

const Chat = memo(
    ({
        chatId,
        conversationId,
        conversation,
        lastError,
        avatar,
        onResend,
        previousInteractions,
        getChatBoxRef,
        onCopy,
        danaVote,
    }) => {
        const partner = getPartner();
        const [feedbackText, setFeedbackText] = useState('');
        const [activeVote, setActiveVote] = useState('');
        const feedbackIdRef = useRef(null);

        const resend = useCallback(
            (interactionId, parentId) => () => {
                return onResend({ interactionId, parentId });
            },
            [onResend],
        );

        const handleOnCopy = useCallback(
            (value) => () => {
                if (!value.content && value.role === 'user') return null;
                onCopy && onCopy(value);
            },
            [onCopy],
        );

        const getInteractionIndex = useCallback(
            (interactionId) => {
                const responsesIds = Object.entries(conversation).reduce((arr, [key, value]) => {
                    if (value.role !== 'user') {
                        arr.push(key);
                    }
                    return arr;
                }, []);

                const index = responsesIds.indexOf(interactionId);
                if (index === -1) return null;
                return index;
            },
            [conversation],
        );

        const keepVote = useCallback(
            (vote) => {
                setActiveVote(vote || '');
            },
            [setActiveVote],
        );

        const onVote = useCallback(
            (interactionId) => (newVote) => () => {
                if (!newVote) return;
                const index = getInteractionIndex(interactionId);
                if (index === null) return;

                ChatGPTService.sendDanaChatFeedback({ conversationId, index, vote: newVote }).then(
                    (id) => {
                        feedbackIdRef.current = id;
                        danaVote({ chatId, interactionId, vote: newVote });
                        keepVote(newVote);
                    },
                );
            },
            [keepVote, chatId, danaVote, getInteractionIndex, conversationId],
        );

        const onCloseFeedback = useCallback(() => {
            setFeedbackText('');
            keepVote();
            feedbackIdRef.current = null;
        }, [keepVote]);

        const onConfirmFeedback = useCallback(
            (text) => {
                ChatGPTService.updateDanaChatFeedback({
                    id: feedbackIdRef.current,
                    feedback: feedbackText,
                }).then(() => {
                    onCloseFeedback();
                });
            },
            [onCloseFeedback, feedbackText],
        );

        if (!conversation) return null;

        return (
            <div className="dana-chat-conversation">
                {Object.entries(conversation).map(([key, value], index) => {
                    if (value.error && lastError === key) {
                        return (
                            <div className="dana-chat-conversation-error" key={key}>
                                <Icon name="infoOutlined" />
                                <Text type="body" color="neutral700">
                                    {getLiteral('label_dana_chat_error_not_sent')}
                                </Text>
                                <Link type="primary" onClick={resend(key, value.parentId)}>
                                    {getLiteral('action_dana_chat_error_try_again')}
                                </Link>
                            </div>
                        );
                    }
                    if (value.error && lastError !== key) {
                        return (
                            <div className="dana-chat-conversation-error" key={key}>
                                <Icon name="infoOutlined" />
                                <Text type="body" color="neutral700">
                                    {getLiteral('label_dana_chat_error_not_sent')}
                                </Text>
                            </div>
                        );
                    }

                    // previousInteractions helps to identify those interactions from chatgpt
                    // that we can print from the store. The other ones have to be injected
                    // directly to the html in order to be able to display graphics and others without flickerings
                    const shouldPassText = !!previousInteractions?.[key] || value.role === 'user';

                    return (
                        <Comment
                            key={key}
                            chatId={chatId}
                            conversationId={conversationId}
                            interactionId={key}
                            getChatBoxRef={getChatBoxRef}
                            // Text is injected via DOM innerHTML when we write the stream
                            text={shouldPassText ? value.content : ''}
                            onCopy={onCopy && value.role !== 'user' && handleOnCopy(value)}
                            avatar={value.role === 'user' ? avatar : partner.DanaIcon}
                            isUser={value.role === 'user'}
                            isProgress={!!value?.progress && !value?.content}
                            loading={value.loading || false}
                            isError={value.error || false}
                            finished={value.finished}
                            vote={value.vote}
                            onVote={onVote(key)}
                            reports={value.reports || null}
                            tables={value.tables || null}
                            creationDate={value.creationDate || null}
                        />
                    );
                })}
                <Feedback
                    vote={activeVote}
                    value={feedbackText}
                    onChange={setFeedbackText}
                    onRequestClose={onCloseFeedback}
                    onClose={onCloseFeedback}
                    onConfirm={onConfirmFeedback}
                />
            </div>
        );
    },
);

export default connect(null, mapDispatchToProps)(Chat);

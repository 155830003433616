import React from 'react';

const MdbA = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g transform="translate(.563 .563)" fill="none" fillRule="evenodd">
            <path fill="#A13638" d="M0 1.876V15l9.492 1.876V0z"></path>
            <path
                d="M5.924 9.805H3.328l-.543 1.56h-1.21l2.53-6.702h1.046l2.536 6.702H6.472l-.548-1.56zm-2.27-.94h1.943l-.971-2.78-.971 2.78z"
                fill="#FFF"
            ></path>
            <path
                d="M12.378 3.445h-2.224v3.032a19.63 19.63 0 002.69-.033c.645-.053 1.241-.137 1.765-.247 1.122-.235 1.912-.586 2.145-.989a.49.49 0 00.072-.247c0-.748-1.91-1.373-4.448-1.516"
                fill="#A13638"
            ></path>
            <path
                d="M15.54 6.444a8.389 8.389 0 01-.931.248c-.93.195-2.088.31-3.343.31-.38 0-.752-.01-1.112-.03V8.95a19.782 19.782 0 002.69-.033 14.43 14.43 0 001.765-.248c1.122-.235 1.913-.586 2.145-.988a.492.492 0 00.072-.248V5.455c0 .377-.483.721-1.285.99"
                fill="#A13638"
            ></path>
            <path
                d="M15.54 8.917a8.389 8.389 0 01-.931.247c-.93.195-2.088.31-3.343.31-.38 0-.752-.01-1.112-.03v1.978c.36.02.731.03 1.112.03a17.53 17.53 0 002.908-.228c1.35-.23 2.319-.618 2.58-1.071a.492.492 0 00.072-.247V7.928c0 .376-.483.72-1.285.989"
                fill="#A13638"
            ></path>
            <path
                d="M15.275 11.472c-1.012.293-2.433.475-4.009.475-.38 0-.752-.01-1.112-.03V14.058a19.788 19.788 0 003.012-.062c2.135-.216 3.66-.785 3.66-1.454V10.4c0 .416-.592.793-1.551 1.072"
                fill="#A13638"
            ></path>
        </g>
    </svg>
);

export default MdbA;

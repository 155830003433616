import React, { memo, useCallback, useMemo } from 'react';
import { ATTENDEE_STATUS, ATTENDEE_TYPES } from 'constants/Constants';
import { Icon, Text, useTheme } from 'hoi-poi-ui';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const AttendeesRow = memo(({ attendee }) => {
    const theme = useTheme();
    const getIcon = useCallback(() => {
        let icon;
        switch (attendee.Status) {
            case ATTENDEE_STATUS.accepted:
                icon = <Icon name="checkCircle" color={theme.colors.green500} />;
                break;
            case ATTENDEE_STATUS.rejected:
                icon = <Icon name="cancel" color={theme.colors.red500} />;
                break;
            case ATTENDEE_STATUS.tentative:
                icon = <Icon name="help" color={theme.colors.yellow400} />;
                break;
            case ATTENDEE_STATUS.noResponse:
            default:
                icon = <Icon name="helpOutline" color={theme.colors.neutral700} />;
                break;
        }

        return icon;
    }, [attendee, theme]);

    const isOrganizer = useMemo(() => {
        return getBackendBoolean(attendee.Organizer);
    }, [attendee]);

    const name = useMemo(() => {
        if (attendee.AttendeeType === ATTENDEE_TYPES.externalAttendee) {
            if (attendee.Name) return attendee.Name;
            else return attendee.Address;
        }
        return attendee.Name;
    }, [attendee]);

    return (
        <div className="fm-agenda-small-detail__attendee-row">
            {getIcon(attendee)}
            <Text className="attendees-row__text">{name}</Text>
            {isOrganizer && (
                <Text className="attendees-row__text-organizer" color="neutral700">
                    {`(${getLiteral('label_organizer')})`}
                </Text>
            )}
        </div>
    );
});

export default AttendeesRow;

import { Map, List } from 'immutable';
import { RATES } from '../../constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';

import { RatesModel } from '../../models';

export default class RatesManager {
    constructor() {}

    getCrudSchema = (config, entityConfig, success, error) => {
        const ratesSchema = [
            {
                description: getLiteral('label_info'),
                show: true,
                tabFields: [
                    {
                        id: 'name',
                        server: 'name',
                        description: getLiteral('label_name_rate'),
                        dataType: 'text',
                    },
                    {
                        id: 'description',
                        server: 'description',
                        description: getLiteral('label_description'),
                        dataType: 'text',
                    },
                    {
                        id: { id: 'idCurrency', desc: 'currency' },
                        server: 'idCurrency',
                        description: getLiteral('label_currency'),
                        dataType: 'singleValueList',
                        valueListName: 'tblCurrency',
                        mandatory: true,
                    },
                    {
                        id: 'startDate',
                        server: 'startDate',
                        description: getLiteral('label_start_date'),
                        dataType: 'date',
                        position: 'right',
                    },
                    {
                        id: 'endDate',
                        server: 'endDate',
                        description: getLiteral('label_end_date'),
                        dataType: 'date',
                        position: 'right',
                    },
                    {
                        id: { id: 'strIdEnvironment', desc: 'branch' },
                        server: 'strIdEnvironment',
                        dataType: 'multipleValueList',
                        description: getLiteral('label_environment'),
                        valueListName: 'tblSucursales',
                        position: 'right',
                    },
                ],
            },
        ];

        const companyRates = [
            {
                description: '#',
                width: 35,
                show: true,
                server: '_',
                dataType: 'position',
                custom: true,
                fixed: true,
            },
            {
                description: getLiteral('title_company'),
                width: 150,
                show: true,
                dataType: 'staticText',
                id: 'companyName',
                custom: true,
                fixed: true,
                flex: 1,
                hasInput: false,
                resize: false,
                canRemove: true,
            },
        ];

        const productRates = [
            {
                description: '#',
                width: 35,
                show: true,
                server: '_',
                dataType: 'position',
                custom: true,
                fixed: true,
            },
            {
                description: getLiteral('title_products'),
                width: 300,
                show: true,
                dataType: 'productName',
                id: 'name',
                custom: true,
                fixed: true,
                flex: 1,
                hasInput: false,
                resize: false,
                canRemove: true,
            },
            {
                description: getLiteral('label_price'),
                width: 150,
                show: true,
                dataType: 'currency',
                id: 'ratePrice',
                custom: true,
                fixed: false,
                resize: false,
            },
            {
                description: getLiteral('label_rates_discount1'),
                width: 150,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount1',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount2'),
                width: 150,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount2',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount3'),
                width: 150,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount3',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount4'),
                width: 150,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount4',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_discount'),
                width: 150,
                show: true,
                dataType: 'percent',
                id: 'totalDiscount',
                custom: true,
                fixed: false,
                resize: true,
                readOnly: true,
                default_value: '',
            },
        ];

        const familyRates = [
            {
                description: '#',
                width: 35,
                show: true,
                server: '_',
                dataType: 'position',
                custom: true,
                fixed: true,
            },
            {
                description: getLiteral('label_family'),
                width: 150,
                show: true,
                dataType: 'staticText',
                className: 'prova',
                id: 'familyName',
                custom: true,
                fixed: true,
                flex: 1,
                hasInput: false,
                resize: false,
                canRemove: true,
            },
            {
                description: getLiteral('label_rates_discount1'),
                width: 200,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount1',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount2'),
                width: 200,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount2',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount3'),
                width: 200,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount3',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_rates_discount4'),
                width: 200,
                show: true,
                dataType: 'percent',
                id: 'rateDiscount4',
                custom: true,
                fixed: false,
                resize: false,
                default_value: '',
            },
            {
                description: getLiteral('label_discount'),
                width: 200,
                show: true,
                dataType: 'percent',
                id: 'totalDiscount',
                custom: true,
                fixed: false,
                resize: true,
                readOnly: true,
                default_value: '',
            },
        ];

        this.context.crudManager.getCrudSchema(
            config,
            entityConfig,
            ratesSchema,
            (schema) => {
                const finalSchema = {
                    form: schema,
                    productrates: productRates,
                    familyrates: familyRates,
                    companyrates: companyRates,
                };
                success(finalSchema);
            },
            error,
        );
    };

    getDefaultEntityCrudHardCode = (crudEntity) => {
        const config = this.context.cacheManager.getConfigStore();
        return (
            crudEntity
                .set('productrates', List())
                // .set('generaldiscounts', List())
                .set('familyrates', List())
                .set('companyrates', List())
                .set('currencysymbol', config.userData.currencySymbol)
        );
    };

    getRates(offset, pageSize, filterSearch, successCallback, errorCallback) {
        this.context.domainManager.getRates(
            offset,
            pageSize,
            filterSearch,
            (data) => {
                if (data.State === '1') {
                    successCallback(data.Result, data.TotalRows);
                } else {
                    errorCallback();
                }
            },
            errorCallback,
        );
    }

    refreshData(finish) {
        this.context.store.dispatch(this.context.actions.RatesActions.refreshData(finish));
    }

    getRatesAll(success, error) {
        const offset = 0;
        const pageSize = 500;
        const filterSearch = '';
        this.getRates(
            offset,
            pageSize,
            filterSearch,
            (data) => {
                success(data);
            },
            error,
        );
    }

    getEntity(id, success, error) {
        const entity = new Promise((resolve, reject) => {
            this.getRate(id, resolve, reject);
        });

        const productRates = new Promise((resolve, reject) => {
            this.getProductsRates(id, resolve, reject);
        });

        const familyRates = new Promise((resolve, reject) => {
            this.getFamilyRates(id, resolve, reject);
        });

        const companyRates = new Promise((resolve, reject) => {
            this.getCompanyRates(id, resolve, reject);
        });

        Promise.all([entity, productRates, familyRates, companyRates]).then((result) => {
            success(new RatesModel(result[0], result[1], result[2], result[3]));
        }, error);
    }

    getRate(id, success, error) {
        this.context.domainManager.getEntity(
            id,
            RATES.entity,
            true,
            (data) => {
                // success(new RatesModel(data));
                success(data);
            },
            error,
        );
    }

    getProductsRates(id, success, error) {
        this.context.domainManager.getProductsRates(
            id,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getFamilyRates(id, success, error) {
        this.context.domainManager.getFamilyRates(
            id,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getCompanyRates(id, success, error) {
        this.context.domainManager.getCompanyRates(
            id,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    getErrors(schema, entityCrud, dynamic) {
        const errorsObject = this.context.crudManager.getErrors(schema.form, entityCrud, dynamic);
        let errors = errorsObject.errors;

        const model = RatesModel.fromCrud(entityCrud);
        errors = getErrorsMap(model.productRates, schema.productrates, errors, 'productrates');
        errors = getErrorsMap(model.familyRates, schema.familyrates, errors, 'familyrates');
        errors = getErrorsMap(model.companyRates, schema.companyrates, errors, 'companyrates');

        errorsObject.errors = errors;

        return errorsObject;
    }

    createEntity(entity, success, error) {
        const crud = RatesModel.fromCrud(entity);
        this.context.domainManager.createEntity(
            RATES.entity,
            crud.saveCrud,
            () => {
                Context.serverListManager
                    .getRatesServerList()
                    .then((data) => {
                        this.context.cacheManager.setServerList('ratesValueList', data);
                    })
                    .then(() => success())
                    .catch(error);
            },
            error,
        );
    }

    updateEntity(id, entity, success, error) {
        const crud = RatesModel.fromCrud(entity);
        this.context.domainManager.updateEntity(
            RATES.entity,
            id,
            crud.saveCrud,
            () => {
                Context.serverListManager
                    .getRatesServerList()
                    .then((data) => {
                        this.context.cacheManager.setServerList('ratesValueList', data);
                    })
                    .then(() => success())
                    .catch(error);
            },
            error,
        );
    }

    transformDataForEntityList = (backendData) => {
        let totalRows = backendData.TotalRows;
        if (backendData.State === '1' && totalRows === -1) {
            totalRows = 0;
        }
        return {
            Data: backendData.Result,
            MetaData: { totalRows: totalRows },
        };
    };
}

const getErrorsMap = (model, schema, errors, schemaName) => {
    let errorList = Map();
    model.forEach((specificModel, index) => {
        let error = Map();
        schema.forEach((schemaProduct) => {
            if (
                schemaProduct.mandatory &&
                IsEmpty(specificModel.entity[schemaProduct.id.toLowerCase()])
            ) {
                error = error.set(schemaProduct.id.toLowerCase(), '  ');
            }
        });
        if (error.size > 0) {
            errorList = errorList.set(index, error);
        }
    });
    if (errorList.size > 0) {
        errors = errors.set(schemaName, errorList);
    }
    return errors;
};

import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';
import moment from 'moment';
import { getElapsedTime } from 'utils/dates';

const ElapsedTimeRow = memo(({ time }) => {
    if (!time) return null;

    const elapsedTime = getElapsedTime(moment(time));

    return (
        <div className="elapsed-time-row">
            <Text>{elapsedTime}</Text>
        </div>
    );
});

export default ElapsedTimeRow;

import CrudModel from './CrudModel';
import { fromExtraFields, fromExtraFieldsV2, toCrudV2SelectValue } from 'utils/crud';
import { getSrcCompanyChipAlternative, getSrcContactCircleAvatar } from 'utils/getSrcAvatar';
import { getBackendBoolean } from 'utils/fm';

export default class ContactModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static standardToCrud(data, mappedSchema) {
        const { Extrafields, Id, IdCompany, Company, ...rest } = data;
        let companyObj;
        if (IdCompany && Company)
            companyObj = {
                value: IdCompany,
                label: Company,
            };

        return {
            ...rest,
            id: Id,
            IdCompany: companyObj,
            isReadOnly: getBackendBoolean(data.IsReadOnly),
            blnUseCompanyAddress: getBackendBoolean(data.blnUseCompanyAddress),
            ...fromExtraFields(
                data.Extrafields && data.Extrafields.ExtraFieldUnit
                    ? data.Extrafields.ExtraFieldUnit
                    : [],
                mappedSchema,
            ),
        };
    }

    static toCrudV1(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);

        return {
            ...baseData,
            ...fromExtraFields(
                data.Extrafields && data.Extrafields.ExtraFieldUnit
                    ? data.Extrafields.ExtraFieldUnit
                    : [],
                mappedSchema,
            ),
        };
    }

    static toCrudV2(data, mappedSchema) {
        let baseData = this.standardToCrud(data, mappedSchema);
        const extraFields = fromExtraFieldsV2(
            data.Extrafields && data.Extrafields.ExtraFieldUnit
                ? data.Extrafields.ExtraFieldUnit
                : [],
            mappedSchema,
        );

        let v2Data = toCrudV2SelectValue(baseData, [
            'Gender',
            'IdContactType',
            'IdCompany',
            'IdCountry',
        ]);

        return { ...baseData, v2Data, ...extraFields };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toDetail(data) {
        return {
            ...Object.keys(data).reduce((obj, key) => {
                obj[key.charAt(0).toLowerCase() + key.slice(1)] = data[key];
                return obj;
            }, {}),
            isReadOnly: getBackendBoolean(data.IsReadOnly),
            blnUseCompanyAddress: getBackendBoolean(data.blnUseCompanyAddress),
        };
    }

    static toList({ data }) {
        const newData = data.map((current) => {
            let srcContact = '';
            let placeholderContact = '';
            let srcCompany = '';
            let placeholderCompany = '';

            const { src, fallbackSrc } = getSrcContactCircleAvatar(current.Id);
            placeholderContact = fallbackSrc;
            srcContact = src;

            // this idLogo should be the same image as if we ask backend for current.IdCompany,
            // but backend asked someday to get the company image with this idLogo
            const logoImage = getSrcCompanyChipAlternative(current.idLogo);
            placeholderCompany = logoImage.fallbackSrc;
            srcCompany = logoImage.src;

            const flattenExtraFields = Object.keys(current).reduce((newObj, key) => {
                newObj[key] = current[key]?.hasOwnProperty('value')
                    ? current[key]?.value
                    : current[key];
                return newObj;
            }, {});

            let obj = {
                rawData: current,
                ...flattenExtraFields,
                gender: current.Gender,
                genderDescription: current.GenderDescription,
                srcContact,
                placeholderContact,
                srcCompany,
                placeholderCompany,
                currencySymbol: current.SymbolCurrency,
            };
            return obj;
        });

        return newData || [];
    }
}

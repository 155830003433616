import {
    KPIS_LOAD_TEAM_GOALS,
    KPIS_LOAD_TEAM_GOALS_SUCCESS,
    KPIS_LOAD_TEAM_GOALS_ERROR,
    KPIS_SET_DRAG_GROUPS,
    KPIS_UPDATE_DRAG_GROUPS,
    KPIS_LOAD_TYPES,
    KPIS_LOAD_TYPES_SUCCESS,
    KPIS_LOAD_TYPES_ERROR,
} from 'constants/ActionTypes';
import { KPIS } from 'constants/Entities';
import Context from 'managers/Context';
import { GoalsService } from 'services';

const getManager = () => Context.entityManager.getEntitiesManager(KPIS);

export function getKpiTypes(kpi) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const kpiEntity = kpi.entity;
            const kpiTypes = getState().kpis.types && getState().kpis.types[kpiEntity];
            if (kpiTypes && kpiTypes.data && !kpiTypes.error && !kpiTypes.loading) {
                return resolve(kpiTypes.data);
            }

            let types = Context.cacheManager.getKpiTypes(kpiEntity);
            if (types) {
                dispatch({ type: KPIS_LOAD_TYPES_SUCCESS, entity: kpi.entity, types });
                return resolve(types);
            }
            dispatch({ type: KPIS_LOAD_TYPES, entity: kpiEntity });
            GoalsService.getKpiTypes(kpi.entity)
                .then((data) => {
                    data = getManager().checkKpiTypesFields(kpiEntity, data);
                    dispatch({ type: KPIS_LOAD_TYPES_SUCCESS, entity: kpi.entity, data });
                    Context.cacheManager.setKpiTypes(kpiEntity, data);
                    resolve(data);
                })
                .catch((error) => {
                    dispatch({ type: KPIS_LOAD_TYPES_ERROR, entity: kpi.entity, error });
                    reject(error);
                });
        });
}

export function getGoalDrilldown(id) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            GoalsService.getGoalDrilldown(id)
                .then(resolve)
                .catch(() => {
                    console.error('Impossible to get drill down from goal');
                    reject();
                });
        });
}

export function getTeamGoals(id) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: KPIS_LOAD_TEAM_GOALS,
            });
            getManager()
                .getTeamGoalsList(id)
                .then((data) => {
                    dispatch({
                        type: KPIS_LOAD_TEAM_GOALS_SUCCESS,
                        payload: { id, data },
                    });
                    resolve(data);
                })
                .catch(() => {
                    dispatch({ type: KPIS_LOAD_TEAM_GOALS_ERROR });
                    reject();
                });
        });
    };
}

export function setDragGroups(dragGroups) {
    return function (dispatch) {
        dispatch({ type: KPIS_SET_DRAG_GROUPS, payload: { dragGroups } });
    };
}

export function updateDragGroups(dragGroups) {
    return function (dispatch) {
        dispatch({ type: KPIS_UPDATE_DRAG_GROUPS, payload: { dragGroups } });
    };
}

export function updateOrder(dragged, targetFullListIndex) {
    return function (dispatch) {
        return getManager().updateOrder(dragged, targetFullListIndex);
    };
}

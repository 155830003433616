export default ({ text, color }) => ({
    title: {
        ...text.text_md,
        marginRight: 16,
        width: '150px',
        display: 'inline-block',
        marginTop: '16px',
        boxSizing: 'border-box',
        wordWrap: 'break-word',
    },
    inputStyle: {
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
        width: 'calc(100% - 15px)',
        whiteSpace: 'pre-line',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    fieldContainer: {
        boxSizing: 'border-box',
        minHeight: '44px',
        margin: '0 20px',
    },
    fieldContainerTextEditor: {
        boxSizing: 'border-box',
        minHeight: '44px',
        margin: '10px 20px 0 20px',
    },
    contentStyle: {
        width: '100%',
    },
});

import Context from 'managers/Context';
import {
    ENTITY_MAP_CLEAR,
    ENTITY_MAP_LOAD_ENTITIES,
    ENTITY_MAP_CHANGE_BOUNDS,
    ENTITY_MAP_LOAD_SUCCESS,
    ENTITY_MAP_SET_LAST_POSITION,
    ENTITY_MAP_CLOSE_INFOWINDOW,
    ENTITY_MAP_LOAD_INFOWINDOW,
    ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS,
    ENTITY_MAP_LOAD_STOP,
    ENTITY_MAP_REFRESH_LOCAL_DATA,
} from 'constants/ActionTypes';
import { checkAndIncrementMapBounds } from 'utils/map';
import { PAGINATION_MAP_ENTITY } from 'constants/Environment';

const getManager = () => Context.entityMapManager;

export function initMap(entity, force, pageSize = PAGINATION_MAP_ENTITY) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const name = entity.entity;
            const list = getState().entityMap[name] || {};
            const entityFilters = getState().entityFilters[name];
            let filters = entityFilters ? entityFilters.filters || {} : {};
            const operators = entityFilters ? entityFilters.operators || {} : {};
            if (!(force || !list.data || (list.data.length === 0 && !list.loading)))
                return reject();
            const bounds = getState().entityMap?.[name]?.bounds || {};
            dispatch({ type: ENTITY_MAP_LOAD_ENTITIES, entity: name });
            Context.entityManager
                .beforeFetchEntity(entity, getState().entityFilters[name])
                .then(({ filtersToLoad }) => {
                    if (filtersToLoad && filtersToLoad.length > 0) {
                        filtersToLoad.forEach(({ filter, values, completeValues = null }) => {
                            if (!filter || !values || values.length === 0) return;
                            filters = dispatch(
                                Context.actions.EntityFiltersActions.changeFilter({
                                    entity,
                                    filter,
                                    value: values,
                                    refresh: false,
                                    completeValues,
                                    isEntityList: false,
                                    info: null,
                                    isPreload: true,
                                }),
                            );
                        });
                    }
                    dispatch(fetchEntity(entity, 0, pageSize, filters, bounds, operators))
                        .then((data, total) => {
                            dispatch({
                                type: ENTITY_MAP_LOAD_SUCCESS,
                                entities: data,
                                total,
                                entity: name,
                            });
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error === 'No result') {
                                reject(error);
                            } else {
                                console.warn('error fetching data for map');
                            }
                        });
                });
        });
}

export function fetchEntity(entity, init, count, filters, bounds, operators) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            getManager().getGeolocatedEntities(
                entity,
                init,
                count,
                filters,
                bounds,
                operators,
                (entities, total, offset) => {
                    // discard result if bounds or filters has changed
                    if (
                        JSON.stringify(filters) === JSON.stringify(filters) &&
                        JSON.stringify(bounds) === JSON.stringify(bounds) &&
                        JSON.stringify(operators) === JSON.stringify(operators)
                    ) {
                        if (count === total) {
                            // Fetch next page if result is full
                            dispatch(
                                fetchEntity(
                                    entity,
                                    init + total,
                                    count,
                                    filters,
                                    bounds,
                                    operators,
                                ),
                            ).then((data) => {
                                resolve([...entities, ...data]);
                            });
                        } else {
                            resolve(entities);
                        }
                    }
                },
                (error) => {
                    console.error(error);
                    if (error === 'No result') {
                        // this is a special case for this method. Here, we need to call to the first
                        // page of all world to get the position of the first entity result. If there
                        // is one, then move the map to this point
                        // We move this responsibility of search any entity around the world
                        // to the original component, if it wants
                        reject(error);
                    } else {
                        dispatch(clear(entity.entity));
                    }
                },
            );
        });
}

export function searchEntityInWorld(entity) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const name = entity.entity;
            const filters = getState().entityFilters[name];
            getManager().getGeolocatedEntities(
                entity,
                0,
                1,
                filters.filters,
                {
                    latitude: 90,
                    latitudeTo: -90,
                    longitude: -180,
                    longitudeTo: 180,
                },
                (result) => {
                    if (result && result.length > 0) {
                        resolve(result[0]);
                    } else {
                        reject();
                    }
                },
                (error) => {
                    console.warn('no geolocated entities');
                    reject();
                },
            );
        });
}

export function changeBounds(entity, newBounds, hasBackendClusters, forceResult) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const name = entity.entity;
            const entityMap = getState().entityMap[name] || {};
            const currentBounds = entityMap.bounds;
            let { needToUpdate, bounds } = checkAndIncrementMapBounds(
                currentBounds,
                newBounds,
                hasBackendClusters,
            );
            if (needToUpdate) {
                dispatch({ type: ENTITY_MAP_CHANGE_BOUNDS, entity: name, bounds });
                dispatch(clear(name, false));
                dispatch(initMap(entity))
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((error) => {
                        reject({ error, forceResult });
                    })
                    .finally(() => dispatch(stopLoadingMap(entity)));
            }
        });
}

export function changeFilter(entity) {
    return function (dispatch, getState) {
        const manager = Context.entityManager.getEntitiesManager(entity);

        const shouldPreventRefreshMapFromChangeFilter =
            manager?.shouldPreventRefreshMapFromChangeFilter
                ? manager.shouldPreventRefreshMapFromChangeFilter()
                : null;
        if (shouldPreventRefreshMapFromChangeFilter) return;
        let entityMapState = getState().entityMap[entity.entity] || {};
        if (entityMapState.list && entityMapState.list.load) {
            dispatch(clear(entity.entity));
            dispatch(initMap(entity, true)).catch((error) => {
                dispatch(stopLoadingMap(entity));
            });
        }
    };
}

export function setLastPosition(entity, lat, lng, zoom) {
    return function (dispatch) {
        dispatch({ type: ENTITY_MAP_SET_LAST_POSITION, entity: entity.entity, lat, lng, zoom });
    };
}

export function stopLoadingMap(entity) {
    return (dispatch) => {
        dispatch({ type: ENTITY_MAP_LOAD_STOP, entity: entity.entity });
    };
}

export function clear(entity, resetInfoWindows = true) {
    return { type: ENTITY_MAP_CLEAR, entity, resetInfoWindows };
}

export function openInfoWindow(entity, entityId) {
    return function (dispatch) {
        const entityName = entity.entity;
        dispatch({
            type: ENTITY_MAP_LOAD_INFOWINDOW,
            entity: entityName,
            id: entityId,
        });
        Context.entityManager.getEntitiesManager(entity).getEntity(
            entityId,
            (data) => {
                dispatch({
                    type: ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS,
                    entity: entityName,
                    id: entityId,
                    data,
                });
            },
            (error) => {
                dispatch(closeInfoWindow(entity, entityId));
            },
        );
    };
}

export function closeInfoWindow(entity) {
    return function (dispatch) {
        dispatch({ type: ENTITY_MAP_CLOSE_INFOWINDOW, entity: entity.entity });
    };
}

export function refreshLocalData(entity) {
    return function (dispatch) {
        dispatch({ type: ENTITY_MAP_REFRESH_LOCAL_DATA, entity: entity.entity });
    };
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { INTERVAL_NEW_MESSAGES } from 'constants/Environment';
import { DASHBOARD_WEB } from 'constants/Features';

import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { SidebarProvider, Sidebar } from '@web/web5';
import { getRoute } from 'utils/routes';
import AnnounceKitComponent from 'containers/components/AnnounceKitComponent';
import MenuSampleData from 'containers/components/MenuSampleData';
import {
    getEnableTimeLine,
    getActivitiesNotificationsBadge,
} from 'containers/Activities/utils/timeline';

const PAGE_WIDGET_IMPLEMENTATIONS = [
    '34396', // FERROLI
    '58787', // AXA
    '66050', // AXA DUMMY
    '71124', // GES_SEGUROS
    '95083', // ECOCEMENTOS DUMMY
    '82658', // ECOCEMENTOS PRO
];

import {
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    ACTIVITIES,
    AGENDA,
    SALESORDERS,
    DOCUMENTS,
    PRODUCTS,
    KPIS,
    BILLBOARD,
    CAMPAIGNS,
    CONVERSATIONS,
    METRICS,
} from 'constants/Entities';

import { MessagesActions } from 'actions';

function mapStateToProps(state) {
    const idUser = state.config?.userData?.idUsuario;
    const notifications = state.notifications?.unread || {};
    const eventsNotifications = notifications?.events || 0;
    const tasksNotifications = notifications?.tasks || 0;
    const messages = state.messages?.unread?.all?.entities;
    const activitiesNotificationsBadge = messages ? getActivitiesNotificationsBadge(messages) : 0;

    return {
        urlPath: window.location.href,
        literals: state.literal.literals,
        permission: state.config?.permission,
        idImplementation: state.config?.userData?.idImplementacion,
        idUser: idUser ? parseInt(idUser, 10) : 0,
        analyticsPro: state.config?.permission?.analyticsPro || false,
        lookerAnalyticsViewer: state.config?.userData?.lookerAnalyticsViewer || false,
        isImpersonated: state.config?.userData?.isImpersonated,
        forbiddenPage: state.entity.forbiddenPage || false,
        calendarNotificationsBadge: eventsNotifications + tasksNotifications,
        brandingWebColor: state.config?.permission?.brandingWebColor || null,
        customLogo: {
            square: state.config?.permission?.customerSidebarLogoSquareUrlWeb || null,
            rectangular: state.config?.permission?.customerSidebarLogoRectangularUrlWeb || null,
        },
        isDeletedWalkThroughData: state.config?.userData?.isDeletedWalkThroughData,
        subscriptionModeCode: state.config?.subscriptionModeCode,
        activitiesNotificationsBadge,
        showEntityPlaceholder: state.config?.showEntityPlaceholder,
        withDashboard: state.config?.flagsmith?.[DASHBOARD_WEB] || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUnreadMessages: bindActionCreators(MessagesActions, dispatch).getUnreadMessages,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class MainNavigationToolbar extends PureComponent {
    constructor(props) {
        super(props);

        this.sections = [];
        this.schema = {};
        this.initNavigationToolbar(props);
    }

    handleGetUnreadMessages() {
        // Getting user unread messages
        // TO-DO: Refactor whenever there's a websockets service available
        if (getEnableTimeLine() && !this.getUnreadMessagesInterval) {
            this.getUnreadMessagesInterval = setInterval(
                () => this.props.getUnreadMessages(),
                INTERVAL_NEW_MESSAGES,
            );
        }
    }

    componentDidMount() {
        this.handleGetUnreadMessages();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.permission !== this.props.permission ||
            prevProps.calendarNotificationsBadge !== this.props.calendarNotificationsBadge ||
            prevProps.activitiesNotificationsBadge !== this.props.activitiesNotificationsBadge
        ) {
            this.sections = [];
            this.schema = {};
            this.initNavigationToolbar(this.props);
            this.forceUpdate();
        }
        if (prevProps.urlPath !== window.location.href) {
            this.updatePath();
        }

        this.handleGetUnreadMessages();
    }

    componentWillUnmount() {
        if (this.getUnreadMessagesInterval) clearInterval(this.getUnreadMessagesInterval);
    }

    onClick = (entityName, submodule) => {
        return () => {
            let obj = {
                event: entityName,
                functionality: 'menuClick',
            };

            if (submodule) obj.submodule = submodule;

            logEvent(obj);
        };
    };

    initNavigationToolbar = (props) => {
        const {
            permission,
            analyticsPro,
            lookerAnalyticsViewer,
            activitiesNotificationsBadge,
            showEntityPlaceholder,
            withDashboard,
        } = props;

        let accountsMenu = {
                type: 'link',
                icon: 'accounts',
                name: getLiteral('title_accounts'),
                link: getRoute(COMPANIES.route),
                hasLimit: false,
                onClick: this.onClick(COMPANIES.trueName),
            },
            contactsMenu = {
                type: 'link',
                icon: 'contacts',
                name: getLiteral('title_contacts'),
                link: getRoute(CONTACTS.route),
                hasLimit: false,
                onClick: this.onClick(CONTACTS.trueName),
            },
            activitiesMenu = {
                type: 'link',
                icon: 'activities',
                name: getLiteral('title_activities'),
                link: getRoute(ACTIVITIES.route),
                entityConversationsLink: getRoute(`${CONVERSATIONS.route}${ACTIVITIES.route}`),
                hasLimit: false,
                hasBadge: true,
                badgeContent: activitiesNotificationsBadge,
            },
            agendaMenu = {
                type: 'link',
                icon: 'calendar',
                name: getLiteral('title_agenda'),
                link: getRoute(AGENDA.route),
                hasLimit: false,
                onClick: this.onClick(AGENDA.trueName),
            },
            documentsMenu = {
                type: 'link',
                icon: 'documents',
                name: getLiteral('title_documents'),
                link: getRoute(DOCUMENTS.route),
                hasLimit: false,
                onClick: this.onClick(DOCUMENTS.trueName),
            },
            opportunitiesMenu = {
                type: 'link',
                icon: 'opportunities',
                name: getLiteral('title_opportunities'),
                link: getRoute(OPPORTUNITIES.route),
                hasLimit: false,
                onClick: this.onClick(OPPORTUNITIES.trueName),
            },
            productsMenu = {
                type: 'link',
                icon: 'products',
                name: getLiteral('title_products'),
                link: getRoute(PRODUCTS.route),
                hasLimit: false,
                onClick: this.onClick(PRODUCTS.trueName),
            },
            salesordersMenu = {
                type: 'link',
                icon: 'salesOrders',
                name: getLiteral('title_salesorders'),
                link: getRoute(SALESORDERS.route),
                hasLimit: false,
                onClick: this.onClick(SALESORDERS.trueName),
            },
            kpisMenu = {
                type: 'link',
                icon: 'goals',
                name: getLiteral('title_admin_kpis'),
                link: getRoute(KPIS.route),
                hasLimit: false,
                onClick: this.onClick(KPIS.trueName),
            },
            metricsMenu = {
                type: 'link',
                icon: 'analyticsPro',
                name: getLiteral('label_metrics_and_reports'),
                link: getRoute(METRICS.route),
                hasLimit: false,
                onClick: this.onClick(METRICS.trueName),
            },
            billboardMenu = {
                type: 'link',
                icon: 'news',
                name: getLiteral('title_billboard_billboards'),
                link: getRoute(BILLBOARD.route),
                hasLimit: false,
                onClick: this.onClick(BILLBOARD.trueName),
            },
            campaignsMenu = {
                type: 'link',
                icon: 'campaigns',
                name: getLiteral('title_campaigns'),
                link: getRoute(CAMPAIGNS.route),
                hasLimit: false,
                onClick: this.onClick(CAMPAIGNS.trueName),
            },
            customPageMenu = {
                type: 'link',
                icon: 'widgets',
                name: getLiteral('title_custom_page'),
                link: getRoute('/custom-page'),
                hasLimit: false,
            },
            dashboardMenu = {
                type: 'link',
                icon: 'dashboardWeb',
                name: getLiteral('title_dashboard'),
                link: getRoute('/dashboard'),
                isNew: true,
            };

        if (withDashboard) {
            this.sections.push(dashboardMenu);
            this.sections.push({
                type: 'divider',
            });
        }

        // Accounts
        if (permission['empresas']) {
            if (!permission['contactos']) accountsMenu['hasLimit'] = true;
            this.sections.push(accountsMenu);
            if (accountsMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // Contacts
        if (permission['contactos']) {
            contactsMenu['hasLimit'] = true;
            this.sections.push(contactsMenu);
            if (contactsMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // Activities
        if (permission['gestiones']) {
            activitiesMenu['hasLimit'] = false;
            this.sections.push(activitiesMenu);
            if (activitiesMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // Agenda
        if (permission['calendar']) {
            agendaMenu['hasLimit'] = true;
            this.sections.push(agendaMenu);
            if (agendaMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // Opportunities
        if (permission['expedientes']) {
            if (
                !permission['ofertas'] &&
                !permission['tarifa'] &&
                !permission['pedidos'] &&
                !permission['salesOrders']
            ) {
                opportunitiesMenu['hasLimit'] = true;
            }

            this.sections.push(opportunitiesMenu);
            if (opportunitiesMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // products
        if (permission['tarifa']) {
            if (!permission['pedidos'] && !permission['salesOrders']) {
                productsMenu['hasLimit'] = true;
            }

            this.sections.push(productsMenu);
            if (productsMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // salesorders
        if (permission['salesOrders']) {
            salesordersMenu['hasLimit'] = true;
            this.sections.push(salesordersMenu);
            if (salesordersMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // documents
        if (permission['documentManagement'] && permission['documentos_general']) {
            documentsMenu['hasLimit'] = false;
            this.sections.push(documentsMenu);
            if (documentsMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        if (
            (permission['informes'] && permission['reports_general']) ||
            permission['sfm'] ||
            (analyticsPro && lookerAnalyticsViewer)
        ) {
            this.sections.push(metricsMenu);
        }

        // kpis
        if (permission['kpisPremium']) {
            if (
                !permission['sfm'] &&
                !permission['billboard'] &&
                !permission['verCampaignsAdmin']
            ) {
                kpisMenu['hasLimit'] = true;
            }

            this.sections.push(kpisMenu);
            if (kpisMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // billboard
        if (permission['billboard']) {
            if (!permission['verCampaignsAdmin']) {
                billboardMenu['hasLimit'] = true;
            }
            this.sections.push(billboardMenu);
            if (billboardMenu['hasLimit'] === true)
                this.sections.push({
                    type: 'divider',
                });
        }

        // campaigns
        if (permission['verCampaignsAdmin']) {
            campaignsMenu['hasLimit'] = !(analyticsPro || lookerAnalyticsViewer);
            this.sections.push(campaignsMenu);
        }

        // custom page
        let customPageActive =
            props.idImplementation &&
            PAGE_WIDGET_IMPLEMENTATIONS.indexOf(props.idImplementation) !== -1;
        if (permission['custom_page'] && customPageActive) {
            this.sections.push({
                type: 'divider',
            });
            this.sections.push(customPageMenu);
        }

        // AddOns Placeholders
        if (
            (!permission['kpisPremium'] ||
                !permission['salesOrders'] ||
                !permission['tarifa'] ||
                !permission['billboard'] ||
                !permission['verCampaignsAdmin']) &&
            permission['viewAddonsConfiguration']
        ) {
            this.sections.push({
                type: 'divider',
            });
            if (!permission['kpisPremium'] && showEntityPlaceholder?.[KPIS.entity])
                this.sections.push({
                    type: 'link',
                    icon: 'goals',
                    link: getRoute('/try-kpis'),
                    name: getLiteral('title_admin_kpis'),
                    hasTrial: true,
                    onClick: this.onClick(KPIS.trueName, 'addons'),
                });
            if (!permission['salesOrders'] && showEntityPlaceholder?.[SALESORDERS.entity])
                this.sections.push({
                    type: 'link',
                    icon: 'salesOrders',
                    link: getRoute('/try-sales-orders'),
                    name: getLiteral('title_salesorders'),
                    hasTrial: true,
                    onClick: this.onClick(SALESORDERS.trueName, 'addons'),
                });
            if (!permission['tarifa'] && showEntityPlaceholder?.[PRODUCTS.entity])
                this.sections.push({
                    type: 'link',
                    icon: 'products',
                    link: getRoute('/try-products'),
                    name: getLiteral('title_products'),
                    hasTrial: true,
                    onClick: this.onClick(PRODUCTS.trueName, 'addons'),
                });
            if (!permission['billboard'] && showEntityPlaceholder?.[BILLBOARD.entity])
                this.sections.push({
                    type: 'link',
                    icon: 'news',
                    link: getRoute('/try-newsmanager'),
                    name: getLiteral('title_billboard_billboards'),
                    hasTrial: true,
                    onClick: this.onClick(BILLBOARD.trueName, 'addons'),
                });
            if (!permission['verCampaignsAdmin'] && showEntityPlaceholder?.[CAMPAIGNS.entity])
                this.sections.push({
                    type: 'link',
                    icon: 'campaigns',
                    link: getRoute('/try-salescampaigns'),
                    name: getLiteral('title_campaigns'),
                    hasTrial: true,
                    onClick: this.onClick(CAMPAIGNS.trueName, 'addons'),
                });
        }

        // cleaning dividers
        this.sections = this.sections.filter((section, i) => {
            if (section.type === 'divider' && this.sections?.[i - 1]?.type === 'divider')
                return false;
            return true;
        });

        if (this.sections[this.sections.length - 1]?.type === 'divider') this.sections.pop();

        this.schema = { appName: 'web', homeLink: '/', menuItems: [...this.sections] };
    };

    updatePath = () => {
        const path = window.location.href;
        return path;
    };

    onMatchActive = (item) => {
        if (!item.link) return;
        const cleanLink = item.link;

        // Avoiding double selection
        if (cleanLink?.includes('web3/index') && window.location.pathname.includes('web3/index')) {
            return true;
        } else if (window.location.pathname.includes('web3/index')) {
            return false;
        }

        let selected =
            window.location.href.includes(cleanLink) ||
            cleanLink === window.location.pathname ||
            window.location.href.includes(item.entityConversationsLink) ||
            item.entityConversationsLink === window.location.pathname ||
            (window.location.hash &&
                window.location.hash !== '#/' &&
                cleanLink?.includes(window.location.hash));

        return selected;
    };

    getPostComponent = (props) => {
        const { isDeletedWalkThroughData, subscriptionModeCode, isImpersonated, permission } =
            this.props;

        if (!isDeletedWalkThroughData && subscriptionModeCode === 'free_trial')
            return <MenuSampleData {...props} />;

        if (!isImpersonated && permission.enableAnnounceKitOption)
            return <AnnounceKitComponent {...props} />;

        return null;
    };

    render() {
        if (this.props.forbiddenPage) return null;

        return (
            <SidebarProvider>
                <Sidebar
                    schema={this.schema}
                    currentHref={this.updatePath()}
                    onMatchActive={this.onMatchActive}
                    onExpand={this.props.onExpand}
                    getPostComponent={this.getPostComponent}
                    customColor={this.props.brandingWebColor}
                    customLogo={this.props.customLogo}
                />
            </SidebarProvider>
        );
    }
}

export default MainNavigationToolbar;

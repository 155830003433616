import CrudModel from './CrudModel';

export default class ProfileSettingsModel extends CrudModel {
    constructor(entity) {
        entity = {
            idUsuario: '',
            nombre: '',
            apellidos: '',
            movil: '',
            email: '',
            langISOInterface: '',
            locale: '',
            foto: '',
            idPrefix: '',
            ...entity,
        };

        super(entity);
    }

    get idUsuario() {
        return this.entity.idUsuario;
    }

    get nombre() {
        return this.entity.name;
    }

    get apellidos() {
        return this.entity.apellidos;
    }

    get movil() {
        return this.entity.movil;
    }

    get email() {
        return this.entity.email;
    }

    get langISOInterface() {
        return this.entity.langisointerface;
    }

    get foto() {
        return this.entity.foto;
    }

    get idPrefix() {
        return this.entity.idPrefix;
    }

    set nombre(string) {
        this.entity.nombre = string;
    }

    set apellidos(string) {
        this.entity.apellidos = string;
    }

    set movil(string) {
        this.entity.movil = string;
    }

    set email(string) {
        this.entity.email = string;
    }

    set langISOInterface(string) {
        this.entity.langISOInterface;
    }

    set foto(string) {
        this.entity.foto;
    }

    set idPrefix(string) {
        this.entity.idPrefix = string;
    }
}

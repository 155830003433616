import {
    FILE_UPLOAD_START,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_PROGRESS,
    FILE_UPLOAD_CLEAR,
    FILE_UPLOAD_CLEAR_ALL,
    FILE_OPEN_MAX_SIZE_MODAL,
    FILE_CLOSE_MAX_SIZE_MODAL,
} from 'constants/ActionTypes';
import { FilesService } from 'services';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { errorToast, infoToast } from 'utils/toast';
import { isEmptyObject } from 'utils/objects';
import { renameDuplicates } from 'utils/files';
import { MAX_FILES, MAX_FILE_SIZE } from 'constants/Constants';

const uploadCancelTokens = {};
let pendingFiles = {};

function __getFilesOverMaxSize(entity, files) {
    const filesOverSize = files.filter((current) => {
        const maxSize = MAX_FILE_SIZE[entity.entity];
        return current.size > maxSize;
    });

    return filesOverSize;
}

export function getFilesOverMaxSize(entity, files) {
    return (dispatch) => {
        const filesOverMaxSize = __getFilesOverMaxSize(entity, files);

        if (filesOverMaxSize?.length) {
            dispatch(openMaxFilesModal(filesOverMaxSize));
        }

        return filesOverMaxSize;
    };
}

export function getValidFiles(entity, newFiles) {
    return (dispatch) => {
        const filesOverMaxSize = __getFilesOverMaxSize(entity, newFiles);
        const filesOverMaxSizeIds = filesOverMaxSize.map((current) => current.id);
        const validFiles = newFiles.filter((current) => !filesOverMaxSizeIds.includes(current.id));

        return validFiles;
    };
}

export function getShouldStopCrudDrop({ entity, oldFiles, newFiles, validFiles }) {
    return (dispatch) => {
        const filesOverMaxSize = dispatch(getFilesOverMaxSize(entity, newFiles));

        if (filesOverMaxSize?.length > 0) {
            dispatch(openMaxFilesModal(filesOverMaxSize));
        }

        return filesOverMaxSize?.length === newFiles?.length;
    };
}

export function openMaxFilesModal(files) {
    return (dispatch) => {
        const filesData = files.map((current) => {
            return {
                name: current.name,
                size: current.size,
                type: current.type,
            };
        });
        dispatch({ type: FILE_OPEN_MAX_SIZE_MODAL, files: filesData });
    };
}

export function closeMaxFilesModal() {
    return (dispatch) => {
        dispatch({ type: FILE_CLOSE_MAX_SIZE_MODAL });
        if (isEmptyObject(pendingFiles)) return Promise.resolve();
        const { entity, entityId, files, refresh } = pendingFiles;
        pendingFiles = {};
        if (!entity || !entityId || !files?.length) {
            return Promise.resolve();
        }
        return dispatch(uploadFiles({ entity, entityId, files, refresh }));
    };
}

export const startUploadFiles = ({ entity, entityId, oldFiles, newFiles, refresh }) => {
    return (dispatch) => {
        if (newFiles?.length === 0 || !entity?.entity || !entityId) return Promise.resolve();
        const validFiles = dispatch(getValidFiles(entity, newFiles));

        const filesOverMaxSize = dispatch(getFilesOverMaxSize(entity, newFiles));

        const validFilesRenamed = renameDuplicates(oldFiles, validFiles);

        if (filesOverMaxSize?.length > 0) {
            openMaxFilesModal(filesOverMaxSize);
            pendingFiles = { entity, entityId, files: validFilesRenamed, refresh };
            return Promise.resolve();
        }

        return dispatch(uploadFiles({ entity, entityId, files: validFilesRenamed, refresh }));
    };
};

const _uploadProgress = (file, percentage, loaded, total) => {
    return {
        type: FILE_UPLOAD_PROGRESS,
        file,
        percentage,
        loaded,
        total,
    };
};

export const uploadFiles = ({ entity, entityId, files, refresh }) => {
    return (dispatch) => {
        if (!entity?.entity || files?.length === 0 || !entityId) {
            pendingFiles = {};
            return Promise.resolve();
        }

        const getUploadProgress =
            (file) =>
            ({ percentage, loaded, total }) => {
                dispatch(_uploadProgress(file, percentage, loaded, total));
            };

        const promises = files.map((file) => {
            const cancelToken = FilesService.createCancelToken();
            uploadCancelTokens[file.id] = cancelToken;

            dispatch({ type: FILE_UPLOAD_START, file });

            return new Promise((resolve, reject) => {
                FilesService.uploadFile({
                    entity: entity.entity,
                    entityId,
                    name: file.name,
                    file,
                    cancelToken,
                    getUploadProgress: getUploadProgress(file),
                })
                    .then((response) => {
                        let counter = 0;
                        const syncro = setInterval(() => {
                            FilesService.getFile(response.data.id).then((data) => {
                                if (counter > 10) {
                                    clearInterval(syncro);
                                    reject('notSync');
                                }
                                counter++;
                                if (data.data.synchronized) {
                                    clearInterval(syncro);
                                    resolve();
                                }
                            });
                        }, 300);

                        delete uploadCancelTokens[file.id];
                    })
                    .catch(() => {
                        delete uploadCancelTokens[file.id];
                        dispatch({ type: FILE_UPLOAD_ERROR, id: file.id });
                        errorToast({ text: getLiteral('error_uploading_file') });
                        reject();
                    });
            });
        });

        const manager = Context.entityManager.getEntitiesManager(entity);

        return Promise.all(promises)
            .then(() => {
                if (refresh && manager?.getFiles) {
                    manager.getFiles(entityId);
                }
            })
            .catch((error) => {
                if (error === 'notSync') {
                    if (manager.getFiles) {
                        manager.getFiles(entityId);
                    }

                    infoToast({ text: getLiteral('label_attachment_waiting_synchronized') });
                }
                console.error(error);
            });
    };
};

export const cancel = (id) => {
    return (dispatch) => {
        const cancelToken = uploadCancelTokens[id];
        cancelToken?.cancel('Operation canceled by the user');
        delete uploadCancelTokens[id];
        dispatch({ type: FILE_UPLOAD_CLEAR, id });
    };
};

export const clear = (id) => {
    return (dispatch) => {
        dispatch({ type: FILE_UPLOAD_CLEAR, id });
    };
};

export const clearAll = () => {
    return (dispatch) => {
        dispatch({ type: FILE_UPLOAD_CLEAR_ALL });
    };
};

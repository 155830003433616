import { setupMomentUser } from 'utils/dates';
import { setupCurrencyLocaleUser } from 'utils/currency';
import { setupUserLocaleNumber } from 'utils/numbers';
import { setBugsnagUser } from 'BugSnagWrapper';
import { isEmptyObject } from 'utils/objects';
import Intercom from 'utils/intercom';
import * as SatismeterUtils from 'utils/satismeter';
import * as NylasUtils from 'utils/nylas';
import * as Tracking from 'utils/tracking';
import Cookies from 'js-cookie';
import * as UserflowUtils from 'utils/userflow';
import * as Hotjar from 'utils/hotjar';
import * as Flagsmith from 'utils/flagsmith';
import { getEntityFromString } from 'utils/getEntityFromString';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import EntityLiterals from 'constants/EntityLiterals';
import { ensureRoute, getCookieDomainName } from 'utils/routes';
import { getBackendBoolean } from 'utils/fm';
import { getProvidersInfo } from 'services/NpsService';
import {
    CustomValidationsService,
    FeaturesService,
    SettingsService,
    axios,
    SynchronizeService,
} from 'services';
import { ENTITY_FILTERS_LOAD, SET_ADDONS_INFO } from 'constants/ActionTypes';

import { FILTERS_CACHE_KEY, ENTITIES_WHITELIST } from 'constants/FilterCache';
import * as GlobalToasts from 'lib/GlobalToasts';

import {
    BILLBOARD,
    CAMPAIGNS,
    KPIS,
    PRODUCTS,
    SALESORDERS,
    OPPORTUNITIES,
} from 'constants/Entities';

import {
    CROSS_FILTERS,
    DANA_GOLDEN_MINUTE_DEBUG_TOOLS,
    DANA_ACTIVITY_EMAIL_CHAT,
    FORCEBI,
    DANA_COMPOSER,
    DASHBOARD_WEB,
} from 'constants/Features';

import {
    STATUS,
    ADDON_GOALMANAGER,
    ADDON_SALESORDERS,
    ADDON_OPPS_GEOPOS,
    ADDON_NEWSMANAGER,
    ADDON_SALES_CAMPAIGNS,
} from 'containers/settings/AddOnsManagement/constants';

const MANAGERSNAMES = {
    domainManager: 'domainManager',
    literalsManager: 'literalsManager',
    sessionManager: 'sessionManager',
    cacheManager: 'cacheManager',
    valueListManager: 'valueListManager',
    crudManager: 'crudManager',
    entityCrudManager: 'entityCrudManager',
    extrafieldManager: 'extrafieldManager',
    entityDetailManager: 'entityDetailManager',
    socketManager: 'socketManager',
    settingsManager: 'settingsManager',
    profileSettingsManager: 'profileSettingsManager',
    usersManager: 'usersManager',
    mergeManager: 'mergeManager',
    bulkManager: 'bulkManager',
    constantsManager: 'constantsManager',
    mailChimpManager: 'mailChimpManager',
    entityMapManager: 'entityMapManager',
    serverListManager: 'serverListManager',
    integrationsManager: 'integrationsManager',
    campaignsManager: 'campaignsManager',
    reportsManager: 'reportsManager',
};

class Context {
    constructor() {
        this.managers = [];
        this.handlers = {
            notify: this._notifyHandlers,
        };
    }

    _notifyHandlers = (entity) => {
        const handlers = this.handlers[entity];
        for (const [key, entityHandler] of Object.entries(handlers)) {
            entityHandler();
        }
    };

    getConfig(paramToken, paramUserKey, refreshToken) {
        const token = paramToken || Cookies.get('token');
        const userKey = paramUserKey;
        this.domainManager.api.setToken(token);
        axios.setToken(token);
        return Promise.all([
            this.domainManager.getPartialConfig(['UserData']),
            this.domainManager.getPartialConfig(['Permisos']),
            this.domainManager.getPartialConfig(['customFieldsSchemas']),
            this.domainManager.getPartialConfig(['readonlyfields']),
            this.domainManager.getPartialConfig(['mandatoryfields']),
            this.domainManager.getPartialConfig(['Views']),
            this.domainManager.getPartialConfig(['webTemplates']),
            this.domainManager.getPartialConfig(['columnsUsuario']),
            this.domainManager.getPartialConfig(['defaultvaluefields']),
            this.domainManager.getPartialConfig(['standardFieldsSchemas']),
        ])
            .then(
                ([
                    config,
                    permisosConfig,
                    customFieldsConfig,
                    readonlyfieldsConfig,
                    mandatoryFieldsConfig,
                    viewsConfig,
                    webTemplatesConfig,
                    columnsUsuarioConfig,
                    defaultvaluefieldsConfig,
                    standardFieldsSchemaConfig,
                ]) => {
                    // Merging parallel config request
                    config.userData.userKey = userKey || config.userData.userKey;
                    config.permisos = permisosConfig.permisos;
                    config.customFields = customFieldsConfig.customFields;
                    config.readonlyfields = readonlyfieldsConfig.readonlyfields;
                    config.mandatoryFields = mandatoryFieldsConfig.mandatoryFields;
                    config.views = viewsConfig.views;
                    config.webTemplates = webTemplatesConfig.webTemplates;
                    config.columnsUsuario = columnsUsuarioConfig.columnsUsuario;
                    config.defaultvaluefields = defaultvaluefieldsConfig.defaultvaluefields;
                    config.standardFieldsSchema = standardFieldsSchemaConfig.standardFieldsSchema;
                    config.token = token;
                    config.refreshToken = refreshToken || Cookies.get('refreshToken');

                    return config;
                },
            )
            .then((config) => {
                return new Promise((resolve, reject) => {
                    this.domainManager.getConfiguration(
                        (configComponents) => {
                            config.components = configComponents || {};
                            resolve(config);
                        },
                        reject,
                        config,
                    );
                });
            })
            .then((config) => {
                return this.domainManager.getConfigUserTypeWorkFlows(config);
            })
            .then((config) => {
                return this.sessionManager.wrapperConfig(config);
            });
    }

    getAddOns(config) {
        return new Promise((resolve, reject) => {
            if (config?.productModeCode === 'business') {
                return resolve();
            }
            const showPlaceholder = (statusCode) =>
                ![STATUS.ACTIVE, STATUS.PURCHASED].includes(statusCode);

            SettingsService.getAddOns()
                .then((data) => {
                    this.store.dispatch({ type: SET_ADDONS_INFO, data });
                    config.showEntityPlaceholder = data.reduce((obj, addOn) => {
                        const { itemCode, statusCode } = addOn;
                        const show = showPlaceholder(statusCode);
                        switch (true) {
                            case itemCode === ADDON_GOALMANAGER:
                                obj[KPIS.entity] = show;
                                break;
                            case itemCode === ADDON_SALESORDERS:
                                obj[PRODUCTS.entity] = show;
                                obj[SALESORDERS.entity] = show;
                                break;
                            case itemCode === ADDON_SALES_CAMPAIGNS:
                                obj[CAMPAIGNS.entity] = show;
                                break;
                            case itemCode === ADDON_NEWSMANAGER:
                                obj[BILLBOARD.entity] = show;
                                break;
                            case itemCode === ADDON_OPPS_GEOPOS:
                                obj[OPPORTUNITIES.entity] = show;
                            default:
                                break;
                        }
                        return obj;
                    }, {});

                    resolve();
                })
                .catch(reject);
        });
    }

    getCustomValidations(config) {
        return new Promise((resolve, reject) => {
            if (!config?.userData?.customValidations) {
                return resolve();
            }
            CustomValidationsService.getCustomValidations()
                .then((data) => {
                    config.customValidationsSchema = data || [];
                    config.customValidationsSchemaMap = config.customValidationsSchema.reduce(
                        (obj, current) => {
                            if (!obj[current.entity]) obj[current.entity] = [];
                            obj[current.entity].push(current);
                            return obj;
                        },
                        {},
                    );
                    resolve();
                })
                .catch(reject);
        });
    }

    getFeatures(config) {
        return new Promise((resolve, reject) => {
            FeaturesService.getFeatureFlags()
                .then((data) => {
                    config.featureFlags = data;
                    resolve();
                })
                .catch((error) => {
                    console.error(error);
                    reject();
                });
        });
    }

    getStarterLists() {
        let promises = [];
        promises.push(
            this.store.dispatch(this.actions.ServerListActions.getList('fm_iana_time_zone')),
        );
        promises.push(this.store.dispatch(this.actions.ServerListActions.getList('tblCurrency')));
        return Promise.all(promises);
    }

    initScripts(config) {
        // Always
        GlobalToasts.loadToasts(config.components[GlobalToasts.CACHE_KEY]);

        const shouldInitUserFlow = getBackendBoolean(config?.userData?.userFlow || false);

        return getProvidersInfo().then((info) => {
            const subscriptionModeCode = config.subscriptionModeCode;
            const isFree = subscriptionModeCode.includes('free');

            if (window.top === window.self || !window.parent) {
                // INTERCOM
                if (
                    info?.intercom &&
                    config.permisos.enableIntercomOption &&
                    !config?.userData?.isImpersonated
                ) {
                    Intercom.load(info?.intercom, shouldInitUserFlow, config?.productModeCode);
                }

                // USERFLOW
                if (shouldInitUserFlow) UserflowUtils.init(info?.userflow?.traits);
            }

            //MIXPANEL EVENTS
            Tracking.init(info?.mixpanel?.traits);

            // HOTJAR
            if (isFree) Hotjar.init(config);

            // NYLAS
            NylasUtils.loadScript();

            // SATISMETER
            if (!config?.userData?.isImpersonated && info?.satismeter)
                SatismeterUtils.show(info.satismeter?.traits);
        });
    }

    initFlagsmith(config) {
        return Flagsmith.init(config)
            .then(() => {
                const FLAGSMITH_FLAGS_TO_CONFIG = [
                    CROSS_FILTERS,
                    DANA_GOLDEN_MINUTE_DEBUG_TOOLS,
                    DANA_ACTIVITY_EMAIL_CHAT,
                    FORCEBI,
                    DANA_COMPOSER,
                    DASHBOARD_WEB,
                ];
                config.flagsmith = FLAGSMITH_FLAGS_TO_CONFIG.reduce((obj, flag) => {
                    obj[flag] = Flagsmith.hasFeature(flag);
                    return obj;
                }, {});
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getNylasStatus(config) {
        return SynchronizeService.getAccount(config?.userData?.email)
            .then((data) => {
                config.nylas = data;
            })
            .catch((e) => {
                console.error(e);
            });
    }

    initConfig(config) {
        if (!config) return Promise.resolve();
        return this.notifyConfigAsync(config).then(() => {
            setBugsnagUser(config.userData);
            setupMomentUser(config.userData.locale);
            setupCurrencyLocaleUser(
                config.userData.locale,
                config.userData.currencyISO,
                config.userData.currencySymbol,
            );
            setupUserLocaleNumber(config.userData.locale);
            // Getting providers info is too slow so we will do it async
            this.initScripts(config);
            return Promise.all([
                this.getCustomValidations(config),
                this.getFeatures(config),
                this.domainManager
                    .getPendingAgreements()
                    .then((agreementsData) => (config.contracts = agreementsData?.Result || [])),
                this.getStarterLists(),
                this.getAddOns(config),
                this.initFlagsmith(config),
                this.getNylasStatus(config),
            ])
                .then(() => this.generateCustomConfig(config))
                .then(() => this.loadFiltersCache(config))
                .then(() => {
                    if (config.contracts?.length) {
                        ensureRoute('/contracts-validation');
                        return config;
                    }

                    // We set login to true, so the app can render the components
                    // We do it here, to wait until the configuration process reach the point where,
                    // each entity can use the filters from cache if there are instead the preloaded
                    // filters from preloadFilters.
                    this.store.dispatch(this.actions.ConfigActions.completeLogin());
                    return config;
                });
        });
    }

    initAsync() {
        const config = this.cacheManager.getConfigStore();
        const checkMustInvalidateConfig = !!Cookies.get('INVALIDATE_CONFIG');
        const checkMustInvalidateLiterals = !!Cookies.get('INVALIDATE_LITERALS');
        if (checkMustInvalidateLiterals) {
            this.cacheManager.removeLiteralsStore();
        }

        if (config && !checkMustInvalidateConfig) return this.initConfig(config);
        else
            return this.getConfig()
                .then((config) => this.initConfig(config))
                .then(() =>
                    Cookies.remove('INVALIDATE_CONFIG', {
                        domain: getCookieDomainName(window.location.hostname),
                    }),
                );
    }

    init() {
        let config = this.cacheManager.getConfigStore();
        config && this.notifyConfig(config);

        // Checking context vs session
        const userSession = Cookies.get('user_token');
        if (
            config &&
            config?.userData?.movil !== userSession &&
            config?.userData?.user !== userSession
        ) {
            config = null;
            this.setConfig(undefined);
            this.cacheManager.clear();
        }

        if (config) {
            setBugsnagUser(config.userData);
            setupMomentUser(config.userData.locale);
            setupCurrencyLocaleUser(
                config.userData.locale,
                config.userData.currencyISO,
                config.userData.currencySymbol,
            );
            setupUserLocaleNumber(config.userData.locale);
            Promise.all([
                this.initScripts(config),
                this.getAddOns(config),
                this.initFlagsmith(config),
                this.getNylasStatus(config),
                this.refreshConfig(config),
            ]).then(() => this.generateCustomConfig(config));
        }
    }

    refreshConfig(config) {
        return Promise.all([
            this.domainManager.getPartialConfig(['UserData']),
            this.domainManager.getPartialConfig(['Permisos']),
        ])
            .then(([userDataConfig, permisosConfig]) => {
                config.userData = userDataConfig.userData;
                config.permisos = permisosConfig.permisos;

                // wrapperConfig logic
                const crudPermission = {};
                config.permisos.crud_permission.permission.forEach((element) => {
                    crudPermission[element.entity] = element;
                });

                config.permisos.crud_permission = crudPermission;
            })
            .catch((e) => {
                console.error(e);
            });
    }

    generateCustomConfig = (config, fromLogin = true) => {
        if (!config.standardFieldsSchemaMap || isEmptyObject(config.standardFieldsSchemaMap)) {
            config.standardFieldsSchemaMap = {};
            if (config.standardFieldsSchema) {
                for (let entity in config.standardFieldsSchema) {
                    config.standardFieldsSchemaMap[entity] = config.standardFieldsSchema[
                        entity
                    ].reduce((obj, field) => {
                        let newField = { ...field };
                        if (newField.hasOwnProperty('isHidden'))
                            newField.isHidden = newField.isHidden === 'True';
                        if (newField.hasOwnProperty('mandatory'))
                            newField.mandatory = newField.mandatory === 'True';
                        if (newField.hasOwnProperty('readOnly'))
                            newField.readOnly = newField.readOnly === 'True';
                        obj[newField.id] = newField;
                        return obj;
                    }, {});
                }
            }
        }

        if (!config.extraFieldsSchemaMap || isEmptyObject(config.extraFieldsSchemaMap)) {
            config.extraFieldsSchemaMap = {};
            if (config?.customFields?.entity) {
                config.extraFieldsSchemaMap = config.customFields.entity.reduce((obj, item) => {
                    obj[item.name] = item.field.reduce((accObj, current) => {
                        accObj[current.id] = current;
                        return accObj;
                    }, {});
                    return obj;
                }, {});
            }
        }

        // When init from app, single component or settings
        config.userData.userKey = config.userData.userKey;

        if (fromLogin) {
            this.setConfig(config);
            this.store.dispatch(this.actions.ConfigActions.setDataConfig(config));
        }

        return config;
    };

    setActions(actions) {
        this.actions = actions;
    }

    setStore(store) {
        this.store = store;
    }

    addManager(manager, managerName) {
        manager.context = this;
        this.managers.push(manager);
        if (managerName) this.handlers[managerName] = {};
    }

    setDomainManager(manager) {
        this.domainManager = manager;
        this.addManager(manager, MANAGERSNAMES.domainManager);
    }

    setLiteralManager(manager) {
        this.literalsManager = manager;
        this.addManager(manager, MANAGERSNAMES.literalsManager);
    }

    setSessionManager(manager) {
        this.sessionManager = manager;
        this.addManager(manager, MANAGERSNAMES.sessionManager);
    }

    setCacheManager(manager) {
        this.cacheManager = manager;
        this.addManager(manager, MANAGERSNAMES.cacheManager);
    }

    setValueListManager(manager) {
        this.valueListManager = manager;
        this.addManager(manager, MANAGERSNAMES.valueListManager);
    }

    setServerListManager(manager) {
        this.serverListManager = manager;
        this.addManager(manager, MANAGERSNAMES.serverListManager);
    }

    setCrudManager(manager) {
        this.crudManager = manager;
        this.addManager(manager, MANAGERSNAMES.crudManager);
    }

    setEntityCrudManager(manager) {
        this.entityCrudManager = manager;
        this.addManager(manager, MANAGERSNAMES.entityCrudManager);
    }

    setExtrafieldManager(manager) {
        this.extraFieldManager = manager;
        this.addManager(manager, MANAGERSNAMES.extrafieldManager);
    }

    setEntityDetailManager(manager) {
        this.entityDetailManager = manager;
        this.addManager(manager, MANAGERSNAMES.entityDetailManager);
    }

    setSocketManager(manager) {
        this.socketManager = manager;
        this.addManager(manager, MANAGERSNAMES.socketManager);
    }

    setSettingsManager(manager) {
        this.settingsManager = manager;
        this.addManager(manager, MANAGERSNAMES.settingsManager);
    }

    setProfileSettingsManager(manager) {
        this.profileSettingsManager = manager;
        this.addManager(manager, MANAGERSNAMES.profileSettingsManager);
    }

    setUsersManager(manager) {
        this.usersManager = manager;
        this.addManager(manager, MANAGERSNAMES.usersManager);
    }

    setMergeManager(manager) {
        this.mergeManager = manager;
        this.addManager(manager, MANAGERSNAMES.mergeManager);
    }

    setBulkManager(manager) {
        this.bulkManager = manager;
        this.addManager(manager, MANAGERSNAMES.bulkManager);
    }

    setConfig(config) {
        this.notifyConfig(config);
        return config;
    }

    setEntityManager(manager) {
        this.entityManager = manager;
        this.addManager(manager);
    }

    setEntityListManager(manager) {
        this.entityListManager = manager;
        this.addManager(manager);
    }

    setEntityMapManager(manager) {
        this.entityMapManager = manager;
        this.addManager(manager, MANAGERSNAMES.entityMapManager);
    }

    setFormats(utilsFormats) {
        this.utilsFormats = utilsFormats;
    }

    setConstants(manager) {
        this.constants = manager;
        this.addManager(manager, MANAGERSNAMES.constantsManager);
    }

    setMailChimpManager(manager) {
        this.mailChimpManager = manager;
        this.addManager(manager, MANAGERSNAMES.mailChimpManager);
    }

    setIntegrationsManager(manager) {
        this.integrationsManager = manager;
        this.addManager(manager, MANAGERSNAMES.integrationsManager);
    }

    setCampaignsManager(manager) {
        this.campaignsManager = manager;
        this.addManager(manager, MANAGERSNAMES.campaignsManager);
    }

    setReportsManager(manager) {
        this.reportsManager = manager;
        this.addManager(manager, MANAGERSNAMES.reportsManager);
    }

    // setSettingsManager(manager){
    // 	this.settingManager = manager;
    // 	this.addManager(manager);
    // }

    notifyConfig(config) {
        this.config = config;
        this.utilsFormats && this.utilsFormats.setConfig(config);
        this.managers.forEach((manager) => {
            manager.setConfig && manager.setConfig(config);
        });
    }

    notifyConfigAsync(config) {
        this.config = config;
        this.utilsFormats && this.utilsFormats.setConfig(config);
        const prs =
            this.managers
                .filter((manager) => manager.setConfigAsync)
                .map((manager) => manager.setConfigAsync(config)) || [];
        return Promise.all(prs);
    }

    refreshLiterals(language) {
        const { idImplementacion } = this.config.userData;
        this.literalsManager.getLiterals(language, idImplementacion);
    }

    clearValueLists() {
        this.cacheManager.removeValueListStore();
        this.cacheManager.removeServerList();
    }

    loadFiltersCache(config) {
        if (!config.userData.maintainFiltersBetweenSessions) return Promise.resolve(config);
        if (!config?.components || !Object.keys(config.components)?.length)
            return Promise.resolve(config);

        const saveAndShow = (newFilters) => {
            GlobalToasts.checkShowToasts();
            // Setting show to false always
            Object.keys(newFilters).forEach((key) => {
                newFilters[key].show = false;
            });

            this.store.dispatch({
                type: ENTITY_FILTERS_LOAD,
                payload: newFilters,
            });

            const components = config.components;
            components[GlobalToasts.CACHE_KEY] = GlobalToasts.toasts;
            Object.keys(newFilters).forEach((key) => {
                components[`${key}_${FILTERS_CACHE_KEY}`] = newFilters[key];
            });

            return this.sessionManager.setAllConfigWeb(components);
        };

        // We need this with schemasPromises for order execution
        const schemasEntity = [];
        const schemasPromises = [];

        let expiredEntities = [];

        // Filtering the filters (store cache v1)
        let filters = Object.keys(config.components).reduce((obj, key) => {
            if (!key.includes(FILTERS_CACHE_KEY)) return obj;
            const cacheItem = config.components[key];
            const entityName = key.replace(`_${FILTERS_CACHE_KEY}`, '');
            const entity = getEntityFromString(entityName);

            if (!config?.permisos?.[entity.generalPermission]) return obj;

            // Enabled entities only
            if (!ENTITIES_WHITELIST.find((entity) => entity.entity === entityName)) return obj;

            // Checking if there are some valid filter for avoid extra computation
            let entityFiltersKeys = Object.keys(cacheItem.filters);

            // Checking month expiration only if we have valid filters
            const expiredMonth = window?.DEBUG_FM?.filtersCache?.expiredDate || new Date();
            expiredMonth.setMonth(expiredMonth.getMonth() - 1);
            if (cacheItem?.updated && new Date(cacheItem?.updated) < expiredMonth) {
                expiredEntities.push(entity);
                // Purging filters
                obj[entityName] = {
                    ...cacheItem,
                    show: false,
                    filters: entityFiltersKeys.reduce((obj, filterKey) => {
                        const filter = cacheItem.filters[filterKey];
                        if (filter.excludeFromCache) return obj;
                        if (filter.noCacheCheck) obj[filterKey] = filter;
                        return obj;
                    }, {}),
                    updated: new Date(),
                };
                return obj;
            }

            // Getting schemas if there are filters and are not expired
            schemasPromises.push(this.entityManager.getEntityFilters(entity));
            schemasEntity.push(entityName);
            obj[entityName] = cacheItem;
            return obj;
        }, {});

        // Show notification if cache expireds by entity
        expiredEntities.forEach((entity) => {
            GlobalToasts.addToast({
                type: 'warning',
                title: getLiteral('label_warning_alert'),
                text: getLiteralWithParameters('label_warning_alert_active_filters_expired', [
                    getLiteral(EntityLiterals[entity]?.title),
                ]),
                route: entity?.route,
            });

            Tracking.logEvent({
                event: entity.trueName,
                functionality: 'automaticRefreshFilters',
            });
        });

        if (!schemasEntity.length) {
            return saveAndShow(filters);
        }

        // Checking some invalidation conditions and merging objects
        return Promise.all(schemasPromises)
            .then((schemas) => {
                schemasEntity.map((entity, i) => {
                    let hasInconsistentFilters = false;
                    let storeFilters = schemas[i].reduce((obj, item) => {
                        const filter = filters[entity].filters[item.id];
                        if (!filter) return obj;
                        if (filter.excludeFromCache) return obj;
                        if (
                            filter.dataType !== item.dataType ||
                            filter.fieldConfiguration !== item.fieldConfiguration ||
                            filter.inputAttrs?.list !== item.inputAttrs?.list ||
                            filter.byParameter !== item.byParameter
                        ) {
                            hasInconsistentFilters = true;
                            return obj;
                        }

                        obj[item.id] = {
                            ...item,
                            completeValues: filter.completeValues,
                            value: filter.value,
                        };

                        return obj;
                    }, {});

                    // Special filters
                    if (filters[entity].filters.matchingName)
                        storeFilters.matchingName = filters[entity].filters.matchingName;
                    if (filters[entity].filters.idView)
                        storeFilters.idView = filters[entity].filters.idView;

                    filters[entity].filters = storeFilters;

                    // Show notifications if inconsistent filters
                    if (hasInconsistentFilters) {
                        const entityObj = getEntityFromString(entity);
                        GlobalToasts.addToast({
                            type: 'warning',
                            title: getLiteral('label_warning_alert'),
                            text: getLiteralWithParameters(
                                'label_warning_alert_change_active_filters',
                                [getLiteral(EntityLiterals[entityObj]?.title)],
                            ),
                        });

                        Tracking.logEvent({
                            event: entityObj.trueName,
                            functionality: 'automaticRefreshFilters',
                        });

                        // If has inconsistent filters then purge all
                        const newFilters = filters[entity].filters;
                        filters[entity].filters = Object.keys(newFilters).reduce(
                            (obj, filterKey) => {
                                const filter = newFilters[filterKey];
                                if (filter.noCacheCheck) obj[filterKey] = filter;
                                return obj;
                            },
                            {},
                        );
                    }
                });
                return saveAndShow(filters);
            })
            .catch((e) => console.error(e));
    }
}

const ContextSingleton = (function () {
    let instance;

    function createInstance() {
        const object = new Context();
        return object;
    }

    return {
        getInstance() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default ContextSingleton.getInstance();

import TextStyle from '../../../../../styles/text';

export default {
    titleDialogStyle: {
        display: 'block',
        padding: '30px 30px 40px 30px',
        fontSize: '18px',
        color: '#000000',
        fontWeight: 500,
        borderBottom: '0',
    },
    bodyDialogStyle: {
        ...TextStyle.text_md,
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    inputDialog: {
        display: 'block',
    },
    inputStyle: {
        ...TextStyle.text_md,
        width: 252,
    },
    hintStyle: {
        ...TextStyle.text_md_hint,
    },
    valueListTextMenu: {
        ...TextStyle.text_md,
    },
    btnCustomDisable: {
        textTransform: 'none',
        marginTop: '24px',
        marginLeft: '15px',
        color: '#f04540',
        textDecoration: 'none',
        fontSize: '13px',
        padding: '0',
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        fontWeight: '400',
    },
};

import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Text, Switch } from 'hoi-poi-ui';
import { ACTIVITIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const MaxDistanceFilter = memo(({ entity, onClick }) => {
    const { isSuspiciousCheckIn } = useSelector((state) => {
        const filters =
            entity.entity === ACTIVITIES.entity
                ? state?.entityFilters?.[ACTIVITIES.entity]?.filters || {}
                : state?.entityFilters?.[entity.entity]?.crossFilters?.[ACTIVITIES.entity] || {};
        return {
            isSuspiciousCheckIn: filters?.isSuspiciousCheckIn?.value || false,
        };
    });

    const handleOnClick = useCallback(() => {
        onClick && onClick(entity);
    }, [entity, onClick]);

    return (
        <div className="activities-max-distance-filter">
            <Text>{getLiteral('label_suspicious_checkin')}</Text>
            <Switch
                checked={isSuspiciousCheckIn}
                onClick={handleOnClick}
                onChange={handleOnClick}
            />
        </div>
    );
});

export default MaxDistanceFilter;

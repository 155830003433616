import Immutable, { Map, List } from 'immutable';
import {
    INIT_CRUD,
    INIT_CRUD_SUCCESS,
    INIT_CRUD_ERROR,
    UPDATE_ENTITY,
    ERRORS_ENTITY,
    SAVE_CRUD_INIT,
    SAVE_CRUD_SUCCESS,
    SAVE_CRUD_ERROR,
    CANCEL_CRUD,
    CLEAR,
    INIT_CRUD_WITHOUT_FORM,
    LOADING_CRUD_DELETE,
    DELETE_CRUD_SUCCESS,
    DELETE_CRUD_ERROR,
    SHOW_CRUD_DELETE_DIALOG,
    TOGGLE_CRUD_PREVIEW,
    CHECK_ENTITY_DELETE,
    CHECK_ALL_DELETE,
    CRUD_REORDER_ROW,
    CRUD_DRAGGED_ROW,
} from '../constants/ActionTypes';

const initialState = {
    loadingCrudView: false,
    error: false,
    errors: Map(),
    firstErrorField: null,
    errorsCrud: Map(),
    entityCrud: Map(),
    entitiesForDelete: {},
    schema: [],
    otherSchema: [],
    loadingSave: false,
    show: false,
    dynamic: Map(),
    dependence: Map(),
    entityType: '',
    loadingWithoutForm: false,
    deleteEntity: {
        loading: false,
        error: false,
        show: false,
        entityType: undefined,
        model: undefined,
    },
    showPreview: false,
};

export default function config(state = initialState, action) {
    switch (action.type) {
        case INIT_CRUD:
            return {
                ...state,
                loadingCrudView: true,
                entityCrud: Map(),
                schema: [],
                show: true,
                showPreview: false,
                error: false,
                entityType: action.entityType,
            };
        case INIT_CRUD_SUCCESS:
            return {
                ...state,
                loadingCrudView: false,
                schema: action.schema,
                otherSchema: action.otherSchema,
                entityCrud: Map(action.entityCrud),
                dynamic: Map(action.dynamic),
                dependence: Map(action.dependence),
            };
        case INIT_CRUD_ERROR:
            return {
                ...state,
                loadingCrudView: false,
                error: true,
            };
        case UPDATE_ENTITY:
            return {
                ...state,
                entityCrud: action.entityCrud,
                dynamic: action.dynamic,
                dependence: action.dependence,
                errors: action.errors,
            };
        case ERRORS_ENTITY:
            return {
                ...state,
                show: true,
                showPreview: false,
                loadingSave: false,
                errors: action.errors,
                firstErrorField: action.firstErrorField,
            };
        case SAVE_CRUD_INIT:
            return {
                ...state,
                loadingSave: true,
                show: true,
                showPreview: false,
            };
        case SAVE_CRUD_SUCCESS:
            return initialState;
        case SAVE_CRUD_ERROR:
            return {
                ...state,
                loadingSave: false,
                errors: Map().set('error', action.error),
                errorsCrud: Map().set('error', action.err),
            };
        case INIT_CRUD_WITHOUT_FORM: {
            return {
                ...state,
                loadingWithoutForm: action.show,
            };
        }
        case SHOW_CRUD_DELETE_DIALOG: {
            const { show, entityType, model } = action;
            return {
                ...state,
                deleteEntity: {
                    ...state.deleteEntity,
                    show,
                    entityType,
                    model,
                },
            };
        }
        case LOADING_CRUD_DELETE: {
            return {
                ...state,
                deleteEntity: { ...state.deleteEntity, loading: true },
            };
        }
        case DELETE_CRUD_ERROR: {
            return {
                ...state,
                deleteEntity: { ...state.deleteEntity, loading: false, error: true },
            };
        }
        case TOGGLE_CRUD_PREVIEW: {
            return {
                ...state,
                showPreview: !state.showPreview,
                show: !state.show,
                loadingSave: false,
            };
        }
        case CHECK_ENTITY_DELETE: {
            const entity = action.entity;
            const exists = state.entitiesForDelete[action.index];
            return {
                ...state,
                entitiesForDelete: {
                    ...state.entitiesForDelete,
                    [action.index]: exists ? null : entity,
                },
            };
        }
        case CHECK_ALL_DELETE: {
            const checked = action.checked;
            const entities = action.entities;
            let entitiesForDelete = {};
            if (!checked) {
                entitiesForDelete = entities.reduce((obj, entity, index) => {
                    obj[index] = entity;
                    return obj;
                }, {});
            }
            return {
                ...state,
                entitiesForDelete,
            };
        }
        case CRUD_REORDER_ROW: {
            let entityCrud = state.entityCrud.update(action.entity, (list) =>
                list.withMutations((list) => {
                    let temp = list.get(action.sourceIndex);
                    return list
                        .set(action.sourceIndex, list.get(action.targetIndex))
                        .set(action.targetIndex, temp);
                }),
            );
            return {
                ...state,
                entityCrud,
            };
        }
        case CRUD_DRAGGED_ROW: {
            return {
                ...state,
                draggedRow: action.row,
            };
        }
        case DELETE_CRUD_SUCCESS:
        case CANCEL_CRUD:
        case CLEAR:
            return initialState;

        default:
            return state;
    }
}

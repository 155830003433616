import CrudModel from './CrudModel';
import {
    fromExtraFields,
    fromExtraFieldsEndpoint,
    fromExtraFieldsV2,
    fromExtraFieldsEndpointV2,
    toCrudV2SelectValue,
    toFloat,
} from 'utils/crud';
import { formatAddress } from 'utils/addresses';
import { getSrcCompanyChipAlternative, getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getBackendBoolean } from 'utils/fm';

export default class CompanyModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static standardToCrud(data, mappedSchema) {
        let comercialMultipleObj = {};

        const idNames = [
            'IdResponsable',
            'IdResponsable2',
            'IdResponsable3',
            'IdResponsable4',
            'IdResponsable5',
        ];

        const labelNames = [
            'Responsible',
            'Responsible2',
            'Responsible3',
            'Responsible4',
            'Responsible5',
        ];

        idNames.forEach((id, index) => {
            if (!data[id]) return;
            let finalId = id.charAt(0).toLowerCase() + id.slice(1);
            comercialMultipleObj = {
                ...comercialMultipleObj,
                [finalId]: {
                    value: data[id],
                    label: data[labelNames[index]],
                },
            };
        });

        let {
            NIF,
            CP,
            Idcalificacion,
            Latitude,
            Longitude,
            IsGeoCoded,
            IdResponsable,
            IdResponsable2,
            IdResponsable3,
            IdResponsable4,
            IdResponsable5,
            Responsible,
            Responsible2,
            Responsible3,
            Responsible4,
            Responsible5,
            PublicCompany,
            ...other
        } = data;

        let newObj = {
            ...Object.keys(other).reduce((obj, key) => {
                obj[key.charAt(0).toLowerCase() + key.slice(1)] = other[key];
                return obj;
            }, {}),
            ...comercialMultipleObj,
            nif: NIF,
            cp: CP,
            idCalificacion: Idcalificacion,
            latitude: toFloat(Latitude),
            longitude: toFloat(Longitude),
            publicCompany: getBackendBoolean(PublicCompany),
            isGeoLocated: !!(IsGeoCoded === 'True' && Latitude && Longitude),
            ...fromExtraFields(
                other.Extrafields && other.Extrafields.ExtraFieldUnit
                    ? other.Extrafields.ExtraFieldUnit
                    : [],
                mappedSchema,
            ),
            isReadOnly: getBackendBoolean(data.ReadOnlyCompany),
            intgeoaccuracy: 0,
        };

        return newObj;
    }

    static toCrudV1(data, mappedSchema) {
        let newObj = this.standardToCrud(data, mappedSchema);

        let extraFields =
            !data.Extrafields.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpoint(data.extraFields || [], mappedSchema)
                : fromExtraFields(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        newObj = {
            ...newObj,
            ...extraFields,
        };

        return newObj;
    }

    static toCrudV2(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);

        const v2Data = toCrudV2SelectValue(baseData, [
            'idCompanyType',
            'idEnvironment',
            'idCompanyState',
            'idCalificacion',
            'idTarifa',
            'idCountry',
        ]);

        const extraFields =
            !data.Extrafields.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpointV2(data.extraFields || [], mappedSchema)
                : fromExtraFieldsV2(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        return { ...baseData, ...v2Data, ...extraFields };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toDetail(data) {
        return {
            ...Object.keys(data).reduce((obj, key) => {
                obj[key.charAt(0).toLowerCase() + key.slice(1)] = data[key];
                return obj;
            }, {}),
            nif: data.NIF,
            cp: data.CP,
            idCalificacion: data.Idcalificacion,
            latitude: toFloat(data.Latitude),
            longitude: toFloat(data.Longitude),
            fullAddress: formatAddress(
                data.Address1,
                data.Address2,
                data.City,
                data.Province,
                data.CP,
                data.Country,
                data.IdCountry,
            ),
            isGeoLocated: !!(data.IsGeoCoded === 'True' && data.Latitude && data.Longitude),
            extraFields: data.extraFields,
            isFollowing: data.FollowingItem !== '-1',
            isReadOnly: getBackendBoolean(data.ReadOnlyCompany),
        };
    }

    static toList({ data }) {
        const newData = data.map((current) => {
            let srcAccount = '';
            let placeholderAccount = '';

            const { src, fallbackSrc } = getSrcCompanyChipAlternative(current.idLogo);
            placeholderAccount = fallbackSrc;
            srcAccount = src;

            let srcResponsible = '';
            let placeholderResponsible = '';
            let srcResponsible2 = '';
            let placeholderResponsible2 = '';
            let srcResponsible3 = '';
            let placeholderResponsible3 = '';
            let srcResponsible4 = '';
            let placeholderResponsible4 = '';
            let srcResponsible5 = '';
            let placeholderResponsible5 = '';

            const idResponsableImage = getSrcUserCircleAvatar(current.IdResponsable);
            placeholderResponsible = idResponsableImage.fallbackSrc;
            srcResponsible = idResponsableImage.src;

            const idResponsableImage2 = getSrcUserCircleAvatar(current.IdResponsable2);
            placeholderResponsible2 = idResponsableImage2.fallbackSrc;
            srcResponsible2 = idResponsableImage2.src;

            const idResponsableImage3 = getSrcUserCircleAvatar(current.IdResponsable3);
            placeholderResponsible3 = idResponsableImage3.fallbackSrc;
            srcResponsible3 = idResponsableImage3.src;

            const idResponsableImage4 = getSrcUserCircleAvatar(current.IdResponsable4);
            placeholderResponsible4 = idResponsableImage4.fallbackSrc;
            srcResponsible4 = idResponsableImage4.src;

            const idResponsableImage5 = getSrcUserCircleAvatar(current.IdResponsable5);
            placeholderResponsible5 = idResponsableImage5.fallbackSrc;
            srcResponsible5 = idResponsableImage5.src;

            const win = getBackendBoolean(current.win);
            const lost = getBackendBoolean(current.lost);

            let isFollowing = false;

            if (Number.isInteger(current.FollowingItem)) {
                isFollowing = current.FollowingItem > 0;
            } else {
                isFollowing = !(
                    current.FollowingItem === 'false' ||
                    current.FollowingItem === 'False' ||
                    current.FollowingItem === '0' ||
                    current.FollowingItem === '-1'
                );
            }

            const flattenExtraFields = Object.keys(current).reduce((newObj, key) => {
                newObj[key] = current[key]?.hasOwnProperty('value')
                    ? current[key]?.value
                    : current[key];
                return newObj;
            }, {});

            let obj = {
                rawData: current,
                ...flattenExtraFields,
                name: current.Name || '',
                srcAccount,
                placeholderAccount,
                srcResponsible,
                srcResponsible2,
                srcResponsible3,
                srcResponsible4,
                srcResponsible5,
                placeholderResponsible,
                placeholderResponsible2,
                placeholderResponsible3,
                placeholderResponsible4,
                placeholderResponsible5,
                win,
                lost,
                isFollowing,
                IsGeoCoded: getBackendBoolean(current.IsGeoCoded),
                currencySymbol: current.SymbolCurrency,
            };

            return obj;
        });

        return newData || [];
    }

    get id() {
        return this.entity.id;
    }

    get isGeoLocated() {
        return this.entity.isgeocoded === 'True' && this.latitude && this.longitude;
    }

    get latitude() {
        return this.entity.latitude ? this.entity.latitude.replace(',', '.') : null;
    }

    get longitude() {
        return this.entity.longitude ? this.entity.longitude.replace(',', '.') : null;
    }
}

import Context from 'managers/Context';
import { formatAddressFromGeocoder } from 'utils/addresses';
import { getNumberAsLocaleNumber } from 'utils/numbers';

export const getBrowserGeolocation = () =>
    new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
            },
            () => {
                console.warn('error when getting geolocation from browser');
                reject();
            },
        );
    });

export const getDistanceInMeters = (lat1, lon1, lat2, lon2) => {
    if (!lat1 || !lon1 || !lat2 || !lon2) return null;
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344 * 1000;
    return Math.floor(dist);
};

export const checkAndIncrementMapBounds = (currentBounds, newBounds, hasBackendClusters) => {
    if (!google || !google.maps) return false;

    let northLatitude = newBounds.latitude;
    let southLatitude = newBounds.latitudeTo;
    let westLongitude = newBounds.longitude;
    let eastLongitude = newBounds.longitudeTo;

    eastLongitude = eastLongitude < westLongitude && eastLongitude < 0 ? 180.0 : eastLongitude;
    westLongitude = westLongitude > eastLongitude && westLongitude > 0 ? -180.0 : westLongitude;

    let newNorthLatitude = Math.min(northLatitude, 90.0);
    let newWestLongitude = Math.max(westLongitude, -180.0);
    let newSouthLatitude = Math.max(southLatitude, -90.0);
    let newEastLongitude = Math.min(eastLongitude, 180.0);

    let finalNorthLatitude;
    let finalSouthLatitude;
    let finalWestLongitude;
    let finalEastLongitude;
    let needToUpdate = false;
    if (!currentBounds || hasBackendClusters) {
        finalNorthLatitude = newNorthLatitude;
        finalSouthLatitude = newSouthLatitude;
        finalWestLongitude = newWestLongitude;
        finalEastLongitude = newEastLongitude;
        needToUpdate = true;
    } else {
        finalNorthLatitude = currentBounds.latitude;
        finalSouthLatitude = currentBounds.latitudeTo;
        finalWestLongitude = currentBounds.longitude;
        finalEastLongitude = currentBounds.longitudeTo;
        if (finalNorthLatitude < northLatitude || finalWestLongitude > westLongitude) {
            finalNorthLatitude = newNorthLatitude;
            finalWestLongitude = newWestLongitude;
            needToUpdate = true;
        }
        if (finalSouthLatitude > southLatitude || finalEastLongitude < eastLongitude) {
            finalSouthLatitude = newSouthLatitude;
            finalEastLongitude = newEastLongitude;
            needToUpdate = true;
        }
    }

    return {
        needToUpdate,
        bounds: {
            latitude: finalNorthLatitude,
            longitude: finalWestLongitude,
            latitudeTo: finalSouthLatitude,
            longitudeTo: finalEastLongitude,
        },
    };
};

export const getFMAddressFromCoordinates = (location, address) => {
    return new Promise((resolve, reject) => {
        FMGeocodeLocation({ location, address })
            .then((results) => {
                resolve(getFMAddressFromGeoCodeResults(results));
            })
            .catch((error) => {
                console.warn('impossible to get the address');
            });
    });
};

export const FMGeocodeLocation = (data) => {
    return new Promise((resolve, reject) => {
        const params = {
            language: Context?.config?.userData?.locale,
            latlng: `${data.location.lat},${data.location.lng}`,
        };
        if (data.address) params.address = data.address;
        Context.domainManager
            .getGeoCoding(params)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.error(error);
            });
    });
};

export const getFMAddressFromGeoCodeResults = (results) => {
    if (!results) return null;

    let result = results.Result.FMGeoCodingResults['0'];

    if (!result) return null;

    let returnResult = {
        locationName: result.FMFormatedAddress,
        googleLocationName: result.FMFormatedAddress,
        latitude: result.FMGeometry.FMLocation.FMLat,
        longitude: result.FMGeometry.FMLocation.FMLng,
        ...result,
    };

    result.FMAddressComponents?.map((item) => {
        let type = item.FMTypes[0];

        if (type === 'neighborhood' || type === 'route') {
            if (type === 'neighborhood') {
                if (!returnResult.shortAddress) {
                    returnResult.shortAddress = item.FMLongName;
                } else {
                    returnResult.neighborhood = item.FMLongName;
                }
            } else {
                returnResult.shortAddress = item.FMLongName;
            }
        }

        switch (type) {
            case 'street_number':
                returnResult.streetNumber = item.FMLongName;
                break;
            case 'postal_code':
                returnResult.postalCode = item.FMLongName;
                break;
            case 'subpremise':
                returnResult.subpremise = item.FMLongName;
            case 'locality':
            case 'sublocality_level_1':
            case 'sublocality':
            case 'postal_town':
                returnResult.locality = item.FMLongName;
                break;
            case 'administrative_area_level_1':
                returnResult.state = item.FMLongName;
                returnResult.stateShort = item.FMShortName;
                break;
            case 'administrative_area_level_2':
                returnResult.region = item.FMLongName;
                break;
            case 'administrative_area_level_3':
                returnResult.administrativeAreaLevel3 = item.FMLongName;
                break;
            case 'country':
                returnResult.country = item.FMLongName;
                returnResult.countryShort = item.FMShortName;
                break;
            default:
                break;
        }
    });

    if (returnResult.countryShort === 'IT') returnResult.administrativeAreaLevel3 = '';

    if (returnResult.shortAddress) {
        returnResult.locationName = formatAddressFromGeocoder(
            returnResult.shortAddress,
            returnResult.streetNumber,
            returnResult.postalCode,
            returnResult.locality,
            returnResult.state,
            returnResult.region,
            returnResult.country,
            returnResult.countryShort,
            returnResult.subpremise,
        );
    }

    return returnResult;
};

export function formatSecondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return { formatted: `${hours}:${formattedMinutes}`, hours, minutes };
}

export function isImperialSystem() {
    const state = Context.store.getState();
    const lengthSystem = state.config?.userData?.lengthSystem;
    return lengthSystem?.toLowerCase() === 'imperial';
}

export function computeRouteTotals(result) {
    let metersYards = 0;
    let totalDist = 0;
    let totalTime = 0;

    const routes = (result?.route || result?.routes)?.[0];
    routes?.legs.forEach((leg) => {
        metersYards += leg.distance.value;
        totalTime += leg.duration.value;
    });
    let calculateTotal = isImperialSystem() ? metersYards / 1760 : metersYards / 1000;

    totalDist = getNumberAsLocaleNumber(calculateTotal, {
        useGrouping: false,
        maximumFractionDigits: 2,
    });

    const timeDesc = formatSecondsToHHMM(totalTime);
    totalTime = getNumberAsLocaleNumber(timeDesc?.formatted, {
        useGrouping: false,
        maximumFractionDigits: 2,
    });

    return {
        totalDist,
        totalTime,
        kmMiles: calculateTotal,
        metersYards,
        hours: timeDesc.hours,
        minutes: timeDesc.minutes,
    };
}

import React from 'react';
import colors from 'constants/colors';

const EditField = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 13.667V17h3.333l9.83-9.83-3.333-3.333L1 13.667zm15.74-9.074a.885.885 0 0 0 0-1.253l-2.08-2.08a.885.885 0 0 0-1.253 0l-1.626 1.626 3.333 3.333 1.626-1.626z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default EditField;

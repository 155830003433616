import { Map, List } from 'immutable';
import { BILLBOARD } from 'constants/Entities';
import { BillboardModel } from 'models';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import Context from 'managers/Context';

const SELECTED_PUBLISHED_ROOT = 'root';
const SELECTED_PUBLISHED_ENVIRONMENTS = 'environments';
const SELECTED_PUBLISHED_GROUPS = 'groups';
const SELECTED_PUBLISHED_MYTEAM = 'myteam';
const SELECTED_PUBLISHED_WHOLECOMPANY = 'wholecompany';
const SELECTED_RECEIVED_ROOT = 'root';
const SELECTED_RECEIVED_PENDING = 'pending';
const SELECTED_RECEIVED_CONFIRMED = 'confirmed';

const HardcodedEnvironmentsMap = {
    [SELECTED_PUBLISHED_MYTEAM]: -1,
    [SELECTED_PUBLISHED_WHOLECOMPANY]: -2,
};

const billboardCrudSchema = [
    {
        description: <Literal literal="label_info" />,
        show: false,
        tabFields: [
            {
                id: 'subject',
                server: 'subject',
                dataType: 'text',
                mandatory: true,
                hintText: <Literal literal="placeholder_billboard_title" />,
                fullWidth: true,
                isFirstToFocus: true,
            },
            // {
            //     id:'startDate',
            //     server:'startDate',
            //     dataType:'date',
            //     description:(<Literal literal="label_start_date"/>),
            // },
            // {
            //     id:'endDate',
            //     server:'endDate',
            //     dataType:'date',
            //     description:(<Literal literal="label_end_date"/>),
            // },
            {
                id: { id: 'environmentsId', desc: 'environmentsDescription' },
                server: 'environmentsId',
                dataType: 'multipleValueList',
                // valueListName: {table: 'tblSucursales', extraValues: [{"-id": SELECTED_PUBLISHED_MYTEAM, descripcion: "label_billboard_team", permission: "billboardMyTeam"}, {"-id": SELECTED_PUBLISHED_WHOLECOMPANY, descripcion: "label_billboard_company", permission: "billboardMyCompany"}], extraValuesFirst: true},
                valueListName: 'tblSucursalesBillboard',
                isCustomValueList: true,
                customValueListParameters: {
                    entity: BILLBOARD,
                    method: 'getBillboardEnvironmentsWithGroups',
                },
                mandatory: true,
                description: <Literal literal="label_billboard_post_to" />,
                fullWidth: true,
                editReadOnly: true,
            },
            {
                id: { id: 'description', descriptionText: 'descriptionText' },
                server: 'description',
                dataType: 'textarea',
                hintText: 'placeholder_billboard_write_message_here',
            },
        ],
    },
];

export default class BillboardManager {
    getCrudSchema = (config, entityConfig, success, error) => {
        this.context.crudManager.getCrudSchema(
            config,
            entityConfig,
            billboardCrudSchema,
            (schema) => {
                const finalSchema = {
                    form: schema,
                };
                success(finalSchema);
            },
            error,
        );
    };

    getBillboardMenu() {
        return new Promise((resolve, reject) => {
            this.context.domainManager.getBillboardMenu(
                (result) => {
                    let state = this.context.store.getState();
                    let permissions = state.config.permission;
                    const literals = state.literal.literals;
                    const isAdmin = permissions.adminBillboard;
                    const billboardMyTeam = permissions.billboardMyTeam;
                    const billboardMyCompany = permissions.billboardMyCompany;
                    const viewEntitiesGroups = permissions.viewEntitiesGroups;

                    // admin part
                    const published = {};
                    if (isAdmin) {
                        const publishedRoot = {
                            node: {
                                id: SELECTED_PUBLISHED_ROOT,
                                name: literals.hasOwnProperty('label_billboard_published')
                                    ? literals.label_billboard_published
                                    : 'label_billboard_published',
                                iconType: 'outbox',
                            },
                            childNodes: [],
                        };
                        published[SELECTED_PUBLISHED_ROOT] = publishedRoot;

                        const publishedEnvironmentsRoot = {
                            node: {
                                id: SELECTED_PUBLISHED_ENVIRONMENTS,
                                name: literals.hasOwnProperty('label_billboard_environments')
                                    ? literals.label_billboard_environments
                                    : 'label_billboard_environments',
                                iconType: 'environment',
                                order: 1,
                            },
                            childNodes: [],
                        };
                        published[SELECTED_PUBLISHED_ENVIRONMENTS] = publishedEnvironmentsRoot;
                        published[SELECTED_PUBLISHED_ROOT].childNodes.push(
                            publishedEnvironmentsRoot.node,
                        );

                        if (billboardMyTeam) {
                            const publishedMyTeam = {
                                node: {
                                    id: SELECTED_PUBLISHED_MYTEAM,
                                    name: literals.hasOwnProperty('label_billboard_team')
                                        ? literals.label_billboard_team
                                        : 'label_billboard_team',
                                    iconType: 'myTeam',
                                    order: 2,
                                },
                                childNodes: [],
                            };
                            published[SELECTED_PUBLISHED_MYTEAM] = publishedMyTeam;
                            published[SELECTED_PUBLISHED_ROOT].childNodes.push(
                                publishedMyTeam.node,
                            );
                        }
                        if (billboardMyCompany) {
                            const publishedWholeCompany = {
                                node: {
                                    id: SELECTED_PUBLISHED_WHOLECOMPANY,
                                    name: literals.hasOwnProperty('label_billboard_company')
                                        ? literals.label_billboard_company
                                        : 'label_billboard_company',
                                    iconType: 'wholeCompany',
                                    order: 3,
                                },
                                childNodes: [],
                            };
                            published[SELECTED_PUBLISHED_WHOLECOMPANY] = publishedWholeCompany;
                            published[SELECTED_PUBLISHED_ROOT].childNodes.push(
                                publishedWholeCompany.node,
                            );
                        }

                        let publishedGroupsRoot = {
                            node: {
                                id: SELECTED_PUBLISHED_GROUPS,
                                // name: literals.hasOwnProperty('label_billboard_all_environments') ? literals['label_billboard_all_environments'] : 'label_billboard_all_environments',
                                name: literals.hasOwnProperty('label_billboard_custom_group')
                                    ? literals.label_billboard_custom_group
                                    : 'label_billboard_custom_group',
                                iconType: 'customGroups',
                                order: 4,
                            },
                            childNodes: [],
                        };
                        if (viewEntitiesGroups) {
                            published[SELECTED_PUBLISHED_GROUPS] = publishedGroupsRoot;
                            published[SELECTED_PUBLISHED_ROOT].childNodes.push(
                                publishedGroupsRoot.node,
                            );
                        }

                        // now fill with environments and groups
                        for (const group of result) {
                            const node = {
                                node: {
                                    id: group.id.toString(),
                                    name: group.name,
                                    // children: [],
                                },
                                childNodes: [],
                            };
                            // we add prefix on node ids to prevent errors with same ids of environments
                            if (viewEntitiesGroups && group.isCustom) {
                                node.node.id = `g-${group['id'].toString()}`;
                                published[node.node.id] = node;
                                published[SELECTED_PUBLISHED_GROUPS].childNodes.push(node.node);
                            } else {
                                node.node.id = `e-${group['id'].toString()}`;
                                published[node.node.id] = node;
                                published[SELECTED_PUBLISHED_ENVIRONMENTS].childNodes.push(
                                    node.node,
                                );
                            }
                        }
                    }

                    // reader part
                    const received = {};
                    const publishedRoot = {
                        node: {
                            id: SELECTED_RECEIVED_ROOT,
                            name: literals.hasOwnProperty('label_billboard_status_received')
                                ? literals.label_billboard_status_received
                                : 'label_billboard_status_received',
                            iconType: 'inbox',
                        },
                        childNodes: [],
                    };
                    received[SELECTED_RECEIVED_ROOT] = publishedRoot;

                    const receivedPendingRoot = {
                        node: {
                            id: SELECTED_RECEIVED_PENDING,
                            name: literals.hasOwnProperty('label_billboard_status_pending')
                                ? literals.label_billboard_status_pending
                                : 'label_billboard_status_pending',
                            order: 1,
                        },
                        childNodes: [],
                    };
                    received[SELECTED_RECEIVED_PENDING] = receivedPendingRoot;
                    received[SELECTED_RECEIVED_ROOT].childNodes.push(receivedPendingRoot.node);

                    const receivedConfirmedRoot = {
                        node: {
                            id: SELECTED_RECEIVED_CONFIRMED,
                            name: literals.hasOwnProperty('label_billboard_status_confirmed')
                                ? literals.label_billboard_status_confirmed
                                : 'label_billboard_status_confirmed',
                            order: 2,
                        },
                        childNodes: [],
                    };
                    received[SELECTED_RECEIVED_CONFIRMED] = receivedConfirmedRoot;
                    received[SELECTED_RECEIVED_ROOT].childNodes.push(receivedConfirmedRoot.node);

                    resolve({ published, received });
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    getEntityFilterParams = () => {
        const isAdmin =
            this.context.config &&
            this.context.config.permisos &&
            this.context.config.permisos.adminBillboard;

        const billboardState = this.context.store.getState().billboard;
        const selectedPublished = billboardState.menu.selectedPublished;
        const selectedReceived = billboardState.menu.selectedReceived;
        const selectedFavourite = billboardState.menu.selectedFavourite;
        const isReader = billboardState.menu.isReader;

        const parameters = {};
        if (
            selectedPublished &&
            selectedPublished !== SELECTED_PUBLISHED_ROOT &&
            selectedPublished !== SELECTED_PUBLISHED_ENVIRONMENTS
        ) {
            if (selectedPublished === SELECTED_PUBLISHED_MYTEAM) {
                parameters.myteam = 1;
            } else if (selectedPublished === SELECTED_PUBLISHED_WHOLECOMPANY) {
                parameters.mycompany = 1;
            } else if (selectedPublished === SELECTED_PUBLISHED_GROUPS) {
                // we need a special case for this to let backend know we want to filter
                // by all the custom groups
                parameters.idgroup = '-1';
            } else if (selectedPublished.indexOf('g-') !== -1) {
                parameters.idgroup = selectedPublished.replace('g-', '');
            } else if (selectedPublished.indexOf('e-') !== -1) {
                parameters.idenvironment = selectedPublished.replace('e-', '');
            } else {
                parameters.idenvironment = selectedPublished;
            }
        }
        if (selectedReceived) {
            if (selectedReceived === SELECTED_RECEIVED_ROOT) {
                parameters.receivedaction = 'all';
            } else if (selectedReceived === SELECTED_RECEIVED_PENDING) {
                parameters.receivedaction = 'pending';
            } else if (selectedReceived === SELECTED_RECEIVED_CONFIRMED) {
                parameters.receivedaction = 'confirmed';
            }
        }
        if (selectedFavourite) {
            parameters.followingitem = 1;
        }
        parameters.isadmin = isAdmin && !isReader;

        return parameters;
    };

    transformDataForEntityList = (backendData) => {
        let totalRows = backendData.TotalRows;
        if (backendData.State === '1' && totalRows === -1) {
            totalRows = 0;
        }
        return {
            Data: backendData.Result,
            MetaData: { totalRows: totalRows },
        };
    };

    getBillboardListForNotifications(success, error) {
        const filter = {
            params: { receivedaction: 'all', isadmin: false },
        };
        return new Promise((resolve, reject) => {
            this.context.domainManager.getEntityList(
                BILLBOARD.entity,
                0,
                5,
                filter,
                '',
                -1,
                null,
                false,
                (data) => {
                    resolve(data.Result);
                },
                reject,
            );
        });
    }

    getEntity(id, success, error) {
        const isAdmin = this.context.config.permisos.adminBillboard;
        const isReader = this.context.store.getState().billboard.menu.isReader;

        this.context.domainManager.getBillboard(
            id,
            isAdmin && !isReader,
            (data) => {
                const billboardModel = new BillboardModel(data);
                const documentsPromise = new Promise((resolve, reject) => {
                    this.getBillboardDocuments(billboardModel, resolve, reject);
                });

                const actionSentPromise = new Promise((resolve, reject) => {
                    if (!isReader) {
                        this.getBillboardActionDetails(id, 'Sent', resolve, reject);
                    } else {
                        resolve();
                    }
                });

                const actionOpenedPromise = new Promise((resolve, reject) => {
                    if (!isReader) {
                        this.getBillboardActionDetails(id, 'Opened', resolve, reject);
                    } else {
                        resolve();
                    }
                });

                const actionConfirmedPromise = new Promise((resolve, reject) => {
                    if (!isReader) {
                        this.getBillboardActionDetails(id, 'Confirmed', resolve, reject);
                    } else {
                        resolve();
                    }
                });

                Promise.all([
                    documentsPromise,
                    actionSentPromise,
                    actionOpenedPromise,
                    actionConfirmedPromise,
                ]).then((result) => {
                    // check if the result is from the selected option that user choose, and not an old selection
                    const selectedId = this.context.store
                        .getState()
                        .detail.tabs.get('0')
                        ?.get('id');
                    if (
                        selectedId &&
                        parseInt(selectedId, 10) === parseInt(billboardModel.id, 10)
                    ) {
                        const documents = result[0];

                        const actionSentUsers = result[1];
                        let actionSentUsersInclude = [];
                        if (actionSentUsers) {
                            actionSentUsersInclude = actionSentUsers.usersInclude;
                        }

                        const actionOpenedUsers = result[2];
                        let actionOpenedUsersInclude = [];
                        let actionOpenedUsersExclude = [];
                        if (actionOpenedUsers) {
                            actionOpenedUsersInclude = actionOpenedUsers.usersInclude;
                            actionOpenedUsersExclude = actionOpenedUsers.usersExclude;
                        }

                        const actionConfirmedUsers = result[3];
                        let actionConfirmedUsersInclude = [];
                        let actionConfirmedUsersExclude = [];
                        if (actionConfirmedUsers) {
                            actionConfirmedUsersInclude = actionConfirmedUsers.usersInclude;
                            actionConfirmedUsersExclude = actionConfirmedUsers.usersExclude;
                        }

                        billboardModel.attachmentFiles = documents
                            ? new List(documents)
                            : new List();
                        billboardModel.actionDetailSentUsers = actionSentUsersInclude;
                        billboardModel.actionDetailSentUsersCount = actionSentUsersInclude.length;
                        billboardModel.actionDetailOpenedUsers = actionOpenedUsersInclude;
                        billboardModel.actionDetailOpenedUsersCount =
                            actionOpenedUsersInclude.length;
                        billboardModel.actionDetailNonOpenedUsers = actionOpenedUsersExclude;
                        billboardModel.actionDetailNonOpenedUsersCount =
                            actionOpenedUsersExclude.length;

                        billboardModel.actionDetailConfirmedUsers = actionConfirmedUsersInclude;
                        billboardModel.actionDetailConfirmedUsersCount =
                            actionConfirmedUsersInclude.length;
                        billboardModel.actionDetailNonConfirmedUsers = actionConfirmedUsersExclude;
                        billboardModel.actionDetailNonConfirmedUsersCount =
                            actionConfirmedUsersExclude.length;

                        if (
                            isReader &&
                            !billboardModel.userHasOpened(this.context.config.userData.idUsuario)
                        ) {
                            this.markBillboardAsOpened(billboardModel, () => {
                                // this.context.domainManager.getBillboard(id, isAdmin, (data) => {
                                //     let billboardModel = new BillboardModel(data);
                                //     success(billboardModel);
                                // });
                                // this.refreshData();
                                success(billboardModel);
                            });
                        } else {
                            success(billboardModel, data);
                        }
                    }
                });
            },
            (errorData) => {
                error(errorData);
            },
        );
    }

    getBillboardDocuments(model, callback) {
        if (model.attachments > 0) {
            this.context.domainManager.getDocumentsEntity(
                BILLBOARD,
                model.id,
                '-1',
                (result) => {
                    callback(result);
                },
                () => {
                    callback();
                },
            );
        } else {
            callback();
        }
    }

    getBillboardActionDetails(id, status, callback) {
        this.context.domainManager.getBillboardActionDetails(
            id,
            status,
            (result) => {
                callback(result);
            },
            () => {
                callback();
            },
        );
    }

    markBillboardAsOpened(model, callback) {
        const idUser = this.context.config.userData.idUsuario;
        if (model.userCreado !== idUser) {
            this.context.domainManager.markBillboardAsOpened(
                model.id,
                () => {
                    callback();
                    // not necessary to refresh data because, in general, the user who is going to call this
                    // will not be a manager of billboard, so the table will be much easier
                    // this.refreshData();
                },
                () => {
                    callback();
                    console.error('Error marking billboard');
                },
            );
        } else {
            callback();
        }
        callback();
    }

    markBillboardAsConfirmed(model, callback) {
        const idUser = this.context.config.userData.idUsuario;
        const isReader = this.context.store.getState().billboard.menu.isReader;
        if (isReader && !model.userHasConfirmed(idUser)) {
            this.context.domainManager.markBillboardAsConfirmed(
                model.id,
                () => {
                    this.context.store.dispatch(
                        this.context.actions.DetailActions.newDetailTab(BILLBOARD, model.id),
                    );
                    this.refreshData();
                    callback();
                },
                () => {
                    callback();
                    console.error('Error marking billboard');
                },
            );
        } else {
            callback();
        }
    }

    getErrors(schema, entityCrud, dynamic) {
        const errors = this.context.crudManager.getErrors(schema.form, entityCrud, dynamic);
        return errors;
    }

    createEntity(entity, success, error) {
        const config = this.context.cacheManager.getConfigStore();
        const files = this.context.store.getState().billboard.crud.get('files');
        entity = this.parseEnvironmentsEntityToVisibility(entity);
        const crud = BillboardModel.fromCrud(entity);
        this.context.domainManager.createBillboard(
            crud.createCrud(),
            (result, id) => {
                let afterManagerDocuments = () => {
                    success(result);
                    this.context.store.dispatch(
                        this.context.actions.BillboardActions.showCreateSuccessDialog(true),
                    );
                };
                Promise.all(this.uploadFiles(files, id))
                    .then(() => {
                        afterManagerDocuments();
                    })
                    .catch(() => {
                        console.error('error while managing files for billboard');
                        afterManagerDocuments();
                    });
            },
            error,
        );
    }

    updateEntity(id, entity, success, error) {
        const config = this.context.cacheManager.getConfigStore();
        const files = this.context.store.getState().billboard.crud.get('files');
        const filesToRemove = this.context.store.getState().billboard.crud.get('filesToRemove');
        entity = this.parseEnvironmentsEntityToVisibility(entity);
        const crud = BillboardModel.fromCrud(entity);
        const billboard = this.context.store.getState().billboard;
        const recompute = !!billboard.crud.get('recompute');
        this.context.domainManager.resendBillboard(
            crud.createCrud(),
            recompute,
            (result, id) => {
                let afterManagerDocuments = () => {
                    success(result);
                    if (recompute) {
                        this.context.store.dispatch(
                            this.context.actions.BillboardActions.showCreateSuccessDialog(true),
                        );
                    }
                };
                Promise.all([...this.uploadFiles(files, id), ...this.deleteFiles(filesToRemove)])
                    .then(() => {
                        afterManagerDocuments();
                    })
                    .catch((error) => {
                        console.error('error while managing files for billboard');
                        afterManagerDocuments();
                    });
            },
            error,
        );
    }

    uploadFiles = (files, idBillboard) => {
        let promisesFiles = [];
        for (const file of files) {
            const promise = new Promise((resolve, reject) => {
                this.context.store
                    .dispatch(
                        this.context.actions.DocumentActions.addDocumentToUploadFromCrud(
                            file,
                            idBillboard,
                            BILLBOARD,
                        ),
                    )
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        console.error('error while uploading a file for billboard');
                        reject();
                    });
            });
            promisesFiles.push(promise);
        }
        return promisesFiles;
    };

    deleteFiles = (files) => {
        let promisesFiles = [];
        for (const idFile of files) {
            const promise = new Promise((resolve, reject) => {
                this.context.store
                    .dispatch(this.context.actions.DocumentActions.deleteDocument(idFile))
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        console.error('error while deleting a file for billboard');
                        reject();
                    });
            });
            promisesFiles.push(promise);
        }
        return promisesFiles;
    };

    parseEnvironmentsEntityToVisibility(entity) {
        let environments = Context.store.getState().valueList.get('tblSucursalesBillboard');
        if (environments) {
            environments = environments.get('data');
        }
        if (!environments || environments.size === 0) return entity;
        environments = environments.toJSON();
        let entityEnvironments = entity.get('environmentsid').split(';');
        entityEnvironments = entityEnvironments.filter((v) => v !== '');
        let environmentsIds = [];
        let usersIds = [];
        let groupsIds = [];
        entityEnvironments.forEach((id) => {
            let environment = environments.find((env) => env['-id'] === id);
            if (environment) {
                if (environment.isInternal) {
                    if (environment.id === -1) {
                        entity = entity.set('isMyTeam', true);
                    } else if (environment.id === -2) {
                        entity = entity.set('isMyCompany', true);
                    }
                } else if (environment.isGroup) {
                    groupsIds.push(environment.id);
                } else {
                    environmentsIds.push(environment.id);
                }
            }
        });
        entity = entity.set('environments', environmentsIds);
        entity = entity.set('users', usersIds);
        entity = entity.set('groups', groupsIds);
        return entity;
    }

    getDefaultEntityCrudHardCode(crudEntity, preloadedValueList) {
        const billboard = this.context.store.getState().billboard;
        // let selectedId = billboard.menu.get('selectedEnvironment') && billboard.menu.get('selectedEnvironment') !== -1
        //                     ? billboard.menu.get('selectedEnvironment')
        //                     : billboard.menu.get('selectedGroup') && billboard.menu.get('selectedGroup') !== -2
        //                         ? billboard.menu.get('selectedGroup')
        //                         : null;
        // let groupName = billboard.menu.get('selectedEnvironment') && billboard.menu.get('selectedEnvironment') !== -1
        //                     ? billboard.menu.get('environments').get(selectedId+'').node.name
        //                     : billboard.menu.get('selectedGroup') && billboard.menu.get('selectedGroup') !== -2
        //                         ? billboard.menu.get('groups').get(selectedId+'').node.name
        //                         : '';
        let selectedId;
        let groupName;
        if (
            billboard.menu.selectedPublished &&
            billboard.menu.selectedPublished !== SELECTED_RECEIVED_ROOT &&
            billboard.menu.selectedPublished !== SELECTED_PUBLISHED_ENVIRONMENTS
        ) {
            const selectedTreeId = billboard.menu.selectedPublished;
            groupName = billboard.menu.published[selectedTreeId.toString()].node.name;
            if (HardcodedEnvironmentsMap[selectedTreeId]) {
                selectedId = HardcodedEnvironmentsMap[selectedTreeId];
            } else {
                selectedId = selectedTreeId;
            }
        }
        // else if(billboard.menu.get('selectedGroup') && billboard.menu.get('selectedGroup') !== '-2') {
        //     selectedId = billboard.menu.get('selectedGroup');
        //     groupName = billboard.menu.get('groups').get(selectedId.toString()).node.name;
        // }
        if (selectedId && groupName) {
            crudEntity = crudEntity.set('environmentsid', `;${selectedId};`);
        }

        return crudEntity;
    }

    refreshData() {
        const state = Context.store.getState();
        const entityList = state?.entityList?.[state?.entityList?.active] || null;
        const useLazyLoad = entityList?.useLazyLoad || false;
        if (useLazyLoad) {
            Context.store.dispatch(
                Context.actions.EntityListActions.init(
                    BILLBOARD,
                    false,
                    null,
                    null,
                    null,
                    useLazyLoad,
                ),
            );
        } else {
            Context.store.dispatch(Context.actions.EntityListActions.init(BILLBOARD, true));
        }
    }

    getBillboardEnvironmentsWithGroups(table, success, error) {
        const resultValue = this.context.cacheManager.getValueListStore(table);
        if (resultValue) {
            success(resultValue);
        } else {
            Context.domainManager
                .getBillboardEnvironmentsWithGroups()
                .then((data) => {
                    // prepare the data to fit in the old and fantastic value list field
                    let internals = data.internals || [];
                    let groups = data.groups || [];
                    let environments = data.environments || [];
                    let finalData =
                        (internals &&
                            internals.length > 0 &&
                            internals.reduce((obj, d) => {
                                obj.push({
                                    ...d,
                                    '-id': d.id.toString(), // this ids are negative numbers
                                    descripcion: d.name,
                                    isInternal: true,
                                    isGroup: false,
                                    isEnvironment: false,
                                });
                                return obj;
                            }, [])) ||
                        [];
                    finalData =
                        (groups &&
                            groups.length > 0 &&
                            groups.reduce((obj, d) => {
                                obj.push({
                                    ...d,
                                    '-id': 'g-' + d.id.toString(),
                                    descripcion: d.name,
                                    isInternal: false,
                                    isGroup: true,
                                    isEnvironment: false,
                                });
                                return obj;
                            }, finalData)) ||
                        finalData;
                    finalData =
                        (environments &&
                            environments.length > 0 &&
                            environments.reduce((obj, d) => {
                                obj.push({
                                    ...d,
                                    '-id': 'e-' + d.id.toString(),
                                    descripcion: d.name,
                                    isInternal: false,
                                    isGroup: false,
                                    isEnvironment: true,
                                });
                                return obj;
                            }, finalData)) ||
                        finalData;
                    this.context.cacheManager.setValueListStore(table, finalData);
                    success(finalData);
                })
                .catch((error) => {
                    console.warn(
                        'There is a problem with the request getBillboardEnvironmentsWithGroups',
                    );
                });
        }
    }

    beforeCrud = () => {
        this.context.store.dispatch(this.context.actions.BillboardActions.resetFilesToUpload());
    };
}

function getNode(tree, id) {
    if (!tree[id]) {
        tree[id] = {};
        tree[id].childNodes = [];
        tree[id].node = {};
    }
    return tree[id];
}

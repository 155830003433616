import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Footer from './Footer';
import { StepOne, StepTwo, StepThree } from './steps';
import { logEvent } from 'utils/tracking';
import { TOUR_MODES } from 'constants/Constants';

import Modal from 'components/Modal';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        login: state.config?.login,
        tourMode: state.config?.userData?.tourMode,
        productModeCode: state.config?.productModeCode,
    };
};

@connect(mapStateToProps)
class Onboarding extends PureComponent {
    state = {
        step: 1,
        isOpen: true,
    };

    handleCloseModal = () => {
        this.setState({ isOpen: false });
        // Changes the labels in the third step depends of the mockup data
        sessionStorage.removeItem('fm_deletedWalkThroughData');
    };

    handleClickPrev = () => {
        const { step } = this.state;
        if (step === 1) return;
        this.setState({ step: step - 1 });
    };

    handleClickNext = () => {
        const { step } = this.state;

        if (step === 1)
            logEvent({
                event: 'signup',
                functionality: 'startTour',
            });
        else if (step === 2)
            logEvent({
                event: 'signup',
                functionality: 'next',
            });
        if (step === 3) return;
        this.setState({ step: step + 1 });
    };

    renderSteps = () => {
        const { step } = this.state;
        const { productModeCode } = this.props;
        const is5500 = productModeCode === '5500';
        return (
            <div className="onboarding-steps-container">
                <StepOne step={step} is5500={is5500} />
                <StepTwo step={step} is5500={is5500} />
                <StepThree step={step} is5500={is5500} />
            </div>
        );
    };

    render() {
        const { step, isOpen } = this.state;
        const { tourMode, login } = this.props;

        if (tourMode === TOUR_MODES.disabled || !login) return null;

        return (
            <Modal width="670px" isOpen={isOpen}>
                <div className="onboarding-container">
                    {this.renderSteps()}
                    <Footer
                        tourMode={tourMode}
                        step={step}
                        handleClickPrev={this.handleClickPrev}
                        handleClickNext={this.handleClickNext}
                        handleCloseModal={this.handleCloseModal}
                    />
                </div>
            </Modal>
        );
    }
}

export default Onboarding;

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'hoi-poi-ui';

import { formatCompleteDate } from 'utils/dates';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import EntityDetail from 'containers/components/EntityDetail';
import EntityCrud from 'containers/components/EntityCrud';

import DashboardHeader from './DashboardHeader';
import DashboardGrid from './DashboardGrid';
import './styles.scss';

const Dashboard = memo(() => {
    const login = useSelector((state) => state.config.login);
    if (!login) return null;

    return (
        <ContentLayout header={<DashboardHeader />}>
            <div className="fm-dashboard__header-date">
                <Text type="subtitle">{formatCompleteDate(new Date())}</Text>
            </div>
            <DashboardGrid />
            <EntityCrud />
            <EntityDetail openAllDetails />
        </ContentLayout>
    );
});

export default Dashboard;

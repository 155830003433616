import Immutable, { List, Map } from 'immutable';
import {
    BILLBOARD_MENU_LOADING,
    BILLBOARD_MENU_SUCCESS,
    BILLBOARD_MENU_ERROR,
    BILLBOARD_MENU_SELECT_PUBLISHED,
    BILLBOARD_MENU_SELECT_RECEIVED,
    BILLBOARD_MENU_SELECT_FAVOURITE,
    BILLBOARD_MENU_SELECT_RECENT,
    BILLBOARD_HOVER_ROW,
    BILLBOARD_DETAIL_SECONDARY_SHOW,
    BILLBOARD_DETAIL_SECONDARY_HIDE,
    BILLBOARD_SHOW_CREATE_SUCCESS,
    BILLBOARD_ADD_FILES_TO_UPLOAD,
    BILLBOARD_RESET_FILES_TO_UPLOAD,
    BILLBOARD_REMOVE_FILE_TO_UPLOAD,
    BILLBOARD_ADD_FILE_TO_REMOVE,
    BILLBOARD_SAVE_CRUD_RECOMPUTE,
} from 'constants/ActionTypes';

const initialState = {
    menu: {
        published: {},
        received: {},
        selectedPublished: '',
        selectedReceived: '',
        selectedFavourite: false,
        selectedRecent: false,
        loading: false,
        error: false,
        isReader: true,
    },
    detail: Immutable.fromJS({
        showSecondary: false,
        secondaryType: null,
    }),
    crud: Immutable.fromJS({
        files: [],
        filesToRemove: [],
        recompute: true,
    }),
    showSuccessDialog: false,
};

export default function reducer(state = initialState, action) {
    let menuState = state.menu;
    let listState = state.list;
    let detailState = state.detail;
    let crudState = state.crud;
    switch (action.type) {
        case BILLBOARD_MENU_LOADING:
            return {
                ...state,
                menu: {
                    ...menuState,
                    loading: true,
                },
            };
        case BILLBOARD_MENU_SUCCESS:
            return {
                ...state,
                menu: {
                    ...menuState,
                    loading: false,
                    error: false,
                    published: action.published,
                    received: action.received,
                },
            };
        case BILLBOARD_MENU_ERROR:
            return {
                ...state,
                menu: {
                    ...menuState,
                    loading: false,
                    error: true,
                },
            };
        case BILLBOARD_MENU_SELECT_PUBLISHED:
            return {
                ...state,
                menu: {
                    ...menuState,
                    selectedPublished: action.id,
                    selectedReceived: '',
                    selectedFavourite: false,
                    selectedRecent: false,
                    isReader: false,
                },
                list: { ...initialState.list },
            };
        case BILLBOARD_MENU_SELECT_RECEIVED:
            return {
                ...state,
                menu: {
                    ...menuState,
                    selectedPublished: '',
                    selectedReceived: action.id,
                    selectedFavourite: false,
                    selectedRecent: false,
                    isReader: true,
                },
                list: { ...initialState.list },
            };
        // case BILLBOARD_MENU_SELECT_FAVOURITE:
        //     menuState = menuState
        //         .update('selectedPublished', () => '')
        //         .update('selectedReceived', () => '')
        //         .update('selectedFavourite', () => true)
        //         .update('selectedRecent', () => false)
        //         .update('isReader', () => false);
        //     listState = initialState.list;
        //     return {
        //         ...state,
        //         menu: menuState,
        //         list: listState,
        //     };
        // case BILLBOARD_MENU_SELECT_RECENT:
        //     menuState = menuState
        //         .update('selectedPublished', () => '')
        //         .update('selectedReceived', () => '')
        //         .update('selectedFavourite', () => false)
        //         .update('selectedRecent', () => true)
        //         .update('isReader', () => false);
        //     listState = initialState.list;
        //     return {
        //         ...state,
        //         menu: menuState,
        //         list: listState,
        //     };
        case BILLBOARD_HOVER_ROW:
            return {
                ...state,
                hoverRow: action.payload.enter ? action.payload.rowIndex : -1,
            };
        case BILLBOARD_DETAIL_SECONDARY_SHOW:
            detailState = detailState
                .update('showSecondary', () => true)
                .update('secondaryType', () => action.payload.secondaryType);
            return {
                ...state,
                detail: detailState,
            };
        case BILLBOARD_DETAIL_SECONDARY_HIDE:
            detailState = detailState
                .update('showSecondary', () => false)
                .update('secondaryType', () => null);
            return {
                ...state,
                detail: detailState,
            };
        case BILLBOARD_SHOW_CREATE_SUCCESS:
            return {
                ...state,
                showSuccessDialog: action.payload.show,
            };
        case BILLBOARD_ADD_FILES_TO_UPLOAD:
            const files = action.payload.files;
            if (files.length > 0) {
                for (const file of files) {
                    const filtered = crudState
                        .get('files')
                        .toJS()
                        .filter((f) => f.name === file.name && f.size === file.size);
                    if (filtered.length === 0)
                        crudState = crudState.update('files', (list) => list.push(file));
                }
            }
            return {
                ...state,
                crud: crudState,
            };
        case BILLBOARD_RESET_FILES_TO_UPLOAD:
            crudState = crudState.update('files', () => new List());
            return {
                ...state,
                crud: crudState,
            };
        case BILLBOARD_REMOVE_FILE_TO_UPLOAD:
            let listFiles = crudState.get('files');
            listFiles = listFiles.delete(action.payload.index);
            crudState = crudState.update('files', () => listFiles);
            return {
                ...state,
                crud: crudState,
            };
        case BILLBOARD_ADD_FILE_TO_REMOVE:
            let list = crudState.get('filesToRemove');
            list = list.push(action.idFile);
            crudState = crudState.update('filesToRemove', () => list);
            return {
                ...state,
                crud: crudState,
            };
        case BILLBOARD_SAVE_CRUD_RECOMPUTE:
            crudState = crudState.update('recompute', () => action.recompute);
            return {
                ...state,
                crud: crudState,
            };
        default:
            return state;
    }
}

import { sort } from 'utils/arrays';

export const buildSelectedPath = (rootItem, selectedItemsArr, itemsTreeData, item) => {
    let newSelectedItemsArr = [];

    if (!item || item?.id === rootItem?.id) {
        newSelectedItemsArr = [rootItem];
    } else if (selectedItemsArr[0].id === item.id) {
        const newArr = selectedItemsArr.filter((current) => current.id === item.id);
        newSelectedItemsArr = newArr;
    } else {
        let shouldLoop = true;

        for (let i = 0; shouldLoop === true; i++) {
            if (newSelectedItemsArr.length === 0) {
                newSelectedItemsArr = [item];
            } else {
                newSelectedItemsArr = [
                    itemsTreeData?.[newSelectedItemsArr[0].idParent],
                    ...newSelectedItemsArr,
                ];
            }
            shouldLoop = newSelectedItemsArr[0]?.idParent !== rootItem.id;
        }
        newSelectedItemsArr = [rootItem, ...newSelectedItemsArr];
    }
    return newSelectedItemsArr;
};

export const buildTreeFromFlatObject = (data) => {
    let tree = {};
    let mappedElement;

    // For children
    data = data.sort((a, b) => a.name.localeCompare(b.name));

    const mappedData = data.reduce((obj, current) => {
        let element = current;
        if (!element.idParent && (element.node_id || element.parentId))
            element.idParent = element.node_id || element.parentId;
        obj[element.id] = element;
        obj[element.id].children = [];
        return obj;
    }, {});

    for (let id in mappedData) {
        if (mappedData.hasOwnProperty(id)) {
            mappedElement = mappedData[id];
            if (mappedElement.idParent && mappedData[mappedElement.idParent]) {
                mappedData[mappedElement.idParent].children.push(mappedElement);
            } else if (!mappedElement.idParent) {
                tree = mappedElement;
            }
        }
    }

    // For root documents
    tree.children = tree?.children?.sort((a, b) => a.name.localeCompare(b.name)) || tree.children;

    return tree;
};

export const mapTree = (mappedTree, tree) => {
    if (!tree) return;
    mappedTree[tree.id] = tree;
    if (tree?.children?.length > 0) {
        mappedTree[tree.id].children = sort(mappedTree[tree.id].children, 'name');
        tree.children.forEach((current) => {
            mapTree(mappedTree, current);
        });
    }
};

export const getTreeFamily = (originalTree, treeData, family) => {
    if (!treeData) return;
    if (!family[treeData.id]) {
        family[treeData.id] = [treeData.id];
    }
    if (treeData.idParent) family[treeData.id].push(treeData.idParent);
    if (treeData.children.length > 0) {
        treeData.children.forEach((current) => {
            for (let line in family) {
                if (
                    family[line].includes(current.idParent) &&
                    originalTree[line].idParent !== current.idParent
                ) {
                    family[line].push(current.id);
                }
            }
            getTreeFamily(originalTree, current, family);
        });
    }
};

// Simple and recursive version for build a breadcrumb path
export function findPath(tree, nodeId) {
    // Recursive helper function to search for the path
    function searchPath(currentNode, currentPath) {
        if (Number(currentNode.id) === Number(nodeId)) {
            currentPath.push(currentNode);
            return currentPath;
        }

        if (currentNode.children && currentNode.children.length > 0) {
            for (let i = 0; i < currentNode.children.length; i++) {
                const result = searchPath(currentNode.children[i], currentPath.concat(currentNode));
                if (result) {
                    return result;
                }
            }
        }

        return null;
    }

    // Initial call to the recursive helper function
    return searchPath(tree, []);
}

export function setFolderActive(tree, id) {
    // Recursive helper function to search for the path
    function searchPath(currentNode) {
        if (Number(currentNode.id) === Number(id)) {
            currentNode.active = true;
            currentNode.toggled = true;
        } else {
            currentNode.active = false;
        }

        if (currentNode.children && currentNode.children.length > 0) {
            for (let i = 0; i < currentNode.children.length; i++) {
                searchPath(currentNode.children[i]);
            }
        }
    }

    searchPath(tree);
}

export function getPathFromSelected(tree, id) {
    if (tree.id === parseInt(id, 10)) {
        return [];
    } else if (tree.children) {
        for (let i = 0; i < tree.children.length; i++) {
            let path = getPathFromSelected(tree.children[i], id);
            if (path !== null) {
                path.unshift(tree.children[i]);
                return path;
            }
        }
    }
    return null;
}

export function toggleMatchingNodes(obj1, obj2) {
    function traverse(obj1, obj2) {
        if (obj1.id === obj2.id && obj1.toggled) {
            obj2.toggled = true;
        }

        if (obj1.children && obj2.children) {
            for (let i = 0; i < obj1.children.length; i++) {
                for (let j = 0; j < obj2.children.length; j++) {
                    traverse(obj1.children[i], obj2.children[j]);
                }
            }
        }
    }

    traverse(obj1, obj2);
}

import { ENTITY_EXTRA_FIELD_LOAD } from 'constants/ActionTypes';

import Context from 'managers/Context';

export function getExtraFieldSchema(entity, success, error) {
    return function (dispatch) {
        Context.extraFieldManager.getExtraFieldSchema(
            entity,
            (schemaExtraField) => {
                dispatch({
                    type: ENTITY_EXTRA_FIELD_LOAD,
                    schemaExtraField,
                    entity: entity.entity,
                });
                success(schemaExtraField);
            },
            error,
        );
    };
}

export function getExtrafieldsByEntity(entityType, entityId, setDynamicAsHidden) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            Context.extraFieldManager.getExtrafieldsByEntity(
                entityType,
                entityId,
                setDynamicAsHidden,
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject();
                },
            );
        });
    };
}

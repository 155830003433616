import {
    ENTITY_LIST_LOAD_LIST,
    ENTITY_LIST_LOAD_SUCCESS,
    ENTITY_LIST_LOAD_ERROR,
    ENTITY_LIST_LOAD_CHANGE_SORT,
    ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD,
    ENTITY_LIST_LOAD_EMPTY,
    ENTITY_LIST_CLEAR,
    ENTITY_LIST_SET_SELECT_ALL,
    ENTITY_LIST_SHOW_FILTERS,
    ENTITY_LIST_SELECT_ROW,
    ENTITY_LIST_SET_DRAGGED_ROW,
    ENTITY_LIST_REMOVE_ONE,
    ENTITY_LIST_SET_SCROLL_TO_TOP,
    ENTITY_LIST_UPDATE_LOCAL,
    ENTITY_LIST_UPDATE_ALL_LOCAL,
    ENTITY_LIST_SET_LAZY_LOAD,
    ENTITY_LIST_SET_IS_LOADING,
    ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER,
    ENTITY_LIST_SET_TOTAL,
    ENTITY_LIST_SET_OFFSET,
} from 'constants/ActionTypes';

import { logEvent } from 'utils/tracking';
import { COMPANIES, CONTACTS, OPPORTUNITIES, SALESORDERS, DOCUMENTS } from 'constants/Entities';

import Context from 'managers/Context';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';

const getManager = () => Context.entityListManager;

function _showError(entity) {
    return { type: ENTITY_LIST_LOAD_ERROR, entity };
}

function _showEmpty(entity) {
    return { type: ENTITY_LIST_LOAD_EMPTY, entity };
}

function _showLoading(entity) {
    return { type: ENTITY_LIST_LOAD_LIST, entity };
}

function _setEntities(entities, entity, total, offset, count, filters) {
    return { type: ENTITY_LIST_LOAD_SUCCESS, entities, total, offset, count, entity, filters };
}

function _changeSort(entity, field) {
    return { type: ENTITY_LIST_LOAD_CHANGE_SORT, field, entity };
}

function _clear(entity, preventSortClear) {
    return { type: ENTITY_LIST_CLEAR, entity, preventSortClear };
}

function _selectRow(entity, rowElement) {
    return { type: ENTITY_LIST_SELECT_ROW, entity, rowElement };
}

function _setDraggedRow(entity, draggedRow) {
    return { type: ENTITY_LIST_SET_DRAGGED_ROW, entity, draggedRow };
}

function _removeOne(entity, index) {
    return { type: ENTITY_LIST_REMOVE_ONE, entity, index };
}

function checkTotalSelected(entity, entities, total) {
    return function (dispatch, getState) {
        const state = getState();
        const entityName = entity.entity;
        const entityListSelect = state.entityListSelect[entityName];
        const entityListTotal = state.entityList[entityName].total;
        if (!entityListSelect) return;
        const newTotal = total === 0 ? entityListTotal : total;
        let allSelected = entityListSelect.total === newTotal && newTotal > 0;
        // Fallback to manual checking
        if (!allSelected) {
            allSelected =
                !entities?.find((item) => item && item.selected !== '1') && entities?.length > 0;
        }
        dispatch(Context.actions.EntityListSelectActions.markSelectAll(entity, allSelected));
    };
}

function changeSortNewTableLazyLoad(entity, sortField, sortDir) {
    return function (dispatch) {
        let newSortDir = -1;
        if (sortDir === 'asc') newSortDir = 0;
        else if (sortDir === 'desc') newSortDir = 1;
        let newSortField = '';
        if (sortDir) newSortField = sortField;

        dispatch({
            type: ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD,
            entity: entity,
            sortField: newSortField,
            sortDir: newSortDir,
        });
    };
}

export function init(
    entity,
    force,
    pageSize = PAGINATION_TABLE_ENTITY,
    startRow,
    sortInfo,
    useLazyLoad,
    lazyInfo = null,
    discardCount,
    isAccumulative,
    relatedEntity,
) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            // be careful with pageSize and backend4 parameters
            if (useLazyLoad) {
                publish(`${REFRESH_TABLE}_${entity.entity}`, lazyInfo);
                return;
            }

            // When using the new dataGrid with lazyLoad, we set the sort throug sortInfo parameter
            if (sortInfo?.sortField) {
                dispatch(
                    changeSortNewTableLazyLoad(entity.entity, sortInfo.sortField, sortInfo.sortDir),
                );
            }
            const name = entity.entity;
            const relatedEntityName = relatedEntity?.entity;
            const list = getState().entityList[name] || {};
            const filters = getState().entityFilters[relatedEntityName || name];
            if (force || !list.data || (list.data.length === 0 && !list.loading)) {
                const count = pageSize || list.count;
                dispatch(_showLoading(name));
                dispatch(setScrollToTop(entity, true));
                Context.entityManager
                    .beforeFetchEntity(relatedEntity || entity, filters)
                    .then(({ filtersToLoad }) => {
                        if (filtersToLoad && filtersToLoad.length > 0) {
                            filtersToLoad.forEach(({ filter, values, completeValues = null }) => {
                                if (!filter || !values || values.length === 0) return;
                                dispatch(
                                    Context.actions.EntityFiltersActions.changeFilter({
                                        entity: relatedEntity || entity,
                                        filter,
                                        value: values,
                                        refresh: false,
                                        completeValues,
                                        isEntityList: false,
                                        info: null,
                                        isPreload: true,
                                    }),
                                );
                            });
                        }
                        const manager = Context.entityManager.getEntitiesManager(entity);
                        if (manager.initFiltersColor) {
                            manager.initFiltersColor();
                        }
                        // The new table from Web5 uses only this method to get the pagination,
                        // >> so we have to give it the startRow to get the proper data offset
                        // For the old table we used the PagedData class
                        let newInit = startRow || 0;

                        dispatch(
                            fetchEntity(
                                entity,
                                newInit,
                                count,
                                discardCount,
                                isAccumulative,
                                resolve,
                                reject,
                                relatedEntity,
                            ),
                        );
                        Context.entityListManager.afterFetchEntity(entity);
                    });
            }
        });
    };
}

export function changePageSize(entity, pageSize, force = false) {
    return function (dispatch) {
        dispatch(clear(entity));
        dispatch(init(entity, force, pageSize));
    };
}

export function fetchEntity(
    entity,
    init,
    count,
    discardCount,
    isAccumulative,
    resolve,
    reject,
    relatedEntity,
) {
    return function (dispatch, getState) {
        const name = entity.entity;
        const relatedEntityName = relatedEntity?.entity;
        const state = getState();
        let filters = state.entityFilters[relatedEntityName || name];
        filters = filters ? filters.filters || {} : {};
        let selectionToken = state.entityListSelect[name];
        selectionToken = selectionToken ? selectionToken.token : null;
        if (!state?.entityList?.[state?.entityList?.active]?.loading) {
            dispatch(_showLoading(name));
        }

        getManager().getEntityList(
            entity,
            init,
            count,
            filters,
            null,
            null,
            selectionToken,
            discardCount,
            (entities, total, offset) => {
                // Since the new table in lazyLoad mode handles data internally
                // we don't need data to be stored and that way we prevent de store from
                // crashing when we have a lot of rows
                switch (entity) {
                    case COMPANIES:
                    case CONTACTS:
                    case OPPORTUNITIES:
                    case SALESORDERS:
                    case DOCUMENTS:
                        dispatch({
                            type: ENTITY_LIST_SET_IS_LOADING,
                            entity: name,
                            isLoading: false,
                            total,
                        });
                        break;
                    default:
                        let finalEntities = [];
                        let finalTotal = total;

                        if (isAccumulative) {
                            const oldEntities =
                                state?.entityList?.[state?.entityList?.active]?.data || [];
                            if (!init) finalEntities = entities;
                            else finalEntities = [...oldEntities, ...entities];
                            finalTotal = finalEntities.length;
                        } else finalEntities = entities;

                        dispatch(
                            _setEntities(finalEntities, name, finalTotal, offset, count, filters),
                        );
                        break;
                }
                dispatch(checkTotalSelected(entity, entities, total));
                resolve && resolve({ entities, total });
            },
            (error) => {
                error === 'No result' ? dispatch(_showEmpty(name)) : dispatch(_showError(name));
                reject && reject();
            },
            true,
            false,
            relatedEntity,
        );
    };
}

export function changeSort(entity, field) {
    return function (dispatch, getState) {
        dispatch(_changeSort(entity.entity, field));
        dispatch(init(entity, true));
    };
}

export function clear(entity, preventSortClear = false) {
    return function (dispatch, getState) {
        dispatch(_clear(entity.entity, preventSortClear));
    };
}

export function selectRow(entity, rowElement) {
    return function (dispatch, getState) {
        dispatch(_selectRow(entity, rowElement));
    };
}

export function selectAll(entity, state) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_LIST_SET_SELECT_ALL,
            entity: entity.entity,
            state,
        });
    };
}

export function setDraggedRow(entity, draggedRow) {
    return function (dispatch) {
        dispatch(_setDraggedRow(entity, draggedRow));
    };
}

export function toggleFilters(entity, state) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_LIST_SHOW_FILTERS,
            entity: entity.entity,
            state,
        });
    };
}

export function selectColumns(entity, fields, config, withReload = true) {
    return function (dispatch, getState) {
        const state = getState();
        const headers = Object.keys(fields).reduce((arr, key) => {
            if (fields[key]) arr.push(key.toLowerCase());
            return arr;
        }, []);

        Context.domainManager
            .selectColumns(entity.customField, headers)
            .then(() => {
                if (config) {
                    logEvent({
                        event: entity.trueName,
                        functionality: 'selectColumns',
                    });
                }

                const entityList = state?.entityList?.[state?.entityList?.active] || null;
                const useLazyLoad = entityList?.useLazyLoad || false;

                // We only need a reload when a new column is visible
                // and we don't have data previously.
                let exitBeforeReload = true;

                Object.entries(fields).forEach(([field, visible]) => {
                    const dataField = config?.columnDefs?.find(
                        (def) => def?.colId === field,
                    )?.field;
                    const alreadyExists =
                        field && entityList?.data?.find((item) => item)?.hasOwnProperty(dataField);
                    if (!visible || !!alreadyExists) exitBeforeReload = false;
                });

                if (exitBeforeReload) return;

                if (!withReload) return;
                if (useLazyLoad) {
                    dispatch(init(entity, false, null, null, null, useLazyLoad));
                } else dispatch(init(entity, true));
            })
            .catch((err) => {
                console.error(err);
            });
    };
}

export function removeOne(entity, index) {
    return function (dispatch) {
        dispatch(_removeOne(entity, index));
    };
}

export function setScrollToTop(entity, scrollToTop) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_LIST_SET_SCROLL_TO_TOP,
            entity: entity.entity,
            scrollToTop,
        });
    };
}

export function updateRowFields(entity, idEntity, fields = {}) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_LIST_UPDATE_LOCAL,
            entity: entity.entity,
            id: idEntity,
            fields,
        });
    };
}

export function updateAllRowsLocally(entity, data = []) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_LIST_UPDATE_ALL_LOCAL,
            payload: {
                entity: entity.entity,
                data,
            },
        });
    };
}

export function standardFieldsConfiguration(entity, schema) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            Context.entityManager
                .standardFieldsConfiguration(entity, schema)
                .then((resultSchema) => {
                    resolve(resultSchema);
                })
                .catch(reject);
        });
    };
}

export function changeActive(entity) {
    return function (dispatch) {
        // Action specially for campaigns
        dispatch({ type: 'ENTITY_LIST_CHANGE_ACTIVE', entity: entity.entity });
    };
}

//Actions related to HoverCell -->
export function shareItem(entity, item, action, isSigned, sendEmail) {
    return (dispatch) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.shareItem) {
            manager.shareItem(item, action, isSigned, sendEmail);
        }
    };
}

export function downloadItem(entity, item, action, isSigned) {
    return (dispatch) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.downloadItem) {
            manager.downloadItem(item, action, isSigned);
        }
    };
}

export function clickItem(entity, item, action) {
    return (dispatch) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.clickItem) {
            manager.clickItem(item, action);
        }
    };
}

export function editItem(entity, item, action) {
    return (dispatch) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.editItem) {
            manager.editItem(item, action);
        }
    };
}

export function deleteItem(entity, item, action) {
    return (dispatch) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.deleteItem) {
            manager.deleteItem(item, action);
        }
    };
}

export function setUseLazyLoad(entity, boolean) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SET_LAZY_LOAD,
            entity: entity.entity,
            useLazyLoad: boolean,
        });
    };
}

export function setPreventSelectionResetOnChangeFilter(entity, bool) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER,
            entity: entity.entity,
            bool,
        });
    };
}
// <-- Actions related to HoverCell

export function getEntityCounts(entity, relatedEntity) {
    return (dispatch, getState) => {
        let state = getState();
        const entityCountName = entity.countName;
        const name = relatedEntity?.entity || entity.entity;
        const filters = state.entityFilters[name];

        return Context.entityManager
            .beforeFetchEntity(entity, filters)
            .then(({ filtersToLoad }) => {
                if (filtersToLoad && filtersToLoad.length > 0) {
                    filtersToLoad.forEach(({ filter, values, completeValues = null }) => {
                        if (!filter || !values || values.length === 0) return;
                        dispatch(
                            Context.actions.EntityFiltersActions.changeFilter({
                                entity,
                                filter,
                                value: values,
                                refresh: false,
                                completeValues,
                                isEntityList: false,
                                info: null,
                                isPreload: true,
                            }),
                        );
                    });
                }

                const params = {
                    filter: Context.actions.EntityFiltersActions.getCleanFilters(
                        getState(),
                        relatedEntity || entity,
                    ),
                };

                const { operatorInfo, extraOperatorInfo } =
                    Context.actions.EntityFiltersActions.getCleanOperators(
                        getState(),
                        relatedEntity || entity,
                    );

                const crossFilterInfo = Context.actions.EntityFiltersActions.getCleanCrossFilters(
                    relatedEntity || entity,
                );

                return Context.entityListManager
                    .getEntityCounts(
                        entityCountName,
                        params,
                        operatorInfo,
                        extraOperatorInfo,
                        crossFilterInfo,
                    )
                    .then((total) => {
                        dispatch({
                            type: ENTITY_LIST_SET_TOTAL,
                            entity: entity.entity,
                            total,
                        });
                        return total;
                    });
            });
    };
}

export function rawGetEntityCounts(entity, filters) {
    return () => {
        const entityCountName = entity.countName;
        return Context.entityListManager.getEntityCounts(entityCountName, filters);
    };
}

export function setOffset(entity, offset) {
    return (dispatch) => {
        dispatch({ type: ENTITY_LIST_SET_OFFSET, entity: entity.entity, offset });
    };
}

export function setIsLoading(entity, loading) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SET_IS_LOADING,
            entity: entity.entity,
            isLoading: loading,
        });
    };
}

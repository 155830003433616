import UtilFormat from 'utils/UtilFormat';
import CrudModel from './CrudModel';
import { getLiteral } from 'utils/getLiteral';

/*
"{"id":1,"idnode":-1,"nombre":"Comercial","descripcion":"","fcreado":"21/11/2016 16:12:14","fcreado_timestamp":"1479744734","fmodificado":"21/11/2016 16:12:14","fmodificado_timestamp":"1479744734","usercreado":"","usermodificad":"","hasparams":false,"idFicheroCrystal":-1,"isCrystal":false,"isFolder":true,"Shared":false,"blnIsConfidential":false,"viewInCompanies":-1,"viewInOpportunities":-1,"viewInSFMCompanies":-1,"viewInSFMUsers":-1,"viewInSFMEnvironments":-1,"viewInProducts":-1,"viewInGeneral":-1}"
*/
export default class ReportModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toList({ data }) {
        const seeReportLiteral = getLiteral('action_see_report');
        const newData = data.map((current, index) => {
            const isTable = current.isFolder === '0' && current.isCrystal === '0' ? true : false;

            const obj = {
                rawData: { ...current },
                ...current,
                seeReport: isTable ? seeReportLiteral : '',
                isTable,
            };
            return obj;
        });

        return newData;
    }

    get id() {
        return this.entity.id;
    }

    get name() {
        return this.entity.nombre;
    }

    get isSignable() {
        return this.entity.issignable;
    }

    get issignable() {
        return this.entity.issignable;
    }

    get date() {
        const result = this.entity.fcreado;
        return UtilFormat.getStringChangeFormat(result, 'DD/MM/YYYY hh:mm:ss', 'L');
    }

    get hasParams() {
        return this.entity.hasparams;
    }

    get isShared() {
        return this.entity.shared === '1' || this.entity.Shared === '1';
    }

    get isCrystal() {
        return this.entity.iscrystal === '1';
    }

    get hasParams() {
        return this.entity.hasparams;
    }
}

import { memo, useCallback, useContext } from 'react';
import { Chip, Tooltip } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getPartner } from 'lib/partners';

import { EmailEditorContext } from '../../../EmailEditorContext';
import './styles.scss';

const DanaSelector = ({ editor }) => {
    const partner = getPartner();
    const { hasContent, setDanaActive } = useContext(EmailEditorContext);
    const { danaActivated } = useSelector((state) => ({
        danaActivated: state.config.permission?.danaActivated,
    }));

    const onClick = useCallback(() => {
        logEvent({
            event: 'emails',
            submodule: 'sendEmail',
            functionality: 'danaAI',
        });

        setDanaActive(true, editor);
    }, [editor, setDanaActive]);

    if (!danaActivated) return null;

    return (
        <Tooltip content={getLiteral('label_enhance_email_hover')}>
            <Chip
                className="fm-ee__dana-selector"
                onClick={onClick}
                size="large"
                isDisabled={!hasContent}
            >
                <div className="fm-ee__dana-selector__icon">{partner.DanaIcon}</div>
                <span>{getLiteral('label_enhance_email')}</span>
            </Chip>
        </Tooltip>
    );
};

export default memo(DanaSelector);

import React from 'react';
import colors from 'constants/colors';

const QuoteSent = ({ color = '$fmDefaultIcons', color2 = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M16.1042 14.9435C18.5329 15.491 21 16.3188 21 18.3333c0 1.7788-1.9121 3.1966-4.4448 3.379v1.721c0 .3134-.2483.5667-.555.5667-.3071 0-.5554-.2533-.5554-.5667v-1.721C12.912 21.53 11 20.1122 11 18.3334c0-.3128.2489-.5666.5555-.5666.3072 0 .5561.2538.5561.5666 0 1.2291 1.7805 2.2667 3.8887 2.2667 2.1076 0 3.8881-1.0376 3.8881-2.2667 0-1.233-2.2877-1.892-4.0081-2.2797C12.647 15.4241 11 14.2834 11 12.6666c0-1.7781 1.9121-3.196 4.4448-3.3784V7.5667c0-.3128.2483-.5667.5555-.5667.3066 0 .555.2539.555.5667v1.7215C19.0878 9.4707 21 10.8885 21 12.6667c0 .3133-.2489.5666-.556.5666-.3067 0-.5556-.2533-.5556-.5666 0-1.2286-1.7805-2.2667-3.8881-2.2667-2.1082 0-3.8887 1.0381-3.8887 2.2667 0 .9531 1.455 1.7827 3.9926 2.2768m11.2376 4.979a6.78 6.78 0 0 0-2.8447.1962 6.8333 6.8333 0 0 0-1.0126.3793 6.6981 6.6981 0 0 0-2.9197 2.6446c-.7487 1.2592-1.0808 2.7584-.8714 4.2761-1.1639.3773-2.4051.5813-3.6931.5813C9.383 28 4 22.6171 4 16 4 9.3834 9.383 4 16.0003 4 22.617 4 28 9.3834 28 16c0 1.373-.2318 2.6929-.6582 3.9225z"
                fill={colors[color] ? colors[color] : '#788590'}
            />
            <path
                d="M27.2416 29.9698a.3914.3914 0 0 1 .216.7524c-2.4707.709-5.0588-.6894-5.7803-3.127-.722-2.4407.701-4.9939 3.1747-5.704 2.47-.7091 5.058.6899 5.7794 3.1278a.3914.3914 0 1 1-.7506.222c-.5988-2.0236-2.754-3.1886-4.8128-2.5975-2.058.5908-3.2381 2.7081-2.6401 4.7296.5988 2.0233 2.754 3.1878 4.8137 2.5967zM30.48 22.665a.3914.3914 0 0 1 .7792.075l-.2626 2.7288a.3914.3914 0 0 1-.4242.3523l-2.7662-.246a.3914.3914 0 0 1 .0694-.7797l2.379.2116.2254-2.342z"
                stroke={colors[color2] ? colors[color2] : '#788590'}
                strokeWidth={'.5'}
                fillRule={'nonzero'}
            />
        </g>
    </svg>
);

export default QuoteSent;

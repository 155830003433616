import Context from '../managers/Context';

import {
    RATES_LIST_LOADING,
    RATES_LIST_LOAD,
    RATES_LIST_ERROR,
    RATES_LIST_SEARCH,
} from '../constants/ActionTypes';
import { RATES } from '../constants/Entities';
import { PAGINATION_TABLE_ENTITY } from '../constants/Environment';

function _showLoading() {
    return {
        type: RATES_LIST_LOADING,
    };
}

function _setEntityTable(data, total) {
    return {
        type: RATES_LIST_LOAD,
        data,
        total,
    };
}

function _showError(error) {
    return {
        type: RATES_LIST_ERROR,
        error,
    };
}

function _changeSearchFilter(text) {
    return {
        type: RATES_LIST_SEARCH,
        search: text,
    };
}

const getManager = () => Context.entityManager.getEntitiesManager(RATES);

export function init(force) {
    return function (dispatch, getState) {
        const { list } = getState().rates;
        if (force || (list.get('data').size === 0 && !list.get('loading'))) {
            dispatch(fetchEntity(0, PAGINATION_TABLE_ENTITY));
        }
    };
}

export function fetchEntity(offset, sizePage) {
    return function (dispatch, getState) {
        dispatch(_showLoading());
        getManager().getRates(
            offset,
            sizePage,
            getState().rates.list.get('search'),
            (data, total) => {
                dispatch(_setEntityTable(data, total));
            },
            (error) => {
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function search(text) {
    return function (dispatch, getState) {
        dispatch(_changeSearchFilter(text));
        dispatch(fetchEntity(0, PAGINATION_TABLE_ENTITY));
    };
}

export function refreshData() {
    return function (dispatch) {
        dispatch(init(true));
    };
}

export function changeSort() {
    return function (dispatch) {
        window.console.log('function not supported');
    };
}

import {
    ENTITY_BULK_EDIT_CONFIRM_OPEN,
    ENTITY_BULK_DELETE_CONFIRM_OPEN,
    ENTITY_BULK_CONFIRM_CLOSE,
    ENTITY_BULK_STATE_TOKEN,
    ENTITY_BULK_LOADING,
} from 'constants/ActionTypes';
import Context from 'managers/Context';
import { getEntityFromString } from 'utils/getEntityFromString';
import { isEmptyObject } from 'utils/objects';
import { BulkService } from 'services';
import { getActiveCrud } from 'utils/crud';

function getSelectionIds(state, entity) {
    const entityName = entity.entity;
    const entityListSelect = state.entityListSelect[entityName];
    const selected = entityListSelect.selected;
    let includedIds = [];
    let excludedIds = [];
    if (!isEmptyObject(selected)) {
        Object.keys(selected).forEach((key) => {
            if (selected[key] && !entityListSelect.hasFilterSelection) includedIds.push(key);
            else if (selected[key] === false) excludedIds.push(key);
        });
    }
    return [includedIds, excludedIds];
}

function getSelectionFilters(state, entity) {
    const entityName = entity.entity;
    const entityListSelect = state.entityListSelect[entityName];
    let filter = {};
    if (entityListSelect?.hasFilterSelection) {
        let filters = state.entityFilters[entityName];
        filters = filters ? filters.filters || {} : {};
        filter = Context.actions.EntityFiltersActions.getCleanFilters(state, entity);
    }
    const operators = Context.actions.EntityFiltersActions.getCleanOperators(state, entity);
    const crossFilters = Context.actions.EntityFiltersActions.getCleanCrossFilters(entity);
    return { crossFilters, filter, operators };
}

export function openBulkEditConfirmDialog() {
    return (dispatch) => {
        dispatch({ type: ENTITY_BULK_EDIT_CONFIRM_OPEN });
    };
}

export function openBulkDeleteConfirmDialog() {
    return (dispatch) => {
        dispatch({ type: ENTITY_BULK_DELETE_CONFIRM_OPEN });
    };
}

export function closeBulkConfirmDialog() {
    return (dispatch) => {
        dispatch({ type: ENTITY_BULK_CONFIRM_CLOSE });
    };
}

export function bulkUpdate(entity) {
    return (dispatch, getState) => {
        dispatch({ type: ENTITY_BULK_LOADING, loading: true });
        const state = getState();
        const entityName = entity.extraFieldTab.toLowerCase();
        const entityCrud = getActiveCrud(state);
        const data =
            Context.entityCrudManager.processForServer(entityCrud.schema, entityCrud.data, true) ||
            {};

        // If all selected then apply filters
        const { crossFilters, filter, operators } = getSelectionFilters(state, entity);

        // Get ids
        const [includedIds, excludedIds] = getSelectionIds(state, entity);

        const params = {
            entityName,
            operation: 'update',
            data: JSON.stringify(data),
            includedIds,
            excludedIds,
            filter,
            operators,
            crossFilters,
        };

        return Context.bulkManager.startBulk(params).then((response) => {
            if (response) {
                dispatch({
                    type: ENTITY_BULK_STATE_TOKEN,
                    token: response,
                });
                return response;
            } else return;
        });
    };
}

export function bulkDelete(entity) {
    return (dispatch, getState) => {
        dispatch({ type: ENTITY_BULK_LOADING, loading: true });
        const state = getState();
        const entityName = entity.extraFieldTab.toLowerCase();

        // If all selected then apply filters
        const { crossFilters, filter, operators } = getSelectionFilters(state, entity);

        // Get ids
        const [includedIds, excludedIds] = getSelectionIds(state, entity);

        const params = {
            entityName,
            operation: 'delete',
            includedIds,
            excludedIds,
            filter,
            operators,
            crossFilters,
        };

        return Context.bulkManager.startBulk(params).then((response) => {
            if (response) {
                dispatch({ type: ENTITY_BULK_STATE_TOKEN, token: response });
                return response;
            } else return;
        });
    };
}

export function checkBulkStatus(token) {
    return () => BulkService.getStatus(token);
}

export function cancelBulk(token) {
    return () => BulkService.cancel(token);
}

export function finishBulk(success) {
    return (dispatch, getState) => {
        const state = getState();
        const entity = getEntityFromString(state.entityListSelect.active);
        const entityList = state?.entityList?.[state?.entityList?.active] || {};
        const useLazyLoad = entityList.useLazyLoad || false;

        dispatch({ type: ENTITY_BULK_CONFIRM_CLOSE });
        dispatch(Context.actions.EntityListSelectActions.resetListSelect(entity, true));
        dispatch(
            Context.actions.EntityListSelectActions.setShouldClearWeb5Checkboxes(entity, true),
        );
        if (success) {
            // useLazyLoad for w5 table
            // make 2 possible calls because null removes default prop values
            if (useLazyLoad) {
                dispatch(
                    Context.actions.EntityListActions.init(
                        entity,
                        false,
                        null,
                        null,
                        null,
                        useLazyLoad,
                    ),
                );
            } else {
                dispatch(Context.actions.EntityListActions.init(entity, true));
            }
        }
    };
}

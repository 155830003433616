/* DEPRECATED */
import { FUZZY_LIST, FUZZY_LIST_SUCCESS, FUZZY_ERROR, CLEAR_FUZZY } from 'constants/ActionTypes';
import Context from 'managers/Context';
import { BILLBOARD, SALESORDERS } from 'constants/Entities';

function _showLoading(entity, field, text, feature = '') {
    return {
        type: FUZZY_LIST,
        entity,
        field,
        text,
        feature,
    };
}

function _showError(entity, field, error, feature = '') {
    return {
        type: FUZZY_ERROR,
        entity,
        field,
        error,
        feature,
    };
}

function _setData(entity, field, text, result, feature = '') {
    return {
        type: FUZZY_LIST_SUCCESS,
        entity,
        field,
        result,
        feature,
    };
}

function _clearData(entity, field) {
    return {
        type: CLEAR_FUZZY,
        entity,
        field,
    };
}

export function setDataFuzzy(entity, field, text, result, feature) {
    return function (dispatch) {
        dispatch(_setData(entity, field, text, result, feature));
    };
}

export function setErrorFuzzy(entity, field, error = 'ERROR', feature) {
    return function (dispatch) {
        dispatch(_showError(entity, field, error, feature));
    };
}

export function getFuzzySearch(
    entity,
    field,
    text,
    entityDependence,
    entityDependenceId,
    usersFromService = '',
) {
    const encodedText = encodeURIComponent(text);
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(_showLoading(entity, field, text));
            if (entity === 'billboard') {
                // billboard does not have a fuzzysearch. We need a special method

                Context.entityManager.getEntitiesManager(BILLBOARD).getBillboardList(
                    0,
                    30,
                    (result) => {
                        dispatch(_setData(entity, field, encodedText, result));
                        resolve(result);
                    },
                    (error) => {
                        dispatch(_showError(entity, field, 'ERROR'));
                        reject(error);
                    },
                );
            } else {
                Context.domainManager.getFuzzySearch(
                    entity,
                    field,
                    encodedText,
                    entityDependence,
                    entityDependenceId,
                    usersFromService,
                    (result) => {
                        dispatch(_setData(entity, field, text, result));
                        resolve(result);
                    },
                    (error) => {
                        dispatch(_showError(entity, field, 'ERROR'));
                        reject(error);
                    },
                );
            }
        });
    };
}

export function clearFuzzySearch(entity, field) {
    return function (dispatch) {
        dispatch(_clearData(entity, field));
    };
}

export function autoCompleteSearch(entity, field, search, feature) {
    const encodedSearch = encodeURIComponent(search);
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // In the SearchList we don't use this dispatch, remove in the future
            dispatch(_showLoading(entity, field, search));
            Context.domainManager.autoCompleteSearch(
                entity,
                field,
                encodedSearch,
                feature,
                null,
                null,
                null,
                (result) => {
                    // In the SearchList we don't use this dispatch, remove in the future
                    dispatch(_setData(entity, field, search, result, feature));
                    resolve(result);
                },
                () => {
                    // In the SearchList we don't use this dispatch, remove in the future
                    dispatch(_showError(entity, field, 'ERROR', feature));
                    reject();
                },
            );
        });
}

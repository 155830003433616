import { Map } from 'immutable';
import Context from 'managers/Context';
import { OPPORTUNITIES, COMPANIES } from 'constants/Entities';
import { OPPORTUNITIES_SET_IS_WON } from 'constants/ActionTypes';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { isEmptyObject } from 'utils/objects';
import { abortXhr } from 'utils/xhr';
import { getParameterByName } from 'utils/url';
import { getEntityFromString } from 'utils/getEntityFromString';
import { OPPORTUNITY_ICON } from 'constants/Images';
import { getCrudFieldConfig } from 'utils/fm/fields';
import { getCustomizeCrudAction, getActiveCrud } from 'utils/crud';
import { translateFollowingItemForBackend } from 'utils/filters';

const mergeOpportunitiesSchema = () => [
    {
        description: getLiteral('label_informations'),
        show: false,
        tabFields: [
            {
                id: 'Reference',
                server: 'Referencia',
                mandatoryReadOnlyField: 'referencia',
                description: getLiteral('label_account_name'),
                title: true,
            },
            {
                id: {
                    id: 'IdOpportunityType',
                    desc: 'OpportunityType',
                },
                server: 'idEstado',
                mandatoryReadOnlyField: 'idestadoobra',
                description: getLiteral('label_opportunity_status'),
            },
            {
                id: {
                    id: 'IdTypeExpediente',
                    desc: 'TypeExpediente',
                },
                server: 'idTypeExpediente',
                mandatoryReadOnlyField: 'idtipo',
                description: getLiteral('label_opportunity_type'),
                list: 'tblTiposExpediente',
            },
            {
                id: 'Amount',
                server: 'dblValor',
                mandatoryReadOnlyField: 'dblvalor',
                dataType: 'currency',
                description: getLiteral('label_value'),
            },
            {
                id: {
                    id: 'IdCurrency',
                    desc: 'SymbolCurrency',
                },
                server: 'idCurrency',
                mandatoryReadOnlyField: '',
                description: getLiteral('label_currency'),
            },
            {
                id: 'Probability',
                server: 'ratio',
                mandatoryReadOnlyField: 'ratio',
                dataType: 'ratio',
                description: getLiteral('label_probabilitysale'),
                inputAttrs: {
                    min: 0,
                    max: 10,
                },
            },
            {
                id: 'ExpectedClose',
                server: 'dtprevfechaventa',
                mandatoryReadOnlyField: 'dtprevfechaventa',
                description: getLiteral('label_sales_forecast'),
            },
            {
                id: 'Close',
                server: 'fechacierre',
                mandatoryReadOnlyField: 'fechacierre',
                description: getLiteral('label_opportunity_close'),
            },
            {
                id: {
                    id: 'IdCompany',
                    desc: 'Company',
                },
                server: 'idEmpresa1',
                mandatoryReadOnlyField: 'idinstalador',
                description: getLiteral('label_account_client'),
            },
            {
                id: {
                    id: 'IdCompanyIntermediate',
                    desc: 'CompanyIntermediate',
                },
                server: 'idEmpresa2',
                mandatoryReadOnlyField: 'idingenieria',
                description: getLiteral('label_account_middleman'),
            },
            {
                id: {
                    id: 'IdCompanyOther',
                    desc: 'CompanyOther',
                },
                server: 'idEmpresa3',
                mandatoryReadOnlyField: 'idconstructora',
                description: getLiteral('label_intermediary2'),
            },
            {
                id: {
                    id: 'IdComercial',
                    desc: 'Comercial',
                },
                server: 'idComercial',
                mandatoryReadOnlyField: 'idcomercial',
                description: getLiteral('label_owner'),
            },
            {
                id: {
                    id: 'IdEnvironment',
                    desc: 'Environment',
                },
                server: 'idSucursal',
                mandatoryReadOnlyField: 'idsucursal',
                description: getLiteral('label_environment'),
            },
            {
                id: 'Comments',
                server: 'Observacion',
                mandatoryReadOnlyField: 'observaciones',
                description: getLiteral('label_observations'),
            },
        ],
    },
    {
        description: getLiteral('label_address_detail'),
        show: true,
        permission: 'opportunitiesGeolocation',
        tabFields: [
            {
                id: 'Address1',
                server: 'direccion',
                mandatoryReadOnlyField: 'direccion',
                description: getLiteral('label_adress_line1'),
            },
            {
                id: 'Address2',
                server: 'direccion2',
                mandatoryReadOnlyField: 'direccion2',
                description: getLiteral('label_adress_line2'),
            },
            {
                id: 'City',
                server: 'strPoblacion',
                mandatoryReadOnlyField: 'strpoblacion',
                description: getLiteral('label_city'),
            },
            {
                id: 'Province',
                server: 'strProvincia',
                mandatoryReadOnlyField: 'strprovincia',
                description: getLiteral('label_province_region'),
            },
            {
                id: 'CP',
                server: 'cp',
                mandatoryReadOnlyField: 'cp',
                description: getLiteral('label_post_code'),
            },
            {
                id: {
                    id: 'IdCountry',
                    desc: 'Country',
                },
                server: 'idCountry',
                mandatoryReadOnlyField: 'idCountry',
                description: getLiteral('label_country'),
            },
            {
                id: 'Latitude',
                server: 'lat',
                mandatoryReadOnlyField: '',
                dataType: 'coordinate',
                description: getLiteral('label_latitude'),
            },
            {
                id: 'Longitude',
                server: 'lon',
                mandatoryReadOnlyField: '',
                dataType: 'coordinate',
                description: getLiteral('label_longitude'),
            },
        ],
    },
];

const stateOpportunityFilterSchema = {
    id: 'state',
    fieldConfiguration: 'idestadoobra',
    dataType: 'singleValueList',
    description: 'label_opportunity_status',
    inputAttrs: {
        list: 'tblEstadoExpediente',
        filterOption: (option) => {
            const state = Context.store.getState();
            const pipelineSelected = state.entityFilters?.[OPPORTUNITIES.entity]?.filters?.type;
            const hasDependency =
                state.config?.standardFieldsSchemaMap?.EXPEDIENTE?.idestadoobra?.strParentField ===
                    'idtipo' || false;

            if (pipelineSelected && hasDependency && option?.data) {
                const pipelineId = pipelineSelected.value[0];
                return option?.data?.idparent === pipelineId;
            }

            return true;
        },
    },
};

let getPipelineRequest = null;
let getRelatedAccountsTimestamp = null;

export default class OpportunitiesManager {
    canAsyncExtraFields() {
        return true;
    }

    getMergeEntitySchema() {
        return mergeOpportunitiesSchema();
    }

    getExtraFieldSchema(success, error) {
        this.context.extraFieldManager.getExtraFieldSchema(
            OPPORTUNITIES,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value['-id'], value);
                });
                success(resultSchema);
            },
            error,
        );
    }

    getEntity(id, success, error, withExtraFields = false) {
        return this.context.domainManager.getEntity(
            id,
            OPPORTUNITIES.entity,
            withExtraFields,
            (data) => {
                let entity = { ...data };
                success(entity, data);
            },
            error,
        );
    }

    updateEntity(id, entity, success, error) {
        this.context.domainManager.updateEntity(OPPORTUNITIES.entity, id, entity, success, error);
    }

    afterSave({ manager, schema, data, result, entity, isModal, originalData }) {
        const state = Context.store.getState();
        const prevIsWon =
            originalData.isWon === true ||
            originalData.isWon === 'true' ||
            originalData.isWin === 1 ||
            originalData.isWin === '1';

        const licenseEditionCode = state?.config?.licenseEditionCode;

        const isValid =
            state?.config?.subscriptionModeCode === 'free_trial' ||
            ['starter', 'essential'].includes(licenseEditionCode);

        isValid &&
            Context.store.dispatch({
                type: OPPORTUNITIES_SET_IS_WON,
                hasChangedToWon:
                    (!prevIsWon && data?.isWon === true) ||
                    (!prevIsWon && data?.isWon?.toLowerCase() === 'true'),
            });
    }

    getFilterSchema(success, error) {
        const state = Context.store.getState();
        const currentUser = `${state.config.userData.nombre} ${state.config.userData.apellidos}`;
        let filterSchema = [
            {
                id: 'companies',
                dataType: 'singleValueList',
                description: getLiteral('label_account'),
                inputAttrs: {
                    ...FuzzyMap.empresas,
                },
                hint: getLiteral('action_searchcompany'),
            },
            {
                id: 'owner1',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_responsible_1'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'owner2',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_responsible_2'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
                permission: 'opportunityMultipleOwners',
            },
            {
                id: 'owner3',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_responsible_3'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
                permission: 'opportunityMultipleOwners',
            },
            {
                id: 'owner4',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_responsible_4'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
                permission: 'opportunityMultipleOwners',
            },
            {
                id: 'owner5',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_responsible_5'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
                permission: 'opportunityMultipleOwners',
            },
            {
                id: 'ratio',
                fieldConfiguration: 'ratio',
                description: getLiteral('label_probability'),
                dataType: 'probability',
                asExtra: true,
                inputAttrs: {
                    min: 0,
                    max: 10,
                },
            },
            {
                id: 'followingItem',
                dataType: 'singleValueList',
                description: getLiteral('label_following'),
                generateOptions: () => {
                    return [
                        { label: getLiteral('label_following'), value: '1' },
                        { label: getLiteral('label_not_following'), value: '2' },
                    ];
                },
            },
            {
                ...stateOpportunityFilterSchema,
                description: getLiteral(stateOpportunityFilterSchema.description),
                groupFunction: this.groupStates,
                getExtraClassNameValue: this.getExtraClassNameValue,
                isPinnable: false,
                isRemovable: false,
                withCheckbox: true,
            },
            {
                id: 'type',
                fieldConfiguration: 'idtipo',
                dataType: 'singleValueList',
                description: getLiteral('label_opportunity_type'),
                inputAttrs: {
                    list: 'tblTiposExpediente',
                    hideIfEmptyList: true,
                    shouldRenderFilterField: this.shouldRenderFilterField,
                },
                withCheckbox: true,
            },
            {
                id: 'dblvalor',
                fieldConfiguration: 'dblvalor',
                dataType: 'currency',
                description: getLiteral('label_value'),
                asExtra: true,
            },
            {
                id: 'dtprevfechaventa',
                fieldConfiguration: 'dtprevfechaventa',
                dataType: 'date',
                description: getLiteral('label_sales_forecast'),
                asExtra: true,
            },
            {
                id: 'idSucursal',
                dataType: 'singleValueList',
                fieldConfiguration: 'idsucursal',
                description: getLiteral('label_environment'),
                inputAttrs: {
                    list: 'tblSucursales',
                },
                asExtra: true,
            },
            {
                id: 'fechacierre',
                fieldConfiguration: 'fechacierre',
                dataType: 'date',
                description: getLiteral('label_opportunity_close'),
                asExtra: true,
            },
            {
                id: 'fcreado',
                dataType: 'date',
                description: getLiteral('label_created'),
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'opportunities-filter__creation-date-start',
                    last: 'opportunites-filter__creation-date-end',
                },
            },
            {
                id: 'fmodificado',
                dataType: 'date',
                description: getLiteral('label_modified'),
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'opportunities-filter__modified-date-start',
                    last: 'opportunities-filter__modified-date-end',
                },
            },
            {
                id: 'fModificadoidEstadoObra',
                dataType: 'date', // TODO: Formato custom
                description: getLiteral('label_status_date_modified'),
                asExtra: true,
            },
            {
                id: 'idcountry',
                dataType: 'singleValueList',
                description: getLiteral('label_country'),
                inputAttrs: {
                    list: 'tblCountries',
                },
                asExtra: true,
                permission: 'opportunitiesGeolocation',
            },
            {
                id: 'strprovincia',
                dataType: 'text',
                description: getLiteral('label_provinceregion'),
                asExtra: true,
                permission: 'opportunitiesGeolocation',
            },
            {
                id: 'strpoblacion',
                dataType: 'text',
                description: getLiteral('label_city'),
                asExtra: true,
                permission: 'opportunitiesGeolocation',
            },
            {
                id: 'cp',
                dataType: 'text',
                description: getLiteral('label_post_code'),
                asExtra: true,
                permission: 'opportunitiesGeolocation',
            },
            // Below are the filters related to the cross filters functionality of this entity
            // the one tagged as "isCrossFilter" is to be shown as cross filter in the allowed entities
            // the ones tagged as "invisible" are standard filters of this entity that will be set from the
            // cross filters section of the matching entity (because UX & BE)
            {
                id: 'hasOpportunity',
                description: getLiteral('label_filters_cross_filters_has_opportunity'),
                dataType: 'bool',
                isCrossFilter: true,
            },
            {
                id: 'hasAccount',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasActivity',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasContact',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasOrder',
                dataType: 'bool',
                invisible: true,
            },
        ];

        if (state.config.userData.generalResponsableFilterWeb)
            filterSchema.push({
                id: 'users',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible'),
                inputAttrs: {
                    list: 'usuarios',
                    field: 'nombre',
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            });
        return filterSchema;
    }

    getSchema(defaultInputSchema) {
        const state = Context.store.getState();
        const isFreeTrial = state?.config?.subscriptionModeCode === 'free_trial';

        let schema = [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'reference'),
                        type: 'text',
                        label: getLiteral('label_opportunity_project_name'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'environment'),
                        type: 'singleValueList',
                        label: getLiteral('label_environment'),
                        description: isFreeTrial && getLiteral('helptext_environment_accounts'),
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'type'),
                        type: 'singleValueList',
                        label: getLiteral('label_opportunity_type'),
                        inputAttrs: {
                            list: 'tblTiposExpediente',
                            hideIfEmptyList: true,
                            beforeRenderCrudField: this.beforeRenderCrudField,
                            shouldRenderField: this.shouldRenderField,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'state'),
                        type: 'singleValueList',
                        label: getLiteral('label_opportunity_status'),
                        inputAttrs: {
                            list: 'tblEstadoExpediente',
                            groupFunction: this.groupStates,
                            getExtraClassNameValue: this.getExtraClassNameValue,
                            withCheckbox: false,
                            getListMoreOptions: this.getListMoreOptions,
                            shouldRenderField: this.shouldRenderStateField,
                            actions: getCustomizeCrudAction(
                                OPPORTUNITIES,
                                '/settings/values-list',
                                `?entity=${OPPORTUNITIES.trueName}&list=tblEstadoExpediente`,
                            ),
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'probability'),
                        type: 'percent',
                        label: getLiteral('label_probability'),
                        inputAttrs: {
                            min: 0,
                            max: 10,
                            beforeRenderCrudField: this.beforeRenderCrudField,
                        },
                    },
                    {
                        id: 'multipleCurrency',
                        type: 'multipleField',
                        label: getLiteral('label_value'),
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'currency'),
                                mandatory: false,
                                type: 'singleValueList',
                                label: getLiteral('label_currency'),
                                hint: getLiteral('label_selectone'),
                                inputAttrs: {
                                    list: 'tblCurrency',
                                    isClearable: false,
                                },
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'amount'),
                                type: 'decimal',
                                label: getLiteral('label_amount'),
                                hint: getLiteral('placeholder_amount'),
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'closeDate'),
                        type: 'date',
                        label: getLiteral('label_opportunity_close'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'expectedCloseDate'),
                        type: 'date',
                        label: getLiteral('label_sales_forecast'),
                        spaced: true,
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'idEmpresa1'),
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_account_client'),
                        hint: getLiteral('action_searchcompany'),
                        inputAttrs: {
                            ...FuzzyMap.empresas,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'idEmpresa2'),
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_account_middleman'),
                        hint: getLiteral('action_searchcompany'),
                        inputAttrs: {
                            ...FuzzyMap.empresas,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'idEmpresa3'),
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_intermediary2'),
                        hint: getLiteral('action_searchcompany'),
                        inputAttrs: {
                            ...FuzzyMap.empresas,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idContactMultiple',
                        fieldConfiguration: 'idContactMultiple',
                        type: 'multipleFuzzySearchSingle',
                        keyIds: 'contactIdList',
                        inputAttrs: {
                            max: 5,
                            labelButton: getLiteral('label_add_contact'),
                            parentField: 'idinstalador',
                            parentFieldForBackend: 'idempresa',
                            parentFieldForContext: 'idEmpresa1',
                        },
                        inputs: [
                            {
                                id: 'idContact',
                                serverId: 'idContact',
                                fieldConfiguration: 'idContact',
                                label: getLiteral('label_contact'),
                                hint: getLiteral('label_contact'),
                                inputAttrs: {
                                    ...FuzzyMap.contactos,
                                },
                            },
                            {
                                id: 'idContact2',
                                serverId: 'idContact2',
                                fieldConfiguration: 'idContact2',
                                label: getLiteral('label_contact_2'),
                                hint: getLiteral('label_contact_2'),
                                inputAttrs: {
                                    ...FuzzyMap.contactos,
                                },
                            },
                            {
                                id: 'idContact3',
                                serverId: 'idContact3',
                                fieldConfiguration: 'idContact3',
                                label: getLiteral('label_contact_3'),
                                hint: getLiteral('label_contact_3'),
                                inputAttrs: {
                                    ...FuzzyMap.contactos,
                                },
                            },
                            {
                                id: 'idContact4',
                                serverId: 'idContact4',
                                fieldConfiguration: 'idContact4',
                                label: getLiteral('label_contact_4'),
                                hint: getLiteral('label_contact_4'),
                                inputAttrs: {
                                    ...FuzzyMap.contactos,
                                },
                            },
                            {
                                id: 'idContact5',
                                serverId: 'idContact5',
                                fieldConfiguration: 'idContact5',
                                label: getLiteral('label_contact_5'),
                                hint: getLiteral('label_contact_5'),
                                inputAttrs: {
                                    ...FuzzyMap.contactos,
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idComercial',
                        serverId: 'idComercial',
                        fieldConfiguration: 'idComercial',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_owner'),
                        hint: getLiteral('label_owner'),
                        inputAttrs: {
                            ...FuzzyMap.comercialOpportunities,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idComercialMultiple',
                        type: 'multipleFuzzySearchSingle',
                        keyIds: 'ownersIds',
                        inputAttrs: {
                            max: 5,
                            labelButton: getLiteral('action_add_owner'),
                            disableButtonOnBulk: true,
                        },
                        inputs: [
                            {
                                id: 'idComercial',
                                serverId: 'IdComercial', // it's in capital on purpose
                                fieldConfiguration: 'idComercial',
                                label: getLiteral('label_opportunity_responsible_1'),
                                hint: getLiteral('label_opportunity_responsible_1'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialOpportunities,
                                },
                            },
                            {
                                id: 'idComercial2',
                                serverId: 'idComercial2',
                                fieldConfiguration: 'idComercial2',
                                label: getLiteral('label_opportunity_responsible_2'),
                                hint: getLiteral('label_opportunity_responsible_2'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialOpportunities,
                                },
                            },
                            {
                                id: 'idComercial3',
                                serverId: 'idComercial3',
                                fieldConfiguration: 'idComercial3',
                                label: getLiteral('label_opportunity_responsible_3'),
                                hint: getLiteral('label_opportunity_responsible_3'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialOpportunities,
                                },
                            },
                            {
                                id: 'idComercial4',
                                serverId: 'idComercial4',
                                fieldConfiguration: 'idComercial4',
                                label: getLiteral('label_opportunity_responsible_4'),
                                hint: getLiteral('label_opportunity_responsible_4'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialOpportunities,
                                },
                            },
                            {
                                id: 'idComercial5',
                                serverId: 'idComercial5',
                                fieldConfiguration: 'idComercial5',
                                label: getLiteral('label_opportunity_responsible_5'),
                                hint: getLiteral('label_opportunity_responsible_5'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialOpportunities,
                                },
                            },
                        ],
                        spaced: true,
                        hasInviteUsers: true,
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'comments'),
                        type: 'textarea',
                        label: getLiteral('label_comment_opportunities'),
                        inputAttrs: {
                            rows: 4,
                            rowsMax: 10,
                            maxLength: 8000,
                        },
                    },
                ],
            },
            {
                title: getLiteral('label_address'),
                section: 'address',
                beforeRenderCrudSection: this.beforeRenderCrudSection,
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'address1'),
                        type: 'text',
                        label: getLiteral('label_address'),
                        hint: getLiteral('label_address'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(OPPORTUNITIES, 'address2'),
                        type: 'text',
                        label: getLiteral('label_address2'),
                        hint: getLiteral('label_address2'),
                    },
                    {
                        id: 'multipleCityProvince',
                        type: 'multipleField',
                        label: ' ',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'city'),
                                type: 'text',
                                // label: getLiteral('label_city'),
                                hint: getLiteral('label_city'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'province'),
                                type: 'text',
                                // label: getLiteral('label_provinceregion'),
                                hint: getLiteral('label_provinceregion'),
                            },
                        ],
                    },
                    {
                        id: 'multipleCPCountry',
                        type: 'multipleField',
                        label: ' ',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'cp'),
                                type: 'text',
                                // label: getLiteral('label_post_code'),
                                hint: getLiteral('label_post_code'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(OPPORTUNITIES, 'idCountry'),
                                type: 'singleValueList',
                                // label: getLiteral('label_country'),
                                hint: getLiteral('label_country'),
                                inputAttrs: {
                                    list: 'tblCountries',
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'geolocation',
                        fieldConfiguration: '',
                        type: 'geolocation',
                        label: '',
                        hiddenForBulk: true,
                        inputAttrs: {
                            latitude: 'latitude',
                            longitude: 'longitude',
                            isGeoLocated: 'isGeoLocated', // custom field created in model
                            address1: 'address1',
                            address2: 'address2',
                            city: 'city',
                            province: 'province',
                            cp: 'cp',
                            country: 'country',
                            idCountry: 'idCountry',
                            mapMarkerIcon: OPPORTUNITY_ICON,
                        },
                        //This inputs despide don't being used directly in the render
                        //(only to consider it as a multiple field), are necessary for backend.
                        inputs: [
                            {
                                ...getCrudFieldConfig(OPPORTUNITIES, 'latitude'),
                                type: 'decimal',
                                label: getLiteral('label_latitude'),
                            },
                            {
                                ...getCrudFieldConfig(OPPORTUNITIES, 'longitude'),
                                type: 'decimal',
                                label: getLiteral('label_longitude'),
                            },
                        ],
                    },
                ],
            },
        ];

        const permissions = Context.store.getState().config.permission;

        if (!permissions.opportunitiesGeolocation) {
            // Remove address section (section that contains geolocation field)
            schema = schema.filter(
                (section) => !section.fields.find((field) => field.id === 'geolocation'),
            );
        }

        schema.forEach((value) => {
            value.fields = value.fields.filter((field) => {
                if (field.id === 'idComercialMultiple') {
                    if (permissions.opportunityMultipleOwners) return true;
                    return false;
                }
                if (field.id === 'idComercial') {
                    if (!permissions.opportunityMultipleOwners) return true;
                    return false;
                }
                if (field.id === 'idContactMultiple') {
                    if (permissions.opportunityMultipleContacts) return true;
                    return false;
                }
                return true;
            });
        });

        return schema;
    }

    getSpecificEntityDefaultValues = (data, id) => {
        if (!data) return;
        const config = Context.store.getState().config;

        if (!id) {
            // cases for creation only
            if (!data.idComercial || isEmptyObject(data.idComercial)) {
                data['idComercial'] = {
                    label: `${config.userData.nombre} ${config.userData.apellidos}`,
                    value: config.userData.idUsuario,
                };
            }
        }

        // someone in forcemanager decide to select the environment of the opportunity
        // either in edition and creation if the object does not have any of them
        if (!data.environment) {
            if (config && config.userData && config.userData.idEntorno) {
                data.environment = config.userData.idEntorno;
            }
        }

        return data;
    };

    groupStates(options, icons) {
        let openOptions = [];
        let closedOptions = [];
        options.map((option) => {
            if (option.blnendstate) closedOptions.push(option);
            else openOptions.push(option);
        });
        let closedWinOptions = [];
        let closedLostOptions = [];
        closedOptions.map((option) => {
            if (option.intendstatetype === '1') {
                option.type = 'success';
                if (icons) option.icon = icons?.won;
                closedWinOptions.push(option);
            } else {
                option.type = 'danger';
                if (icons) option.icon = icons?.lost;
                closedLostOptions.push(option);
            }
        });
        closedOptions = [...closedWinOptions, ...closedLostOptions];
        let groupedOptions = [
            { label: getLiteral('label_opportunity_open'), options: openOptions },
            { label: getLiteral('label_opportunity_closed'), options: closedOptions },
        ];

        return groupedOptions;
    }

    getListMoreOptions(options, list) {
        if (list !== 'tblEstadoExpediente') return;
        const state = Context.store.getState();
        const entityCrud = getActiveCrud(state);
        const opportunityStateLabel = entityCrud?.data?.opportunityType;
        const opportunityStateValue = entityCrud?.data?.state;

        if (opportunityStateValue === '-1' || opportunityStateValue === undefined) return options;
        if (options.find((item) => item['-id'] === opportunityStateValue)) return options;
        return [...options, { label: opportunityStateLabel, value: opportunityStateValue }];
    }

    getExtraClassNameValue(option) {
        if (!option.blnendstate) return;
        if (option.intendstatetype === '1') return 'opportunity-state-win';
        if (option.intendstatetype === '0') return 'opportunity-state-lost';
    }

    beforeRenderCrudSection(section) {
        if (!section || !section.section) return;
        const state = Context.store.getState();
        const permissions = state.config.permission;
        const activeCrud = getActiveCrud(state);

        if (
            section.section === 'address' &&
            activeCrud &&
            activeCrud.data &&
            activeCrud.data.id &&
            !permissions.opportunitiesGeolocation
        ) {
            section.show = false;
            return section;
        }

        return section;
    }

    beforeRenderCrudField(field) {
        const state = Context.store.getState();
        const enablePipelineOpportunities = state?.config?.userData?.enablePipelineOpportunities;
        switch (field.id) {
            case 'type':
                if (enablePipelineOpportunities) {
                    field.label = getLiteral('label_opportunity_pipeline');
                }
                break;
            case 'probability':
                // We must save readOnly because field is mutated and we must preserve original value
                if (!field.hasOwnProperty('originalReadOnly'))
                    field.originalReadOnly = field.readOnly;

                const entityCrud = getActiveCrud(state);
                const crudData = entityCrud?.data || {};
                const serverListStates = state.serverList?.tblEstadoExpediente?.data;
                const mappedServerListStates =
                    serverListStates?.length > 0 &&
                    serverListStates.reduce((obj, current) => {
                        obj[current.value] = current;
                        return obj;
                    }, {});

                field.readOnly =
                    mappedServerListStates?.[crudData?.state]?.blnendstate ||
                    field.originalReadOnly;
                break;
        }

        return field;
    }

    shouldRenderStateField = (options, list, props) => {
        if (props?.parentField === 'idtipo') {
            const types = this.getDataFromServerList('tblTiposExpediente');
            return types?.length;
        }
        return true;
    };

    shouldRenderField(options, list) {
        if (options && options.length === 1 && list === 'tblTiposExpediente') return false;
        else return true;
    }

    shouldRenderFilterField(field) {
        const state = Context.store.getState();

        if (
            field &&
            field.inputAttrs &&
            field.inputAttrs.list &&
            field.inputAttrs.list === 'tblTiposExpediente' &&
            state &&
            state.serverList &&
            state.serverList.tblTiposExpediente &&
            state.serverList.tblTiposExpediente.data &&
            state.serverList.tblTiposExpediente.data.length > 0
        ) {
            return true;
        }
    }

    getDefaultDependantValue(options, parentId, parentOptions) {
        // check first if the field state is dependant from type
        const state = Context.store.getState();
        const isStateDependantOfType =
            state.config &&
            state.config.standardFieldsSchemaMap &&
            state.config.standardFieldsSchemaMap.EXPEDIENTE &&
            state.config.standardFieldsSchemaMap.EXPEDIENTE.idestadoobra &&
            state.config.standardFieldsSchemaMap.EXPEDIENTE.idestadoobra.strParentField === 'idtipo'
                ? true
                : false;
        if (!isStateDependantOfType) return;
        if (!options || options.length === 0 || !parentId) return;
        if (parentOptions) {
            const mappedParentOptions = parentOptions.reduce((obj, current) => {
                obj[current.value] = current;
                return obj;
            }, {});

            const mappedOptions = options.reduce((obj, current) => {
                obj[current] = current;
                return obj;
            }, {});

            const defaultTypeStatus = mappedParentOptions[parentId].defaultstatusid;

            if (defaultTypeStatus && mappedOptions[defaultTypeStatus]) {
                return mappedParentOptions[parentId].defaultstatusid;
            }
        }

        let defaultValue = '';
        let dependant = [];
        let unParented = [];

        options.forEach((current) => {
            if (current.idparent === parentId) dependant.push(current);
            else if (
                current.idparent === '-1' ||
                current.idparent === '0' ||
                current.idparent === ''
            ) {
                unParented.push(current);
            }
        });

        if (dependant.length > 0) defaultValue = dependant[0].value;
        else if (unParented.length > 0) defaultValue = unParented[0].value;

        return defaultValue;
    }

    getRelatedProbability(options, parentId, prevProbability) {
        if (!options || options.length === 0 || !parentId) return;

        let probability = '';
        const mappedOptions = options.reduce((obj, current) => {
            obj[current.value] = current;
            return obj;
        }, {});

        const option = mappedOptions[parentId];

        if (option && option.ratiostatus) {
            return String(option.ratiostatus / 10);
        } else if (option && (option.blnendstate || (option.blnendstate && !prevProbability))) {
            return option.intendstatetype && option.intendstatetype === '1' ? '10' : '0';
        } else if (prevProbability) {
            return String(prevProbability);
        }

        return probability;
    }

    getDataFromServerList = (table) => {
        const state = Context.store.getState();
        const serverListData =
            state.serverList && state.serverList[table] && state.serverList[table].data
                ? state.serverList[table].data
                : [];

        return serverListData;
    };

    whileInitCrud = (data, mappedSchema) => {
        return new Promise((resolve) => {
            const state = Context.store.getState();
            const crudActive = getActiveCrud(state);
            const statuses = this.getDataFromServerList('tblEstadoExpediente');
            const types = this.getDataFromServerList('tblTiposExpediente');
            const userData = state.config?.userData || {};

            let newData = { ...data };

            if (crudActive.isBulkAction) return resolve(newData);

            if (data && !data.type && !data.state) {
                const newState = this.getDefaultDependantValue(statuses, data.type);

                if (newState) {
                    newData.state = newState;
                    newData.probability = this.getRelatedProbability(
                        statuses,
                        newState,
                        data.probability,
                    );
                }
            } else if (!data.probability) {
                newData.probability = this.getRelatedProbability(
                    statuses,
                    newData.state,
                    data.probability,
                );
            }

            if (types?.length === 1) newData.type = types[0].value;

            if (!data.currency && !mappedSchema?.currency?.defaultValue && userData?.currencyId) {
                const currencyValues = state?.serverList?.tblCurrency?.data?.filter(
                    (current) => current.value === userData.currencyId,
                );

                if (currencyValues?.[0]) {
                    newData.currency = currencyValues[0].value;
                }
            }
            resolve(newData);
        });
    };

    beforeCrud = () =>
        new Promise((resolve) => {
            Context.store
                .dispatch(Context.actions.ServerListActions.getList('tblTiposExpediente'))
                .finally(() => {
                    resolve({});
                });
        });

    changeFields = (fields) => {
        const state = Context.store.getState();
        const statuses = this.getDataFromServerList('tblEstadoExpediente');
        const types = this.getDataFromServerList('tblTiposExpediente');

        const crudActive = getActiveCrud(state);
        const crudData = crudActive && crudActive.data;

        let newFields = { ...fields };

        if (fields && fields.type) {
            // We pass types to get the default status if there is in the selected type
            const newState = this.getDefaultDependantValue(statuses, fields.type, types);

            if (newState && crudData) {
                newFields.state = newState;
                newFields.probability = this.getRelatedProbability(
                    statuses,
                    newState,
                    crudData.probability,
                );
            }
        }

        if (fields && fields.state && crudData && !newFields.probability) {
            newFields.probability = this.getRelatedProbability(
                statuses,
                fields.state,
                crudData.probability,
            );
        }

        return newFields;
    };

    refreshData() {
        this.context.store.dispatch(this.context.actions.CrudActions.cancelCrud());
        this.context.store.dispatch(
            this.context.actions.EntityListActions.init(OPPORTUNITIES, true),
        );
    }

    setFollow(id, follow, isShared = false, success, error) {
        this.context.domainManager.setFollow(
            OPPORTUNITIES.entity,
            id,
            follow,
            isShared,
            success,
            error,
        );
    }

    getPipeline(filters, operators, success, error) {
        if (getPipelineRequest) {
            abortXhr(getPipelineRequest);
        }
        // TODO this use case is not working at all in backend, but we can
        // TODO control it ourself in opportunities footer
        // const state = this.context.store.getState();
        // let customParams = { endState: 2 };
        let customParams = {};

        // if (filters && filters.state && filters.state.value && filters.state.value.length > 0) {
        //     /*
        //     if only opened states, endState === 1
        //     if only closed states, endState === 2
        //     otherwise, endState does not exist
        //      */
        //     const filteredStates = filters.state.value;
        //     let openedStates = [];
        //     let closedStates = [];
        //     state.serverList.tblEstadoExpediente.data.forEach((state) => {
        //         if (filteredStates.includes(state.value)) {
        //             if (state.blnendstate) closedStates.push(state);
        //             else openedStates.push(state);
        //         }
        //     });
        //     if (closedStates.length === 0 && openedStates.length > 0) {
        //         customParams.endState = 1;
        //     }
        // }
        // TODO end TODO

        getPipelineRequest = this.context.domainManager.getOpportunitiesPipeline(
            filters,
            operators,
            customParams,
            (data) => {
                if (data && data.Result) {
                    success && success(data.Result);
                } else {
                    error && error();
                }
            },
            error,
        );
    }

    getCustomViewKey() {
        return 'sfm_opportunities_custom_view';
    }

    preloadFilters(filters) {
        if (filters && !filters.isFirstLoad) return Promise.resolve();

        const state = Context.store.getState();
        const config = state.config;

        if (config) {
            if (config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName]) {
                // If idestadoobra isHidden we don't have to get the open states
                if (
                    config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName]['idestadoobra'] &&
                    config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName]['idestadoobra']
                        .isHidden
                ) {
                    return Promise.resolve();
                }
            }
        }

        return new Promise((resolve, reject) => {
            this.getOpenStates()
                .then((openStates) => {
                    const data = [
                        {
                            filter: {
                                ...stateOpportunityFilterSchema,
                                description: getLiteral(stateOpportunityFilterSchema.description),
                                noCacheCheck: true,
                            },
                            values: openStates.map((state) => state.value),
                            completeValues: openStates.map((state) => {
                                return {
                                    value: state.value,
                                    label: state.label,
                                    idParent: state.idparent,
                                };
                            }),
                        },
                    ];

                    resolve(data);
                })
                .catch(reject);
        });
    }

    getOpenStates() {
        return new Promise((resolve, reject) => {
            Context.serverListManager
                .getList('tblEstadoExpediente')
                .then((list) => {
                    const openStates = list.filter((value) => value.blnendstate === false);
                    resolve(openStates);
                })
                .catch(reject);
        });
    }

    afterFetchEntity() {
        if (Context?.config?.userData?.avoidOpportunityStatisticsFooterRequest) return;
        const loadPipeline = this.context.actions.OpportunitiesActions.loadPipeline();
        this.context.store.dispatch(loadPipeline);
    }

    afterGetSchema({ schema, dependencyMap, dynamicMap }) {
        return Context.serverListManager
            .getList('tblEstadoExpediente')
            .then((result) => {
                // Opportunity close date visibility
                const dynamicIds = result
                    .filter((state) => state.blnendstate)
                    .map((state) => state.value);
                if (!dynamicMap.idestadoobra) dynamicMap.idestadoobra = {};
                dynamicMap.idestadoobra.fechacierre = dynamicIds;
                return {
                    schema,
                    dependencyMap,
                    dynamicMap,
                };
            })
            .then(({ schema, dependencyMap, dynamicMap }) => {
                if (!dynamicMap.idinstalador) dynamicMap.idinstalador = {};
                dynamicMap.idinstalador.idContactMultiple = '_all_';
                return {
                    schema,
                    dependencyMap,
                    dynamicMap,
                };
            })
            .then(({ schema, dependencyMap, dynamicMap }) => {
                const state = Context.store.getState();
                const permissions = state.config.permission;
                const activeCrud = getActiveCrud(state);
                const isEditing = activeCrud && activeCrud.id;
                schema.map((currElement, index) => {
                    schema[index].fields = currElement.fields.filter((field) => {
                        switch (field.id) {
                            case 'address1':
                            case 'address2':
                            case 'multipleCityProvince':
                            case 'multipleCPCountry':
                            case 'geolocation':
                                if (isEditing && !permissions.GeoCodeOpportunitiesCrud) {
                                    field.readOnly = true;
                                    field?.inputs?.forEach((input) => (input.readOnly = true));
                                }
                                return true;
                            default:
                                return true;
                        }
                    });
                });
                return {
                    schema,
                    dependencyMap,
                    dynamicMap,
                };
            });
    }

    beforeDetail = (id) => {
        const requestTimestamp = new Date().getTime();
        getRelatedAccountsTimestamp = requestTimestamp;
        return new Promise((resolve, reject) => {
            // Getting extra data
            if (id) {
                this.context.domainManager
                    .getDataForEntityWidget({
                        sourceEntity: 'opportunity',
                        widget: 'relatedaccounts',
                        entityId: parseInt(id, 10),
                    })
                    .then((data = []) => {
                        if (getRelatedAccountsTimestamp === requestTimestamp) {
                            resolve({ relatedAccounts: data });
                        } else {
                            console.warn('Abort printing data due a second request');
                        }
                    })
                    .catch(reject);
            } else {
                resolve({});
            }
        });
    };

    beforeSave = (schema, data, isDuplicated) => {
        // backend fix: the field ratio is mandatory to send
        // something like 0 if is not informed by the user
        if (!data.hasOwnProperty('ratio') || !data.ratio) {
            data['ratio'] = 0;
        }
        let finalData = { ...data };
        if (isDuplicated)
            finalData = {
                ...finalData,
                Referencia: `${getLiteral('label_duplicate')} ${
                    finalData?.Referencia || finalData?.name
                }`,
            };
        return finalData;
    };

    changeFieldsFromWeb3 = () => {
        const entityFrom = getParameterByName('entityfrom');
        const idEntityFrom = getParameterByName('idfrom');
        const nameFrom = getParameterByName('namefrom');
        if (!(entityFrom && idEntityFrom && nameFrom)) return;

        let fields = {};
        switch (getEntityFromString(entityFrom)) {
            case COMPANIES:
                fields['idEmpresa1'] = {
                    label: nameFrom,
                    value: idEntityFrom,
                };
                break;
        }
        return fields;
    };

    calculateSpecialHiddenFields = (dbSchema) => {
        let hiddenFields = [];
        let valueIsHidden = dbSchema && dbSchema.dblvalor && dbSchema.dblvalor.isHidden === 'True';
        if (valueIsHidden) {
            hiddenFields.push('idcurrency');
        }
        return hiddenFields;
    };

    listConfiguration = (schema) => {
        return new Promise((resolve) => {
            Promise.all([Context.serverListManager.getList('tblTiposExpediente')])
                .then(([tblTiposExpediente]) => {
                    if (!tblTiposExpediente || tblTiposExpediente.length === 0) {
                        let opportunityTypeFieldIndex = schema.findIndex((field) => {
                            return field.colId === 'IdTypeExpediente';
                        });
                        if (opportunityTypeFieldIndex !== -1) {
                            schema.splice(opportunityTypeFieldIndex, 1);
                        }
                    }
                    resolve(schema);
                })
                .catch(() => {
                    console.error('error in listConfiguration Opportunities');
                    // return the original schema without any extra configuration
                    resolve(schema);
                });
        });
    };

    hideHiddenFields = (schema) => {
        return new Promise((resolve) => {
            const config = Context.store.getState().config;
            if (!config || isEmptyObject(config)) return schema;

            const standardFieldsConfiguration =
                config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName] || [];

            let statusIsHidden = standardFieldsConfiguration['idestadoobra']
                ? standardFieldsConfiguration['idestadoobra'].isHidden
                : false;

            if (statusIsHidden) {
                const finalSchema = schema.filter((field) => {
                    if (
                        field.fieldConfiguration &&
                        ['win', 'lost', 'idtipo'].includes(field.fieldConfiguration)
                    ) {
                        return false;
                    }
                    return true;
                });
                schema = [...finalSchema];
            }

            // Applying opportunitiesGeolocation
            const permissions = config.permission;
            if (!permissions.opportunitiesGeolocation) {
                const geolocationFields = [
                    'address',
                    'cp',
                    'latitude',
                    'longitude',
                    'city',
                    'province',
                    'country',
                    'isgeocoded',
                ];
                schema = schema.filter((field) => !geolocationFields.includes(field.colId));
            }

            resolve(schema);
        });
    };

    isEntityReadOnly = (data) => {
        return data && data.isReadOnly && getBackendBoolean(data.isReadOnly);
    };

    formatFiltersToSend = (filters) => {
        const newFilters = translateFollowingItemForBackend(filters, OPPORTUNITIES);
        return newFilters;
    };
}

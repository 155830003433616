import React, { memo, useMemo } from 'react';
import { Icon, Text } from 'hoi-poi-ui';
import AttendeesRow from './AttendeesRow';
import { getLiteralWithParameters } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';
import { ATTENDEE_STATUS } from 'constants/Constants';

import './styles.scss';

const AttendeesList = memo(({ data, onBack }) => {
    const attendeesSorted = useMemo(() => {
        const attendees = data.attendees;
        let organizer = [];
        let accepted = [];
        let rejected = [];
        let tentative = [];
        let noResponse = [];

        attendees.forEach((current) => {
            if (getBackendBoolean(current.Organizer)) {
                organizer.push(current);
            } else {
                switch (current.Status) {
                    case ATTENDEE_STATUS.accepted:
                        accepted.push(current);
                        break;
                    case ATTENDEE_STATUS.rejected:
                        rejected.push(current);
                        break;
                    case ATTENDEE_STATUS.tentative:
                        tentative.push(current);
                        break;
                    case ATTENDEE_STATUS.noResponse:
                    default:
                        noResponse.push(current);
                        break;
                }
            }
        });
        return [...organizer, ...accepted, ...rejected, ...tentative, ...noResponse];
    }, [data]);

    const text = useMemo(() => {
        const attendees = data.attendees;
        const attendeesNumber = attendees.length;
        const literal =
            attendeesNumber === 1
                ? 'label_calendar_invited_users_des_singular'
                : 'label_calendar_invited_users_des_plural';
        return getLiteralWithParameters(literal, [attendeesNumber, data.CreatedUserName]);
    }, [data]);

    return (
        <div className="fm-agenda-small-detail__attendee-list">
            {onBack && (
                <>
                    <div className="attendees-list__title-wrapper" onClick={onBack}>
                        <div className="attendees-list__back">
                            <Icon name="arrowBack" />
                        </div>
                        <Text className="attendees-list__title">{text}</Text>
                    </div>
                    <div className="attendees-list__divider" />
                </>
            )}
            <div className="attendees-list__rows">
                {attendeesSorted.map((current) => (
                    <AttendeesRow attendee={current} />
                ))}
            </div>
        </div>
    );
});

export default AttendeesList;

import {
    ENTITY_CRUD_HIDE,
    ENTITY_CRUD_CLOSE,
    ENTITY_CRUD_OPEN,
    ENTITY_CRUD_INIT,
    ENTITY_CRUD_INIT_SUCCESS,
    ENTITY_CRUD_INIT_ERROR,
    ENTITY_CRUD_CHANGE,
    ENTITY_CRUD_SAVE,
    ENTITY_CRUD_SAVE_LOADING,
    ENTITY_CRUD_SAVE_ERROR,
    ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE,
    ENTITY_CRUD_TOGGLE_CONFIRM_MODAL,
    ENTITY_CRUD_INIT_WORKFLOW,
    ENTITY_CRUD_CHECK_DUPLICATE,
    ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS,
    ENTITY_CRUD_CHECK_DUPLICATE_ERROR,
    ENTITY_CRUD_CHECK_DUPLICATE_RESET,
    ENTITY_CRUD_PURGE_ACTIVE,
    ENTITY_CRUD_OVERLAY,
    ENTITY_CRUD_DUPLICATES_ERROR,
    ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL,
    ENTITY_CRUD_UPDATE_TABLE_ERRORS,
    ENTITY_CRUD_SET_ACTIVE,
} from 'constants/ActionTypes';

const initialState = {
    isOpen: false,
    activeModal: [],
    active: null,
    isConfirmModalOpen: false,
    isExtraFieldsModalOpen: false,
    urlToOpen: null,
};

// This function change errors to null only for fields that are provided
function nullifyData(fields) {
    const errors = JSON.parse(JSON.stringify(fields));
    return Object.keys(errors).reduce((obj, key) => {
        const item = errors[key];
        const isSelectField = item?.label && item?.value;
        if (item && typeof item === 'object' && item.constructor === Object && !isSelectField) {
            obj[key] = Object.keys(item).reduce((obj2, key2) => {
                obj2[key2] = null;
                return obj2;
            }, {});
        } else {
            obj[key] = null;
        }
        return obj;
    }, {});
}

export default function config(state = initialState, action) {
    const crudName = state.activeModal.length
        ? state.activeModal[state.activeModal.length - 1]
        : state.active;

    const innerInitialState = {
        loading: false,
        loadingSave: false,
        errorsSave: {},
        firstErrorField: false,
        errors: {},
        entity: null,
        id: null,
        extraId: null,
        schema: [],
        data: {},
        originalData: {},
        dependencyMap: {},
        dynamicMap: {},
        dynamicHiddenMap: {},
        isBulkAction: false,
        workFlowAction: null,
        duplicates: {},
        isModal: false,
        crudTab: null,
        preComponents: [],
        duplicateOverlay: false,
        duplicateNoChanges: false,
        hasFilesToSave: false,
        version: null,
        isFromTab: false,
    };

    // Default specific entity state
    const entityState = state[crudName] || innerInitialState;

    switch (action.type) {
        case ENTITY_CRUD_INIT:
            let newActive = '';
            if (action.entity) {
                newActive = action.entity;
                if (action.id) newActive += `-${action.id}`;
                else if (action.extraId) newActive += `-${action.extraId}`;
                else {
                    const existing = state.activeModal.filter((current) =>
                        current.includes(`${action.entity}-new-`),
                    );
                    if (state.active && state.active.includes(`${action.entity}-new-`)) {
                        newActive += `-new-${existing.length + 2}`;
                    } else newActive += `-new-${existing.length + 1}`;
                }
            }

            let newActiveModal = [...state.activeModal];
            let newActiveSide = null;
            if (action.isModal) {
                newActiveSide = state.active || null;
                newActiveModal.push(newActive);
            } else {
                newActiveSide = newActive;
            }

            return {
                ...state,
                isOpen: state.isOpen || (!action.isModal && !action.isFromDetail),
                active: newActiveSide,
                activeModal: newActiveModal,
                [newActive]: {
                    ...entityState,
                    entity: action.entity,
                    id: action.id,
                    extraId: action.extraId,
                    loading: true,
                    firstErrorField: false,
                    errorsSave: {},
                    errors: {},
                    isBulkAction: action.isBulkAction,
                    data: {},
                    originalData: {},
                    isModal: action.isModal,
                    crudTab: action.crudTab || null,
                    extraInfo: action.extraInfo || null,
                    hasFilesToSave: false,
                    parentCrudInfo: action.parentCrudInfo || null,
                    version: action.version || null,
                    isFromTab: action.isFromTab,
                    avoidRedirects: action.avoidRedirects,
                },
            };
        case ENTITY_CRUD_INIT_SUCCESS:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    loading: false,
                    loadingSave: false,
                    firstErrorField: false,
                    firstFieldToFocus: action.firstFieldToFocus,
                    errors: {},
                    schema: action.schema,
                    mappedSchema: action.mappedSchema,
                    configMap: action.configMap,
                    dependencyMap: action.dependencyMap,
                    dynamicMap: action.dynamicMap,
                    dynamicHiddenMap: action.dynamicHiddenMap,
                    data: action.data || {},
                    originalData: action.data || {},
                    preComponents: action.preComponents || [],
                },
            };
        case ENTITY_CRUD_INIT_ERROR:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    loading: false,
                    loadingSave: false,
                    error: true,
                    errors: action.errors || {},
                },
            };
        case ENTITY_CRUD_INIT_WORKFLOW:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    data: action.data,
                    loading: action.loading,
                    loadingSave: action.loadingSave,
                },
            };
        case ENTITY_CRUD_CHANGE:
            const errors = nullifyData(action.fields);
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    data: {
                        ...entityState.data,
                        ...action.fields,
                    },
                    errorsSave: {
                        ...entityState.errorsSave,
                        ...errors,
                    },
                    firstErrorField: null,
                    dynamicHiddenMap: {
                        ...entityState.dynamicHiddenMap,
                        ...action.dynamicHiddenMap,
                    },
                    workFlowAction: action.workFlowAction,
                    duplicateNoChanges: false,
                },
            };
        case ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    hasFilesToSave: action.hasFilesToSave || false,
                },
            };
        case ENTITY_CRUD_SAVE:
            return {
                ...state,
                [crudName]: innerInitialState,
            };
        case ENTITY_CRUD_SAVE_LOADING:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    loadingSave: action.state,
                },
            };
        case ENTITY_CRUD_SAVE_ERROR:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    loadingSave: false,
                    errorsSave: action.errors || {},
                    firstErrorField: action.firstErrorField,
                },
            };
        case ENTITY_CRUD_UPDATE_TABLE_ERRORS:
            return {
                ...state,
                [active]: {
                    ...entityState,
                    errorsSave: {
                        ...entityState.errorsSave,
                        products: action.errors,
                    },
                },
            };
        case ENTITY_CRUD_TOGGLE_CONFIRM_MODAL:
            return {
                ...state,
                isConfirmModalOpen: action.bool,
            };
        case ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL:
            return {
                ...state,
                isExtraFieldsModalOpen: action.bool,
                urlToOpen: action.url,
                queryString: action.queryString,
            };
        case ENTITY_CRUD_HIDE:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    errorsSave: {},
                    firstErrorField: false,
                    errors: {},
                    data: { ...entityState.originalData },
                },
                isOpen: false,
            };
        case ENTITY_CRUD_OPEN:
            return {
                ...state,
                isOpen: true,
            };
        case ENTITY_CRUD_CLOSE:
            if (state.activeModal.length) {
                const lastModalActive = state.activeModal[state.activeModal.length - 1];
                let newState = {
                    ...state,
                    activeModal: state.activeModal.filter((current) => current !== lastModalActive),
                };
                delete newState[lastModalActive];
                return newState;
            }

            if (action.tabs && Object.entries(action.tabs).length > 1) {
                return {
                    ...state,
                    isOpen: false,
                };
            }

            return initialState;
        case ENTITY_CRUD_CHECK_DUPLICATE:
            let toasts = (entityState.duplicates && entityState.duplicates.toasts) || {};

            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    duplicates: {
                        ...entityState.duplicateList,
                        loading: true,
                        error: false,
                        toasts: toasts,
                        currentField: action.currentField,
                    },
                },
            };
        case ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS:
            let newToasts = action.toastsDuplicate
                ? action.toastsDuplicate
                : entityState.duplicates.toasts;

            if (action.fieldToRemove) delete newToasts[action.fieldToRemove];
            if (action.newField) newToasts[action.newField] = action.newValues;

            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    duplicates: {
                        ...entityState.duplicates,
                        loading: false,
                        toasts: { ...newToasts },
                        currentField: '',
                    },
                },
            };
        case ENTITY_CRUD_CHECK_DUPLICATE_ERROR:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    duplicates: {
                        ...entityState.duplicates,
                        error: true,
                        currentField: '',
                    },
                },
            };
        case ENTITY_CRUD_CHECK_DUPLICATE_RESET:
            if (!crudName) return { ...state };
            else {
                return {
                    ...state,
                    [crudName]: {
                        ...entityState,
                        duplicates: {},
                    },
                };
            }
        case ENTITY_CRUD_PURGE_ACTIVE:
            return {
                ...state,
                active: action.fromEntity || undefined,
                [crudName]: undefined,
            };
        case ENTITY_CRUD_OVERLAY:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    duplicateOverlay: action.bool,
                },
            };
        case ENTITY_CRUD_DUPLICATES_ERROR:
            return {
                ...state,
                [crudName]: {
                    ...entityState,
                    duplicateNoChanges: true,
                },
            };
        case ENTITY_CRUD_SET_ACTIVE:
            return {
                ...state,
                active: action.active,
            };
        default:
            return state;
    }
}

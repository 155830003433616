import Context from 'managers/Context';
import {
    APIKEY_INFO_LOADING,
    APIKEY_INFO_SUCCESS,
    APIKEY_INFO_ERROR,
    APIKEY_GENERATE_LOADING,
} from 'constants/ActionTypes';

function _showLoading() {
    return { type: APIKEY_INFO_LOADING };
}

function _loadInfo(apiKey, privateApiKey) {
    return {
        type: APIKEY_INFO_SUCCESS,
        payload: { apiKey, privateApiKey },
    };
}

function _showError(error) {
    return {
        type: APIKEY_INFO_ERROR,
        payload: { error },
    };
}

function _showLoadingCreation() {
    return { type: APIKEY_GENERATE_LOADING };
}

export function getApiKey(success, error) {
    return function (dispatch) {
        dispatch(_showLoading());
        Context.domainManager.getApiKey(
            (apiKey, privateApiKey) => {
                dispatch(_loadInfo(apiKey, privateApiKey));
                success();
            },
            (error) => {
                dispatch(_showError('Error'));
                error();
            },
        );
    };
}

export function createApiKey(success, error) {
    return function (dispatch) {
        dispatch(_showLoadingCreation());
        Context.domainManager.createApiKey(
            (apiKey, privateApiKey) => {
                dispatch(_loadInfo(apiKey, privateApiKey));
                success();
            },
            (error) => {
                dispatch(_showError('Error'));
                error();
            },
        );
    };
}

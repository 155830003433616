import React, { memo, useCallback } from 'react';
import { useTheme } from 'hoi-poi-ui';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const ImagesLoader = memo(({ width, height }) => {
    const theme = useTheme();

    const getRow = useCallback(({ x, y, width, height }) => {
        return <rect x={x} y={y} rx="3" ry="3" width={width} height={height || 20} />;
    }, []);

    const renderRows = useMemo(() => {
        let rows = [];
        let baseY = 20;
        let baseCircleY = 40;
        const rowHeight = 75;
        const numRows = Math.floor(height / rowHeight);
        const firstWidth = width * 0.3;
        const secondWidth = width * 0.18;
        const thirdWidth = width * 0.5;
        const fourthWidth = width * 0.13;
        const dateWidth = width * 0.2;

        const circleRadius = 20;
        const circlePosition = 44;
        const baseX = 80;
        const datePosition = width * 0.63;

        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    {getRow({ x: baseX, y: baseY, width: firstWidth })}
                    {getRow({ x: datePosition, y: baseY, width: dateWidth })}
                    {getRow({ x: baseX, y: baseY + 25, width: secondWidth })}
                    {getRow({ x: baseX, y: baseY + 25 * 2, width: thirdWidth })}
                    {getRow({ x: baseX, y: baseY + 25 * 3, width: fourthWidth })}
                </Fragment>,
            );
            baseCircleY = baseCircleY + rowHeight;
            baseY = baseY + rowHeight;
        }
        return rows;
    }, [width, height, getRow]);

    const getSquare = useCallback(({ x, y }) => {}, []);

    return (
        <div className="activities-detail-info-placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
            >
                {renderRows}
            </ContentLoader>
        </div>
    );
});

export default ImagesLoader;
